import React from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from 'semantic-ui-react';
import ReactCountryFlag from 'react-country-flag';

const CountryPicker = ({ locations, name, id, onChange, error, defaultValue, disabled }) => {
  const countryOptions = locations
    .map(({ name, short_name, id }) => {
      return {
        key: id,
        value: id,
        text: name,
        flag: <ReactCountryFlag countryCode={short_name} style={{ marginRight: '0.5em' }} />
      };
    });

  return (
    <Dropdown
      name={name}
      id={id}
      onChange={onChange}
      error={error}
      defaultValue={defaultValue}
      fluid
      search
      selection
      disabled={disabled}
      options={countryOptions}
    />
  );
};

CountryPicker.propTypes = {
  locations: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    short_name: PropTypes.string
  })).isRequired,
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  error: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  defaultValue: PropTypes.number,
  disabled: PropTypes.bool
};

CountryPicker.defaultProps = {
  error: null,
  defaultValue: null,
  disabled: false
};

export default CountryPicker;
