import React from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import RequestErrorMessage from '../../components/common/requestErrorMessage';
import TransformationPublishForm from '../../components/forms/transformationForm';
import Loading from '../../components/common/loading';
import useAPI from '../../hooks/api';
import useLocale from '../../hooks/i18n';
import useTitle from '../../hooks/title';
import { TRANSFORMATION_STATUS } from '../../constants/transformation';
import useOnUnmount from '../../hooks/onUnmount';

const EditContributionPage = () => {
  const { t } = useLocale();
  const { id } = useParams();
  useTitle(t('page_titles.edit_contribution', { id }));
  const { client, queryClient, getFullResponseErrorMessage } = useAPI();
  const transformationRequest =
    client.transformation.getUnpublishedTransformationById(id);
  const curationRequest =
    client.transformation.getCurationsForUnpublishedTransformationById(id, 1);
  const {
    isLoading: transformationIsLoading,
    error: transformationError,
    data: transformation
  } = useQuery(transformationRequest.key, transformationRequest.fn);
  const {
    isLoading: curationIsLoading,
    error: curationError,
    data: curation
  } = useQuery({
    queryFn: curationRequest.fn,
    queryKey: curationRequest.key,
    enabled: transformation?.status === TRANSFORMATION_STATUS.CHANGES_REQUESTED
  });

  useOnUnmount(() => {
    queryClient.removeQueries(transformationRequest.key);
  });

  if (
    transformationIsLoading ||
    (curationIsLoading &&
      transformation?.status === TRANSFORMATION_STATUS.CHANGES_REQUESTED)
  ) {
    return <Loading fullscreen />;
  }

  if (transformationError) {
    return (
      <RequestErrorMessage
        header={t('edit_contribution.error.header')}
        error={getFullResponseErrorMessage(transformationError)}
      />
    );
  }

  if (curationError) {
    return (
      <RequestErrorMessage
        header={t('edit_contribution.error.header')}
        error={getFullResponseErrorMessage(curationError)}
      />
    );
  }

  if (
    transformation.status !== TRANSFORMATION_STATUS.DRAFT &&
    transformation.status !== TRANSFORMATION_STATUS.CHANGES_REQUESTED
  ) {
    return (
      <RequestErrorMessage
        header={t('edit_contribution.error.header')}
        error={t('edit_contribution.error.status.description')}
      />
    );
  }

  return (
    <TransformationPublishForm
      transformationId={id}
      initialData={transformation.transformation_obj}
      metadata={transformation.metadata}
      curation={curation ? curation[0] : null}
    />
  );
};

export default EditContributionPage;
