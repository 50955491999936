import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import {
  faMagnifyingGlass,
  faShareNodes,
  faUpload,
  faBars,
  faEnvelope,
  faLocationDot,
  faPhone,
  faEarthAmericas,
  faHeart,
  faX,
  faXmark,
  faArrowRight,
  faArrowLeft,
  faGlobe,
  faPen
} from '@fortawesome/free-solid-svg-icons';

export const loadIcons = () => {
  library.add(
    fab,
    faMagnifyingGlass,
    faShareNodes,
    faUpload,
    faBars,
    faEnvelope,
    faLocationDot,
    faPhone,
    faEarthAmericas,
    faHeart,
    faX,
    faXmark,
    faArrowRight,
    faArrowLeft,
    faGlobe,
    faPen
  );
};
