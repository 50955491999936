import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Segment } from 'semantic-ui-react';
import { RegulationsFormView } from '../../transformationForm';
import AutoResizeTextArea from '../../../common/form/autoResizeTextArea';
import BooleanFormField from '../../../common/form/booleanFormField';
import useLocale from '../../../../hooks/i18n';
import useFormHelper from '../../../../hooks/formHelper';

const TEXT_AREA_LABEL = 'comment_regulations';
const PUBLISH_LABEL = 'publish';

const CurateRegulationsFormView = ({ transformationForm, form }) => {
  const { t } = useLocale();
  const {
    handleChangeWithValidation,
    handleChangeNoValidation,
    handleBlurValidate,
    resolveError
  } = useFormHelper(form);
  const { getValues } = form;

  return (
    <Fragment>
      <RegulationsFormView
        renderAs={Segment}
        editable={false}
        form={transformationForm}
      />

      <AutoResizeTextArea
        name={TEXT_AREA_LABEL}
        id={TEXT_AREA_LABEL}
        label={t('curate_contribution.form.comment_regulations.label')}
        onChange={handleChangeNoValidation}
        onBlur={handleBlurValidate}
        error={resolveError(TEXT_AREA_LABEL)}
        defaultValue={getValues(TEXT_AREA_LABEL)}
      />

      <hr />

      <BooleanFormField
        name={PUBLISH_LABEL}
        id={PUBLISH_LABEL}
        label={t('curate_contribution.form.publish.label')}
        onChange={handleChangeWithValidation}
        error={resolveError(PUBLISH_LABEL)}
        defaultValue={getValues(PUBLISH_LABEL)}
      />
    </Fragment>
  );
};

CurateRegulationsFormView.propTypes = {
  transformationForm: PropTypes.shape({
    formState: PropTypes.shape({
      isSubmitting: PropTypes.bool
    }),
    handleSubmit: PropTypes.func,
    getValues: PropTypes.func
  }).isRequired,
  form: PropTypes.shape({
    formState: PropTypes.shape({
      isSubmitting: PropTypes.bool
    }),
    handleSubmit: PropTypes.func,
    getValues: PropTypes.func
  }).isRequired
};

export default CurateRegulationsFormView;
