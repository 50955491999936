import React from 'react';
import CardContainer from '../../ui/cardContainer';
import { Grid, Image } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { DYNAMIC_PUBLIC_ROUTES } from '../../../constants/routes';
import PropTypes from 'prop-types';
import TransformationLikeItem from '../transformationLikes';
import useLocale from '../../../hooks/i18n';

const TransformationViewSidebarHeader = ({
  className,
  transformationTitle,
  producingSpeciesName,
  authorUsername,
  authorPicture,
  authorCompleteName,
  likes,
  userLiked,
  id
}) => {
  const { t } = useLocale();
  return (
    <CardContainer className={`transformation-header ${className}`}>
      <Grid>
        <Grid.Row>
          <Grid.Column width={13}>
            <h1>{transformationTitle}</h1>
          </Grid.Column>
          <Grid.Column width={3} verticalAlign="middle" className="bookmark-column">
            <TransformationLikeItem type="bookmark" likes={likes.bookmark} liked={userLiked.bookmark} id={id} />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column className="origin">
            <p>
              <span className="from">
                {t('transformation_view.sidebar_header.from')}
              </span>
              {producingSpeciesName}
            </p>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row verticalAlign="middle" className="published-by">
          <Grid.Column>
            <div className="author">
              <span className="header">
                {t('transformation_view.sidebar_header.published_by')}
              </span>
              <div className="author-avatar">
                <Link to={DYNAMIC_PUBLIC_ROUTES.userProfile(authorUsername)}>
                  <Image src={authorPicture} avatar />
                  <span>{authorCompleteName}</span>
                </Link>
              </div>
            </div>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row
          columns="4"
          textAlign="center"
          className="crowdsourcing-likes"
        >
          <Grid.Column>
            <TransformationLikeItem type="technically_viable" likes={likes.technically_viable} liked={userLiked.technically_viable} id={id} />
          </Grid.Column>
          <Grid.Column>
            <TransformationLikeItem type="economically_viable" likes={likes.economically_viable} liked={userLiked.economically_viable} id={id} />
          </Grid.Column>
          <Grid.Column>
            <TransformationLikeItem type="environmentally_viable" likes={likes.environmentally_viable} liked={userLiked.environmentally_viable} id={id} />
          </Grid.Column>
          <Grid.Column>
            <TransformationLikeItem type="socially_viable" likes={likes.socially_viable} liked={userLiked.socially_viable} id={id} />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </CardContainer>
  );
};

TransformationViewSidebarHeader.propTypes = {
  className: PropTypes.string,
  transformationTitle: PropTypes.string.isRequired,
  producingSpeciesName: PropTypes.string.isRequired,
  authorUsername: PropTypes.string.isRequired,
  authorPicture: PropTypes.string.isRequired,
  authorCompleteName: PropTypes.string.isRequired,
  likes: PropTypes.shape({
    bookmark: PropTypes.number,
    economically_viable: PropTypes.number,
    environmentally_viable: PropTypes.number,
    socially_viable: PropTypes.number,
    technically_viable: PropTypes.number
  }).isRequired,
  userLiked: PropTypes.shape({
    bookmark: PropTypes.bool,
    economically_viable: PropTypes.bool,
    environmentally_viable: PropTypes.bool,
    socially_viable: PropTypes.bool,
    technically_viable: PropTypes.bool
  }).isRequired,
  id: PropTypes.number.isRequired
};

TransformationViewSidebarHeader.defaultProps = {
  className: ''
};

export default TransformationViewSidebarHeader;
