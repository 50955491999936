import React from 'react';
import PropTypes from 'prop-types';
import TransformationItemContainer from '../TranformationItemContainer';
import ImageSection from '../sections/ImageSection';
import MainSection from '../sections/MainSection';

const NonEditableUnpublishedTransformationItem = ({ aggregatedTransformation }) => {
  const { metadata, author, transformation_obj } = aggregatedTransformation;

  return (
    <TransformationItemContainer>
      <ImageSection
        picture={metadata.picture}
      />
      <MainSection
        title={transformation_obj.basic_information?.producing_species_name}
        subtitle={transformation_obj.basic_information?.organic_material_name}
        description={transformation_obj.basic_information?.brief_biomass_description}
        author={author}
      />
    </TransformationItemContainer>
  );
};

NonEditableUnpublishedTransformationItem.propTypes = {
  aggregatedTransformation: PropTypes.shape({
    transformation_obj: PropTypes.shape({
      basic_information: PropTypes.shape({
        producing_species_name: PropTypes.string,
        organic_material_name: PropTypes.string,
        brief_biomass_description: PropTypes.string
      })
    }).isRequired,
    author: PropTypes.shape({
      family_name: PropTypes.string.isRequired,
      given_name: PropTypes.string.isRequired,
      picture: PropTypes.string.isRequired,
      username: PropTypes.string.isRequired
    }).isRequired,
    metadata: PropTypes.shape({
      picture: PropTypes.string.isRequired
    }).isRequired
  }).isRequired
};

export default NonEditableUnpublishedTransformationItem;
