import React from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { AppContextProvider } from './context/AppContext';
import { I18nContextProvider } from './context/i18nContext';
import Router from './router';
import { loadIcons } from './utils/icons';

loadIcons();

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      staleTime: 5 * 60 * 1000,
      retry: false
    }
  }
});

const App = () => {
  return (
    <AppContextProvider>
      <I18nContextProvider>
        <QueryClientProvider client={queryClient}>
          <Router />
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </I18nContextProvider>
    </AppContextProvider>
  );
};

export default App;
