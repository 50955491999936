import Cookies from 'js-cookie';

const cookieOptions = {
  path: '/',
  expires: null,
  domain: window.location.hostname,
  // secure: window.location.hostname !== 'localhost',
  secure: false, // Need to remove this once we have the actual domain with HTTPS.
  sameSite: 'strict'
};

const KEY_PREFIX = '_CognitoAuth';

const resolveKey = (key) => {
  return `${KEY_PREFIX}${key}`;
};

class SessionCookieStorage {
  static syncPromise = Promise.resolve();

  static setItem(key, value) {
    Cookies.set(resolveKey(key), value, cookieOptions);
    return value;
  }

  static getItem(key) {
    return Cookies.get(resolveKey(key), cookieOptions);
  }

  static removeItem(key) {
    Cookies.remove(resolveKey(key), cookieOptions);
  }

  static clear() {
    Object.keys(Cookies.get())
      .filter((key) => key.startsWith(KEY_PREFIX))
      .forEach((key) => {
        SessionCookieStorage.removeItem(key);
      });
  }

  static sync() {
    return SessionCookieStorage.syncPromise;
  }
}

export const shouldReadFromCookieStorage = () => {
  return Object.keys(Cookies.get()).filter((key) => key.startsWith(KEY_PREFIX)).length > 0;
};

export default SessionCookieStorage;
