/* eslint-disable max-lines */
import React from 'react';
import PropTypes from 'prop-types';
import { Container, Form, Icon, Popup } from 'semantic-ui-react';
import PopupFormFieldInput from '../../../common/form/popupFormFieldInput';
import BubbleFormField from '../../../common/form/bubbleFormField';
import PopupFormFieldArea from '../../../common/form/popupFormFieldArea';
import CountryPicker from '../../../common/form/countryPicker';
import useLocale from '../../../../hooks/i18n';
import useFormHelper from '../../../../hooks/formHelper';
import PopupFormFieldSelect from '../../../common/form/popupFormFieldSelect';

const FORM_NAMES = {
  producing_species_name: 'basic_information.producing_species_name',
  common_names: 'basic_information.common_names',
  biomass_class: 'basic_information.biomass_class',
  organic_material_name: 'basic_information.organic_material_name',
  source_type: 'basic_information.id_source_type',
  source_class: 'basic_information.id_source_class',
  source_sub_class: 'basic_information.id_source_sub_class',
  location: 'basic_information.id_location',
  brief_biomass_description: 'basic_information.brief_biomass_description',
  current_uses_or_transformations: 'basic_information.current_uses_or_transformations',
  current_negative_impact: 'basic_information.current_negative_impact',
  trl_eu: 'basic_information.id_trl_eu'
};

const BasicInfoFormView = ({
  renderAs,
  editable,
  form,
  source_types,
  source_classes,
  source_sub_classes,
  trl_eu,
  locations
}) => {
  const { t } = useLocale();
  const { resolveError, handleChangeWithValidation } = useFormHelper(form);
  const { getValues } = form;
  const ParentComponent = renderAs;

  const biomassClassOptions = [
    {
      key: 'n',
      value: 'N',
      text: t('transformation.form.basic_information.biomass_class.options.natural')
    },
    {
      key: 'r',
      value: 'R',
      text: t('transformation.form.basic_information.biomass_class.options.residual')
    },
    {
      key: 'p',
      value: 'P',
      text: t('transformation.form.basic_information.biomass_class.options.produced')
    }
  ];

  const typeOptions = source_types.map(({ id, type }) => {
    return {
      key: id,
      value: id,
      text: type
    };
  });
  const classOptions = source_classes.map(({ id, class: text }) => {
    return {
      key: id,
      value: id,
      text
    };
  });
  const subClassOptions = source_sub_classes.map(({ id, sub_class }) => {
    return {
      key: id,
      value: id,
      text: sub_class
    };
  });
  const trlEuOptions = trl_eu.map(({ id, name }) => {
    return {
      key: id,
      value: id,
      text: name
    };
  });


  return (
    <ParentComponent className="transformation-card basic-info-form">
      <Container fluid={renderAs !== Form}>
        <PopupFormFieldInput
          name={FORM_NAMES.producing_species_name}
          id={FORM_NAMES.producing_species_name}
          label={t('transformation.form.basic_information.producing_species_name.label')}
          hint={t('transformation.form.basic_information.producing_species_name.hint')}
          hintExample={t('transformation.form.basic_information.producing_species_name.hint.example')}
          onChange={handleChangeWithValidation}
          error={resolveError(FORM_NAMES.producing_species_name)}
          defaultValue={getValues(FORM_NAMES.producing_species_name)}
          disabled={!editable}
        />

        <Form.Group inline widths="equal">
          <BubbleFormField
            name={FORM_NAMES.common_names}
            id={FORM_NAMES.common_names}
            label={t('transformation.form.basic_information.common_names.label')}
            hint={t('transformation.form.basic_information.common_names.hint')}
            hintExample={t('transformation.form.basic_information.common_names.hint.example')}
            onChange={handleChangeWithValidation}
            error={resolveError(FORM_NAMES.common_names)}
            defaultValue={getValues(FORM_NAMES.common_names)}
            disabled={!editable}
          />

          <PopupFormFieldSelect
            name={FORM_NAMES.biomass_class}
            id={FORM_NAMES.biomass_class}
            label={t('transformation.form.basic_information.biomass_class.label')}
            hint={t('transformation.form.basic_information.biomass_class.hint')}
            options={biomassClassOptions}
            onChange={handleChangeWithValidation}
            error={resolveError(FORM_NAMES.biomass_class)}
            defaultValue={getValues(FORM_NAMES.biomass_class)}
            disabled={!editable}
          />
        </Form.Group>

        <Form.Group inline widths="equal">
          <PopupFormFieldInput
            name={FORM_NAMES.organic_material_name}
            id={FORM_NAMES.organic_material_name}
            label={t('transformation.form.basic_information.organic_material_name.label')}
            hint={t('transformation.form.basic_information.organic_material_name.hint')}
            hintExample={t('transformation.form.basic_information.organic_material_name.hint.example')}
            onChange={handleChangeWithValidation}
            error={resolveError(FORM_NAMES.organic_material_name)}
            defaultValue={getValues(FORM_NAMES.organic_material_name)}
            disabled={!editable}
          />

          <PopupFormFieldSelect
            label={t('transformation.form.basic_information.source_type.label')}
            hint={t('transformation.form.basic_information.source_type.hint')}
            name={FORM_NAMES.source_type}
            id={FORM_NAMES.source_type}
            options={typeOptions}
            onChange={handleChangeWithValidation}
            error={resolveError(FORM_NAMES.source_type)}
            defaultValue={getValues(FORM_NAMES.source_type)}
            disabled={!editable}
          />
        </Form.Group>

        <Form.Group inline widths="equal">
          <PopupFormFieldSelect
            label={t('transformation.form.basic_information.source_class.label')}
            hint={t('transformation.form.basic_information.source_class.hint')}
            name={FORM_NAMES.source_class}
            id={FORM_NAMES.source_class}
            options={classOptions}
            onChange={handleChangeWithValidation}
            error={resolveError(FORM_NAMES.source_class)}
            defaultValue={getValues(FORM_NAMES.source_class)}
            disabled={!editable}
          />

          <PopupFormFieldSelect
            label={t('transformation.form.basic_information.source_sub_class.label')}
            hint={t('transformation.form.basic_information.source_sub_class.hint')}
            name={FORM_NAMES.source_sub_class}
            id={FORM_NAMES.source_sub_class}
            options={subClassOptions}
            onChange={handleChangeWithValidation}
            error={resolveError(FORM_NAMES.source_sub_class)}
            defaultValue={getValues(FORM_NAMES.source_sub_class)}
            disabled={!editable}
          />
        </Form.Group>

        <Form.Field>
          <label htmlFor={FORM_NAMES.location}>{t('transformation.form.basic_information.location.label')}</label>
          <Popup trigger={<Icon name="help circle" />} position="right center">
            <p>{t('transformation.form.basic_information.location.hint')}</p>
          </Popup>
          <CountryPicker
            name={FORM_NAMES.location}
            id={FORM_NAMES.location}
            locations={locations}
            onChange={handleChangeWithValidation}
            error={resolveError(FORM_NAMES.location)}
            defaultValue={getValues(FORM_NAMES.location)}
            disabled={!editable}
          />
        </Form.Field>

        <PopupFormFieldArea
          label={t('transformation.form.basic_information.brief_biomass_description.label')}
          hint={t('transformation.form.basic_information.brief_biomass_description.hint')}
          name={FORM_NAMES.brief_biomass_description}
          id={FORM_NAMES.brief_biomass_description}
          onChange={handleChangeWithValidation}
          error={resolveError(FORM_NAMES.brief_biomass_description)}
          defaultValue={getValues(FORM_NAMES.brief_biomass_description)}
          disabled={!editable}
        />

        <BubbleFormField
          name={FORM_NAMES.current_uses_or_transformations}
          id={FORM_NAMES.current_uses_or_transformations}
          label={t('transformation.form.basic_information.current_uses_or_transformations.label')}
          hint={t('transformation.form.basic_information.current_uses_or_transformations.hint')}
          hintExample={t('transformation.form.basic_information.current_uses_or_transformations.hint.example')}
          onChange={handleChangeWithValidation}
          error={resolveError(FORM_NAMES.current_uses_or_transformations)}
          defaultValue={getValues(FORM_NAMES.current_uses_or_transformations)}
          disabled={!editable}
        />

        <PopupFormFieldArea
          name={FORM_NAMES.current_negative_impact}
          id={FORM_NAMES.current_negative_impact}
          label={t('transformation.form.basic_information.current_negative_impact.label')}
          hint={t('transformation.form.basic_information.current_negative_impact.hint')}
          onChange={handleChangeWithValidation}
          error={resolveError(FORM_NAMES.current_negative_impact)}
          defaultValue={getValues(FORM_NAMES.current_negative_impact)}
          disabled={!editable}
        />

        <PopupFormFieldSelect
          label={t('transformation.form.basic_information.trl_eu.label')}
          hint={t('transformation.form.basic_information.trl_eu.hint')}
          name={FORM_NAMES.trl_eu}
          id={FORM_NAMES.trl_eu}
          options={trlEuOptions}
          onChange={handleChangeWithValidation}
          error={resolveError(FORM_NAMES.trl_eu)}
          defaultValue={getValues(FORM_NAMES.trl_eu)}
          disabled={!editable}
        />
      </Container>
    </ParentComponent>
  );
};

BasicInfoFormView.propTypes = {
  renderAs: PropTypes.oneOfType([PropTypes.string, PropTypes.elementType]),
  editable: PropTypes.bool,
  source_types: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      type: PropTypes.string
    })
  ).isRequired,
  source_classes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      class: PropTypes.string
    })
  ).isRequired,
  source_sub_classes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      sub_class: PropTypes.string
    })
  ).isRequired,
  trl_eu: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string
    })
  ).isRequired,
  locations: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      short_name: PropTypes.string
    })
  ).isRequired,
  form: PropTypes.shape({
    formState: PropTypes.shape({
      isSubmitting: PropTypes.bool
    }),
    handleSubmit: PropTypes.func,
    getValues: PropTypes.func
  }).isRequired
};

BasicInfoFormView.defaultProps = {
  renderAs: Form,
  editable: true
};

export default BasicInfoFormView;
