import React, { Fragment, useState } from 'react';
import { Input } from 'semantic-ui-react';
import useLocale from '../../../hooks/i18n';
import PropTypes from 'prop-types';
import useQueryParams from '../../../hooks/queryParams';
import { PUBLIC_ROUTES } from '../../../constants/routes';
import { KEYCODES } from '../../../constants/codes';
import useAPI from '../../../hooks/api';

const SearchTransformationInput = ({ className, initialQuery, searchButton, ...props }) => {
  const { t } = useLocale();
  const { navigateWithQueryParams } = useQueryParams();
  const { queryClient } = useAPI();
  const [query, setQuery] = useState(initialQuery);
  const SearchButtonComponent = searchButton;

  const runSearch = async () => {
    // Clear the previous searches.
    await queryClient.removeQueries({
      predicate: (query) => {
        const key = ['transformations', 'published', 'search'];
        return key.every((e) => query.queryKey.includes(e));
      }
    });
    navigateWithQueryParams(PUBLIC_ROUTES.explore, {
      q: query
    });
  };

  const handleQueryChange = ({ target: { value } }) => {
    setQuery(value);
  };

  const handleSearch = ({ keyCode }) => {
    if (keyCode === KEYCODES.ENTER) {
      runSearch();
    }
  };

  return (
    <Fragment>
      <Input
        className={`search-input ${className}`}
        icon="search"
        iconPosition="left"
        placeholder={t('navbar.search.placeholder')}
        value={query}
        onChange={handleQueryChange}
        onKeyDown={handleSearch}
        {...props}
      />

      {
        SearchButtonComponent &&
          <SearchButtonComponent onClick={runSearch} />
      }
    </Fragment>
  );
};

SearchTransformationInput.propTypes = {
  className: PropTypes.string,
  initialQuery: PropTypes.string,
  searchButton: PropTypes.func
};

SearchTransformationInput.defaultProps = {
  className: '',
  initialQuery: '',
  searchButton: null
};

export default SearchTransformationInput;
