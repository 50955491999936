import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Form, Icon, Popup, Label } from 'semantic-ui-react';

const SEPARATOR = ',';

const createBubbles = (text) => {
  return text.split(SEPARATOR)
    .map((t) => t.trim())
    .filter((t) => t.length > 0);
};

const BubbleFormField = ({ label, id, name, hint, hintExample, color, onChange, defaultValue, ...props }) => {
  const defaultValueAsString = Array.isArray(defaultValue) ? defaultValue.join(SEPARATOR) : defaultValue;

  const [text, setText] = useState(defaultValueAsString);
  const bubbles = createBubbles(text);

  const handleTextChange = (event, { name, value }) => {
    setText(value);
    onChange(event, {
      name,
      value: createBubbles(value) // Kinda dumb to recompute this but useEffect kept causing infinite loops, and I couldn't find a better way.
    });
  };

  return (
    <div className="bubble-form-field field">
      <div className="field hint-container">
        <label>{label}</label>
        {hint && (
          <Popup trigger={<Icon name="help circle" />} position="right center">
            <p>{hint}</p>
            {hintExample && <p>{hintExample}</p>}
          </Popup>
        )}
      </div>
      <div className="bubble-form-field-input">
        <Form.Input
          fluid
          onChange={handleTextChange}
          value={text}
          id={id}
          name={name}
          {...props}
        />
      </div>
      {
        bubbles.length > 0 &&
        <div className="bubble-holder">
          {
            bubbles.map((value, index) => (
              <Label key={index} color={color}>
                {value}
              </Label>
            ))
          }
        </div>
      }
    </div>
  );
};

BubbleFormField.propTypes = {
  label: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  hint: PropTypes.string,
  hintExample: PropTypes.string,
  color: PropTypes.oneOf(['red', 'orange', 'yellow', 'olive', 'green', 'teal', 'blue', 'violet', 'purple', 'pink', 'brown', 'grey', 'black']),
  onChange: PropTypes.func,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)])
};

BubbleFormField.defaultProps = {
  hint: null,
  hintExample: null,
  color: 'blue',
  onChange: () => false,
  defaultValue: ''
};


export default BubbleFormField;
