import React from 'react';
import { Button, Container, Form, Message, Segment } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import useFormHelper from '../../../hooks/formHelper';
import { Link } from 'react-router-dom';
import { PROTECTED_ROUTES } from '../../../constants/routes';
import useLocale from '../../../hooks/i18n';

const BecomeCuratorFormView = ({
  form,
  onSubmit,
  error,
  success,
  disabled
}) => {
  const { t } = useLocale();
  const { handleChangeNoValidation, handleBlurValidate, resolveError } =
    useFormHelper(form);
  const {
    handleSubmit,
    formState: { isSubmitting }
  } = form;

  return (
    <Container text className="become-curator-container">
      <Form
        className="curator_request_form"
        onSubmit={handleSubmit(onSubmit)}
        error={!!error}
        success={success}
      >
        <Message
          error
          header={t('become_curator.form.errors.header')}
          content={error}
        />
        <Message success header={t('become_curator.form.success.header')} />
        <Form.TextArea
          id="description"
          name="description"
          label={t('become_curator.form.description.label')}
          onChange={handleChangeNoValidation}
          onBlur={handleBlurValidate}
          error={resolveError('description')}
          disabled={disabled}
        />
        <Button
          className="submit-btn"
          type="submit"
          color="green"
          loading={isSubmitting}
          disabled={disabled}
        >
          {t('become_curator.form.actions.submit')}
        </Button>
      </Form>
      <Segment basic textAlign="center">
        <Link className="back-to-applications" to={PROTECTED_ROUTES.becomeCurator}>{t('become_curator.link.back_to_applications')}</Link>
      </Segment>
    </Container>
  );
};

BecomeCuratorFormView.propTypes = {
  form: PropTypes.shape({
    formState: PropTypes.shape({
      isSubmitting: PropTypes.bool
    }),
    handleSubmit: PropTypes.func
  }).isRequired,
  onSubmit: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  error: PropTypes.string,
  success: PropTypes.bool
};

BecomeCuratorFormView.defaultProps = {
  error: null,
  success: null
};

export default BecomeCuratorFormView;
