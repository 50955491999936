import React from 'react';
import PropTypes from 'prop-types';
import useLocale from '../../../hooks/i18n';
import useTitle from '../../../hooks/title';
import PageDelimiter from '../../common/pageDelimiter';
import { Grid } from 'semantic-ui-react';
import AdminDashboardMenu from '../adminDashboardMenu';

const AdminPageWrapper = ({ page, children }) => {
  const { t } = useLocale();
  useTitle(t('page_titles.admin_dashboard'));

  return (
    <PageDelimiter>
      <Grid className="admin-dashboard-page">
        <Grid.Column computer={5} tablet={16} mobile={16} className="menu-column">
          <AdminDashboardMenu activeItem={page} />
        </Grid.Column>
        <Grid.Column computer={11} tablet={16} mobile={16} className="tab-column">
          {children}
        </Grid.Column>
      </Grid>
    </PageDelimiter>
  );
};

AdminPageWrapper.propTypes = {
  page: PropTypes.oneOf(['dashboard', 'curator_requests', 'resource_management']).isRequired,
  children: PropTypes.node.isRequired
};

export default AdminPageWrapper;
