import React from 'react';
import AdminPageWrapper from '../../components/admin/adminPageWrapper';
import useLocale from '../../hooks/i18n';
import useAPI from '../../hooks/api';
import usePageQuery from '../../hooks/pageQuery';
import { useQuery } from '@tanstack/react-query';
import Loading from '../../components/common/loading';
import RequestErrorMessage from '../../components/common/requestErrorMessage';
import { PaginatedContainer } from '../../components/common/pagination';
import CardContainer from '../../components/ui/cardContainer';
import CuratorRequestLogic from './CuratorRequestLogic';
import { Icon, Message } from 'semantic-ui-react';

const AdminCuratorRequestsPage = () => {
  const { client, getFullResponseErrorMessage } = useAPI();
  const { currentPage, updatePage } = usePageQuery();
  const request = client.application.getAllCuratorApplications(true, currentPage);
  const {
    isLoading,
    error,
    data: paginatedCuratorApplications
  } = useQuery(request.key, request.fn);
  const { t } = useLocale();

  if (isLoading) {
    return <Loading fullscreen />;
  }

  if (error) {
    return (
      <RequestErrorMessage
        header={t('admin.curator_requests.error.header')}
        error={getFullResponseErrorMessage(error)}
      />
    );
  }

  const { result: curatorApplications, pagination } = paginatedCuratorApplications;

  return (
    <AdminPageWrapper page="curator_requests">
      <div className="admin-curator-requests">
        <h1 className="curator-requests-header">
          {t('admin.curator_requests.header')}
        </h1>
        {
          curatorApplications.length !== 0 ? (
            <PaginatedContainer as={CardContainer} className="card-container" pagination={pagination} onChange={updatePage}>
              {curatorApplications.map((app) => {
                return (
                  <CuratorRequestLogic
                    key={app.id}
                    id={app.id}
                    role={app.applicant.role}
                    description={app.description}
                    picture={app.applicant.picture}
                    date={app.created_at}
                    username={app.applicant.username}
                  />
                );
              })}
            </PaginatedContainer>
          ) : (
            <Message info>
              <Message.Header>
                <Icon name="info circle" color="green" size="large" />
                <span>{t('admin.curator_request.active.empty.header')}</span>
              </Message.Header>
              <p>{t('admin.curator_request.active.empty.description')}</p>
            </Message>
          )
        }
      </div>
    </AdminPageWrapper>
  );
};

export default AdminCuratorRequestsPage;
