import md5 from 'md5';
import { DEFAULT_USER_IMAGE } from '../constants/assets';

const GRAVATAR_BASE_URL = 'https://www.gravatar.com';
const GRAVATAR_KEYS = {
  size: 's',
  default: 'd'
};
const GRAVATAR_DEFAULT_OPTIONS = {
  size: 512,
  default: DEFAULT_USER_IMAGE
};

export const getGravatarURL = (email, options = GRAVATAR_DEFAULT_OPTIONS) => {
  if (!options.size) {
    options.size = GRAVATAR_DEFAULT_OPTIONS.size;
  }
  if (!options.default) {
    options.default = GRAVATAR_DEFAULT_OPTIONS.default;
  }
  const keyUpdatedOptions = Object.keys(options).reduce((acc, key) => {
    const newKey = GRAVATAR_KEYS[key];
    acc[newKey] = options[key];
    return acc;
  }, {});
  const searchParams = new URLSearchParams(keyUpdatedOptions);

  const address = email.toString().trim().toLowerCase();
  const hash = md5(address);

  return `${GRAVATAR_BASE_URL}/avatar/${hash}.jpg?${searchParams.toString()}`;
};
