import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import Joi from 'joi';
import { joiResolver } from '@hookform/resolvers/joi';
import UserSettingsFormView from './UserSettingsFormView';
import PropTypes from 'prop-types';
import useAuth from '../../../hooks/auth';

const VALID_USERNAME_REGEX = /^[\w._-]*$/;
const FORBIDDEN_USERNAMES = ['me'];
// See: https://en.wikipedia.org/wiki/E.164
const PHONE_E164_REGEX = /^\+[1-9]\d{1,14}$/;

const SelfUserUpdateSchema = Joi.object({
  username: Joi.string()
    .min(3)
    .max(20)
    .disallow(...FORBIDDEN_USERNAMES)
    .pattern(VALID_USERNAME_REGEX)
    .lowercase()
    .trim()
    .messages({
      'string.min': 'settings.user.errors.username.length',
      'string.max': 'settings.user.errors.username.length',
      'any.invalid': 'settings.user.errors.username.disallowed',
      'string.pattern.base': 'settings.user.errors.username.pattern',
      'string.empty': 'settings.user.errors.username.required'
    }),
  given_name: Joi.string().min(1).max(45).trim().messages({
    'string.min': 'settings.user.errors.given_name.length',
    'string.max': 'settings.user.errors.given_name.length',
    'string.empty': 'settings.user.errors.given_name.required'
  }),
  family_name: Joi.string().min(1).max(45).trim().messages({
    'string.max': 'settings.user.errors.family_name.length',
    'string.min': 'settings.user.errors.family_name.length',
    'string.empty': 'settings.user.errors.family_name.required'
  }),
  picture: Joi.string().trim(),
  email_notifications_enabled: Joi.bool(),
  institution_name: Joi.string().max(256).messages({
    'string.max': 'settings.user.errors.institution_name.max',
    'string.empty': 'settings.user.errors.institution_name.required'
  }),
  institution_position: Joi.string().max(256).messages({
    'string.max': 'settings.user.errors.institution_position.max',
    'string.empty': 'settings.user.errors.institution_position.required'
  }),
  professional_affiliation: Joi.string().max(256).messages({
    'string.max': 'settings.user.errors.professional_affiliation.max',
    'string.empty': 'settings.user.errors.professional_affiliation.required'
  }),
  location_country: Joi.string().max(256).messages({
    'string.max': 'settings.user.errors.location_country.max',
    'string.empty': 'settings.user.errors.location_country.required'
  }),
  location_province: Joi.string().max(256).messages({
    'string.max': 'settings.user.errors.location_province.max',
    'string.empty': 'settings.user.errors.location_province.required'
  }),
  location_city: Joi.string().max(256).messages({
    'string.max': 'settings.user.errors.location_city.max',
    'string.empty': 'settings.user.errors.location_city.required'
  }),
  phone: Joi.string().pattern(PHONE_E164_REGEX).allow(null).allow('').messages({
    'string.pattern.base': 'settings.user.errors.phone.pattern'
  }),
  socials_website: Joi.string().uri().max(256).allow(null).allow('').messages({
    'string.uri': 'settings.user.errors.socials_website.uri',
    'string.max': 'settings.user.errors.socials_website.max'
  }),
  socials_facebook: Joi.string().max(256).allow(null).allow('').messages({
    'string.max': 'settings.user.errors.socials_facebook.max'
  }),
  socials_instagram: Joi.string().max(256).allow(null).allow('').messages({
    'string.max': 'settings.user.errors.socials_instagram.max'
  }),
  socials_twitter: Joi.string().max(256).allow(null).allow('').messages({
    'string.max': 'settings.user.errors.socials_twitter.max'
  }),
  socials_linkedin: Joi.string().max(256).allow(null).allow('').messages({
    'string.max': 'settings.user.errors.socials_linkedin.max'
  }),
  bio: Joi.string().max(1000).allow(null).allow('').messages({
    'string.max': 'settings.user.errors.bio.max'
  })
});

const UserSettingsFormLogic = ({ onSubmit }) => {
  const { me } = useAuth();
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  const form = useForm({
    mode: 'onSubmit',
    resolver: joiResolver(SelfUserUpdateSchema)
  });

  const handleSubmit = async (data) => {
    setSuccess(false);
    try {
      const cleanData = {};
      for (const key in data) {
        if (data[key]) {
          cleanData[key] = data[key];
        }
      }
      if (Object.keys(cleanData).length > 0) {
        await(onSubmit(cleanData));
        setSuccess(true);
      }
    } catch (error) {
      setError(error);
      setSuccess(false);
    }
  };

  return (
    <UserSettingsFormView
      form={form}
      onSubmit={handleSubmit}
      user={me}
      error={error}
      success={success}
    />
  );
};

UserSettingsFormLogic.propTypes = {
  onSubmit: PropTypes.func.isRequired
};

export default UserSettingsFormLogic;
