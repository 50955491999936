import React from 'react';
import { Button } from 'semantic-ui-react';
import { useQuery } from '@tanstack/react-query';
import SearchTransformationInput from '../../components/ui/searchTransformationInput';
import Loading from '../../components/common/loading';
import RequestErrorMessage from '../../components/common/requestErrorMessage';
import { PaginatedContainer } from '../../components/common/pagination';
import TransformationItemGroup from '../../components/transformation/transformationItemGroup';
import useAPI from '../../hooks/api';
import useLocale from '../../hooks/i18n';
import useQueryParams from '../../hooks/queryParams';
import useTitle from '../../hooks/title';
import usePageQuery from '../../hooks/pageQuery';

const ExplorePage = () => {
  const { currentLocale, t } = useLocale();
  const { queryParams } = useQueryParams();
  const query = queryParams.get('q') ?? '';
  const { currentPage, updatePage } = usePageQuery();

  useTitle(query ?
    t('page_titles.explore.with_query', {
      query
    }) :
    t('page_titles.explore.no_query')
  );

  const paginatedContainer = ({ children }) => (
    <div className="explore-pagination-container">{children}</div>
  );

  const { client, getFullResponseErrorMessage } = useAPI();
  const request = client.transformation.searchPublishedTransformations(currentLocale, query, currentPage);
  const { isLoading, error, data: paginatedTransformations } = useQuery(request.key, request.fn);

  if (isLoading) {
    return <Loading fullscreen />;
  }

  if (error) {
    return (
      <RequestErrorMessage
        header={t('explore.errors.header')}
        error={getFullResponseErrorMessage(error)}
      />
    );
  }

  const { result: transformations, pagination } = paginatedTransformations;

  return (
    <div className="explore-page">
      <div className="search-container">
        <SearchTransformationInput
          className="search-input"
          initialQuery={query}
          searchButton={(buttonProps) => (
            <Button color="green" {...buttonProps}>
              {t('explore.search_button.label')}
            </Button>
          )}
        />
      </div>
      <div className="published-transformations">
        <h1 className="published-transformations__header">
          {t('explore.published_transformations.header')}
        </h1>

        <PaginatedContainer as={paginatedContainer} pagination={pagination} onChange={updatePage}>
          <TransformationItemGroup
            aggregatedTransformations={transformations}
            type="published"
            emptyHeader={t('explore.search.result.empty.header')}
            emptyDescription={t('explore.search.result.empty.description')}
          />
        </PaginatedContainer>
      </div>
    </div>
  );
};

export default ExplorePage;
