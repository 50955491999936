import ForgotPasswordForm from './ForgotPasswordForm';
import ForgotPasswordFormLogic from './ForgotPasswordFormLogic';
import ForgotPasswordFormView from './ForgotPasswordFormView';

export default ForgotPasswordForm;

export {
  ForgotPasswordFormLogic,
  ForgotPasswordFormView
};
