import React from 'react';
import PropTypes from 'prop-types';
import { Container, Form } from 'semantic-ui-react';
import BubbleFormField from '../../../common/form/bubbleFormField';
import YesNoDontKnowFormField from '../../../common/form/yesNoDontKnowFormField';
import DocumentaryEvidenceInput from '../DocumentaryEvidenceInput';
import useLocale from '../../../../hooks/i18n';
import useFormHelper from '../../../../hooks/formHelper';
import PopupFormFieldArea from '../../../common/form/popupFormFieldArea';

const FORM_NAMES = {
  brief_description: 'transformation_process.brief_description',
  evidences: 'transformation_process.evidences',
  developed_technologies: 'transformation_process.developed_technologies',
  products_or_services: 'transformation_process.products_or_services',
  industrial_applications: 'transformation_process.industrial_applications',
  associated_pollutants: 'transformation_process.associated_pollutants',
  patents_associated: 'transformation_process.patents_associated',
  licenses_emitted: 'transformation_process.licenses_emitted',
  free_access: 'transformation_process.free_access'
};

const TransformationProcessFormView = ({ renderAs, editable, form }) => {
  const { t } = useLocale();
  const { resolveError, handleChangeWithValidation } = useFormHelper(form);
  const { getValues } = form;
  const ParentComponent = renderAs;

  return (
    <ParentComponent className="transformation-card transformation-process-form">
      <Container fluid={renderAs !== Form}>
        <PopupFormFieldArea
          name={FORM_NAMES.brief_description}
          id={FORM_NAMES.brief_description}
          hint={t('transformation.form.transformation_process.brief_description_of_transformation_process.hint')}
          label={t('transformation.form.transformation_process.brief_description_of_transformation_process')}
          onChange={handleChangeWithValidation}
          error={resolveError(FORM_NAMES.brief_description)}
          defaultValue={getValues(FORM_NAMES.brief_description)}
          disabled={!editable}
        />

        <DocumentaryEvidenceInput
          hint={t('transformation.form.transformation_process.evidences.hint')}
          name={FORM_NAMES.evidences}
          form={form}
          disabled={!editable}
        />

        <BubbleFormField
          name={FORM_NAMES.developed_technologies}
          id={FORM_NAMES.developed_technologies}
          label={t('transformation.form.transformation_process.developed_technologies.label')}
          hint={t('transformation.form.transformation_process.developed_technologies.hint')}
          hintExample={t('transformation.form.transformation_process.developed_technologies.hint.example')}
          onChange={handleChangeWithValidation}
          error={resolveError(FORM_NAMES.developed_technologies)}
          defaultValue={getValues(FORM_NAMES.developed_technologies)}
          disabled={!editable}
        />

        <BubbleFormField
          name={FORM_NAMES.products_or_services}
          id={FORM_NAMES.products_or_services}
          label={t('transformation.form.transformation_process.products_or_services.label')}
          hint={t('transformation.form.transformation_process.products_or_services.hint')}
          hintExample={t('transformation.form.transformation_process.products_or_services.hint.example')}
          onChange={handleChangeWithValidation}
          error={resolveError(FORM_NAMES.products_or_services)}
          defaultValue={getValues(FORM_NAMES.products_or_services)}
          disabled={!editable}
        />

        <BubbleFormField
          name={FORM_NAMES.industrial_applications}
          id={FORM_NAMES.industrial_applications}
          label={t('transformation.form.transformation_process.industrial_applications.label')}
          hint={t('transformation.form.transformation_process.industrial_applications.hint')}
          hintExample={t('transformation.form.transformation_process.industrial_applications.hint.example')}
          onChange={handleChangeWithValidation}
          error={resolveError(FORM_NAMES.industrial_applications)}
          defaultValue={getValues(FORM_NAMES.industrial_applications)}
          disabled={!editable}
        />

        <BubbleFormField
          name={FORM_NAMES.associated_pollutants}
          id={FORM_NAMES.associated_pollutants}
          label={t('transformation.form.transformation_process.associated_pollutants.label')}
          hint={t('transformation.form.transformation_process.associated_pollutants.hint')}
          hintExample={t('transformation.form.transformation_process.associated_pollutants.hint.example')}
          onChange={handleChangeWithValidation}
          error={resolveError(FORM_NAMES.associated_pollutants)}
          defaultValue={getValues(FORM_NAMES.associated_pollutants)}
          disabled={!editable}
        />

        <YesNoDontKnowFormField
          name={FORM_NAMES.free_access}
          id={FORM_NAMES.free_access}
          label={t('transformation.form.transformation_process.free_access.label')}
          hint={t('transformation.form.transformation_process.free_access.hint')}
          yesLabel={t('transformation.form.ui.yes')}
          noLabel={t('transformation.form.ui.no')}
          dontKnowLabel={t('transformation.form.ui.dont.know')}
          onChange={handleChangeWithValidation}
          error={resolveError(FORM_NAMES.free_access)}
          defaultValue={getValues(FORM_NAMES.free_access)}
          disabled={!editable}
        />

        <YesNoDontKnowFormField
          name={FORM_NAMES.patents_associated}
          id={FORM_NAMES.patents_associated}
          label={t('transformation.form.transformation_process.patents_associated.label')}
          hint={t('transformation.form.transformation_process.patents_associated.hint')}
          yesLabel={t('transformation.form.ui.yes')}
          noLabel={t('transformation.form.ui.no')}
          dontKnowLabel={t('transformation.form.ui.dont.know')}
          onChange={handleChangeWithValidation}
          error={resolveError(FORM_NAMES.patents_associated)}
          defaultValue={getValues(FORM_NAMES.patents_associated)}
          disabled={!editable}
        />

        <YesNoDontKnowFormField
          name={FORM_NAMES.licenses_emitted}
          id={FORM_NAMES.licenses_emitted}
          label={t('transformation.form.transformation_process.licenses_emitted.label')}
          hint={t('transformation.form.transformation_process.licenses_emitted.hint')}
          yesLabel={t('transformation.form.ui.yes')}
          noLabel={t('transformation.form.ui.no')}
          dontKnowLabel={t('transformation.form.ui.dont.know')}
          onChange={handleChangeWithValidation}
          error={resolveError(FORM_NAMES.licenses_emitted)}
          defaultValue={getValues(FORM_NAMES.licenses_emitted)}
          disabled={!editable}
        />
      </Container>
    </ParentComponent>
  );
};

TransformationProcessFormView.propTypes = {
  renderAs: PropTypes.oneOfType([PropTypes.string, PropTypes.elementType]),
  editable: PropTypes.bool,
  form: PropTypes.shape({
    formState: PropTypes.shape({
      isSubmitting: PropTypes.bool
    }),
    handleSubmit: PropTypes.func,
    getValues: PropTypes.func
  }).isRequired
};

TransformationProcessFormView.defaultProps = {
  renderAs: Form,
  editable: true
};

export default TransformationProcessFormView;
