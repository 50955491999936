import React, { Fragment } from 'react';
import useLocale from '../../../../hooks/i18n';
import { Grid } from 'semantic-ui-react';
import PropTypes from 'prop-types';

const TransformationViewSectionMarket = React.forwardRef(({ market }, ref) => {
  const { t } = useLocale();
  return (
    <Fragment>
      {market.production_estimated_value ? (
        <Grid.Row>
          <Grid.Column>
            <h3 ref={ref}>{t('transformation.form.market.production_costs_estimation.label')}</h3>
            <p>{`${market.production_estimated_value ?? ''} ${
              market.production_estimated_currency.unit && market.production_estimated_unit ?
                `${market.production_estimated_currency.unit} / ${market.production_estimated_unit}` :
                ''
            }`}
            </p>
          </Grid.Column>
        </Grid.Row>
      ) : (
        <div className="market-ref" ref={ref} />
      )}
      {market.economical_estimated_value && (
        <Grid.Row>
          <Grid.Column>
            <h3>{t('transformation.form.market.economical_value_unit_estimation.label')}</h3>
            <p>{`${market.economical_estimated_value ?? ''} ${
              market.economical_estimated_currency.unit && market.economical_estimated_unit ?
                `${market.economical_estimated_currency.unit} / ${market.economical_estimated_unit}` :
                ''
            }`}
            </p>
          </Grid.Column>
        </Grid.Row>
      )}
      {market.structure_costs_analysis && (
        <Grid.Row>
          <Grid.Column>
            <h3>{t('transformation.form.market.structure_costs_analysis.label')}</h3>
            <p>{market.structure_costs_analysis}</p>
          </Grid.Column>
        </Grid.Row>
      )}
      {market.market_analysis && (
        <Grid.Row>
          <Grid.Column>
            <h3>{t('transformation.form.market.market_analysis.label')}</h3>
            <p>{market.market_analysis}</p>
          </Grid.Column>
        </Grid.Row>
      )}
      {market.referent_region_businesses && (
        <Grid.Row>
          <Grid.Column>
            <h3>{t('transformation.form.market.referent_region_businesses.label')}</h3>
            <p>{market.referent_region_businesses?.join(', ')}</p>
          </Grid.Column>
        </Grid.Row>
      )}
      {market.donors_financing_institutions && (
        <Grid.Row>
          <Grid.Column>
            <h3>{t('transformation.form.market.donors_financing_institutions.label')}</h3>
            <p>{market.donors_financing_institutions?.join(', ')}</p>
          </Grid.Column>
        </Grid.Row>
      )}
    </Fragment>
  );
});

TransformationViewSectionMarket.propTypes = {
  market: PropTypes.shape({
    production_estimated_value: PropTypes.number,
    production_estimated_currency: PropTypes.shape({
      name: PropTypes.string,
      unit: PropTypes.string
    }),
    production_estimated_unit: PropTypes.string,
    economical_estimated_value: PropTypes.number,
    economical_estimated_currency: PropTypes.shape({
      name: PropTypes.string,
      unit: PropTypes.string
    }),
    economical_estimated_unit: PropTypes.string,
    structure_costs_analysis: PropTypes.string,
    market_analysis: PropTypes.string,
    referent_region_businesses: PropTypes.arrayOf(PropTypes.string),
    donors_financing_institutions: PropTypes.arrayOf(PropTypes.string)
  }).isRequired
};

export default TransformationViewSectionMarket;
