import React from 'react';
import useLocale from '../../hooks/i18n';
import useTitle from '../../hooks/title';
import UserSettingsForm from '../../components/forms/userSettingsForm';
import CardContainer from '../../components/ui/cardContainer';

const UserSettingsPage = () => {
  const { t } = useLocale();
  useTitle(t('page_titles.user_settings'));

  return (
    <div className="user-settings-page">
      <CardContainer className="user-settings-card">
        <UserSettingsForm />
      </CardContainer>
    </div>
  );
};

export default UserSettingsPage;
