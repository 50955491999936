import React, { useState } from 'react';
import { Accordion, Button, Grid, Menu } from 'semantic-ui-react';
import LocaleSwitcher from '../../localeSwitcher';
import SearchTransformationInput from '../../../ui/searchTransformationInput';
import { Link } from 'react-router-dom';
import { PUBLIC_ROUTES } from '../../../../constants/routes';
import LinkWithScroll from '../../linkWithScroll';
import MenuAccordionItem from '../MenuAccordionItem';
import useLocale from '../../../../hooks/i18n';

const GuestMobileNavbarContent = () => {
  const { t } = useLocale();
  const [activeItem, setActiveItem] = useState(-1);
  const handleAccordionItemClicked = (index) => {
    setActiveItem((prevState) => (prevState === index ? -1 : index));
  };
  return (
    <Grid.Row className="mobile-navbar-content">
      <Grid.Column>
        <LocaleSwitcher />
        <SearchTransformationInput />
        <Accordion as={Menu} vertical fluid className="navbar-mobile-menu">
          <MenuAccordionItem
            activeIndex={activeItem}
            index={0}
            title={t('navbar.explore')}
            onAccordionItemClicked={handleAccordionItemClicked}
            content={
              <div className="menu-item-content">
                <Link to={PUBLIC_ROUTES.explore}>
                  {t('navbar.dropdown.explore.opportunities.title')}
                </Link>
              </div>
            }
          />
          <MenuAccordionItem
            activeIndex={activeItem}
            index={1}
            title={t('navbar.about')}
            onAccordionItemClicked={handleAccordionItemClicked}
            content={
              <div className="menu-item-content">
                <Link to={PUBLIC_ROUTES.about}>{t('navbar.about')}</Link>
                <LinkWithScroll
                  path={PUBLIC_ROUTES.about}
                  scrollTo="faq"
                  content={t('navbar.dropdown.about.faq.title')}
                />
                <LinkWithScroll
                  path={PUBLIC_ROUTES.about}
                  scrollTo="contact"
                  content={t('navbar.dropdown.about.contact.title')}
                />
              </div>
            }
          />
        </Accordion>
        <div className="login-signup">
          <Button
            className="login-btn"
            as={Link}
            to={PUBLIC_ROUTES.login}
            content={t('navbar.login')}
          />
          <Button
            as={Link}
            to={PUBLIC_ROUTES.signup}
            color="green"
            content={t('navbar.signup')}
          />
        </div>
      </Grid.Column>
    </Grid.Row>
  );
};

export default GuestMobileNavbarContent;
