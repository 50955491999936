import React from 'react';
import { Button, Grid, Image } from 'semantic-ui-react';
import contribute from '../../assets/images/contribute.webp';
import explore from '../../assets/images/explore.webp';
import publish from '../../assets/images/publish.webp';
import community from '../../assets/images/community.webp';
import governmentActors from '../../assets/icons/objective/government_actors.svg';
import importantlyYou from '../../assets/icons/objective/importantly_you.svg';
import privateBusinesses from '../../assets/icons/objective/private_businesses.svg';
import scientificCommunity from '../../assets/icons/objective/scientific_community.svg';
import startups from '../../assets/icons/objective/startups_and_pymes.svg';
import useLocale from '../../hooks/i18n';
import useTitle from '../../hooks/title';
import useAuth from '../../hooks/auth';
import { Link } from 'react-router-dom';
import { PROTECTED_ROUTES, PUBLIC_ROUTES } from '../../constants/routes';
import PageDelimiter from '../../components/common/pageDelimiter';
import CardContainer from '../../components/ui/cardContainer';
import idb from '../../assets/logo/idb-logo.webp';
import usfq from '../../assets/logo/usfq-logo.svg';

const HomePage = () => {
  const { t } = useLocale();
  useTitle(t('page_titles.home'));
  const { signedIn, me } = useAuth();

  return (
    <div className="home-page">
      <div className="home-page-container">
        <div className="home-page__first-section">
          <PageDelimiter>
            <div className="first-section__header">
              <h1>{t('home.first_section.header')}</h1>
              <p>{t('home.first_section.subheader')}</p>
              <Button
                color="green"
                className="publish-btn"
                as={Link}
                to={
                  signedIn ?
                    PROTECTED_ROUTES.contributions :
                    PUBLIC_ROUTES.login
                }
              >
                {t('home.first_section.btn.publish')}
              </Button>
            </div>
            <p className="first-section__hero">
              {t('home.first_section.hero')}
            </p>
          </PageDelimiter>
        </div>

        <div className="home-page__second-section">
          <div className="second-section-delimiter">
            <h2 className="home-page__header-secondary second-section__header">
              {t('home.second_section.header')}
            </h2>
            <p className="home-page__subheader second-section__subheader">
              {t('home.second_section.subheader')}
            </p>
            <div className="second-section__images">
              <Grid stackable columns={3} className="second-section-grid">
                <Grid.Column>
                  <CardContainer className="image-card">
                    <Link to={PUBLIC_ROUTES.explore}>
                      <img
                        className="image-card__image"
                        src={explore}
                        alt="jungle"
                      />
                      <h3 className="image-card__header">
                        {t('home.second_section.card.header.1')}
                      </h3>
                      <p className="image-card__description">
                        {t('home.second_section.card.description.1')}
                      </p>
                    </Link>
                  </CardContainer>
                </Grid.Column>
                <Grid.Column>
                  <CardContainer className="image-card">
                    <Link
                      to={
                        signedIn ?
                          PROTECTED_ROUTES.contributions :
                          PUBLIC_ROUTES.login
                      }
                    >
                      <img
                        className="image-card__image"
                        src={publish}
                        alt="hands holding plant"
                      />
                      <h3 className="image-card__header">
                        {t('home.second_section.card.header.2')}
                      </h3>
                      <p className="image-card__description">
                        {t('home.second_section.card.description.2')}
                      </p>
                    </Link>
                  </CardContainer>
                </Grid.Column>
                <Grid.Column>
                  <CardContainer className="image-card">
                    <Link
                      to={
                        !signedIn ?
                          PUBLIC_ROUTES.login :
                          me.role === 'CONTRIBUTOR' ?
                            PROTECTED_ROUTES.becomeCurator :
                            PROTECTED_ROUTES.curations
                      }
                    >
                      <img
                        className="image-card__image"
                        src={contribute}
                        alt="man at top of mountain"
                      />
                      <h3 className="image-card__header">
                        {t('home.second_section.card.header.3')}
                      </h3>
                      <p className="image-card__description">
                        {t('home.second_section.card.description.3')}
                      </p>
                    </Link>
                  </CardContainer>
                </Grid.Column>
              </Grid>
            </div>
          </div>
        </div>

        <div className="home-page__third-section">
          <div className="third-section-delimiter">
            <div className="third-section__how-it-works">
              <h2 className="home-page__header-secondary how-it-works__header">
                {t('home.third_section.objective.header')}
              </h2>
              <p className="home-page__subheader how-it-works__subheader">
                {t('home.third_section.objective.description')}
              </p>
            </div>
            <Grid doubling className="third-section__icons" columns={5}>
              <Grid.Column className="icon-column">
                <Image src={privateBusinesses} />
                <span>{t('home.third_section.icons.private_firms')}</span>
              </Grid.Column>
              <Grid.Column className="icon-column">
                <Image src={governmentActors} />
                <span>{t('home.third_section.icons.public_institutions')}</span>
              </Grid.Column>
              <Grid.Column className="icon-column">
                <Image src={startups} />
                <span>{t('home.third_section.icons.startups')}</span>
              </Grid.Column>
              <Grid.Column className="icon-column">
                <Image src={scientificCommunity} />
                <span>{t('home.third_section.icons.scientific_community')}</span>
              </Grid.Column>
              <Grid.Column className="icon-column">
                <Image src={importantlyYou} />
                <span>{t('home.third_section.icons.civil_society')}</span>
              </Grid.Column>
            </Grid>

            <Grid stackable columns={2} className="third-section-steps">
              <Grid.Column>
                <img
                  className="item__image"
                  src={community}
                  alt="group of people"
                />
              </Grid.Column>
              <Grid.Column className="step step--right first-step">
                <h3 className="home-page__header-secondary">
                  {t('home.third_section.steps.item.header.1')}
                </h3>
                <p className="home-page__subheader">
                  {t('home.third_section.steps.item.subheader.1')}
                </p>
                {signedIn ? (
                  <Button color="green" className="home-page-btn item__btn">
                    {t('home.third_section.steps.item.btn.signed_in')}
                  </Button>
                ) : (
                  <div className="buttons-container">
                    <Button
                      as={Link}
                      to={PUBLIC_ROUTES.login}
                      className="home-page-btn item__btn"
                    >
                      {t('home.third_section.steps.item.btn.login')}
                    </Button>
                    <Button
                      color="green"
                      className="home-page-btn item__btn"
                      as={Link}
                      to={PUBLIC_ROUTES.signup}
                    >
                      {t('home.third_section.steps.item.btn.signup')}
                    </Button>
                  </div>
                )}
              </Grid.Column>

              <Grid.Column className="step step--left">
                <h3 className="home-page__header-secondary">
                  {t('home.third_section.steps.item.header.2')}
                </h3>
                <p className="home-page__subheader">
                  {t('home.third_section.steps.item.subheader.2')}
                </p>
              </Grid.Column>
              <Grid.Column className="step step--right">
                <h3 className="home-page__header-secondary">
                  {t('home.third_section.steps.item.header.3')}
                </h3>
                <p className="home-page__subheader">
                  {t('home.third_section.steps.item.subheader.3')}
                </p>
              </Grid.Column>

              <Grid.Column className="step step--left">
                <h3 className="home-page__header-secondary">
                  {t('home.third_section.steps.item.header.4')}
                </h3>
                <p className="home-page__subheader">
                  {t('home.third_section.steps.item.subheader.4')}
                </p>
              </Grid.Column>
              <Grid.Column className="step step--right">
                <h3 className="home-page__header-secondary">
                  {t('home.third_section.steps.item.header.5')}
                </h3>
                <p className="home-page__subheader">
                  {t('home.third_section.steps.item.subheader.5')}
                </p>
              </Grid.Column>
            </Grid>
          </div>
        </div>

        <PageDelimiter className="organization-section home-page__fifth-section">
          <h3>{t('about.third_section.header')}</h3>
          <p className="subtitle">{t('about.third_section.subheader')}</p>
          <CardContainer className="organization-section__card">
            <a href="https://www.iadb.org/en" target="_blank" rel="noreferrer">
              <Image loading="lazy" size="medium" src={idb} />
            </a>
            <a href="https://www.usfq.edu.ec/" target="_blank" rel="noreferrer">
              <Image loading="lazy" size="small" src={usfq} />
            </a>
          </CardContainer>
        </PageDelimiter>

      </div>
    </div>
  );
};

export default HomePage;
