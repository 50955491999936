import React from 'react';
import PropTypes from 'prop-types';
import TransformationItemGroup from '../../transformation/transformationItemGroup';
import useLocale from '../../../hooks/i18n';

const UserProfileContributions = ({ transformations, curations }) => {
  const { t } = useLocale();

  return (
    <div className="profile-contributions">
      <div className="contributions">
        <h2 className="contributions-title">
          {t('user.profile.contributions.header')}
        </h2>
        <h3>
          {t('user.profile.contributions.header.subtitle')}
        </h3>

        <TransformationItemGroup
          aggregatedTransformations={transformations}
          type="published"
          emptyHeader={t('user.profile.contributions.empty.header')}
          emptyDescription={t('user.profile.contributions.empty.description')}
        />
      </div>

      <div className="curations">
        <h2 className="contributions-title">
          {t('user.profile.curations.header')}
        </h2>
        <h3>
          {t('user.profile.curations.header.subtitle')}
        </h3>

        <TransformationItemGroup
          aggregatedTransformations={curations}
          type="published"
          emptyHeader={t('user.profile.curations.empty.header')}
          emptyDescription={t('user.profile.curations.empty.description')}
        />
      </div>
    </div>
  );
};

UserProfileContributions.propTypes = {
  transformations: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  curations: PropTypes.arrayOf(PropTypes.shape({})).isRequired
};

export default UserProfileContributions;
