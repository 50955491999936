import React from 'react';
import { Grid } from 'semantic-ui-react';
import TransformationLikeItem from '../../transformationLikes';
import PropTypes from 'prop-types';

const CrowdSourcingSection = ({ likes, userLiked, id }) => {
  return (
    <Grid.Column
      computer={2}
      tablet={3}
      mobile={3}
      className="transformation-likes"
    >
      <Grid.Row columns={1} className="bookmark-row">
        <Grid.Column>
          <TransformationLikeItem type="bookmark" likes={likes.bookmark} liked={userLiked.bookmark} id={id} />
        </Grid.Column>
      </Grid.Row>

      <Grid.Row className="crowdsourcing-row">
        <Grid columns={2} verticalAlign="bottom" className="crowdsourcing-grid">
          <Grid.Column>
            <TransformationLikeItem type="technically_viable" likes={likes.technically_viable} liked={userLiked.technically_viable} id={id} />
          </Grid.Column>

          <Grid.Column>
            <TransformationLikeItem type="economically_viable" likes={likes.economically_viable} liked={userLiked.economically_viable} id={id} />
          </Grid.Column>

          <Grid.Column>
            <TransformationLikeItem type="environmentally_viable" likes={likes.environmentally_viable} liked={userLiked.environmentally_viable} id={id} />
          </Grid.Column>

          <Grid.Column>
            <TransformationLikeItem type="socially_viable" likes={likes.socially_viable} liked={userLiked.socially_viable} id={id} />
          </Grid.Column>
        </Grid>
      </Grid.Row>
    </Grid.Column>
  );
};

CrowdSourcingSection.propTypes = {
  likes: PropTypes.shape({
    bookmark: PropTypes.number,
    economically_viable: PropTypes.number,
    environmentally_viable: PropTypes.number,
    socially_viable: PropTypes.number,
    technically_viable: PropTypes.number
  }).isRequired,
  userLiked: PropTypes.shape({
    bookmark: PropTypes.bool,
    economically_viable: PropTypes.bool,
    environmentally_viable: PropTypes.bool,
    socially_viable: PropTypes.bool,
    technically_viable: PropTypes.bool
  }).isRequired,
  id: PropTypes.number.isRequired
};

export default CrowdSourcingSection;
