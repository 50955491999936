import React from 'react';
import PropTypes from 'prop-types';
import { Form, Container, Message, Button } from 'semantic-ui-react';
import useLocale from '../../../hooks/i18n';
import useFormHelper from '../../../hooks/formHelper';

const ResendVerificationFormView = ({ form, onSubmit, error, success }) => {
  const { t } = useLocale();
  const { handleChangeNoValidation, handleBlurValidate, resolveError } = useFormHelper(form);
  const { handleSubmit, formState: { isSubmitting } } = form;

  return (
    <Form onSubmit={handleSubmit(onSubmit)} error={!!error}>
      <Container text fluid>
        <Message error header={t('resend_verification.errors.header')} content={error} />
        <Message
          positive
          header={t('resend_verification.success.header')}
          hidden={!success}
          content={t('resend_verification.success.description')}
        />

        <Form.Input
          name="email"
          fluid
          label={t('resend_verification.form.email')}
          placeholder="your@email.com"
          onChange={handleChangeNoValidation}
          onBlur={handleBlurValidate}
          error={resolveError('email')}
        />

        <Button type="submit" color="blue" loading={isSubmitting} disabled={success}>
          {t('resend_verification.form.submit')}
        </Button>
      </Container>
    </Form>
  );
};

ResendVerificationFormView.propTypes = {
  form: PropTypes.shape({
    formState: PropTypes.shape({
      isSubmitting: PropTypes.bool
    }),
    handleSubmit: PropTypes.func
  }).isRequired,
  onSubmit: PropTypes.func.isRequired,
  error: PropTypes.string,
  success: PropTypes.bool.isRequired
};

ResendVerificationFormView.defaultProps = {
  error: null
};

export default ResendVerificationFormView;
