import React from 'react';
import BecomeCuratorForm from '../../components/forms/becomeCuratorForm';
import useLocale from '../../hooks/i18n';
import useTitle from '../../hooks/title';

const BecomeCuratorFormPage = () => {
  const { t } = useLocale();
  useTitle(t('page_titles.become_curator_application'));

  return (
    <BecomeCuratorForm />
  );
};

export default BecomeCuratorFormPage;
