import React from 'react';
import { useLocation } from 'react-router-dom';
import useLocale from '../../hooks/i18n';
import useTitle from '../../hooks/title';
import BackButton from '../../components/common/backButton';
import { PUBLIC_ROUTES } from '../../constants/routes';

const NotFoundPage = () => {
  const location = useLocation();
  const { t } = useLocale();
  useTitle(t('page_titles.not_found'));

  return (
    <div className="page-not-found">
      <BackButton
        className="page-not-found__back-btn"
        linkTo={PUBLIC_ROUTES.home}
        text={t('not_found.page.back')}
      />
      <div className="page-not-found__container">
        <h1>404</h1>
        <h2 className="subtitle">
          {t('not_found.page.subtitle')} {location.pathname}
        </h2>
        <p className="content">{t('not_found.page.something_went_wrong')}</p>
      </div>
    </div>
  );
};

export default NotFoundPage;
