import React from 'react';
import { Dropdown } from 'semantic-ui-react';
import ReactCountryFlag from 'react-country-flag';
import useLocale from '../../../hooks/i18n';

const LocaleSwitcher = () => {
  const { AVAILABLE_LOCALES, LOCALE_FLAGS, updateLocale, currentLocale } = useLocale();

  const dropdownOptions = Object.entries(AVAILABLE_LOCALES).map(([key, name]) => {
    return {
      key,
      text: name,
      value: key,
      active: key === currentLocale,
      flag: <ReactCountryFlag countryCode={LOCALE_FLAGS[key]} style={{ marginRight: '0.5em' }} />
    };
  });

  const handleLocaleChange = (_, { value }) => {
    updateLocale(value);
  };

  return (
    <Dropdown
      className="icon locale-icon"
      floating
      icon="world"
      text=" "
      options={dropdownOptions}
      onChange={handleLocaleChange}
    />
  );
};

export default LocaleSwitcher;
