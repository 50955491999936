import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';
import CurateTransformationFormView from './CurateTransformationFormView';
import useLocale from '../../../hooks/i18n';

const CurationSchema = Joi.object({
  comment_basic_information: Joi.string().allow('').allow(null).default(''),
  comment_transformation_process: Joi.string().allow('').allow(null).default(''),
  comment_scalability: Joi.string().allow('').allow(null).default(''),
  comment_sustainability: Joi.string().allow('').allow(null).default(''),
  comment_market: Joi.string().allow('').allow(null).default(''),
  comment_regulations: Joi.string().allow('').allow(null).default(''),
  publish: Joi.boolean().required().allow(null).default(false)
});

const CurateTransformationFormLogic = ({ transformation, initialCuration, resources, onCommit, onSave, onDelete }) => {
  const { t } = useLocale();
  const form = useForm({
    mode: 'onSubmit',
    resolver: joiResolver(CurationSchema),
    defaultValues: initialCuration
  });
  const transformationForm = useForm({
    mode: 'onSubmit',
    defaultValues: transformation
  });
  const [error, setError] = useState(null);

  const handleCommit = async (data) => {
    if (!data.publish) {
      const atLeastOneComment = Object.entries(data)
        .filter(([k]) => k.startsWith('comment'))
        .some(([, v]) => !!v);

      if (!atLeastOneComment) {
        setError(t('curate_contribution.form.errors.deny_comment_check'));
        return;
      }
    }

    try {
      setError(null);
      await onCommit(data);
    } catch (error) {
      setError(error);
    }
  };

  const handleSave = async (data) => {
    try {
      setError(null);
      await onSave(data);
    } catch (error) {
      setError(error);
    }
  };

  const handleDelete = async () => {
    try {
      setError(null);
      await onDelete();
    } catch (error) {
      setError(error);
    }
  };

  return (
    <CurateTransformationFormView
      onCommit={handleCommit}
      onSave={handleSave}
      onDelete={handleDelete}
      form={form}
      error={error}
      transformationForm={transformationForm}
      resources={resources}
    />
  );
};

CurateTransformationFormLogic.propTypes = {
  transformation: PropTypes.shape({}).isRequired,
  initialCuration: PropTypes.shape({}).isRequired,
  resources: PropTypes.shape({}).isRequired,
  onSave: PropTypes.func.isRequired,
  onCommit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired
};

export default CurateTransformationFormLogic;
