import React from 'react';
import PageDelimiter from '../../components/common/pageDelimiter';
import useLocale from '../../hooks/i18n';
import useTitle from '../../hooks/title';

const TermsAndConditionsPage = () => {
  const { t } = useLocale();
  useTitle(t('page_titles.terms_and_conditions'));
  return (
    <PageDelimiter className="terms-and-conditions">
      <div className="main-content">
        <h1>{t('terms_and_conditions.title')}</h1>
        <p>{t('terms_and_conditions.content.1')}</p>
        <p>{t('terms_and_conditions.content.2')}</p>
        <p>{t('terms_and_conditions.content.3')}</p>
        <p>{t('terms_and_conditions.content.4')}</p>
        <p>{t('terms_and_conditions.content.5')}</p>
        <p>{t('terms_and_conditions.content.6')}</p>
        <p>{t('terms_and_conditions.content.7')}</p>
        <p>{t('terms_and_conditions.content.8')}</p>
        <p>{t('terms_and_conditions.content.9')}</p>
        <p>{t('terms_and_conditions.content.10')}</p>
        <p>{t('terms_and_conditions.content.11')}</p>
        <p>{t('terms_and_conditions.content.12')}</p>
        <p>{t('terms_and_conditions.content.13')}</p>
        <p>{t('terms_and_conditions.content.14')}</p>
        <p>{t('terms_and_conditions.content.15')}</p>
      </div>
    </PageDelimiter>
  );
};

export default TermsAndConditionsPage;
