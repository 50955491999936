import React from 'react';
import { Form } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import useFormHelper from '../../../hooks/formHelper';
import useLocale from '../../../hooks/i18n';

const ResourceModalFormView = ({ form, names, titles }) => {
  const { t } = useLocale();
  const { handleChangeNoValidation, handleBlurValidate, resolveError } =
    useFormHelper(form);
  const { getValues } = form;

  return (
    <div className="resource-modal__form">
      {names.map((name, i) => (
        <div key={i} className="form_group">
          <Form.Input
            type="text"
            label={t('admin.resource_management.modal.form.en.label', { title: t(titles[i]) })}
            id="en"
            name={`en.${name}`}
            onChange={handleChangeNoValidation}
            onBlur={handleBlurValidate}
            error={resolveError(`en.${name}`)}
            defaultValue={getValues(`en.${name}`)}
          />

          <Form.Input
            type="text"
            label={t('admin.resource_management.modal.form.es.label', { title: t(titles[i]) })}
            id="es"
            name={`es.${name}`}
            onChange={handleChangeNoValidation}
            onBlur={handleBlurValidate}
            error={resolveError(`es.${name}`)}
            defaultValue={getValues(`es.${name}`)}
          />

          <Form.Input
            type="text"
            label={t('admin.resource_management.modal.form.pt.label', { title: t(titles[i]) })}
            id="pt"
            name={`pt.${name}`}
            onChange={handleChangeNoValidation}
            onBlur={handleBlurValidate}
            error={resolveError(`pt.${name}`)}
            defaultValue={getValues(`pt.${name}`)}
          />
        </div>
      ))}
    </div>
  );
};

ResourceModalFormView.propTypes = {
  form: PropTypes.shape({
    formState: PropTypes.shape({
      isSubmitting: PropTypes.bool
    }),
    handleSubmit: PropTypes.func,
    getValues: PropTypes.func
  }).isRequired,
  names: PropTypes.arrayOf(PropTypes.string).isRequired,
  titles: PropTypes.arrayOf(PropTypes.string).isRequired
};

export default ResourceModalFormView;
