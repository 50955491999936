class TransformationService {
  constructor(client) {
    this.client = client;
  }

  async postUnpublishedTransformation() {
    try {
      const response = await this.client.instance.post('/transformations');
      return response.data.data;
    } catch (error) {
      this.client.handleResponseError('Error creating new unpublished transformation.', error);
    }
  }

  async #getAllUnpublishedTransformations() {
    try {
      let result = [];
      let page = 1;

      while (page > 0) {
        const response = await this.client.instance.get('/transformations/unpublished', {
          params: { page }
        });

        const { data } = response.data;

        result = result.concat(data.result);
        page = data.pagination.isLastPage ? -1 : page + 1;
      }

      return result;
    } catch (error) {
      this.client.handleResponseError('Error fetching unpublished transformations.', error);
    }
  }

  getAllUnpublishedTransformations() {
    return {
      key: ['unpublished-transformations'],
      fn: () => this.#getAllUnpublishedTransformations()
    };
  }

  async #getUnpublishedTransformationById(id) {
    try {
      const response = await this.client.instance.get(`/transformations/unpublished/${id}`);
      return response.data.data;
    } catch (error) {
      this.client.handleResponseError(`Error fetching unpublished transformation with id: ${id}`, error);
    }
  }

  getUnpublishedTransformationById(id) {
    return {
      key: ['unpublished-transformation', id],
      fn: () => this.#getUnpublishedTransformationById(id)
    };
  }

  async #getCurationsForUnpublishedTransformationById(id, limit) {
    try {
      const response = await this.client.instance.get(`/transformations/unpublished/${id}/curations`, {
        params: {
          limit
        }
      });
      return response.data.data;
    } catch (error) {
      this.client.handleResponseError(`Error fetching curations for unpublished transformation with id: ${id}`, error);
    }
  }

  getCurationsForUnpublishedTransformationById(id, limit) {
    return {
      key: ['unpublished-transformation', id, 'curations', limit],
      fn: () => this.#getCurationsForUnpublishedTransformationById(id, limit)
    };
  }

  async patchUnpublishedTransformation(id, data) {
    try {
      const response = await this.client.instance.patch(`/transformations/unpublished/${id}`, data);
      return response.data.data;
    } catch (error) {
      this.client.handleResponseError(`Error updating unpublished transformation with id: ${id}`, error);
    }
  }

  async deleteUnpublishedTransformation(id) {
    try {
      const response = await this.client.instance.delete(`/transformations/unpublished/${id}`);
      return response.data.data;
    } catch (error) {
      this.client.handleResponseError(`Error deleting unpublished transformation with id: ${id}`, error);
    }
  }

  async patchUnpublishedTransformationMetadataByID(id, picture) {
    try {
      const response = await this.client.instance.patch(`/transformations/unpublished/${id}/metadata`, { picture });
      return response.data.data;
    } catch (error) {
      this.client.handleResponseError(`Error updating metadata of unpublished transformation with id: ${id}`, error);
    }
  }

  async postRequestCuration(id) {
    try {
      const response = await this.client.instance.post(`/transformations/unpublished/${id}/request-curation`);
      return response.data.data;
    } catch (error) {
      this.client.handleResponseError(`Error requesting curation for unpublished transformation with id: ${id}`, error);
    }
  }

  async #getAllUnpublishedTransformationsAwaitingCuration(page) {
    try {
      const response = await this.client.instance.get('/transformations/awaiting-curation', {
        params: { page }
      });
      return response.data.data;
    } catch (error) {
      this.client.handleResponseError('Error fetching unpublished transformations awaiting curation', error);
    }
  }

  getAllUnpublishedTransformationsAwaitingCuration(page = 1) {
    return {
      key: ['transformations', 'awaiting-curation', page],
      fn: () => this.#getAllUnpublishedTransformationsAwaitingCuration(page)
    };
  }

  async #getTransformationAwaitingCurationById(id) {
    try {
      const response = await this.client.instance.get(`/transformations/awaiting-curation/${id}`);
      return response.data.data;
    } catch (error) {
      this.client.handleResponseError(`Error fetching unpublished transformation awaiting curation ${id}`, error);
    }
  }

  getTransformationAwaitingCurationById(id) {
    return {
      key: ['transformations', 'awaiting-curation', id],
      fn: () => this.#getTransformationAwaitingCurationById(id)
    };
  }

  async #getPublishedTransformation(id, lang) {
    try {
      const response = await this.client.instance.get(`/transformations/published/${id}`, {
        params: { lang }
      });
      return response.data.data;
    } catch (error) {
      this.client.handleResponseError(`Error fetching published transformation ${id} with language ${lang}.`, error);
    }
  }

  getPublishedTransformation(id, lang) {
    return {
      key: ['transformations', 'published', id, lang],
      fn: () => this.#getPublishedTransformation(id, lang)
    };
  }

  async #listPublishedTransformations(lang, page) {
    try {
      const response = await this.client.instance.get('/transformations/published', {
        params: { lang, page }
      });
      return response.data.data;
    } catch (error) {
      this.client.handleResponseError(`Error fetching published transformations with language ${lang}.`, error);
    }
  }

  listPublishedTransformations(lang, page = 1) {
    return {
      key: ['transformations', 'published', 'list', lang, page],
      fn: () => this.#listPublishedTransformations(lang, page)
    };
  }

  async #searchPublishedTransformations(lang, query, page) {
    try {
      const response = await this.client.instance.get('/transformations/published/search', {
        params: { lang, page, query }
      });
      return response.data.data;
    } catch (error) {
      this.client.handleResponseError(`Error fetching published transformations with language ${lang}.`, error);
    }
  }

  searchPublishedTransformations(lang, query, page = 1) {
    if (!query) {
      return this.listPublishedTransformations(lang, page);
    }

    return {
      key: ['transformations', 'published', 'search', lang, page],
      fn: () => this.#searchPublishedTransformations(lang, query, page)
    };
  }

  async likePublishedTransformation(id, type) {
    try {
      const response = await this.client.instance.post(`/transformations/published/${id}/like`, null, {
        params: { type }
      });
      return response.data.data;
    } catch (error) {
      this.client.handleResponseError(`Error liking published transformation ${id}.`, error);
    }
  }

  async unlikePublishedTransformation(id, type) {
    try {
      const response = await this.client.instance.delete(`/transformations/published/${id}/like`, {
        params: { type }
      });
      return response.data.data;
    } catch (error) {
      this.client.handleResponseError(`Error unliking published transformation ${id}.`, error);
    }
  }
}

export default TransformationService;
