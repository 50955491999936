import React from 'react';
import PropTypes from 'prop-types';
import { Message } from 'semantic-ui-react';
import {
  PublishedTransformationItem,
  EditableUnpublishedTransformationItem,
  NonEditableUnpublishedTransformationItem,
  AwaitingCurationTransformationItem,
  InCurationTransformationItem
} from '../transformationItem';

const transformationViewsByType = {
  published: PublishedTransformationItem,
  editableUnpublished: EditableUnpublishedTransformationItem,
  nonEditableUnpublished: NonEditableUnpublishedTransformationItem,
  awaitingCuration: AwaitingCurationTransformationItem,
  inCuration: InCurationTransformationItem
};

const TransformationItemGroup = ({ className, title, emptyHeader, emptyDescription, aggregatedTransformations, type }) => {
  const TransformationViewComponent = transformationViewsByType[type];

  return (
    <div className={className}>
      {
        title && (
          <h2>{title}</h2>
        )
      }
      {
        aggregatedTransformations.length < 1 ? (
          <Message
            icon="folder open outline"
            header={emptyHeader}
            content={emptyDescription}
          />
        ) : (
          aggregatedTransformations.map((aggregatedTransformation, idx) => (
            <TransformationViewComponent key={idx} aggregatedTransformation={aggregatedTransformation} />
          ))
        )
      }
    </div>
  );
};

TransformationItemGroup.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  emptyHeader: PropTypes.string.isRequired,
  emptyDescription: PropTypes.string.isRequired,
  aggregatedTransformations: PropTypes.arrayOf(PropTypes.shape({})),
  type: PropTypes.oneOf(['published', 'editableUnpublished', 'nonEditableUnpublished', 'awaitingCuration', 'inCuration']).isRequired
};

TransformationItemGroup.defaultProps = {
  className: '',
  title: null,
  aggregatedTransformations: []
};

export default TransformationItemGroup;
