import React from 'react';
import PropTypes from 'prop-types';

const PageDelimiter = ({ children, className }) => {
  return <div className={`global-page-delimiter ${className ?? ''}`}>{children}</div>;
};

PageDelimiter.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string
};

PageDelimiter.defaultProps = {
  className: null
};

export default PageDelimiter;
