import React, { Fragment } from 'react';
import { Grid } from 'semantic-ui-react';
import useLocale from '../../../../hooks/i18n';
import PropTypes from 'prop-types';

const TransformationViewSectionBasicInformation = React.forwardRef(({ image, basicInformation }, ref) => {
  const { t } = useLocale();

  const biomassClassStrings = {
    N: t('transformation.form.basic_information.biomass_class.options.natural'),
    R: t('transformation.form.basic_information.biomass_class.options.residual'),
    P: t('transformation.form.basic_information.biomass_class.options.produced')
  };

  return (
    <Fragment>
      <Grid.Row>
        <Grid.Column width={8}>
          <h3 ref={ref}>{t('transformation.form.image.title')}</h3>
          <img className="transformation-image" src={image} alt="transformation icon" />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <h3>{t('transformation.form.basic_information.producing_species_name.label')}</h3>
          <p>{basicInformation.producing_species_name}</p>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns={2}>
        <Grid.Column>
          <h3>{t('transformation.form.basic_information.common_names.label')}</h3>
          <p>{basicInformation.common_names?.join(', ')}</p>
        </Grid.Column>
        <Grid.Column>
          <h3>{t('transformation.form.basic_information.biomass_class.label')}</h3>
          <p>{biomassClassStrings[basicInformation.biomass_class]}</p>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns={2}>
        <Grid.Column>
          <h3>{t('transformation.form.basic_information.organic_material_name.label')}</h3>
          <p>{basicInformation.organic_material_name}</p>
        </Grid.Column>
        <Grid.Column>
          <h3>{t('transformation.form.basic_information.source_type.label')}</h3>
          <p>{basicInformation.source_type}</p>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns={2}>
        <Grid.Column>
          <h3>{t('transformation.form.basic_information.source_class.label')}</h3>
          <p>{basicInformation.source_class}</p>
        </Grid.Column>
        <Grid.Column>
          <h3>{t('transformation.form.basic_information.source_sub_class.label')}</h3>
          <p>{basicInformation.source_sub_class}</p>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <h3>{t('transformation.form.basic_information.location.label')}</h3>
          <p>{basicInformation.location.name}</p>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <h3>{t('transformation.form.basic_information.brief_biomass_description.label')}</h3>
          <p>{basicInformation.brief_biomass_description}</p>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <h3>{t('transformation.form.basic_information.current_uses_or_transformations.label')}</h3>
          <p>{basicInformation.current_uses_or_transformations?.join(', ')}</p>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <h3>{t('transformation.form.basic_information.current_negative_impact.label')}</h3>
          <p>{basicInformation.current_negative_impact}</p>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <h3>{t('transformation.form.basic_information.trl_eu.label')}</h3>
          <p>{basicInformation.trl_eu}</p>
        </Grid.Column>
      </Grid.Row>
    </Fragment>
  );
});

TransformationViewSectionBasicInformation.propTypes = {
  image: PropTypes.string.isRequired,
  basicInformation: PropTypes.shape({
    producing_species_name: PropTypes.string,
    common_names: PropTypes.arrayOf(PropTypes.string),
    biomass_class: PropTypes.string,
    organic_material_name: PropTypes.string,
    source_type: PropTypes.string,
    source_class: PropTypes.string,
    source_sub_class: PropTypes.string,
    location: PropTypes.shape({
      name: PropTypes.string,
      short_name: PropTypes.string
    }),
    brief_biomass: PropTypes.string,
    brief_biomass_description: PropTypes.string,
    current_uses_or_transformations: PropTypes.arrayOf(PropTypes.string),
    current_negative_impact: PropTypes.string,
    trl_eu: PropTypes.string
  }).isRequired
};

export default TransformationViewSectionBasicInformation;
