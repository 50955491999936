import React, { Fragment } from 'react';
import { Grid } from 'semantic-ui-react';
import useLocale from '../../../../hooks/i18n';
import PropTypes from 'prop-types';
import { bitFieldToBitArray } from '../../../../utils/bitfield';
import { monthLabels } from '../../../common/form/multiMonthPicker/MultiMonthPicker';
import { yesNoUnsureStrings } from '../../../../pages/transformationPage/TransformationPage';

const TransformationViewSectionScalability = React.forwardRef(({ scalability }, ref) => {
  const { t } = useLocale();
  const annualAvailabilityKeys = monthLabels.filter((month, i) =>
    bitFieldToBitArray(scalability.annual_availability)[i]);
  return (
    <Fragment>
      <Grid.Row>
        <Grid.Column>
          <h3 ref={ref}>{t('transformation.form.scalability.technical_scalability.label')}</h3>
          <p>{t(yesNoUnsureStrings[scalability.technical_scalability])}</p>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <h3>{t('transformation.form.scalability.technical_scalability_challenges.label')}</h3>
          <p>{scalability.technical_scalability_challenges}</p>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <h3>{t('transformation.form.scalability.required_equipment.label')}</h3>
          <p>{scalability.required_equipment?.join(', ')}</p>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <h3>{t('transformation.form.scalability.required_supplies.label')}</h3>
          <p>{scalability.required_supplies?.join(', ')}</p>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <h3>{t('transformation.form.scalability.required_skills.label')}</h3>
          <p>{scalability.required_skills?.join(', ')}</p>
        </Grid.Column>
      </Grid.Row>
      {scalability.min_value && (
        <Grid.Row>
          <Grid.Column>
            <h3>{t('transformation.form.scalability.minimal_scale_production.label')}</h3>
            <p>{`${scalability.min_value} ${scalability.min_unit ?? ''}`}</p>
          </Grid.Column>
        </Grid.Row>
      )}
      {scalability.max_value && (
        <Grid.Row>
          <Grid.Column>
            <h3>{t('transformation.form.scalability.maximum_production_volume.label')}</h3>
            <p>{`${scalability.max_value} ${scalability.max_unit ?? ''}`}</p>
          </Grid.Column>
        </Grid.Row>
      )}
      <Grid.Row>
        <Grid.Column>
          <h3>{t('transformation.form.scalability.logistical_scalability.label')}</h3>
          <p>{t(yesNoUnsureStrings[scalability.logistical_scalability])}</p>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <h3>{t('transformation.form.scalability.logistical_scalability_challenges.label')}</h3>
          <p>{scalability.logistical_scalability_challenges}</p>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <h3>{t('transformation.form.scalability.economical_scalability.label')}</h3>
          <p>{t(yesNoUnsureStrings[scalability.economical_scalability])}</p>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <h3>{t('transformation.form.scalability.economical_scalability_challenges.label')}</h3>
          <p>{scalability.economical_scalability_challenges}</p>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <h3>{t('transformation.form.scalability.annual_availability.label')}</h3>
          <p>{annualAvailabilityKeys.map((key) => t(key)).join(', ')}</p>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <h3>{t('transformation.form.scalability.climate.label')}</h3>
          <p>{scalability.climate}</p>
        </Grid.Column>
      </Grid.Row>
    </Fragment>
  );
});

TransformationViewSectionScalability.propTypes = {
  scalability: PropTypes.shape({
    technical_scalability: PropTypes.string,
    technical_scalability_challenges: PropTypes.string,
    required_equipment: PropTypes.arrayOf(PropTypes.string),
    required_supplies: PropTypes.arrayOf(PropTypes.string),
    required_skills: PropTypes.arrayOf(PropTypes.string),
    min_value: PropTypes.number,
    min_unit: PropTypes.string,
    max_value: PropTypes.number,
    max_unit: PropTypes.string,
    logistical_scalability: PropTypes.string,
    logistical_scalability_challenges: PropTypes.string,
    economical_scalability: PropTypes.string,
    economical_scalability_challenges: PropTypes.string,
    annual_availability: PropTypes.number,
    climate: PropTypes.string
  }).isRequired
};

export default TransformationViewSectionScalability;
