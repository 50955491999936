import React from 'react';
import ResendVerificationFormLogic from './ResendVerificationFormLogic';
import useAuth from '../../../hooks/auth';
import useLocale from '../../../hooks/i18n';

const ResendVerificationForm = () => {
  const { resendVerificationCode } = useAuth();
  const { t } = useLocale();

  const handleSubmit = async (email) => {
    try {
      await resendVerificationCode(email);
    } catch (resendVerificationError) {
      throw resendVerificationError.getFriendlyMessage(t);
    }
  };

  return (
    <ResendVerificationFormLogic onSubmit={handleSubmit} />
  );
};

export default ResendVerificationForm;
