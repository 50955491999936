import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Form, Header, Image, Item, Message } from 'semantic-ui-react';
import AutoResizeTextArea from '../../common/form/autoResizeTextArea';
import useLocale from '../../../hooks/i18n';
import useFormHelper from '../../../hooks/formHelper';
import ImageAssetPicker from '../../common/imageAssetPicker';
import { USER_IMAGES } from '../../../constants/assets';
import BooleanFormField from '../../common/form/booleanFormField';
import edit from '../../../assets/icons/edit.svg';

const UserSettingsFormView = ({ form, onSubmit, user, success, error }) => {
  const { t } = useLocale();
  const { handleChangeNoValidation, handleBlurValidate, resolveError } =
    useFormHelper(form);
  const {
    handleSubmit,
    formState: { isSubmitting },
    setValue
  } = form;
  const [selectedImageURL, setSelectedImageURL] = React.useState(
    user.picture ?? null
  );
  const [imagePickerOpen, setImagePickerOpen] = React.useState(false);

  useEffect(() => {
    if (selectedImageURL !== user.picture) {
      setValue('picture', selectedImageURL);
    }
  }, [selectedImageURL, setValue, user.picture]);

  return (
    <Form
      className="user-settings-form"
      onSubmit={handleSubmit(onSubmit)}
      error={!!error}
      success={success}
    >
      <div className="form-container">
        <Message error header={t('settings.errors.header')} content={error} />
        <Message success header={t('settings.user.form.success.header')} />
        <Header as="h2">{t('settings.user.form.header')}</Header>
        <ImageAssetPicker
          header={t('settings.user.form.image_picker.header')}
          selectedImageURL={selectedImageURL}
          images={USER_IMAGES}
          onClose={() => {
            setSelectedImageURL(user.picture);
            setImagePickerOpen(false);
          }}
          setSelectedImageURL={setSelectedImageURL}
          onConfirm={() => {
            setImagePickerOpen(false);
          }}
          loading={false}
          trigger={
            <Item
              className="image-trigger"
              onClick={() => {
                setImagePickerOpen(true);
              }}
            >
              <Image
                className="user-profile-image"
                avatar
                src={selectedImageURL}
                size="medium"
              />
              <Image className="edit-profile-icon" src={edit} />
            </Item>
          }
          open={imagePickerOpen}
        />
        <Form.Input
          placeholder={user.username}
          name="username"
          fluid
          label={t('settings.user.form.username')}
          onChange={handleChangeNoValidation}
          onBlur={handleBlurValidate}
          error={resolveError('username')}
        />

        <AutoResizeTextArea
          name="bio"
          id="bio"
          placeholder={user.bio}
          label={t('settings.user.form.bio')}
          error={resolveError('bio')}
          onChange={handleChangeNoValidation}
          onBlur={handleBlurValidate}
        />

        <Form.Group widths="equal">
          <Form.Input
            placeholder={user.given_name}
            name="given_name"
            fluid
            label={t('settings.user.form.given_name')}
            onChange={handleChangeNoValidation}
            onBlur={handleBlurValidate}
            error={resolveError('given_name')}
          />
          <Form.Input
            placeholder={user.family_name}
            name="family_name"
            fluid
            label={t('settings.user.form.family_name')}
            onChange={handleChangeNoValidation}
            onBlur={handleBlurValidate}
            error={resolveError('family_name')}
          />
        </Form.Group>

        <Form.Group widths="equal">
          <Form.Input
            placeholder={user.institution_name}
            name="institution_name"
            fluid
            label={t('settings.user.form.institution_name')}
            onChange={handleChangeNoValidation}
            onBlur={handleBlurValidate}
            error={resolveError('institution_name')}
          />
          <Form.Input
            name="institution_position"
            fluid
            label={t('settings.user.form.institution_position')}
            placeholder={user.institution_position}
            onChange={handleChangeNoValidation}
            onBlur={handleBlurValidate}
            error={resolveError('institution_position')}
          />
        </Form.Group>
        <Form.Input
          name="professional_affiliation"
          fluid
          label={t('settings.user.form.professional_affiliation')}
          placeholder={user.professional_affiliation}
          onChange={handleChangeNoValidation}
          onBlur={handleBlurValidate}
          error={resolveError('professional_affiliation')}
        />

        <Form.Group widths="equal">
          <Form.Input
            name="location_country"
            fluid
            label={t('settings.user.form.location_country')}
            placeholder={user.location_country}
            onChange={handleChangeNoValidation}
            onBlur={handleBlurValidate}
            error={resolveError('location_country')}
          />
          <Form.Input
            name="location_province"
            fluid
            label={t('settings.user.form.location_province')}
            onChange={handleChangeNoValidation}
            placeholder={user.location_province}
            onBlur={handleBlurValidate}
            error={resolveError('location_province')}
          />
          <Form.Input
            name="location_city"
            fluid
            label={t('settings.user.form.location_city')}
            placeholder={user.location_city}
            onChange={handleChangeNoValidation}
            onBlur={handleBlurValidate}
            error={resolveError('location_city')}
          />
        </Form.Group>

        <BooleanFormField
          onChange={handleChangeNoValidation}
          name="email_notifications_enabled"
          label={t('settings.user.form.email_notifications_enabled')}
          id="email_notifications_enabled"
          defaultValue={user.email_notifications_enabled}
          noLabel={t('settings.user.form.no')}
          yesLabel={t('settings.user.form.yes')}
        />

        <Header as="h3" className="contact-header">
          {t('settings.user.form.contact.divider')}
        </Header>
        <Form.Group widths="equal">
          <Form.Input
            name="phone"
            label={t('settings.user.form.socials.phone')}
            onChange={handleChangeNoValidation}
            onBlur={handleBlurValidate}
            placeholder={user.phone}
            error={resolveError('phone')}
          />
          <Form.Input
            name="socials_website"
            label={t('settings.user.form.socials.website')}
            onChange={handleChangeNoValidation}
            onBlur={handleBlurValidate}
            placeholder={user.socials_website}
            error={resolveError('socials_website')}
          />
        </Form.Group>
        <Form.Group widths="equal">
          <Form.Input
            name="socials_facebook"
            label={t('settings.user.form.socials.facebook')}
            onChange={handleChangeNoValidation}
            onBlur={handleBlurValidate}
            placeholder={user.socials_facebook}
            error={resolveError('socials_facebook')}
          />
          <Form.Input
            name="socials_twitter"
            label={t('settings.user.form.socials.twitter')}
            onChange={handleChangeNoValidation}
            onBlur={handleBlurValidate}
            placeholder={user.socials_twitter}
            error={resolveError('socials_twitter')}
          />
        </Form.Group>
        <Form.Group widths="equal">
          <Form.Input
            name="socials_instagram"
            label={t('settings.user.form.socials.instagram')}
            onChange={handleChangeNoValidation}
            onBlur={handleBlurValidate}
            placeholder={user.socials_instagram}
            error={resolveError('socials_instagram')}
          />
          <Form.Input
            name="socials_linkedin"
            label={t('settings.user.form.socials.linkedin')}
            onChange={handleChangeNoValidation}
            onBlur={handleBlurValidate}
            placeholder={user.socials_linkedin}
            error={resolveError('socials_linkedin')}
          />
        </Form.Group>

        <Button
          className="submit-user-settings-btn"
          type="submit"
          color="blue"
          loading={isSubmitting}
          floated="right"
        >
          {t('settings.user.form.ui.update')}
        </Button>
      </div>
    </Form>
  );
};

UserSettingsFormView.propTypes = {
  form: PropTypes.shape({
    formState: PropTypes.shape({
      isSubmitting: PropTypes.bool
    }),
    handleSubmit: PropTypes.func,
    setValue: PropTypes.func
  }).isRequired,
  user: PropTypes.shape({
    username: PropTypes.string,
    bio: PropTypes.string,
    given_name: PropTypes.string,
    family_name: PropTypes.string,
    picture: PropTypes.string,
    email_notifications_enabled: PropTypes.bool,
    institution_name: PropTypes.string,
    institution_position: PropTypes.string,
    professional_affiliation: PropTypes.string,
    location_country: PropTypes.string,
    location_province: PropTypes.string,
    location_city: PropTypes.string,
    phone: PropTypes.string,
    socials_website: PropTypes.string,
    socials_facebook: PropTypes.string,
    socials_twitter: PropTypes.string,
    socials_instagram: PropTypes.string,
    socials_linkedin: PropTypes.string
  }).isRequired,
  onSubmit: PropTypes.func.isRequired,
  success: PropTypes.bool.isRequired,
  error: PropTypes.string
};

UserSettingsFormView.defaultProps = {
  error: null
};

export default UserSettingsFormView;
