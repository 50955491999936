import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';
import { joiPasswordExtendCore } from 'joi-password';
import useLocale from '../../../hooks/i18n';
import SignUpFormView from './SignUpFormView';
import { getGravatarURL } from '../../../utils/avatar';
import { VALID_USERNAME_REGEX } from '../../../constants/schemas';

const joiPassword = Joi.extend(joiPasswordExtendCore);

const parseFormData = (data) => {
  return Object.keys(data).reduce((acc, key) => {
    if (key.startsWith('extra_')) {
      const newKey = key.split('extra_')[1];
      acc.extras[newKey] = data[key];
    } else {
      acc[key] = data[key];
    }

    return acc;
  }, { extras: {} });
};

// Strings might need to be updated if any of the values of the rules below change.
const SignUpSchema = Joi.object({
  email: Joi.string().email({ tlds: { allow: false } }).max(256).trim().required()
    .messages({
      'string.email': 'signup.errors.email.invalid',
      'string.max': 'signup.errors.email.max',
      'string.empty': 'signup.errors.email.required',
      'any.required': 'signup.errors.email.required'
    }),
  username: Joi.string().min(3).max(20).disallow('me').pattern(VALID_USERNAME_REGEX).lowercase().trim().required()
    .messages({
      'string.min': 'signup.errors.username.length',
      'string.max': 'signup.errors.username.length',
      'any.invalid': 'signup.errors.username.disallowed',
      'string.pattern.base': 'signup.errors.username.pattern',
      'string.empty': 'signup.errors.username.required',
      'any.required': 'signup.errors.username.required'
    }),
  given_name: Joi.string().min(1).max(45).trim().required()
    .messages({
      'string.min': 'signup.errors.given_name.length',
      'string.max': 'signup.errors.given_name.length',
      'string.empty': 'signup.errors.given_name.required',
      'any.required': 'signup.errors.given_name.required'
    }),
  family_name: Joi.string().min(1).max(45).trim().required()
    .messages({
      'string.max': 'signup.errors.family_name.length',
      'string.min': 'signup.errors.family_name.length',
      'string.empty': 'signup.errors.family_name.required',
      'any.required': 'signup.errors.family_name.required'
    }),
  extra_institution_name: Joi.string().max(256).trim().required()
    .messages({
      'string.max': 'signup.errors.institution_name.max',
      'string.empty': 'signup.errors.institution_name.required',
      'any.required': 'signup.errors.institution_name.required'
    }),
  extra_institution_position: Joi.string().max(256).trim().required()
    .messages({
      'string.max': 'signup.errors.institution_position.max',
      'string.empty': 'signup.errors.institution_position.required',
      'any.required': 'signup.errors.institution_position.required'
    }),
  extra_professional_affiliation: Joi.string().max(256).trim().required()
    .messages({
      'string.max': 'signup.errors.professional_affiliation.max',
      'string.empty': 'signup.errors.professional_affiliation.required',
      'any.required': 'signup.errors.professional_affiliation.required'
    }),
  extra_location_country: Joi.string().max(256).trim().required()
    .messages({
      'string.max': 'signup.errors.location_country.max',
      'string.empty': 'signup.errors.location_country.required',
      'any.required': 'signup.errors.location_country.required'
    }),
  extra_location_province: Joi.string().max(256).trim().required()
    .messages({
      'string.max': 'signup.errors.location_province.max',
      'string.empty': 'signup.errors.location_province.required',
      'any.required': 'signup.errors.location_province.required'
    }),
  extra_location_city: Joi.string().max(256).trim().required()
    .messages({
      'string.max': 'signup.errors.location_city.max',
      'string.empty': 'signup.errors.location_city.required',
      'any.required': 'signup.errors.location_city.required'
    }),

  password: joiPassword.string().min(8).minOfSpecialCharacters(1).minOfLowercase(1).minOfUppercase(1).noWhiteSpaces().required()
    .messages({
      'password.minOfSpecialCharacters': 'signup.errors.password.special_chars',
      'password.minOfLowercase': 'signup.errors.password.lowercase',
      'password.minOfUppercase': 'signup.errors.password.uppercase',
      'password.noWhiteSpaces': 'signup.errors.password.white_spaces',
      'string.empty': 'signup.errors.password.required',
      'any.required': 'signup.errors.password.required'
    }),
  password_confirm: Joi.any().equal(Joi.ref('password')).required()
    .messages({
      'any.only': 'signup.errors.password_confirm.different',
      'string.empty': 'signup.errors.password_confirm.required',
      'any.required': 'signup.errors.password_confirm.required'
    })
});

const SignUpFormLogic = ({ onSubmit }) => {
  const form = useForm({
    mode: 'onSubmit',
    resolver: joiResolver(SignUpSchema)
  });
  const { currentLocale } = useLocale();
  const [error, setError] = useState(null);

  const handleSubmit = async (data) => {
    const parsedData = parseFormData(data);
    parsedData.picture = getGravatarURL(data.email);
    parsedData.locale = currentLocale;

    try {
      await onSubmit(parsedData);
    } catch (error) {
      setError(error);
    }
  };

  return (
    <SignUpFormView form={form} onSubmit={handleSubmit} error={error} />
  );
};

SignUpFormLogic.propTypes = {
  onSubmit: PropTypes.func.isRequired
};

export default SignUpFormLogic;
