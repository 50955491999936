/* eslint-disable no-empty-function */
const devMode = process.env.REACT_APP_NODE_ENV === 'development';
const noop = () => { };

export const devLog = devMode ? {
  info: console.info,
  log: console.log,
  error: console.error,
  warn: console.warn
} : {
  info: noop,
  log: noop,
  error: noop,
  warn: noop
};
