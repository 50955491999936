import React from 'react';
import useQueryParams from '../../../hooks/queryParams';
import PropTypes from 'prop-types';

const LinkWithScroll = ({ path, scrollTo, content, className }) => {
  const { navigateWithQueryParams } = useQueryParams();

  const handleClick = () => {
    navigateWithQueryParams(path, {
      scrollTo: scrollTo
    });
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleClick();
    }
  };

  return (
    <span
      className={className}
      role="link"
      onClick={handleClick}
      onKeyDown={handleKeyDown}
      tabIndex={0}
    >
      {content}
    </span>
  );
};

LinkWithScroll.propTypes = {
  path: PropTypes.string.isRequired,
  scrollTo: PropTypes.string.isRequired,
  content: PropTypes.node.isRequired,
  className: PropTypes.string
};

LinkWithScroll.defaultProps = {
  className: ''
};

export default LinkWithScroll;
