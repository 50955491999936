import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Segment } from 'semantic-ui-react';
import { BasicInfoFormView } from '../../transformationForm';
import AutoResizeTextArea from '../../../common/form/autoResizeTextArea';
import useLocale from '../../../../hooks/i18n';
import useFormHelper from '../../../../hooks/formHelper';

const TEXT_AREA_LABEL = 'comment_basic_information';

const CurateBasicInfoFormView = ({ transformationForm, form, locations, source_classes, source_sub_classes, source_types, trl_eu }) => {
  const { t } = useLocale();
  const { handleChangeNoValidation, handleBlurValidate, resolveError } = useFormHelper(form);
  const { getValues } = form;

  return (
    <Fragment>
      <BasicInfoFormView
        renderAs={Segment}
        editable={false}
        form={transformationForm}
        locations={locations}
        source_classes={source_classes}
        source_sub_classes={source_sub_classes}
        source_types={source_types}
        trl_eu={trl_eu}
      />

      <AutoResizeTextArea
        name={TEXT_AREA_LABEL}
        id={TEXT_AREA_LABEL}
        label={t('curate_contribution.form.comment_basic_information.label')}
        onChange={handleChangeNoValidation}
        onBlur={handleBlurValidate}
        error={resolveError(TEXT_AREA_LABEL)}
        defaultValue={getValues(TEXT_AREA_LABEL)}
      />
    </Fragment>
  );
};

CurateBasicInfoFormView.propTypes = {
  transformationForm: PropTypes.shape({
    formState: PropTypes.shape({
      isSubmitting: PropTypes.bool
    }),
    handleSubmit: PropTypes.func,
    getValues: PropTypes.func
  }).isRequired,
  form: PropTypes.shape({
    formState: PropTypes.shape({
      isSubmitting: PropTypes.bool
    }),
    handleSubmit: PropTypes.func,
    getValues: PropTypes.func
  }).isRequired,
  locations: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    short_name: PropTypes.string
  })).isRequired,
  source_classes: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    class: PropTypes.string
  })).isRequired,
  source_sub_classes: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    sub_class: PropTypes.string
  })).isRequired,
  source_types: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    type: PropTypes.string
  })).isRequired,
  trl_eu: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string
  })).isRequired
};

export default CurateBasicInfoFormView;
