/* eslint-disable max-lines */
class ResourceService {
  constructor(client) {
    this.client = client;
  }

  async #getAllResources(lang) {
    try {
      const response = await this.client.instance.get('/resources', {
        params: {
          lang
        }
      });
      const resources = response.data.data;

      return {
        ...resources,
        climates: resources.climates.sort((a, b) =>
          a.name.localeCompare(b.name)
        ),
        currencies: resources.currencies.sort((a, b) =>
          a.name.localeCompare(b.name)
        ),
        locations: resources.locations.sort((a, b) =>
          a.name.localeCompare(b.name)
        ),
        source_classes: resources.source_classes.sort((a, b) =>
          a.class.localeCompare(b.class)
        ),
        source_sub_classes: resources.source_sub_classes.sort((a, b) =>
          a.sub_class.localeCompare(b.sub_class)
        ),
        source_types: resources.source_types.sort((a, b) =>
          a.type.localeCompare(b.type)
        ),
        trl_eu: resources.trl_eu.sort((a, b) => a.name.localeCompare(b.name)),
        sustainable_development_goals:
          resources.sustainable_development_goals.sort((a, b) =>
            a.goal.localeCompare(b.goal)
          )
      };
    } catch (error) {
      this.client.handleResponseError('Error fetching all resources.', error);
    }
  }

  getAllResources(lang) {
    return {
      key: ['resources', lang],
      fn: () => this.#getAllResources(lang)
    };
  }

  async #getAllLanguages() {
    try {
      const response = await this.client.instance.get('/resources/languages');
      return response.data.data;
    } catch (error) {
      this.client.handleResponseError(
        'Error fetching all language resources.',
        error
      );
    }
  }

  getAllLanguages() {
    return {
      key: ['resources', 'languages'],
      fn: () => this.#getAllLanguages()
    };
  }

  async postClimate({ en, es, pt }) {
    try {
      const response = await this.client.instance.post('/resources/climates', {
        en,
        es,
        pt
      });
      return response.data.data;
    } catch (error) {
      this.client.handleResponseError(
        `Error creating new climate resource`,
        error
      );
    }
  }

  async updateClimateByID(id, lang, name) {
    try {
      const response = await this.client.instance.patch(
        `/resources/climates/${id}`,
        name,
        { params: { lang } }
      );
      return response.data.data;
    } catch (error) {
      this.client.handleResponseError(
        `Error updating climate with id: ${id}`,
        error
      );
    }
  }

  async postCurrency({ en, es, pt }) {
    try {
      const response = await this.client.instance.post('/resources/currencies', {
        en,
        es,
        pt
      });
      return response.data.data;
    } catch (error) {
      this.client.handleResponseError(
        `Error creating new currency resource`,
        error
      );
    }
  }

  async updateCurrencyByID(id, lang, currency) {
    try {
      const response = await this.client.instance.patch(
        `/resources/currencies/${id}`,
        currency,
        { params: { lang } }
      );
      return response.data.data;
    } catch (error) {
      this.client.handleResponseError(
        `Error updating currency with id: ${id}`,
        error
      );
    }
  }

  async postSourceClass({ en, es, pt }) {
    try {
      const response = await this.client.instance.post('/resources/source-classes', {
        en,
        es,
        pt
      });
      return response.data.data;
    } catch (error) {
      this.client.handleResponseError(
        `Error creating new source class resource`,
        error
      );
    }
  }

  async updateSourceClassByID(id, lang, sourceClass) {
    try {
      const response = await this.client.instance.patch(
        `/resources/source-classes/${id}`,
        sourceClass,
        { params: { lang } }
      );
      return response.data.data;
    } catch (error) {
      this.client.handleResponseError(
        `Error updating source class with id: ${id}`,
        error
      );
    }
  }

  async postSourceSubClass({ en, es, pt }) {
    try {
      const response = await this.client.instance.post('/resources/source-sub-classes', {
        en,
        es,
        pt
      });
      return response.data.data;
    } catch (error) {
      this.client.handleResponseError(
        `Error creating new source sub-class resource`,
        error
      );
    }
  }

  async updateSourceSubClassByID(id, lang, sourceSubClass) {
    try {
      const response = await this.client.instance.patch(
        `/resources/source-sub-classes/${id}`,
        sourceSubClass,
        { params: { lang } }
      );
      return response.data.data;
    } catch (error) {
      this.client.handleResponseError(
        `Error updating source sub-class with id: ${id}`,
        error
      );
    }
  }

  async postSourceType({ en, es, pt }) {
    try {
      const response = await this.client.instance.post('/resources/source-type', {
        en,
        es,
        pt
      });
      return response.data.data;
    } catch (error) {
      this.client.handleResponseError(
        `Error creating new source type resource`,
        error
      );
    }
  }

  async updateSourceTypeByID(id, lang, sourceType) {
    try {
      const response = await this.client.instance.patch(
        `/resources/source-types/${id}`,
        sourceType,
        { params: { lang } }
      );
      return response.data.data;
    } catch (error) {
      this.client.handleResponseError(
        `Error updating source type with id: ${id}`,
        error
      );
    }
  }

  async postTRL({ en, es, pt }) {
    try {
      const response = await this.client.instance.post('/resources/trl-eu', {
        en,
        es,
        pt
      });
      return response.data.data;
    } catch (error) {
      this.client.handleResponseError(
        `Error creating new trl-eu resource`,
        error
      );
    }
  }

  async updateTRLByID(id, lang, trl) {
    try {
      const response = await this.client.instance.patch(
        `/resources/trl-eu/${id}`,
        trl,
        { params: { lang } }
      );
      return response.data.data;
    } catch (error) {
      this.client.handleResponseError(
        `Error updating trl-eu with id: ${id}`,
        error
      );
    }
  }

  async postSustainableDevelopmentGoal({ en, es, pt }) {
    try {
      const response = await this.client.instance.post('/resources/sustainable-development-goals', {
        en,
        es,
        pt
      });
      return response.data.data;
    } catch (error) {
      this.client.handleResponseError(
        `Error creating new sustainable development goal resource`,
        error
      );
    }
  }

  async updateSustainableDevelopmentGoalByID(id, lang, sustainableDevelopmentGoal) {
    try {
      const response = await this.client.instance.patch(
        `/resources/sustainable-development-goals/${id}`,
        sustainableDevelopmentGoal,
        { params: { lang } }
      );
      return response.data.data;
    } catch (error) {
      this.client.handleResponseError(
        `Error updating sustainable development goal with id: ${id}`,
        error
      );
    }
  }

  async postLocation({ en, es, pt }) {
    try {
      const response = await this.client.instance.post('/resources/locations', {
        en,
        es,
        pt
      });
      return response.data.data;
    } catch (error) {
      this.client.handleResponseError(
        `Error creating new location resource`,
        error
      );
    }
  }

  async updateLocationByID(id, lang, location) {
    try {
      const response = await this.client.instance.patch(
        `/resources/locations/${id}`,
        location,
        { params: { lang } }
      );
      return response.data.data;
    } catch (error) {
      this.client.handleResponseError(
        `Error updating location with id: ${id}`,
        error
      );
    }
  }
}

export default ResourceService;
