import React from 'react';
import PropTypes from 'prop-types';
import { Form, Container, Message, Button } from 'semantic-ui-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useLocale from '../../../../hooks/i18n';
import useFormHelper from '../../../../hooks/formHelper';

const ForgotPasswordFormViewOne = ({ form, onSubmit, error }) => {
  const { t } = useLocale();
  const { handleChangeNoValidation, handleBlurValidate, resolveError } = useFormHelper(form);
  const { handleSubmit, formState: { isSubmitting } } = form;

  return (
    <Form onSubmit={handleSubmit(onSubmit)} error={!!error}>
      <Container>
        <Message error header={t('forgot_password.errors.header')} content={error} />

        <Form.Input
          name="email"
          fluid
          label={t('forgot_password.form.email')}
          placeholder="your@email.com"
          onChange={handleChangeNoValidation}
          onBlur={handleBlurValidate}
          error={resolveError('email')}
        />

        <Button type="submit" color="blue" loading={isSubmitting}>
          <FontAwesomeIcon icon="fa-solid fa-arrow-right" />
        </Button>
      </Container>
    </Form>
  );
};

ForgotPasswordFormViewOne.propTypes = {
  form: PropTypes.shape({
    formState: PropTypes.shape({
      isSubmitting: PropTypes.bool
    }),
    handleSubmit: PropTypes.func
  }).isRequired,
  onSubmit: PropTypes.func.isRequired,
  error: PropTypes.string
};

ForgotPasswordFormViewOne.defaultProps = {
  error: null
};

export default ForgotPasswordFormViewOne;
