import React from 'react';
import PropTypes from 'prop-types';
import { Form, Icon, Popup } from 'semantic-ui-react';

const PopupFormFieldSelect = ({
  id,
  name,
  label,
  hint,
  hintExample,
  options,
  ...props
}) => {
  return (
    <Form.Field>
      <div className="field hint-container">
        <label htmlFor={id}>{label}</label>
        <Popup trigger={<Icon name="help circle" />} position="right center">
          <p>{hint}</p>
          {hintExample && <p>{hintExample}</p>}
        </Popup>
      </div>
      <Form.Select id={id} name={name} fluid options={options} {...props} />
    </Form.Field>
  );
};

PopupFormFieldSelect.propTypes = {
  label: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  hint: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      text: PropTypes.string
    })
  ).isRequired,
  hintExample: PropTypes.string
};

PopupFormFieldSelect.defaultProps = {
  hintExample: null
};

export default PopupFormFieldSelect;
