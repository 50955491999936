import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';
import LoginFormView from './LoginFormView';

const LoginSchema = Joi.object({
  email: Joi.string().email({ tlds: { allow: false } }).trim().required()
    .messages({
      'string.email': 'login.errors.email.invalid',
      'string.empty': 'login.errors.email.required',
      'any.required': 'login.errors.email.required'
    }),
  password: Joi.string().required()
    .messages({
      'string.empty': 'login.errors.password.required',
      'any.required': 'login.errors.password.required'
    }),
  staySignedIn: Joi.boolean().default(false)
});

const LoginFormLogic = ({ onSubmit }) => {
  const form = useForm({
    mode: 'onSubmit',
    resolver: joiResolver(LoginSchema)
  });
  const [error, setError] = useState(null);

  const handleSubmit = async (data) => {
    try {
      await onSubmit(data);
    } catch (error) {
      setError(error);
    }
  };

  return (
    <LoginFormView form={form} onSubmit={handleSubmit} error={error} />
  );
};

LoginFormLogic.propTypes = {
  onSubmit: PropTypes.func.isRequired
};

export default LoginFormLogic;
