import React from 'react';
import { Container } from 'semantic-ui-react';
import { UserPastCuratorApplicationsLogic } from '../../components/user/userCuratorApplication';
import useLocale from '../../hooks/i18n';
import useTitle from '../../hooks/title';

const BecomeCuratorPage = () => {
  const { t } = useLocale();
  useTitle(t('page_titles.become_curator'));

  return (
    <Container text className="become-curator-page">
      <UserPastCuratorApplicationsLogic />
    </Container>
  );
};

export default BecomeCuratorPage;
