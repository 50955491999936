import React, { Fragment } from 'react';
import { Grid } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import useLocale from '../../../../hooks/i18n';
import { yesNoUnsureStrings } from '../../../../pages/transformationPage/TransformationPage';
import { EVIDENCE_MAX_AGE } from '../../../../constants/transformation';

const getEvidenceAge = (publishingYear) => {
  const currentYear = new Date().getFullYear();
  return currentYear - publishingYear;
};

const TransformationViewSectionTransformationProcess = React.forwardRef(({ transformationProcess }, ref) => {
  const { t } = useLocale();
  return (
    <Fragment>
      <Grid.Row>
        <Grid.Column>
          <h3 ref={ref}>
            {t('transformation.form.transformation_process.brief_description_of_transformation_process')}
          </h3>
          <p>{transformationProcess.brief_description}</p>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <h3>{t('transformation.form.transformation_process.evidences.label')}</h3>
          {transformationProcess.evidences.map((evidence, i) => {
            const evidenceAge = getEvidenceAge(evidence.publishing_year);
            const shouldDisplayWarning = evidenceAge >= EVIDENCE_MAX_AGE;
            const className = shouldDisplayWarning ? 'evidence_url warning' : 'evidence_url';
            const title = shouldDisplayWarning ? t('transformation.view.evidences.age_warning', { age: evidenceAge }) : '';

            return (
              <a key={i} className={className} target="_blank" rel="noreferrer" href={evidence.evidence_url} title={title}>
                ({evidence.publishing_year}) {evidence.url_description}
              </a>
            );
          })}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <h3>{t('transformation.form.transformation_process.developed_technologies.label')}</h3>
          <p>{transformationProcess.developed_technologies.join(', ')}</p>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <h3>{t('transformation.form.transformation_process.products_or_services.label')}</h3>
          <p>{transformationProcess.products_or_services.join(', ')}</p>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <h3>{t('transformation.form.transformation_process.industrial_applications.label')}</h3>
          <p>{transformationProcess.industrial_applications.join(', ')}</p>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <h3>{t('transformation.form.transformation_process.associated_pollutants.label')}</h3>
          <p>{transformationProcess.associated_pollutants.join(', ')}</p>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <h3>{t('transformation.form.transformation_process.free_access.label')}</h3>
          <p>{t(yesNoUnsureStrings[transformationProcess.free_access])}</p>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <h3>{t('transformation.form.transformation_process.patents_associated.label')}</h3>
          <p>{t(yesNoUnsureStrings[transformationProcess.patents_associated])}</p>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <h3>{t('transformation.form.transformation_process.licenses_emitted.label')}</h3>
          <p>{t(yesNoUnsureStrings[transformationProcess.licenses_emitted])}</p>
        </Grid.Column>
      </Grid.Row>
    </Fragment>
  );
});

TransformationViewSectionTransformationProcess.propTypes = {
  transformationProcess: PropTypes.shape({
    brief_description: PropTypes.string,
    evidences: PropTypes.arrayOf(PropTypes.shape({
      evidence_url: PropTypes.string,
      url_description: PropTypes.string,
      publishing_year: PropTypes.number
    })),
    developed_technologies: PropTypes.arrayOf(PropTypes.string),
    products_or_services: PropTypes.arrayOf(PropTypes.string),
    industrial_applications: PropTypes.arrayOf(PropTypes.string),
    associated_pollutants: PropTypes.arrayOf(PropTypes.string),
    free_access: PropTypes.string,
    patents_associated: PropTypes.string,
    licenses_emitted: PropTypes.string
  }).isRequired
};

export default TransformationViewSectionTransformationProcess;
