import { useMemo, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const useQueryParams = () => {
  const { search } = useLocation();
  const navigate = useNavigate();

  const queryParams = useMemo(() => new URLSearchParams(search), [search]);

  const setQueryParam = useCallback((query, value) => {
    queryParams.set(query, value);
    navigate({ search: queryParams.toString() });
  }, [queryParams, navigate]);

  const navigateWithQueryParams = useCallback((route, params) => {
    navigate(`${route}?${new URLSearchParams(params).toString()}`);
  }, [navigate]);

  return {
    queryParams,
    setQueryParam,
    navigateWithQueryParams
  };
};

export default useQueryParams;
