import React from 'react';
import BecomeCuratorFormLogic from './BecomeCuratorFormLogic';
import useAPI from '../../../hooks/api';
import { useMutation } from '@tanstack/react-query';

const BecomeCuratorForm = () => {
  const { client, queryClient } = useAPI();
  const postApplicationMutation = useMutation(
    client.application.postApplicationToBecomeCurator.bind(client.application)
  );

  const handleSubmit = async ({ description }) => {
    try {
      await postApplicationMutation.mutateAsync(description);
      await queryClient.invalidateQueries(client.application.getAllCuratorApplicationsForUser().key);
      await queryClient.invalidateQueries(client.application.getAllCuratorApplications().key);
    } catch (error) {
      throw (error);
    }
  };

  return <BecomeCuratorFormLogic onSubmit={handleSubmit} />;
};

export default BecomeCuratorForm;
