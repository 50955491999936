import React from 'react';
import PropTypes from 'prop-types';
import { Loader, Dimmer } from 'semantic-ui-react';
import useLocale from '../../../hooks/i18n';

const Loading = ({ fullscreen, indeterminate, size, inverted }) => {
  const { t } = useLocale();

  return (
    <Dimmer active page={fullscreen}>
      <Loader indeterminate={indeterminate} size={size} inverted={inverted}>
        {t('ui.loading.loading')}
      </Loader>
    </Dimmer>
  );
};

Loading.propTypes = {
  fullscreen: PropTypes.bool,
  indeterminate: PropTypes.bool,
  size: PropTypes.oneOf(['mini', 'tiny', 'small', 'medium', 'large', 'big', 'huge', 'massive']),
  inverted: PropTypes.bool
};

Loading.defaultProps = {
  fullscreen: false,
  indeterminate: false,
  size: 'medium',
  inverted: false
};

export default Loading;
