import { createBrowserHistory } from 'history';

export const history = createBrowserHistory();

export const stripHashFromHostname = () => {
  const { hash } = history.location;
  if (hash) {
    const path = hash.replace(/^#/, '');
    if (path) {
      history.replace(path);
    }
  }
};
