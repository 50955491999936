/* eslint-disable max-statements */
import React, { Fragment, useReducer } from 'react';
import PropTypes from 'prop-types';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import CurateTransformationFormLogic from './CurateTransformationFormLogic';
import Loading from '../../common/loading';
import RequestErrorMessage from '../../common/requestErrorMessage';
import ConfirmationModal from '../../forms/transformationForm/ConfirmationModal';
import useLocale from '../../../hooks/i18n';
import useAPI from '../../../hooks/api';
import { PROTECTED_ROUTES } from '../../../constants/routes';

const ACTIONS = {
  SHOW: 'SHOW',
  HIDE: 'HIDE',
  SET_ERROR: 'SET_ERROR'
};

const initialModalState = {
  delete: {
    error: null,
    show: false
  },
  commit: {
    error: null,
    show: false
  }
};

const modalReducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.SHOW:
      return {
        ...state,
        [action.modal]: {
          ...state[action.modal],
          show: true
        }
      };

    case ACTIONS.HIDE:
      return {
        ...state,
        [action.modal]: {
          ...state[action.modal],
          show: false
        }
      };

    case ACTIONS.SET_ERROR:
      return {
        ...state,
        [action.modal]: {
          ...state[action.modal],
          error: action.payload
        }
      };

    default:
      return state;
  }
};

const CurateTransformationForm = ({ transformation, curation }) => {
  const initialCuration = {
    comment_basic_information: curation.comment_basic_information,
    comment_transformation_process: curation.comment_transformation_process,
    comment_scalability: curation.comment_scalability,
    comment_sustainability: curation.comment_sustainability,
    comment_market: curation.comment_market,
    comment_regulations: curation.comment_regulations,
    publish: curation.publish
  };

  const { t, currentLocale } = useLocale();
  const navigate = useNavigate();
  const { client, queryClient, getFullResponseErrorMessage } = useAPI();
  const resourceRequest = client.resource.getAllResources(currentLocale);
  const { isLoading, error, data: resources } = useQuery(resourceRequest.key, resourceRequest.fn);
  const updateCurationMutation = useMutation((data) => client.curation.putOngoingCuration(curation.id, data));
  const commitCurationMutation = useMutation(() => client.curation.commitOngoingCuration(curation.id));
  const deleteCurationMutation = useMutation(() => client.curation.deleteOngoingCuration(curation.id));

  const [modalState, modalDispatch] = useReducer(modalReducer, initialModalState);

  const handleSave = async (data) => {
    try {
      await updateCurationMutation.mutateAsync(data);
    } catch (error) {
      throw getFullResponseErrorMessage(error);
    }
  };

  const handleCommitConfirm = async () => {
    modalDispatch({ type: ACTIONS.SET_ERROR, modal: 'commit', payload: null });

    try {
      await commitCurationMutation.mutateAsync();
      await queryClient.invalidateQueries({
        predicate: (query) => {
          const keyCuration = ['curation', 'ongoing'];
          const keyTransformation = ['transformations', 'awaiting-curation'];
          return keyCuration.every((e) => query.queryKey.includes(e)) || keyTransformation.every((e) => query.queryKey.includes(e));
        }
      });

      modalDispatch({ type: ACTIONS.HIDE, modal: 'commit' });
      navigate(PROTECTED_ROUTES.curations);
    } catch (error) {
      modalDispatch({ type: ACTIONS.SET_ERROR, modal: 'commit', payload: getFullResponseErrorMessage(error) });
    }
  };

  const handleDeleteConfirm = async () => {
    modalDispatch({ type: ACTIONS.SET_ERROR, modal: 'delete', payload: null });

    try {
      await deleteCurationMutation.mutateAsync();
      await queryClient.invalidateQueries({
        predicate: (query) => {
          const keyCuration = ['curation', 'ongoing'];
          const keyTransformation = ['transformations', 'awaiting-curation'];
          return keyCuration.every((e) => query.queryKey.includes(e)) || keyTransformation.every((e) => query.queryKey.includes(e));
        }
      });

      modalDispatch({ type: ACTIONS.HIDE, modal: 'delete' });
      navigate(PROTECTED_ROUTES.curations);
    } catch (error) {
      modalDispatch({ type: ACTIONS.SET_ERROR, modal: 'delete', payload: getFullResponseErrorMessage(error) });
    }
  };

  const handleModalOpen = (modalName) => () => {
    modalDispatch({ type: ACTIONS.SHOW, modal: modalName });
  };

  const handleModalClose = (modalName) => () => {
    modalDispatch({ type: ACTIONS.HIDE, modal: modalName });
  };

  if (isLoading) {
    return (
      <Loading fullscreen />
    );
  }

  if (error) {
    return (
      <RequestErrorMessage header={t('transformation.form.error.header')} error={getFullResponseErrorMessage(error)} />
    );
  }

  return (
    <Fragment>
      <CurateTransformationFormLogic
        transformation={transformation.transformation_obj}
        initialCuration={initialCuration}
        onSave={handleSave}
        onCommit={handleModalOpen('commit')}
        onDelete={handleModalOpen('delete')}
        resources={resources}
      />

      <ConfirmationModal
        open={modalState.delete.show}
        error={modalState.delete.error}
        loading={deleteCurationMutation.isLoading}
        onConfirm={handleDeleteConfirm}
        onClose={handleModalClose('delete')}
        header={t('curate_contribution.modals.delete.header')}
        contentArray={[t('curate_contribution.modals.delete.description.1'), t('curate_contribution.modals.delete.description.2')]}
        confirmLabel={t('curate_contribution.modals.delete.actions.delete.label')}
        closeLabel={t('curate_contribution.modals.delete.actions.close.label')}
        errorHeader={t('curate_contribution.modals.delete.error.header')}
      />

      <ConfirmationModal
        open={modalState.commit.show}
        error={modalState.commit.error}
        loading={commitCurationMutation.isLoading}
        onConfirm={handleCommitConfirm}
        onClose={handleModalClose('commit')}
        header={t('curate_contribution.modals.commit.header')}
        contentArray={[t('curate_contribution.modals.commit.description.1'), t('curate_contribution.modals.commit.description.2')]}
        confirmLabel={t('curate_contribution.modals.commit.actions.submit.label')}
        closeLabel={t('curate_contribution.modals.commit.actions.close.label')}
        errorHeader={t('curate_contribution.modals.commit.error.header')}
      />
    </Fragment>
  );
};

CurateTransformationForm.propTypes = {
  transformation: PropTypes.shape({
    transformation_obj: PropTypes.shape({})
  }).isRequired,
  curation: PropTypes.shape({
    id: PropTypes.number.isRequired,
    comment_basic_information: PropTypes.string,
    comment_transformation_process: PropTypes.string,
    comment_scalability: PropTypes.string,
    comment_sustainability: PropTypes.string,
    comment_market: PropTypes.string,
    comment_regulations: PropTypes.string,
    publish: PropTypes.bool
  }).isRequired
};

export default CurateTransformationForm;
