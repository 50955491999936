import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Auth } from 'aws-amplify';
import Client from '../services/api/Client';
import { devLog } from '../utils/logging';

const client = new Client(process.env.REACT_APP_API_URL);

const AppContext = React.createContext(null);

const AppContextProvider = ({ children }) => {
  const [token, setToken] = useState('');
  const [me, setMe] = useState(null);
  const [initialLoading, setInitialLoading] = useState(true);

  const updateCurrentUser = useCallback(async () => {
    if (!client.token) {
      setMe(null);
      return;
    }

    try {
      const user = await client.user.getCurrentUser();
      devLog.info('Fetched current user:', user);
      setMe(user);
    } catch (error) {
      devLog.error('Could not fetch current user:', error);
    }
  }, []);

  const setTokenAsync = useCallback(async (token) => {
    client.setToken(token);
    setToken(token);
    await updateCurrentUser();
  }, [updateCurrentUser]);

  useEffect(() => {
    devLog.info('Trying to login automatically...');
    Auth.currentSession()
      .then(async (session) => {
        devLog.info('Current session:', session);
        await setTokenAsync(session.getAccessToken().getJwtToken());
      })
      .catch((error) => {
        devLog.warn('Error when trying to login automatically, maybe no user session was found?', error);
      })
      .finally(() => {
        setInitialLoading(false);
      });
  }, [setTokenAsync]);

  const context = {
    token,
    setTokenAsync,
    setMe,
    me,
    client
  };

  if (initialLoading) {
    return null;
  }

  return (
    <AppContext.Provider value={context}>
      {children}
    </AppContext.Provider>
  );
};

AppContextProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default AppContext;

export {
  AppContextProvider
};
