import React from 'react';
import PropTypes from 'prop-types';
import { Container, Pagination } from 'semantic-ui-react';
import useQueryParams from '../../../hooks/queryParams';

const PaginatedContainer = ({ pageQueryParamName, as, className, children, pagination, onChange }) => {
  const { setQueryParam } = useQueryParams();
  const ContainerComponent = as ?? Container;

  const handlePageChange = (_, { activePage }) => {
    setQueryParam(pageQueryParamName, activePage);
    onChange?.(activePage);
  };

  return (
    <ContainerComponent className={className}>
      {children}

      {
        pagination.totalPages > 0 && (
          <Pagination
            className="pagination-bar"
            activePage={pagination.currentPage}
            onPageChange={handlePageChange}
            totalPages={pagination.totalPages}
          />
        )
      }
    </ContainerComponent>
  );
};

PaginatedContainer.propTypes = {
  pageQueryParamName: PropTypes.string,
  as: PropTypes.func,
  className: PropTypes.string,
  children: PropTypes.node,
  pagination: PropTypes.shape({
    currentIndex: PropTypes.number.isRequired,
    total: PropTypes.number.isRequired,
    currentPage: PropTypes.number.isRequired,
    totalPages: PropTypes.number.isRequired,
    previousStart: PropTypes.number.isRequired,
    nextStart: PropTypes.number.isRequired,
    isFirstPage: PropTypes.bool.isRequired,
    isLastPage: PropTypes.bool.isRequired
  }).isRequired,
  onChange: PropTypes.func
};

PaginatedContainer.defaultProps = {
  pageQueryParamName: 'page',
  as: null,
  className: '',
  children: null,
  onChange: null
};

export default PaginatedContainer;
