import SignUpForm from './SignUpForm';
import SignUpFormLogic from './SignUpFormLogic';
import SignUpFormView from './SignUpFormView';

export default SignUpForm;

export {
  SignUpFormLogic,
  SignUpFormView
};
