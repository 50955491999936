import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Grid } from 'semantic-ui-react';
import useLocale from '../../../../hooks/i18n';

const TransformationViewSectionSustainability = React.forwardRef(({ sustainability }, ref) => {
  const { t } = useLocale();
  return (
    <Fragment>
      <Grid.Row>
        <Grid.Column>
          <h3 ref={ref}>
            {t('transformation.form.sustainability.contributes_species_preservation.label')}
          </h3>
          <p>{sustainability.contributes_species_preservation}</p>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <h3>{t('transformation.form.sustainability.contributes_circular_economy.label')}</h3>
          <p>{sustainability.contributes_circular_economy}</p>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <h3>{t('transformation.form.sustainability.sustainable_development_goals.label')}</h3>
          <p>{sustainability.sustainable_development_goals?.join(', ')}</p>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <h3>{t('transformation.form.sustainability.closes_productive_cycle_gap.label')}</h3>
          <p>
            {sustainability.closes_productive_cycle_gap ?
              t('transformation.form.ui.yes') :
              t('transformation.form.ui.no')}
          </p>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <h3>{t('transformation.form.sustainability.replaces_materials_fossil_origin.label')}</h3>
          <p>
            {sustainability.replaces_materials_fossil_origin ?
              t('transformation.form.ui.yes') :
              t('transformation.form.ui.no')}
          </p>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <h3>{t('transformation.form.sustainability.reduces_greenhouse_effect_gasses.label')}</h3>
          <p>
            {sustainability.reduces_greenhouse_effect_gasses ?
              t('transformation.form.ui.yes') :
              t('transformation.form.ui.no')}
          </p>
        </Grid.Column>
      </Grid.Row>
    </Fragment>
  );
});

TransformationViewSectionSustainability.propTypes = {
  sustainability: PropTypes.shape({
    contributes_species_preservation: PropTypes.string,
    contributes_circular_economy: PropTypes.string,
    sustainable_development_goals: PropTypes.arrayOf(PropTypes.string),
    closes_productive_cycle_gap: PropTypes.bool,
    replaces_materials_fossil_origin: PropTypes.bool,
    reduces_greenhouse_effect_gasses: PropTypes.bool
  }).isRequired
};

export default TransformationViewSectionSustainability;
