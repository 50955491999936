import React from 'react';
import PropTypes from 'prop-types';
import { Menu } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import CardContainer from '../../ui/cardContainer';
import useLocale from '../../../hooks/i18n';
import { PROTECTED_ROUTES } from '../../../constants/routes';

const menuItems = [
  { name: 'curator_requests', textKey: 'admin.dashboard.menu.curator_requests', link: PROTECTED_ROUTES.adminCuratorRequests },
  { name: 'resource_management', textKey: 'admin.dashboard.menu.resource_management', link: PROTECTED_ROUTES.adminResourceManagement }
];

const AdminDashboardMenu = ({ activeItem }) => {
  const { t } = useLocale();

  return (
    <CardContainer className="admin-dashboard-card-container">
      <Menu vertical secondary fluid className="admin-dashboard-menu">
        <Menu.Item header>
          {t('admin.dashboard.menu.header')}
        </Menu.Item>

        {
          menuItems.map(({ name, textKey, link }) => (
            <Menu.Item
              key={name}
              name={name}
              active={activeItem === name}
              as={Link}
              to={link}
            >
              {t(textKey)}
            </Menu.Item>
          ))
        }
      </Menu>
    </CardContainer>
  );
};

AdminDashboardMenu.propTypes = {
  activeItem: PropTypes.string.isRequired
};

export default AdminDashboardMenu;
