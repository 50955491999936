import React, { useRef, useState } from 'react';
import { Button, Container, Grid } from 'semantic-ui-react';
import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import useAPI from '../../hooks/api';
import useLocale from '../../hooks/i18n';
import useTitle from '../../hooks/title';
import RequestErrorMessage from '../../components/common/requestErrorMessage';
import Loading from '../../components/common/loading';
import PageDelimiter from '../../components/common/pageDelimiter';
import CardContainer from '../../components/ui/cardContainer';
import BackButton from '../../components/common/backButton';
import { PUBLIC_ROUTES } from '../../constants/routes';
import {
  TransformationViewSidebarHeader,
  TransformationViewSidebarMenu
} from '../../components/transformation/transformationView';
import {
  TransformationViewSectionBasicInformation,
  TransformationViewSectionConsiderations,
  TransformationViewSectionMarket,
  TransformationViewSectionScalability,
  TransformationViewSectionSustainability,
  TransformationViewSectionTransformationProcess
} from '../../components/transformation/transformationView/sections';

export const yesNoUnsureStrings = {
  yes: 'transformation.form.ui.yes',
  no: 'transformation.form.ui.no',
  idk: 'transformation.form.ui.dont.know'
};

const TransformationPage = () => {
  const { currentLocale, t } = useLocale();
  const { id } = useParams();
  const { client, getFullResponseErrorMessage } = useAPI();
  const request = client.transformation.getPublishedTransformation(
    id,
    currentLocale
  );
  const { isLoading, error, data } = useQuery(request.key, request.fn);
  useTitle(
    data?.transformation?.basic_information?.producing_species_name ??
      t('page_titles.loading')
  ); // TODO: Identify what should be the transformation title.

  const [currentSection, setCurrentSection] = useState(0);

  const basicInformationSection = useRef(null);
  const transformationProcessSection = useRef(null);
  const scalabilitySection = useRef(null);
  const sustainableDevelopmentSection = useRef(null);
  const marketSection = useRef(null);
  const otherConsiderationsSection = useRef(null);

  const sections = [
    basicInformationSection,
    transformationProcessSection,
    scalabilitySection,
    sustainableDevelopmentSection,
    marketSection,
    otherConsiderationsSection
  ];

  const scrollToSection = (elementRefIndex) => {
    if (sections[elementRefIndex]) {
      sections[elementRefIndex].current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handlePrintClick = () => {
    window.print();
  };

  if (isLoading) {
    return <Loading fullscreen />;
  }

  if (error) {
    return (
      <RequestErrorMessage
        header={t('transformation_view.request.error.header')}
        error={getFullResponseErrorMessage(error)}
      />
    );
  }

  if (!data) {
    return <Container text>{t('transformation_view.request.error')}</Container>;
  }

  return (
    <PageDelimiter className="transformation-page">
      <div className="buttons-container">
        <BackButton
          text={t('transformation_view.back_btn')}
          linkTo={PUBLIC_ROUTES.explore}
          className="back-btn"
        />
        <Button className="pdf-btn pdf-btn--mobile" onClick={handlePrintClick}>
          {t('transformation_view.save_btn')}
        </Button>
      </div>

      <Grid>
        <Grid.Column computer={5} tablet={6} mobile={16} className="transformation-sidebar">
          <div className="sidebar-container">
            <TransformationViewSidebarHeader
              producingSpeciesName={data.transformation.basic_information.producing_species_name}
              transformationTitle={data.transformation.basic_information.producing_species_name}
              authorUsername={data.author.username}
              authorPicture={data.author.picture}
              authorCompleteName={`${data.author.given_name} ${data.author.family_name}`}
              likes={data.likes}
              userLiked={data.user_liked}
              id={data.transformation.id_transformation}
            />
            <CardContainer className="menu-card-container">
              <TransformationViewSidebarMenu
                setCurrentSection={setCurrentSection}
                scrollToSection={scrollToSection}
                currentSection={currentSection}
              />
              <Button className="pdf-btn" onClick={handlePrintClick}>
                {t('transformation_view.save_btn')}
              </Button>
            </CardContainer>
          </div>
        </Grid.Column>
        <Grid.Column computer={11} tablet={10} mobile={16} className="transformation-view-column">
          <TransformationViewSidebarHeader
            className="transformation-header--print"
            producingSpeciesName={data.transformation.basic_information.producing_species_name}
            transformationTitle={data.transformation.basic_information.producing_species_name}
            authorUsername={data.author.username}
            authorPicture={data.author.picture}
            authorCompleteName={`${data.author.given_name} ${data.author.family_name}`}
            likes={data.likes}
            userLiked={data.user_liked}
            id={data.transformation.id_transformation}
          />
          <CardContainer>
            <Grid className="transformation-view-container">
              <TransformationViewSectionBasicInformation
                image={data.transformation.metadata.picture}
                basicInformation={data.transformation.basic_information}
                ref={basicInformationSection}
              />
              <TransformationViewSectionTransformationProcess
                ref={transformationProcessSection}
                transformationProcess={
                  data.transformation.transformation_process
                }
              />
              <TransformationViewSectionScalability
                scalability={data.transformation.scalability}
                ref={scalabilitySection}
              />
              <TransformationViewSectionSustainability
                sustainability={data.transformation.sustainability}
                ref={sustainableDevelopmentSection}
              />
              <TransformationViewSectionMarket
                market={data.transformation.market}
                ref={marketSection}
              />
              <TransformationViewSectionConsiderations
                regulations={data.transformation.regulations}
                ref={otherConsiderationsSection}
              />
            </Grid>
          </CardContainer>
        </Grid.Column>
      </Grid>
    </PageDelimiter>
  );
};

export default TransformationPage;
