import React from 'react';
import { useNavigate } from 'react-router-dom';
import ForgotPasswordFormLogic from './ForgotPasswordFormLogic';
import useAuth from '../../../hooks/auth';
import useLocale from '../../../hooks/i18n';
import { PUBLIC_ROUTES } from '../../../constants/routes';

const ForgotPasswordForm = () => {
  const { forgotPassword, forgotPasswordSubmit } = useAuth();
  const { t } = useLocale();
  const navigate = useNavigate();

  const handleForgotPassword = async ({ email }) => {
    try {
      await forgotPassword(email);
    } catch (forgotPasswordError) {
      throw forgotPasswordError.getFriendlyMessage(t);
    }
  };

  const handleForgotPasswordSubmit = async ({ email, code, password }) => {
    try {
      await forgotPasswordSubmit(email, code, password);
      navigate(PUBLIC_ROUTES.login);
    } catch (submitForgotPasswordError) {
      throw submitForgotPasswordError.getFriendlyMessage(t);
    }
  };

  return (
    <ForgotPasswordFormLogic onForgotPassword={handleForgotPassword} onForgotPasswordSubmit={handleForgotPasswordSubmit} />
  );
};

export default ForgotPasswordForm;
