import React from 'react';
import { Grid, Header } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import useLocale from '../../../hooks/i18n';

const UserCuratorApplication = ({ description, date, accepted }) => {
  const { t } = useLocale();
  return (
    <Grid celled className="user-curator-application">
      <Grid.Column width={10}>
        <Header as="h2" size="tiny">
          {t('become_curator.application.header')}
        </Header>
        <p className="description">{description}</p>
      </Grid.Column>
      <Grid.Column width={6}>
        <p>{t('become_curator.application.date')}{date}</p>
        <p>{t('become_curator.application.status')}</p>
        {accepted && <p>{t('become_curator.application.status.accepted')}</p>}
        {accepted === false && <p>{t('become_curator.application.status.denied')}</p>}
        {accepted === null && <p>{t('become_curator.application.status.active')}</p>}
      </Grid.Column>
    </Grid>
  );
};

UserCuratorApplication.propTypes = {
  description: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  accepted: PropTypes.bool
};

UserCuratorApplication.defaultProps = {
  accepted: null
};

export default UserCuratorApplication;
