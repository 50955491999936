import React from 'react';
import PropTypes from 'prop-types';
import { Button, Form, Message } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import useLocale from '../../../hooks/i18n';
import useFormHelper from '../../../hooks/formHelper';
import { PUBLIC_ROUTES } from '../../../constants/routes';
import symbol from '../../../assets/logo/symbol_green.svg';
import CardContainer from '../../ui/cardContainer';

const LoginFormView = ({ form, onSubmit, error }) => {
  const { t } = useLocale();
  const { handleChangeNoValidation, handleBlurValidate, resolveError } =
    useFormHelper(form);
  const {
    handleSubmit,
    formState: { isSubmitting }
  } = form;

  return (
    <CardContainer className="login-container">
      <Form
        className="login-form"
        onSubmit={handleSubmit(onSubmit)}
        error={!!error}
      >
        <Message error header={t('login.errors.header')} content={error} />
        <img className="login-symbol" src={symbol} alt="symbol logo" />
        <h1 className="ui-header">{t('login.header')}</h1>
        <p className="hero-text ui-text">
          {t('login.hero.text')}
        </p>
        <div className="form-fields">
          <Form.Input
            name="email"
            label={t('login.form.email')}
            placeholder="your@email.com"
            onChange={handleChangeNoValidation}
            onBlur={handleBlurValidate}
            error={resolveError('email')}
          />

          <Form.Input
            name="password"
            type="password"
            label={t('login.form.password')}
            onChange={handleChangeNoValidation}
            onBlur={handleBlurValidate}
            error={resolveError('password')}
          />

          <Form.Checkbox
            name="staySignedIn"
            type="checkbox"
            label={t('login.form.stay_signed_in')}
            onChange={handleChangeNoValidation}
            onBlur={handleBlurValidate}
            error={resolveError('staySignedIn')}
          />
        </div>

        <p className="links">
          <Link to={PUBLIC_ROUTES.forgotPassword}>
            {t('login.links.forgot_password')}
          </Link>

          <Link to={PUBLIC_ROUTES.resendVerification}>
            {t('login.links.resend_verification')}
          </Link>
        </p>

        <Button
          className="submit-login-btn"
          type="submit"
          color="green"
          fluid
          loading={isSubmitting}
        >
          {t('login.login')}
        </Button>
        <p>
          {t('login.dont.have.account')}
          <Link to={PUBLIC_ROUTES.signup}>{t('login.register')}</Link>
        </p>
      </Form>
    </CardContainer>
  );
};

LoginFormView.propTypes = {
  form: PropTypes.shape({
    formState: PropTypes.shape({
      isSubmitting: PropTypes.bool
    }),
    handleSubmit: PropTypes.func
  }).isRequired,
  onSubmit: PropTypes.func.isRequired,
  error: PropTypes.string
};

LoginFormView.defaultProps = {
  error: null
};

export default LoginFormView;
