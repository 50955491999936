import UserSettingsForm from './UserSettingsForm';
import UserSettingsFormView from './UserSettingsFormView';
import UserSettingsFormLogic from './UserSettingsFormLogic';

export default UserSettingsForm;

export {
  UserSettingsFormView,
  UserSettingsFormLogic
};
