import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import useAuth from '../hooks/auth';
import ProtectedRoute from './ProtectedRoute';
import PageWrapper from '../components/common/pageWrapper';
import HomePage from '../pages/home';
import SignUpPage from '../pages/signUp';
import LoginPage from '../pages/login';
import ForgotPasswordPage from '../pages/forgotPassword';
import ResendVerificationPage from '../pages/resendVerification';
import NotFoundPage from '../pages/notFound';
import UserProfilePage from '../pages/userProfile';
import EditContributionPage from '../pages/editContribution';
import MyContributionsPage from '../pages/myContributions';
import AwaitingCurationsPage from '../pages/awaitingCurations';
import EditCurationPage from '../pages/editCuration';
import UserSettingsPage from '../pages/userSettings';
import AdminCuratorRequestsPage from '../pages/adminCuratorRequests';
import AdminResourceManagementPage from '../pages/adminResourceManagement';
import BecomeCuratorPage, { BecomeCuratorFormPage } from '../pages/becomeCurator';
import TransformationPage from '../pages/transformationPage';
import ExplorePage from '../pages/explore';
import AboutPage from '../pages/about';
import TermsAndConditionsPage from '../pages/termsAndConditions';
import { PUBLIC_ROUTES, PROTECTED_ROUTES, DYNAMIC_PUBLIC_ROUTES, DYNAMIC_PROTECTED_ROUTES } from '../constants/routes';


const Router = () => {
  const { me, signedIn } = useAuth();

  return (
    <BrowserRouter>
      <PageWrapper>
        <Routes>
          <Route path={PUBLIC_ROUTES.home} element={<HomePage />} />
          <Route path={PUBLIC_ROUTES.signup} element={<SignUpPage />} />
          <Route path={PUBLIC_ROUTES.login} element={<LoginPage />} />
          <Route path={PUBLIC_ROUTES.about} element={<AboutPage />} />
          <Route path={PUBLIC_ROUTES.termsAndConditions} element={<TermsAndConditionsPage />} />

          <Route path={PUBLIC_ROUTES.forgotPassword} element={<ForgotPasswordPage />} />
          <Route path={PUBLIC_ROUTES.resendVerification} element={<ResendVerificationPage />} />

          <Route path={PUBLIC_ROUTES.explore} element={<ExplorePage />} />

          <Route element={<ProtectedRoute allowed={signedIn} />}>
            <Route path={DYNAMIC_PROTECTED_ROUTES.editContribution(':id')} element={<EditContributionPage />} />
            <Route path={PROTECTED_ROUTES.contributions} element={<MyContributionsPage />} />
            <Route path={PROTECTED_ROUTES.settings} element={<UserSettingsPage />} />
          </Route>

          <Route element={<ProtectedRoute allowed={signedIn && (me.role === 'EXPERT' || me.role === 'ADMINISTRATOR')} />}>
            <Route path={PROTECTED_ROUTES.curations} element={<AwaitingCurationsPage />} />
            <Route path={DYNAMIC_PROTECTED_ROUTES.editCuration(':id')} element={<EditCurationPage />} />
          </Route>

          <Route element={<ProtectedRoute allowed={signedIn && me.role === 'ADMINISTRATOR'} />}>
            <Route path={PROTECTED_ROUTES.admin} element={<Navigate to={PROTECTED_ROUTES.adminCuratorRequests} replace />} />
            <Route path={PROTECTED_ROUTES.adminCuratorRequests} element={<AdminCuratorRequestsPage />} />
            <Route path={PROTECTED_ROUTES.adminResourceManagement} element={<AdminResourceManagementPage />} />
          </Route>

          <Route element={<ProtectedRoute allowed={signedIn && me.role === 'CONTRIBUTOR'} />}>
            <Route path={PROTECTED_ROUTES.becomeCurator} element={<BecomeCuratorPage />} />
            <Route path={PROTECTED_ROUTES.newApplication} element={<BecomeCuratorFormPage />} />
          </Route>

          <Route
            path={DYNAMIC_PUBLIC_ROUTES.userProfile(':username')}
            element={<UserProfilePage />}
          />

          <Route
            path={DYNAMIC_PUBLIC_ROUTES.transformationPage(':id')}
            element={<TransformationPage />}
          />

          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </PageWrapper>
    </BrowserRouter>
  );
};

export default Router;
