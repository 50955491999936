/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable max-statements */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Loader } from 'semantic-ui-react';
import { useQuery } from '@tanstack/react-query';

const InfiniteScrollContainer = ({ className, itemComponent, paginatedRequest, errorComponent, emptyComponent, ...props }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [items, setItems] = useState([]);
  const request = paginatedRequest(currentPage);
  const { error, data, isLoading } = useQuery(request.key, request.fn);

  const ItemComponent = itemComponent;
  const ErrorComponent = errorComponent;
  const EmptyComponent = emptyComponent;

  const latestItems = data?.result ?? null;
  const pagination = data?.pagination ?? null;
  const hasMore = pagination ? !pagination.isLastPage : true;
  const isEmpty = !items.length && !latestItems?.length && !isLoading;

  useEffect(() => {
    if (latestItems) {
      setItems([...items, ...latestItems]);
    }
  }, [latestItems]);

  const handleNext = () => {
    if (hasMore && !isLoading) {
      setCurrentPage(currentPage + 1);
    }
  };

  if (error) {
    if (!ErrorComponent) {
      return null;
    }

    return (
      <ErrorComponent error={error} />
    );
  }

  if (isEmpty) {
    if (!EmptyComponent) {
      return null;
    }

    return (
      <EmptyComponent />
    );
  }

  return (
    <InfiniteScroll
      next={handleNext}
      hasMore={hasMore}
      loader={null}
      dataLength={items.length}
      {...props}
    >
      {
        items.map((data, idx) => (
          <ItemComponent key={idx} {...data} />
        ))
      }

      {
        isLoading && (
          <div className="infinite-scroll-loader">
            <Loader active inline="centered" size="large" />
          </div>
        )
      }
    </InfiniteScroll>
  );
};

InfiniteScrollContainer.propTypes = {
  className: PropTypes.string,
  itemComponent: PropTypes.func.isRequired,
  errorComponent: PropTypes.func,
  emptyComponent: PropTypes.func,
  paginatedRequest: PropTypes.func.isRequired
};

InfiniteScrollContainer.defaultProps = {
  className: '',
  errorComponent: null,
  emptyComponent: null
};

export default InfiniteScrollContainer;
