import React from 'react';
import PropTypes from 'prop-types';
import { Button, Grid, Icon } from 'semantic-ui-react';

const DeleteSection = ({ onDelete }) => {
  return (
    <Grid.Column
      computer={2}
      tablet={3}
      mobile={3}
      className="delete-button-container"
      verticalAlign="middle"
    >
      <Button
        basic
        icon
        color="red"
        onClick={onDelete}
      >
        <Icon name="trash" />
      </Button>
    </Grid.Column>
  );
};

DeleteSection.propTypes = {
  onDelete: PropTypes.func.isRequired
};

export default DeleteSection;
