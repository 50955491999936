import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'semantic-ui-react';

const AutoResizeTextArea = ({ id, onChange, ...props }) => {
  const [textAreaRef, setTextAreaRef] = useState(null);

  useEffect(() => {
    setTextAreaRef(document.getElementById(id));
  }, [id]);

  // Ugly hack because Form.TextArea does not do forwardRef, so I cannot find another way
  // to get the node ref without doing a document query.
  const resizeTextArea = () => {
    if (!textAreaRef) {
      return;
    }

    textAreaRef.style.height = 'auto';
    textAreaRef.style.height = `${textAreaRef.scrollHeight}px`;
  };

  useEffect(resizeTextArea, [textAreaRef]);

  const handleChange = (event, ...args) => {
    resizeTextArea();

    if (onChange) {
      onChange(event, ...args);
    }
  };

  return (
    <Form.TextArea
      id={id}
      onChange={handleChange}
      style={{ overflow: 'hidden' }}
      {...props}
    />
  );
};

AutoResizeTextArea.propTypes = {
  id: PropTypes.string.isRequired,
  onChange: PropTypes.func
};

AutoResizeTextArea.defaultProps = {
  onChange: null
};

export default AutoResizeTextArea;
