export const bitArrayToBitField = (arr) => {
  return parseInt(arr.join(''), 2);
};

export const bitFieldToBitArray = (bitfield, length = -1) => {
  const bitfieldArray = Number(bitfield)
    .toString(2)
    .split('')
    .map((n) => parseInt(n, 2));

  if (length < 1) {
    return bitfieldArray;
  }

  if (bitfieldArray.length > length) {
    throw new Error(`Converted bitfield array ${bitfieldArray} does not fit in a array of size ${length}`);
  }

  const finalArray = Array.from(new Array(length)).map(() => 0);
  for (let i = 0; i < bitfieldArray.length; i++) {
    finalArray[finalArray.length - 1 - i] = bitfieldArray[bitfieldArray.length - 1 - i];
  }

  return finalArray;
};
