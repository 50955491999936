import React, { useState } from 'react';
import { Accordion, Grid, Item, Menu } from 'semantic-ui-react';
import LocaleSwitcher from '../../localeSwitcher';
import SearchTransformationInput from '../../../ui/searchTransformationInput';
import { Link } from 'react-router-dom';
import {
  DYNAMIC_PUBLIC_ROUTES,
  PROTECTED_ROUTES,
  PUBLIC_ROUTES
} from '../../../../constants/routes';
import LinkWithScroll from '../../linkWithScroll';
import useLocale from '../../../../hooks/i18n';
import MenuAccordionItem from '../MenuAccordionItem';
import PropTypes from 'prop-types';

const UserMobileNavbarContent = ({ user, onSignOut }) => {
  const { t } = useLocale();
  const [activeItem, setActiveItem] = useState(-1);
  const handleAccordionItemClicked = (index) => {
    setActiveItem((prevState) => (prevState === index ? -1 : index));
  };

  return (
    <Grid.Row className="mobile-navbar-content">
      <Grid.Column>
        <LocaleSwitcher />
        <SearchTransformationInput />
        <Accordion as={Menu} vertical fluid className="navbar-mobile-menu">
          <MenuAccordionItem
            activeIndex={activeItem}
            index={0}
            title={t('navbar.explore')}
            onAccordionItemClicked={handleAccordionItemClicked}
            content={
              <div className="menu-item-content">
                <Link to={PUBLIC_ROUTES.explore}>
                  {t('navbar.dropdown.explore.opportunities.title')}
                </Link>
              </div>
            }
          />

          <MenuAccordionItem
            activeIndex={activeItem}
            index={1}
            title={t('navbar.about')}
            onAccordionItemClicked={handleAccordionItemClicked}
            content={
              <div className="menu-item-content">
                <Link to={PUBLIC_ROUTES.about}>{t('navbar.about')}</Link>
                <LinkWithScroll
                  path={PUBLIC_ROUTES.about}
                  scrollTo="faq"
                  content={t('navbar.dropdown.about.faq.title')}
                />
                <LinkWithScroll
                  path={PUBLIC_ROUTES.about}
                  scrollTo="contact"
                  content={t('navbar.dropdown.about.contact.title')}
                />
              </div>
            }
          />

          <MenuAccordionItem
            activeIndex={activeItem}
            index={2}
            title={t('navbar.mobile.contribute')}
            onAccordionItemClicked={handleAccordionItemClicked}
            content={
              <div className="menu-item-content">
                <Link to={PROTECTED_ROUTES.contributions}>
                  {t('navbar.publish')}
                </Link>
                {(user.role === 'EXPERT' || user.role === 'ADMINISTRATOR') && (
                  <Link to={PROTECTED_ROUTES.curations}>
                    {t('navbar.dropdown.curations')}
                  </Link>
                )}
                {user.role === 'CONTRIBUTOR' && (
                  <Link to={PROTECTED_ROUTES.becomeCurator}>
                    {t('navbar.dropdown.become_curator')}
                  </Link>
                )}
              </div>
            }
          />

          <MenuAccordionItem
            activeIndex={activeItem}
            index={3}
            title={t('navbar.dropdown.profile')}
            onAccordionItemClicked={handleAccordionItemClicked}
            content={
              <div className="menu-item-content">
                <Link to={DYNAMIC_PUBLIC_ROUTES.userProfile(user.username)}>
                  {t('navbar.mobile.my_profile')}
                </Link>
                <Link to={PROTECTED_ROUTES.settings}>
                  {t('navbar.dropdown.settings')}
                </Link>
                <Item
                  className="sign-out"
                  onClick={onSignOut}
                  content={t('navbar.sign_out')}
                />
              </div>
            }
          />

          {user.role === 'ADMINISTRATOR' && (
            <MenuAccordionItem
              activeIndex={activeItem}
              index={4}
              title={t('navbar.mobile.admin')}
              onAccordionItemClicked={handleAccordionItemClicked}
              content={
                <div className="menu-item-content">
                  <Link to={PROTECTED_ROUTES.admin}>
                    {t('navbar.mobile.admin.dashboard')}
                  </Link>
                  <Link to={PROTECTED_ROUTES.adminCuratorRequests}>
                    {t('navbar.mobile.admin.curator_requests')}
                  </Link>
                  <Link to={PROTECTED_ROUTES.adminResourceManagement}>
                    {t('navbar.mobile.admin.resource_management')}
                  </Link>
                </div>
              }
            />
          )}
        </Accordion>
      </Grid.Column>
    </Grid.Row>
  );
};

UserMobileNavbarContent.propTypes = {
  onSignOut: PropTypes.func.isRequired,
  user: PropTypes.shape({
    username: PropTypes.string,
    picture: PropTypes.string,
    role: PropTypes.string
  }).isRequired
};

export default UserMobileNavbarContent;
