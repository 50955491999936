/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { Grid } from 'semantic-ui-react';
import useAuth from '../../../../hooks/auth';
import useQueryParams from '../../../../hooks/queryParams';
import UserComputerNavbar from './UserComputerNavbar';
import UserMobileNavbar from './UserMobileNavbar';

const UserNavbar = ({ user, onBarsClicked, isMobileNavbarShown }) => {
  const { signOut } = useAuth();
  const { pathname } = useLocation();
  const { queryParams } = useQueryParams();

  useEffect(() => {
    // close mobile navbar whenever route changes or queryParam changes
    if (isMobileNavbarShown) {
      onBarsClicked();
    }
  }, [pathname, queryParams]);

  return (
    <Grid className="navbar user-navbar">
      <UserComputerNavbar user={user} onSignOut={signOut} pathname={pathname} />
      <UserMobileNavbar
        onBarsClicked={onBarsClicked}
        isVisible={isMobileNavbarShown}
        onSignOut={signOut}
        user={user}
        pathname={pathname}
      />
    </Grid>
  );
};

UserNavbar.propTypes = {
  user: PropTypes.shape({
    username: PropTypes.string,
    picture: PropTypes.string,
    role: PropTypes.string
  }).isRequired,
  onBarsClicked: PropTypes.func.isRequired,
  isMobileNavbarShown: PropTypes.bool.isRequired
};

export default UserNavbar;
