import ResendVerificationForm from './ResendVerificationForm';
import ResendVerificationFormLogic from './ResendVerificationFormLogic';
import ResendVerificationFormView from './ResendVerificationFormView';

export default ResendVerificationForm;

export {
  ResendVerificationFormLogic,
  ResendVerificationFormView
};
