import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Form, Icon, Popup } from 'semantic-ui-react';
import useLocale from '../../../../hooks/i18n';
import { bitArrayToBitField, bitFieldToBitArray } from '../../../../utils/bitfield';

export const monthLabels = [
  'form.multi_month_picker.january',
  'form.multi_month_picker.february',
  'form.multi_month_picker.march',
  'form.multi_month_picker.april',
  'form.multi_month_picker.may',
  'form.multi_month_picker.june',
  'form.multi_month_picker.july',
  'form.multi_month_picker.august',
  'form.multi_month_picker.september',
  'form.multi_month_picker.october',
  'form.multi_month_picker.november',
  'form.multi_month_picker.december'
];

const MultiMonthPicker = ({ onChange, name, label, id, hint, hintExample, defaultValue, disabled }) => {
  const initialValue = defaultValue ? bitFieldToBitArray(defaultValue, monthLabels.length) : monthLabels.map(() => 0);

  const { t } = useLocale();
  const [values, setValues] = useState(initialValue);

  const handleChange = (_, { index, checked }) => {
    const newValues = [...values];
    newValues[index] = Number(checked);
    setValues(newValues);

    onChange(null, { name, value: bitArrayToBitField(newValues) });
  };

  return (
    <div className="multi-month-picker field" id={id}>
      <label htmlFor={id}>{label}</label>
      {hint && (
        <Popup trigger={<Icon name="help circle" />} position="right center">
          <p>{hint}</p>
          {hintExample && <p>{hintExample}</p>}
        </Popup>
      )}
      <div className="months-box">
        {monthLabels.map((label, index) => (
          <Form.Checkbox
            key={index}
            index={index}
            label={t(label)}
            defaultChecked={!!values[index]}
            onChange={handleChange}
            disabled={disabled}
          />
        ))}
      </div>
    </div>
  );
};

MultiMonthPicker.propTypes = {
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  hint: PropTypes.string,
  hintExample: PropTypes.string,
  defaultValue: PropTypes.number,
  disabled: PropTypes.bool
};

MultiMonthPicker.defaultProps = {
  hint: null,
  hintExample: null,
  defaultValue: null,
  disabled: false
};

export default MultiMonthPicker;
