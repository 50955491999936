import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Form, Icon, Popup } from 'semantic-ui-react';
import useLocale from '../../../../hooks/i18n';

const YesNoDontKnowFormField = ({
  label,
  id,
  onChange,
  yesLabel,
  noLabel,
  dontKnowLabel,
  name,
  hint,
  hintExample,
  defaultValue,
  disabled
}) => {
  const { t } = useLocale();
  const [value, setValue] = useState(defaultValue);

  const handleChange = (newValue) => {
    setValue(newValue);
    onChange(null, { name, value: newValue });
  };

  const handleYes = () => {
    handleChange('yes');
  };

  const handleNo = () => {
    handleChange('no');
  };

  const handleDontKnow = () => {
    handleChange('idk');
  };

  return (
    <Form.Field>
      <Form.Group className="yes-no-form-field" inline id={id}>
        <div className="field hint-container">
          <label htmlFor={id}>{label}</label>
          {hint && (
            <Popup
              trigger={<Icon name="help circle" />}
              position="right center"
            >
              <p>{hint}</p>
              {hintExample && <p>{hintExample}</p>}
            </Popup>
          )}
        </div>

        <div className="radio-container">
          <Form.Radio
            label={yesLabel || t('form.yes_no_idk_form_field.default.yes')}
            checked={value === 'yes'}
            onChange={handleYes}
            disabled={disabled}
          />

          <Form.Radio
            label={noLabel || t('form.yes_no_idk_form_field.default.no')}
            checked={value === 'no'}
            onChange={handleNo}
            disabled={disabled}
          />

          <Form.Radio
            label={dontKnowLabel || t('form.yes_no_idk_form_field.default.idk')}
            checked={value === 'idk'}
            onChange={handleDontKnow}
            disabled={disabled}
          />
        </div>
      </Form.Group>
    </Form.Field>
  );
};

YesNoDontKnowFormField.propTypes = {
  label: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  yesLabel: PropTypes.string,
  noLabel: PropTypes.string,
  dontKnowLabel: PropTypes.string,
  hint: PropTypes.string,
  hintExample: PropTypes.string,
  defaultValue: PropTypes.string,
  disabled: PropTypes.bool
};

YesNoDontKnowFormField.defaultProps = {
  yesLabel: null,
  noLabel: null,
  dontKnowLabel: null,
  hint: null,
  hintExample: null,
  defaultValue: null,
  disabled: false
};

export default YesNoDontKnowFormField;
