class UserService {
  constructor(client) {
    this.client = client;
  }

  // This query is not used by useQuery(), so it doesn't need to return a key and the function reference.
  async getCurrentUser() {
    try {
      const response = await this.client.instance.get('/users/me');
      return response.data.data;
    } catch (error) {
      this.client.handleResponseError('Error fetching current user.', error);
    }
  }

  async #getUser(username) {
    try {
      const response = await this.client.instance.get(`/users/${username}`);
      return response.data.data;
    } catch (error) {
      this.client.handleResponseError(`Error fetching user ${username}.`, error);
    }
  }

  getUser(username) {
    return {
      key: ['user', username],
      fn: () => this.#getUser(username)
    };
  }

  async #getFullUser(username) {
    try {
      const response = await this.client.instance.get(`/users/${username}/full`);
      return response.data.data;
    } catch (error) {
      this.client.handleResponseError(`Error fetching user ${username}.`, error);
    }
  }

  getFullUser(username) {
    return {
      key: ['user', 'full', username],
      fn: () => this.#getFullUser(username)
    };
  }

  async patchCurrentUser(data) {
    try {
      const response = await this.client.instance.patch('/users/me', data);
      return response.data.data;
    } catch (error) {
      this.client.handleResponseError('Error updating current user.', error);
    }
  }
}

export default UserService;
