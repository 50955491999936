import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ForgotPasswordFormViewOne from './views/ForgotPasswordFormViewOne';
import ForgotPasswordFormViewTwo from './views/ForgotPasswordFormViewTwo';

const components = [ForgotPasswordFormViewOne, ForgotPasswordFormViewTwo];

const ForgotPasswordFormView = ({ form, onSubmit, error }) => {
  const [current, setCurrent] = useState(0);
  const ViewComponent = components[current];

  const handleSubmit = async (data) => {
    await onSubmit(data, current);
    setCurrent(Math.min(current + 1, components.length - 1));
  };

  return (
    <ViewComponent
      form={form}
      onSubmit={handleSubmit}
      error={error}
    />
  );
};

ForgotPasswordFormView.propTypes = {
  form: PropTypes.shape({
    formState: PropTypes.shape({
      isSubmitting: PropTypes.bool
    }),
    handleSubmit: PropTypes.func
  }).isRequired,
  onSubmit: PropTypes.func.isRequired,
  error: PropTypes.string
};

ForgotPasswordFormView.defaultProps = {
  error: null
};

export default ForgotPasswordFormView;
