import { useState, useCallback } from 'react';
import useQueryParams from './queryParams';

const usePageQuery = (pageQueryParamName = 'page') => {
  const { queryParams } = useQueryParams();
  const [currentPage, setCurrentPage] = useState(parseInt(queryParams.get(pageQueryParamName) ?? '1', 10));

  const updatePage = useCallback((page) => {
    setCurrentPage(page);
  }, []);

  return {
    currentPage,
    updatePage,
    name: pageQueryParamName
  };
};

export default usePageQuery;
