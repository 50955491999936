import { useEffect } from 'react';

const BASE_TITLE = 'Transformar';

const useTitle = (title) => {
  useEffect(() => {
    document.title = title ? `${BASE_TITLE} | ${title}` : BASE_TITLE;
  }, [title]);

  return null;
};

export default useTitle;
