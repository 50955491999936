import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';
import ResendVerificationFormView from './ResendVerificationFormView';

const VerificationSchema = Joi.object({
  email: Joi.string().email({ tlds: { allow: false } }).trim().required()
    .messages({
      'string.email': 'resend_verification.errors.email.invalid',
      'string.empty': 'resend_verification.errors.email.required',
      'any.required': 'resend_verification.errors.email.required'
    })
});

const ResendVerificationFormLogic = ({ onSubmit }) => {
  const form = useForm({
    mode: 'onSubmit',
    resolver: joiResolver(VerificationSchema)
  });
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  const handleSubmit = async ({ email }) => {
    setError(null);

    try {
      await onSubmit(email);
      setSuccess(true);
    } catch (error) {
      setError(error);
      setSuccess(false);
    }
  };

  return (
    <ResendVerificationFormView
      form={form}
      onSubmit={handleSubmit}
      error={error}
      success={success}
    />
  );
};

ResendVerificationFormLogic.propTypes = {
  onSubmit: PropTypes.func.isRequired
};

export default ResendVerificationFormLogic;
