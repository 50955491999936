import React from 'react';
import { Link } from 'react-router-dom';
import { PUBLIC_ROUTES } from '../../../constants/routes';
import LinkWithScroll from '../linkWithScroll';
import logoBasicGreen from '../../../assets/logo/logo-basic_green.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TRANSFORMAR_SOCIALS } from './Footer';
import useLocale from '../../../hooks/i18n';

const FooterBasic = () => {
  const { t } = useLocale();
  return (
    <div className="footer footer--basic">
      <div className="footer-delimiter">
        <div className="footer-items">
          <div className="footer-items__bar">
            <div className="bar__links-container">
              <div className="bar__links">
                <Link to={PUBLIC_ROUTES.about}>
                  {t('footer.items.bar.links.about')}
                </Link>
                <LinkWithScroll
                  path={PUBLIC_ROUTES.about}
                  scrollTo="faq"
                  content={t('footer.items.bar.links.help')}
                />
              </div>
              <div className="bar__links">
                <LinkWithScroll
                  path={PUBLIC_ROUTES.about}
                  scrollTo="contact"
                  content={t('footer.items.bar.links.contact')}
                />
                <Link
                  to={PUBLIC_ROUTES.termsAndConditions}
                  className="terms-and-conditions-link"
                >
                  {t('footer.items.bar.links.terms_and_conditions')}
                </Link>
              </div>
            </div>
            <img className="bar__logo" src={logoBasicGreen} alt="logo" />
            <div className="bar__socials">
              <a
                href={TRANSFORMAR_SOCIALS.linkedin}
                target="_blank"
                rel="noreferrer"
              >
                <FontAwesomeIcon icon="fa-brands fa-linkedin" />
              </a>
              <a
                href={TRANSFORMAR_SOCIALS.twitter}
                target="_blank"
                rel="noreferrer"
              >
                <FontAwesomeIcon icon="fa-brands fa-twitter" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FooterBasic;
