import React from 'react';
import { Grid } from 'semantic-ui-react';
import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import useAPI from '../../hooks/api';
import useLocale from '../../hooks/i18n';
import useTitle from '../../hooks/title';
import RequestErrorMessage from '../../components/common/requestErrorMessage';
import UserSideProfile from '../../components/user/userSideProfile';
import UserProfileContributions from '../../components/user/userProfileContributions';
import UserNotFound from '../../components/user/userNotFound';
import Loading from '../../components/common/loading';

const UserProfilePage = () => {
  const { t } = useLocale();
  const { client, getFullResponseErrorMessage } = useAPI();
  const { username } = useParams();
  const request = client.user.getFullUser(username);
  const {
    isLoading,
    error,
    data: fullUser
  } = useQuery(request.key, request.fn);
  useTitle(fullUser?.user?.username ?? t('page_titles.loading'));

  if (isLoading) {
    return <Loading fullscreen />;
  }

  if (error) {
    return (
      <RequestErrorMessage
        header={t('user_profile.error.header')}
        error={getFullResponseErrorMessage(error)}
      />
    );
  }

  if (!fullUser) {
    return <UserNotFound />;
  }

  const { user, transformations, curations } = fullUser;

  return (
    <div className="user-profile-page">
      <Grid>
        <Grid.Row columns={2}>
          <Grid.Column computer={5} tablet={16} mobile={16} className="side-profile-column">
            <UserSideProfile
              givenName={user.given_name}
              familyName={user.family_name}
              institutionPosition={user.institution_position}
              institutionName={user.institution_name}
              country={user.location_country}
              city={user.location_city}
              website={user.socials_website}
              facebook={user.socials_facebook}
              instagram={user.socials_instagram}
              twitter={user.socials_twitter}
              linkedin={user.socials_linkedin}
              {...user}
            />
          </Grid.Column>
          <Grid.Column computer={11} tablet={16} mobile={16}>
            <UserProfileContributions
              transformations={transformations}
              curations={curations}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
};

export default UserProfilePage;
