import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from 'semantic-ui-react';

const ImageSection = ({ picture }) => {
  return (
    <Grid.Column computer={3} tablet={3} mobile={3} className="transformation-image-column">
      <img
        className="transformation-image"
        src={picture}
        alt="transformation icon"
      />
    </Grid.Column>
  );
};

ImageSection.propTypes = {
  picture: PropTypes.string.isRequired
};

export default ImageSection;
