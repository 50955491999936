import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Segment } from 'semantic-ui-react';

const TransformationItemContainer = ({ children }) => {
  return (
    <Segment className="general-transformation-item">
      <Grid className="transformation-grid">
        {children}
      </Grid>
    </Segment>
  );
};

TransformationItemContainer.propTypes = {
  children: PropTypes.node.isRequired
};

export default TransformationItemContainer;
