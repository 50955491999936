/* eslint-disable max-params */

export class ResponseError extends Error {
  constructor(name, message, status, originalPayload, friendlyMessageKey) {
    super(message);
    this.name = name;
    this.status = status;
    this.originalPayload = originalPayload;
    this.friendlyMessageKey = friendlyMessageKey;
  }
}

export class UnknownResponseError extends ResponseError {
  constructor(message, status) {
    super('UnknownResponseError', message, status, null, 'services.api.errors.unknown.message');
  }
}

export class NotFoundError extends ResponseError {
  constructor(apiError) {
    super('NotFoundError', apiError.message, 404, apiError, 'services.api.errors.not_found.message');
  }
}

export class ValidationError extends ResponseError {
  constructor(apiError) {
    super('ValidationError', apiError.message, 400, apiError, 'services.api.errors.validation.message');
  }
}

export class BadRequestError extends ResponseError {
  constructor(apiError) {
    super('BadRequestError', apiError.message, 400, apiError, 'services.api.errors.bad_request.message');
  }
}

export class ForbiddenError extends ResponseError {
  constructor(apiError) {
    super('ForbiddenError', apiError.message, 403, apiError, 'services.api.errors.forbidden.message');
  }
}

export class UnauthorizedError extends ResponseError {
  constructor(apiError) {
    super('UnauthorizedError', apiError.message, 401, apiError, 'services.api.errors.unauthorized.message');
  }
}

export class InvalidSessionError extends ResponseError {
  constructor() {
    super('InvalidSessionError', 'Session has been invalidated and client failed to update token.', 401, null, 'services.api.errors.invalidated.message');
  }
}

export class ResponseErrorFactory {
  static create(axiosError) {
    const apiError = axiosError.response?.data?.error;
    if (apiError) {
      switch (apiError.name) {
        case 'ResourceNotFoundError':
          return new NotFoundError(apiError);

        case 'ModelValidationError':
          return new ValidationError(apiError);

        case 'BadRequestError':
          return new BadRequestError(apiError);

        case 'ForbiddenRequestError':
          return new ForbiddenError(apiError);

        case 'UnauthorizedRequestError':
          return new UnauthorizedError(apiError);

        default:
          return new UnknownResponseError(apiError.message, axiosError.response?.status);
      }
    }

    if (axiosError.response?.data.message === 'Unauthorized') {
      return new InvalidSessionError();
    }

    return new UnknownResponseError(axiosError.message, axiosError.response?.status);
  }
}
