import React from 'react';
import PropTypes from 'prop-types';
import { Container, Form } from 'semantic-ui-react';
import BooleanFormField from '../../../common/form/booleanFormField';
import useLocale from '../../../../hooks/i18n';
import useFormHelper from '../../../../hooks/formHelper';
import PopupFormFieldArea from '../../../common/form/popupFormFieldArea';
import PopupFormFieldSelect from '../../../common/form/popupFormFieldSelect';

const FORM_NAMES = {
  contributes_species_preservation: 'sustainability.contributes_species_preservation',
  contributes_circular_economy: 'sustainability.contributes_circular_economy',
  sustainable_development_goals: 'sustainability.id_sustainable_development_goals',
  closes_productive_cycle_gap: 'sustainability.closes_productive_cycle_gap',
  replaces_materials_fossil_origin: 'sustainability.replaces_materials_fossil_origin',
  reduces_greenhouse_effect_gasses: 'sustainability.reduces_greenhouse_effect_gasses'
};

const SustainabilityFormView = ({ renderAs, editable, form, sustainable_development_goals }) => {
  const { t } = useLocale();
  const { handleChangeWithValidation, resolveError } = useFormHelper(form);
  const { getValues } = form;
  const ParentComponent = renderAs;

  const sustainableDevelopmentGoalsOptions = sustainable_development_goals.map(({ id, goal }) => {
    return {
      key: id,
      value: id,
      text: goal
    };
  });

  return (
    <ParentComponent className="transformation-card sustainability-form">
      <Container fluid={renderAs !== Form}>
        <PopupFormFieldArea
          name={FORM_NAMES.contributes_species_preservation}
          id={FORM_NAMES.contributes_species_preservation}
          hint={t('transformation.form.sustainability.contributes_species_preservation.hint')}
          label={t('transformation.form.sustainability.contributes_species_preservation.label')}
          onChange={handleChangeWithValidation}
          error={resolveError(FORM_NAMES.contributes_species_preservation)}
          defaultValue={getValues(FORM_NAMES.contributes_species_preservation)}
          disabled={!editable}
        />

        <PopupFormFieldArea
          name={FORM_NAMES.contributes_circular_economy}
          id={FORM_NAMES.contributes_circular_economy}
          label={t('transformation.form.sustainability.contributes_circular_economy.label')}
          hint={t('transformation.form.sustainability.contributes_circular_economy.hint')}
          onChange={handleChangeWithValidation}
          error={resolveError(FORM_NAMES.contributes_circular_economy)}
          defaultValue={getValues(FORM_NAMES.contributes_circular_economy)}
          disabled={!editable}
        />

        <PopupFormFieldSelect
          name={FORM_NAMES.sustainable_development_goals}
          id={FORM_NAMES.sustainable_development_goals}
          label={t('transformation.form.sustainability.sustainable_development_goals.label')}
          hint={t('transformation.form.sustainability.sustainable_development_goals.hint')}
          options={sustainableDevelopmentGoalsOptions}
          multiple
          onChange={handleChangeWithValidation}
          error={resolveError(FORM_NAMES.sustainable_development_goals)}
          defaultValue={getValues(FORM_NAMES.sustainable_development_goals)}
          disabled={!editable}
        />

        <BooleanFormField
          name={FORM_NAMES.closes_productive_cycle_gap}
          id={FORM_NAMES.closes_productive_cycle_gap}
          label={t('transformation.form.sustainability.closes_productive_cycle_gap.label')}
          hint={t('transformation.form.sustainability.closes_productive_cycle_gap.hint')}
          yesLabel={t('transformation.form.ui.yes')}
          noLabel={t('transformation.form.ui.no')}
          onChange={handleChangeWithValidation}
          error={resolveError(FORM_NAMES.closes_productive_cycle_gap)}
          defaultValue={getValues(FORM_NAMES.closes_productive_cycle_gap)}
          disabled={!editable}
        />

        <BooleanFormField
          name={FORM_NAMES.replaces_materials_fossil_origin}
          id={FORM_NAMES.replaces_materials_fossil_origin}
          label={t('transformation.form.sustainability.replaces_materials_fossil_origin.label')}
          hint={t('transformation.form.sustainability.replaces_materials_fossil_origin.hint')}
          yesLabel={t('transformation.form.ui.yes')}
          noLabel={t('transformation.form.ui.no')}
          onChange={handleChangeWithValidation}
          error={resolveError(FORM_NAMES.replaces_materials_fossil_origin)}
          defaultValue={getValues(FORM_NAMES.replaces_materials_fossil_origin)}
          disabled={!editable}
        />

        <BooleanFormField
          name={FORM_NAMES.reduces_greenhouse_effect_gasses}
          id={FORM_NAMES.reduces_greenhouse_effect_gasses}
          label={t('transformation.form.sustainability.reduces_greenhouse_effect_gasses.label')}
          hint={t('transformation.form.sustainability.reduces_greenhouse_effect_gasses.hint')}
          yesLabel={t('transformation.form.ui.yes')}
          noLabel={t('transformation.form.ui.no')}
          onChange={handleChangeWithValidation}
          error={resolveError(FORM_NAMES.reduces_greenhouse_effect_gasses)}
          defaultValue={getValues(FORM_NAMES.reduces_greenhouse_effect_gasses)}
          disabled={!editable}
        />
      </Container>
    </ParentComponent>
  );
};

SustainabilityFormView.propTypes = {
  renderAs: PropTypes.oneOfType([PropTypes.string, PropTypes.elementType]),
  editable: PropTypes.bool,
  form: PropTypes.shape({
    formState: PropTypes.shape({
      isSubmitting: PropTypes.bool
    }),
    handleSubmit: PropTypes.func,
    getValues: PropTypes.func
  }).isRequired,
  sustainable_development_goals: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      goal: PropTypes.string
    })
  ).isRequired
};

SustainabilityFormView.defaultProps = {
  renderAs: Form,
  editable: true
};

export default SustainabilityFormView;
