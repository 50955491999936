import React, { useState } from 'react';
import { joiResolver } from '@hookform/resolvers/joi';
import BecomeCuratorFormView from './BecomeCuratorFormView';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import Joi from 'joi';

const BecomeCuratorSchema = Joi.object({
  description: Joi.string().min(100).max(512).trim().required().messages({
    'string.max': 'become_curator.form.errors.description.max',
    'string.min': 'become_curator.form.errors.description.min',
    'any.required': 'become_curator.form.errors.description.required'
  })
});

const BecomeCuratorFormLogic = ({ onSubmit }) => {
  const form = useForm({
    mode: 'onSubmit',
    resolver: joiResolver(BecomeCuratorSchema)
  });

  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [disabled, setDisabled] = useState(false);

  const handleSubmit = async (description) => {
    setSuccess(false);
    setDisabled(false);
    try {
      await onSubmit(description);
      setSuccess(true);
      setDisabled(true);
    } catch (error) {
      setError(error.message);
      setSuccess(false);
      setDisabled(true);
    }
  };

  return (
    <BecomeCuratorFormView
      form={form}
      onSubmit={handleSubmit}
      success={success}
      error={error}
      disabled={disabled}
    />
  );
};

BecomeCuratorFormLogic.propTypes = {
  onSubmit: PropTypes.func.isRequired
};

export default BecomeCuratorFormLogic;
