import React from 'react';
import ReactDOM from 'react-dom/client';
import Amplify from 'aws-amplify';
import 'semantic-ui-less/semantic.less';
import './styles/main.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { stripHashFromHostname } from './utils/history';
import SessionCookieStorage, { shouldReadFromCookieStorage } from './aws/sessionCookieStorage';

Amplify.configure({
  Auth: {
    userPoolId: process.env.REACT_APP_AUTH_POOL_ID,
    region: process.env.REACT_APP_AUTH_REGION,
    userPoolWebClientId: process.env.REACT_APP_AUTH_CLIENT_ID,
    storage: shouldReadFromCookieStorage() ? SessionCookieStorage : window.localStorage
  }
});
stripHashFromHostname();

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
