/* eslint-disable max-lines */

const PAGE_TITLES = {
  'page_titles.home': 'Inicio',
  'page_titles.login': 'Conecte-se',
  'page_titles.forgot_password': 'Esqueceu sua senha?',
  'page_titles.my_contributions': 'Minhas contribuições',
  'page_titles.not_found': 'Página não encontrada',
  'page_titles.coming_soon': 'Em breve',
  'page_titles.resend_verification': 'Reenviar seu código de verificação',
  'page_titles.sign_up': 'Cadastre-se',
  'page_titles.user_settings': 'Configurações do usuário',
  'page_titles.awaiting_curation': '{count, plural, one {1 Transformação precisa} other {# Transformações precisam}} de curadoria',
  'page_titles.admin_dashboard': 'Admin Dashboard',
  'page_titles.curate_contribution': 'Curando transformação {id}',
  'page_titles.edit_contribution': 'Editando transformação {id}',
  'page_titles.become_curator': 'Torne-se um curador',
  'page_titles.become_curator_application': 'Formulário de inscrição para se tornar um curador',
  'page_titles.explore.no_query': 'Explore as transformações',
  'page_titles.explore.with_query': 'Procurando {query}',
  'page_titles.loading': 'Carregando...',
  'page_titles.terms_and_conditions': 'Termos e condições',
  'page.titles.about': 'Sobre nós'
};

const NAVBAR = {
  'navbar.home': 'Inicio',
  'navbar.explore': 'Explorar',
  'navbar.about': 'Sobre nós',
  'navbar.login': 'Conecte-se',
  'navbar.signup': 'Cadastre-se',
  'navbar.profile': 'Perfil',
  'navbar.admin': 'Administrador',
  'navbar.become_curator': 'Torne-se um curador',
  'navbar.settings': 'Configurações',
  'navbar.sign_out': 'Sair',
  'navbar.curations': 'Curar',
  'navbar.publish': 'Publicar',
  'navbar.login_signup': 'Iniciar sessão / Cadastre-se',
  'navbar.get_involved': 'Envolver-se',

  'navbar.dropdown.profile': 'Perfil',
  'navbar.dropdown.settings': 'Configurações',
  'navbar.dropdown.sign_out': 'Sair',
  'navbar.dropdown.curations': 'Curar',
  'navbar.dropdown.publish': 'Publicar',
  'navbar.dropdown.admin': 'Administrador',
  'navbar.dropdown.become_curator': 'Torne-se um curador',
  'navbar.dropdown.explore.opportunities.title': 'Oportunidades',
  'navbar.dropdown.explore.opportunities.description': 'Crowdsourcing, pesquisa e compartilhar',
  'navbar.dropdown.explore.publish.title': 'Publicar',
  'navbar.dropdown.explore.publish.description': 'Contribuir com transformações',
  'navbar.dropdown.about.faq.title': 'Perguntas frequentes',
  'navbar.dropdown.about.faq.description': 'Resolva suas dúvidas',
  'navbar.dropdown.about.contact.title': 'Contacte-nos',
  'navbar.dropdown.about.contact.description': 'Nossos canais de comunicação',
  'navbar.dropdown.get_involved.partnership.title': 'Faça parceria!',
  'navbar.dropdown.get_involved.partnership.description': 'Descubra como você pode fazer parte da equipe.',

  'navbar.search.placeholder': 'Pesquisar em Transformar...',
  'navbar.mobile.my_profile': 'O meu perfil',
  'navbar.mobile.contribute': 'Contribuir',
  'navbar.mobile.admin': 'Administrador',
  'navbar.mobile.admin.dashboard': 'Dashboard',
  'navbar.mobile.admin.curator_requests': 'Pedidos de curador',
  'navbar.mobile.admin.resource_management': 'Gestão de recursos'
};

const HOME = {
  'home.first_section.header': 'Transformar, para um futuro sustentável.',
  'home.first_section.subheader': 'A pesquisa de hoje, a inovação de amanhã.',
  'home.first_section.btn.publish': 'Publicar',
  'home.first_section.hero': 'Contribuir e beneficiar do primeiro ecossistema de conhecimento aberto sobre oportunidades para a transformação sustentável da biomassa na América Latina e no Caribe.',

  'home.second_section.header': 'Explore ou publique',
  'home.second_section.subheader': 'Você pode explorar e também publicar oportunidades para transformar a biomassa em produtos de base biológica, com base na pesquisa científica.',

  'home.second_section.card.header.1': 'Explorar',
  'home.second_section.card.description.1': 'Encontre conhecimento científico sobre oportunidades para transformar a biomassa em produtos bio-baseados.',
  'home.second_section.card.header.2': 'Publicar',
  'home.second_section.card.description.2': 'Você gostaria de compartilhar seu conhecimento sobre como transformar a biomassa para usos industriais? Publique sua pesquisa e torne-se um contribuinte.',
  'home.second_section.card.header.3': 'Verificar',
  'home.second_section.card.description.3': 'Quanto mais você contribuir, maior seu nome e instituição aparecerá em nosso ranking. Nós convidamos você a construir uma oportunidade incrível para se revelar ao mundo!',

  'home.third_section.objective.header': 'O nosso objectivo',
  'home.third_section.objective.description': 'Criar o maior catálogo aberto de oportunidades para a transformação sustentável da biomassa em produtos bio-baseados, por crowdsourcing conhecimento científico que hoje é fragmentado e disperso em revistas e publicações técnicas. Este catálogo aberto visa beneficiar diferentes tipos de partes interessadas na América Latina e no Caribe:',
  'home.third_section.icons.private_firms': 'Empresas privadas',
  'home.third_section.icons.public_institutions': 'Instituições públicas',
  'home.third_section.icons.startups': 'Startups',
  'home.third_section.icons.scientific_community': 'Comunidade Económica Europeia',
  'home.third_section.icons.civil_society': 'Sociedade Civil',

  'home.third_section.steps.item.header.1': '1. Cadastre-se',
  'home.third_section.steps.item.subheader.1': 'Junte-se à Transformar e tenha acesso a informações revisadas e curadas por especialistas em assuntos.',
  'home.third_section.steps.item.btn.signed_in': 'Você já faz parte da comunidade',
  'home.third_section.steps.item.btn.signup': 'Cadastre-se',
  'home.third_section.steps.item.btn.login': 'Conecte-se',
  'home.third_section.steps.item.header.2': '2. Explore oportunidades',
  'home.third_section.steps.item.subheader.2': 'Mergulhe em uma floresta virtual de oportunidades para transformar a biomassa em produtos bio-baseados através de consultas de pesquisa.',
  'home.third_section.steps.item.header.3': '3. Compartilhe seu conhecimento',
  'home.third_section.steps.item.subheader.3': 'Compartilhe seu conhecimento e faça parte da comunidade virtual de especialistas. Você está contribuindo para a partilha de conhecimento e tomada de decisão que podem beneficiar a sociedade civil, pesquisadores, empresas, instituições e também nossos recursos biológicos renováveis.',
  'home.third_section.steps.item.header.4': '4. Verifica e cura',
  'home.third_section.steps.item.subheader.4': 'Verifica e cura o conteúdo em oportunidades contribuídas por outros, ajudando a manter a qualidade do catálogo.',
  'home.third_section.steps.item.header.5': '5. Trazê-lo para a vida',
  'home.third_section.steps.item.subheader.5': 'Use os dados para ficar à frente da curva. Feito para estudos, negócios, projetos e muito mais.'
};

const ABOUT = {
  'about.first_section.header': 'Transformar visa facilitar o acesso a oportunidades baseadas em ciência sobre a transformação de recursos biológicos renováveis em produtos bio-baseados',
  'about.first_section.subheader': 'Juntos podemos construir e curar um catálogo aberto de oportunidades que informarão os tomadores de decisão na América Latina e no Caribe.',

  'about.second_section.header': 'O que é Transformar?',
  'about.second_section.description': 'Transformar é uma praça pública digital para uma partilha de conhecimento aberto sobre oportunidades para a transformação sustentável dos recursos biológicos renováveis em produtos bio-baseados. Visa apoiar a tomada de decisões das diferentes partes interessadas da bioeconomia na América Latina e no Caribe: instituições públicas, comunidade de pesquisa, setor privado, sociedade civil, investidores, doadores e instituições financeiras.',

  'about.third_section.header': 'Organização',
  'about.third_section.subheader': 'Este projeto foi projetado e patrocinado pelo Banco Interamericano de Desenvolvimento, e desenvolvido e agora hospedado pela Universidade de São Francisco de Quito.',

  'about.third_section.organizations.idb': 'Banco Interamericano de Desenvolvimento',
  'about.third_section.organizations.idb.description': 'O Banco Interamericano de Desenvolvimento é dedicado a melhorar vidas. Fundada em 1959, o BID é uma das principais fontes de financiamento a longo prazo para o desenvolvimento econômico, social e institucional na América Latina e no Caribe. O BID também realiza pesquisas de ponta e fornece conselhos políticos, assistência técnica e treinamento para clientes do setor público e privado em toda a região.',
  'about.third_section.organizations.usfq': 'Universidade São Francisco de Quito',
  'about.third_section.organizations.usfq.description': 'Equipe de projeto',

  'about.fourth_section.faq.header': 'Perguntas frequentes (FAQ)',
  'about.fourth_section.faq.subheader': 'Respondemos às suas perguntas. Aqui estão as perguntas mais frequentes da nossa comunidade.',
  'about.fourth_section.faq.pdf.subheader': 'Se você é um contribuinte da Transformar pela primeira vez ou um suporte de longa data, entendemos que você pode ter dúvidas sobre nossa plataforma. É por isso que criamos este recurso abrangente para ajudá-lo a encontrar as respostas que você precisa rapidamente e facilmente.',
  'about.fourth_section.faq.pdf.btn.label': 'Baixar como PDF',
  'about.fourth_section.faq.pdf.filename': 'Perguntas frequentes - Transformar',
  'about.fourth_section.faq.pdf.quality_criteria_guide.filename': 'Guia de qualidade de dados - Transformar',

  'about.fourth_section.faq.content.first.question': 'Como criar minha primeira publicação?',
  'about.fourth_section.faq.content.first.answer': 'Para compartilhar sua primeira publicação, você deve fazer login no Transformar com sua conta. Se ainda não estiver registado, convidamo-lo a fazê-lo. Uma vez na página principal, você deve clicar em "Publicar" e uma janela será exibida onde você pode ver: suas transformações em andamento, as mudanças exigidas pelos curadores e suas submissões no processo de curadoria. Em seguida, clique em "Adicionar novo" e você será capaz de acessar os campos para completar sua publicação.',
  'about.fourth_section.faq.content.second.question': 'O que significa fazer uma nova publicação?',
  'about.fourth_section.faq.content.second.answer.1': 'Sua publicação permite que você compartilhe oportunidades para o uso e transformação da biomassa em produtos de valor comercial. Para isso, 6 tabulações foram propostas para completar informações para que este objetivo seja cumprido.',
  'about.fourth_section.faq.content.second.answer.2': 'As 6 abas de informação são:',
  'about.fourth_section.faq.content.second.answer.tabs.1': 'Informação básica',
  'about.fourth_section.faq.content.second.answer.tabs.2': 'Processo de transformação',
  'about.fourth_section.faq.content.second.answer.tabs.3': 'Escalabilidade',
  'about.fourth_section.faq.content.second.answer.tabs.4': 'Desenvolvimento sustentável',
  'about.fourth_section.faq.content.second.answer.tabs.5': 'Potencial de mercado',
  'about.fourth_section.faq.content.second.answer.tabs.6': 'Outras considerações',
  'about.fourth_section.faq.content.second.answer.3': 'Ao preencher cada uma dessas abas com as informações solicitadas, o progresso da sua publicação será concluído.',
  'about.fourth_section.faq.content.third.question': 'Que características meu projeto deve ter para se publicar em Transformar?',
  'about.fourth_section.faq.content.third.answer': 'Seu projeto deve compartilhar uma oportunidade para o uso e transformação da biomassa primária e residual em produtos de valor comercial, relevantes para os países da América Latina e do Caribe com base em biomassa abundante na região. Seu projeto pode ser na fase de pesquisa aplicada (protótipo) ou na fase de produção (comercial), ou disponível para ser adotado na produção. Por outro lado, deve ter documentado evidências que lhe dão apoio suficiente e maturidade tecnológica maior ou igual a TRL5.',
  'about.fourth_section.faq.content.fourth.question': 'Como posso participar no processo de revisão da qualidade das publicações (oportunidades)?',
  'about.fourth_section.faq.content.fourth.answer': 'Se você deseja participar do processo de revisão e avaliação, você deve se aplicar para se tornar um curador. Você vai encontrar esta opção no menu principal. Uma vez que seu aplicativo foi aprovado, você receberá um email de notificação. Por favor, note que você participará do processo de revisão para garantir que as informações tenham passado um padrão de qualidade mínimo. No entanto, o curador não é responsável por garantir que a informação nos artigos científicos mencionados seja correta, pois essa é a responsabilidade dos revisores nos periódicos onde a pesquisa original foi publicada.',
  'about.fourth_section.faq.content.fifth.question': 'Como é o processo de revisão e avaliação da qualidade das informações realizadas?',
  'about.fourth_section.faq.content.fifth.answer': 'O curador de informações continuará a rever uma oportunidade com base nos critérios de qualidade da plataforma. Esses critérios de qualidade visam analisar as informações sobre as oportunidades e identificar aqueles com potencial mais significativo com base em critérios de viabilidade técnica e valor econômico, social e ambiental. Estes critérios incluem um grupo que avalia se a contribuição pode ser publicada no Transformar. Em contrapartida, um segundo grupo centra-se na avaliação da qualidade da contribuição em termos do seu potencial.',
  'about.fourth_section.faq.content.fifth.answer.download_quality_criteria_guide': 'Baixe o guia de critérios de qualidade aqui.',

  'about.fifth_section.contact.header': 'Contacte-nos!',
  'about.fifth_section.contact.subheader': 'Alguma pergunta ou observação? Escreva-nos uma mensagem!',
  'about.fifth_section.contact.socials.title': 'Siga nossas redes sociais',
  'about.fifth_section.contact.email.title': 'Envie-nos um email'
};

const SIGNUP = {
  'signup.header': 'Bem-vindo à Transformar™',
  'signup.hero.text': 'Contribua e se beneficie do primeiro ecossistema de conhecimento aberto sobre oportunidades para a transformação sustentável da biomassa na América Latina e no Caribe.',
  'signup.already.registered': 'Já tem uma conta?',
  'signup.login': 'Conecte-se',

  'signup.form.email': 'Email',
  'signup.form.username': 'Nome de utilizador',
  'signup.form.password': 'Senha',
  'signup.form.password_confirm': 'Confirmar senha',
  'signup.form.given_name': 'Nome',
  'signup.form.family_name': 'Sobrenome',
  'signup.form.institution_name': 'Nome da instituição',
  'signup.form.institution_position': 'Posição na instituição',
  'signup.form.professional_affiliation': 'Afiliação profissional',
  'signup.form.location_country': 'Pais',
  'signup.form.location_province': 'Estado',
  'signup.form.location_city': 'Cidade',

  'signup.errors.email.invalid': 'Email inválido',
  'signup.errors.email.max': 'Email não pode ser superior a 256 caracteres',
  'signup.errors.email.required': 'Email não pode ser vazio',

  'signup.errors.username.length': 'O nome de usuário deve ser entre 3 e 20 caracteres',
  'signup.errors.username.disallowed': 'O nome de usuário não pode ser "me"',
  'signup.errors.username.pattern': 'O nome de usuário só pode conter letras, números e os caracteres: . _ -',
  'signup.errors.username.required': 'O nome de usuário não pode ser vazio',

  'signup.errors.given_name.length': 'O nome deve ser entre 1 e 45 caracteres',
  'signup.errors.given_name.required': 'O nome não pode ser vazio',

  'signup.errors.family_name.length': 'O sobrenome deve ser entre 1 e 45 caracteres',
  'signup.errors.family_name.required': 'O sobrenome não pode estar vazio',

  'signup.errors.institution_name.max': 'O nome da instituição não pode ser superior a 256 caracteres',
  'signup.errors.institution_name.required': 'O nome da instituição não pode ser vazio',

  'signup.errors.institution_position.max': 'A posição da instituição não pode ser superior a 256 caracteres',
  'signup.errors.institution_position.required': 'A posição da instituição não pode ser vazia',

  'signup.errors.professional_affiliation.max': 'A afiliação profissional não pode ser superior a 256 caracteres',
  'signup.errors.professional_affiliation.required': 'A afiliação profissional não pode estar vazia',

  'signup.errors.location_country.max': 'O país não pode ser superior a 256 caracteres',
  'signup.errors.location_country.required': 'O país não pode estar vazio',

  'signup.errors.location_province.max': 'O estado não pode ser superior a 256 caracteres',
  'signup.errors.location_province.required': 'O estado não pode estar vazio',

  'signup.errors.location_city.max': 'A cidade não pode ser superior a 256 caracteres',
  'signup.errors.location_city.required': 'A cidade não pode estar vazia',

  'signup.errors.password.special_chars': 'A senha deve conter pelo menos 1 caractere especial',
  'signup.errors.password.lowercase': 'A senha deve conter pelo menos 1 caractere minúscula',
  'signup.errors.password.uppercase': 'A senha deve conter pelo menos 1 caractere maiúscula',
  'signup.errors.password.white_spaces': 'A senha não pode conter espaços',
  'signup.errors.password.required': 'A senha não pode estar vazia',

  'signup.errors.password_confirm.different': 'Deve ser o mesmo que a senha',
  'signup.errors.password_confirm.required': 'A confirmação de senha não pode ser vazia',

  'signup.signup': 'Cadastre-se',

  'signup.errors.header': 'Não foi possível registrar você',

  'signup.post.header': 'Obrigado por registrar {name}!',
  'signup.post.description': 'Por favor, verifique sua caixa de entrada ({email}) para um email de verificação e siga as instruções incluídas.',
  'signup.post.buttons.login': 'Já se verificou? Clique aqui para entrar',
  'signup.post.buttons.resend_code': 'Não recebeu nenhum código de verificação?'
};

const LOGIN = {
  'login.header': 'Bem-vindo!',
  'login.hero.text': 'Entre na sua conta Transformar™ e continue a participar e a beneficiar da partilha de conhecimentos sobre oportunidades para a transformação da biomassa.',

  'login.form.email': 'Email',
  'login.form.password': 'Senha',
  'login.form.stay_signed_in': 'Fique registrado',

  'login.errors.email.invalid': 'Email inválido',
  'login.errors.email.required': 'O email não pode ser vazio',

  'login.errors.password.required': 'A senha não pode estar vazia',

  'login.login': 'Login',

  'login.dont.have.account': 'Não tem conta?',
  'login.register': 'Cadastre-se aqui',

  'login.links.forgot_password': 'Esqueceu sua senha?',
  'login.links.resend_verification': 'Não recebeu o seu código de verificação?',

  'login.errors.header': 'Não foi possível registrar você'
};

const AUTH = {
  'auth.generic.errors.validation': 'Uma validação exigida falhou.',
  'auth.generic.errors.unauthorized': 'Não está autorizado a fazer isto.',

  'auth.login.errors.external': 'Aconteceu algo errado ao tentar fazer login. Por favor, tente novamente mais tarde.',
  'auth.login.errors.internal': 'Aconteceu algo errado ao tentar fazer login. Se você vir esta mensagem, entre em contato com um administrador do sistema.',
  'auth.login.errors.wrong_credentials': 'Email ou senha podem estar incorretas. Verifique suas credenciais e tente novamente.',
  'auth.login.errors.password_reset_required': 'Por favor, reinicie sua senha.',
  'auth.login.errors.rate_limit': 'Você tentou entrar demais, por favor, espere e tente novamente mais tarde.',
  'auth.login.errors.confirmation_required': 'Seu email ainda não foi verificado. Por favor, verifique a sua caixa de entrada.',
  'auth.login.errors.unknown': 'Um erro desconhecido aconteceu ao tentar fazer login. Se você vir esta mensagem, entre em contato com um administrador do sistema.',

  'auth.signup.errors.external': 'Aconteceu algo errado ao tentar inscrever-te. Por favor, tente novamente mais tarde.',
  'auth.signup.errors.internal': 'Aconteceu algo errado ao tentar inscrever-te. Se você vir esta mensagem, entre em contato com um administrador do sistema.',
  'auth.signup.errors.invalid_password': 'A senha que você tentou usar não é segura o suficiente.',
  'auth.signup.errors.rate_limit': 'Você tentou se inscrever demais, por favor, espere e tente novamente mais tarde.',
  'auth.signup.errors.email_exists': 'Este email já está em uso.',
  'auth.signup.errors.username_exists': 'Este nome de usuário já está em uso.',
  'auth.signup.errors.unknown': 'Um erro desconhecido aconteceu ao tentar inscrevê-lo. Se você vir esta mensagem, entre em contato com um administrador do sistema.',

  'auth.confirm_signup.errors.external': 'Aconteceu algo errado ao tentar confirmar o seu cadastro. Por favor, tente novamente mais tarde.',
  'auth.confirm_signup.errors.internal': 'Aconteceu algo errado ao tentar confirmar o seu cadastro. Se você vir esta mensagem, entre em contato com um administrador do sistema.',
  'auth.confirm_signup.errors.already_exists': 'Este email já está em uso.',
  'auth.confirm_signup.errors.wrong_code': 'O código que você forneceu está incorreta. Certifique-se de ter inserido o código certo e tente novamente.',
  'auth.confirm_signup.errors.code_expired': 'O código fornecido expirou. Solicite outro código de verificação e tente novamente.',
  'auth.confirm_signup.errors.rate_limit': 'Tentou confirmar o seu registo demasiado, por favor, espere e tente novamente mais tarde.',
  'auth.confirm_signup.errors.email_not_found': 'Este email não é usado por qualquer conta.',
  'auth.confirm_signup.errors.unknown': 'Um erro desconhecido aconteceu ao tentar confirmar o seu cadastro. Se você vir esta mensagem, entre em contato com um administrador do sistema.',

  'auth.resend_verification_code.errors.external': 'Aconteceu algo errado ao tentar reenviar-lhe um código de verificação. Por favor, tente novamente mais tarde.',
  'auth.resend_verification_code.errors.internal': 'Aconteceu algo errado ao tentar reenviar-lhe um código de verificação. Se você vir esta mensagem, entre em contato com um administrador do sistema.',
  'auth.resend_verification_code.errors.delivery_failure': 'O código de verificação solicitado não pode ser enviado. Tente novamente mais tarde.',
  'auth.resend_verification_code.errors.rate_limit': 'Você tentou reenviar um código de verificação demais, por favor, espere e tente novamente mais tarde.',
  'auth.resend_verification_code.errors.unknown': 'Um erro desconhecido aconteceu ao tentar reenviar-lhe um código de verificação. Se você vir esta mensagem, entre em contato com um administrador do sistema.',

  'auth.global_sign_out.errors.external': 'Aconteceu algo errado ao tentar desconectar você de todos seus dispositivos. Por favor, tente novamente mais tarde.',
  'auth.global_sign_out.errors.internal': 'Aconteceu algo errado ao tentar desconectar você de todos seus dispositivos. Se você vir esta mensagem, entre em contato com um administrador do sistema.',
  'auth.global_sign_out.errors.password_reset_required': 'Você precisa redefinir sua senha antes de se desconectar você de todos seus dispositivos.',
  'auth.global_sign_out.errors.rate_limit': 'Você tentou se desconectar você de todos seus dispositivos demais, por favor, espere e tente novamente mais tarde.',
  'auth.global_sign_out.errors.unknown': 'Um erro desconhecido aconteceu ao tentar desconectar você de todos seus dispositivos. Se você vir esta mensagem, entre em contato com um administrador do sistema.',

  'auth.change_password.errors.external': 'Aconteceu algo errado ao tentar mudar sua senha. Por favor, tente novamente mais tarde.',
  'auth.change_password.errors.internal': 'Aconteceu algo errado ao tentar mudar sua senha. Se você vir esta mensagem, entre em contato com um administrador do sistema.',
  'auth.change_password.errors.invalid_password': 'Sua nova senha é inválida. Certifique-se de que é seguro o suficiente e tente novamente.',
  'auth.change_password.errors.password_reset_required': 'Você precisa redefinir sua senha antes de alterar sua senha.',
  'auth.change_password.errors.rate_limit': 'Você tentou mudar sua senha demais, por favor, espere e tente novamente mais tarde.',
  'auth.change_password.errors.unknown': 'Um erro desconhecido aconteceu ao tentar mudar sua senha. Se você vir esta mensagem, entre em contato com um administrador do sistema.',

  'auth.forgot_password.errors.external': 'Aconteceu algo errado ao tentar redefinir sua senha. Por favor, tente novamente mais tarde.',
  'auth.forgot_password.errors.internal': 'Aconteceu algo errado ao tentar redefinir sua senha. Se você vir esta mensagem, entre em contato com um administrador do sistema.',
  'auth.forgot_password.errors.delivery_failure': 'O código de redefinição de senha não pode ser enviado para o seu email. Tente novamente mais tarde.',
  'auth.forgot_password.errors.rate_limit': 'Você tentou redefinir sua senha demais, por favor, espere e tente novamente mais tarde.',
  'auth.forgot_password.errors.unknown': 'Um erro desconhecido aconteceu ao tentar redefinir sua senha. Se você vir esta mensagem, entre em contato com um administrador do sistema.',

  'auth.forgot_password_submit.errors.external': 'Aconteceu algo errado ao tentar enviar sua redefinição de senha. Por favor, tente novamente mais tarde.',
  'auth.forgot_password_submit.errors.internal': 'Aconteceu algo errado ao tentar enviar sua redefinição de senha. Se você vir esta mensagem, entre em contato com um administrador do sistema.',
  'auth.forgot_password_submit.errors.wrong_code': 'O código que você forneceu está incorreta. Certifique-se de ter inserido o código certo e tente novamente.',
  'auth.forgot_password_submit.errors.code_expired': 'O código fornecido expirou. Por favor, reinicie sua senha novamente.',
  'auth.forgot_password_submit.errors.invalid_password': 'Sua nova senha é inválida. Certifique-se de que é seguro o suficiente e tente novamente.',
  'auth.forgot_password_submit.errors.rate_limit': 'Você tentou redefinir sua senha demais, por favor, espere e tente novamente mais tarde.',
  'auth.forgot_password_submit.errors.user_not_confirmed': 'Seu email ainda não foi verificado. Por favor, verifique sua caixa de entrada para um email de verificação.',
  'auth.forgot_password_submit.errors.unknown': 'Um erro desconhecido aconteceu ao tentar enviar sua redefinição de senha. Se você vir esta mensagem, entre em contato com um administrador do sistema.'
};

const RESEND_VERIFICATION = {
  'resend_verification.header': 'Reenviar seu código de verificação',
  'resend_verification.description': 'Digite sua direção de email aqui. Se uma conta estiver associada a este email, uma mensagem de verificação será enviada para a sua caixa de entrada. Depois de receber a mensagem, siga as instruções.',

  'resend_verification.form.email': 'Email',
  'resend_verification.form.submit': 'Reenviar verificação',

  'resend_verification.success.header': 'Verifique sua caixa de entrada',
  'resend_verification.success.description': 'Se este email corresponder a um email registrado válido, então você deve receber uma mensagem com outras instruções.',

  'resend_verification.errors.header': 'Não foi possível reenviar-lhe um código de verificação',

  'resend_verification.errors.email.invalid': 'Email inválido',
  'resend_verification.errors.email.required': 'Email não pode ser vazio'
};

const FORGOT_PASSWORD = {
  'forgot_password.header': 'Esqueceu sua senha?',
  'forgot_password.description': 'Digite sua direção de email aqui. Se uma conta estiver associada a este email, um código será enviado para sua caixa de entrada que será usado para redefinir sua senha.',

  'forgot_password.form.email': 'Email',
  'forgot_password.form.code': 'Código',
  'forgot_password.form.password': 'Senha',
  'forgot_password.form.password_confirm': 'Confirmar senha',
  'forgot_password.form.submit': 'Redefinir senha',

  'forgot_password.errors.header': 'Não foi possível redefinir sua senha',

  'forgot_password.errors.email.invalid': 'Email inválido',
  'forgot_password.errors.email.required': 'Email não pode ser vazio',

  'forgot_password.errors.code.required': 'Código não pode ser vazio',

  'forgot_password.errors.password.special_chars': 'A senha deve conter pelo menos 1 caractere especial',
  'forgot_password.errors.password.lowercase': 'A senha deve conter pelo menos 1 caractere minúscula',
  'forgot_password.errors.password.uppercase': 'A senha deve conter pelo menos 1 caractere maiúscula',
  'forgot_password.errors.password.white_spaces': 'A senha não pode conter espaços',
  'forgot_password.errors.password.required': 'A senha não pode estar vazia',

  'forgot_password.errors.password_confirm.different': 'Deve ser o mesmo que a senha',
  'forgot_password.errors.password_confirm.required': 'A confirmação de senha não pode ser vazia'
};

const EXPLORE = {
  'explore.errors.header': 'Não foi possível buscar transformações',
  'explore.search_button.label': 'Pesquisar',
  'explore.published_transformations.header': 'Oportunidades',

  'explore.search.result.empty.header': 'Nenhuma oportunidade encontrada.',
  'explore.search.result.empty.description': 'Não foram encontradas oportunidades que satisfaçam seus critérios de busca.'
};

const TRANSFORMATION = {
  'transformation.item.defaults.title': 'Transformação sem nome',
  'transformation.item.defaults.description': 'Não há descrição disponível.',
  'transformation.item.defaults.subtitle': 'Nenhum nome orgânico bruto fornecido.',

  'transformation.form.error.header': 'Não foi possível obter os dados necessários para o formulário.',
  'transformation.form.heading.message.header': 'Como preencher esta seção',

  'transformation.form.curation.header': 'O que nossos curadores disseram sobre esta seção',
  'transformation.form.curation.empty.text': 'Tudo nesta seção está OK, não há necessidade de atualizações.',

  'transformation.form.image.title': 'Imagem',
  'transformation.form.image.picker.header': 'Escolha uma imagem para o seu documento.',
  'transformation.form.image.picker.errors.header': 'Aconteceu algo errado ao tentar atualizar a imagem de transformação',
  'transformation.form.image.picker.change_image': 'Alterar imagem',
  'transformation.form.image.picker.click_to_add_image': 'Clique para adicionar uma imagem',

  /* TAB 1 - Basic Information */
  'transformation.form.basic_information.title': 'Informação básica',
  'transformation.form.basic_information.heading': 'Por favor, forneça o máximo de informações possível sobre o material orgânico específico / biomassa (por exemplo, cascas de cacau) usado no processo / produto de UMA transformação. Se você gostaria de fornecer mais de um processo de transformação para o mesmo material orgânico, por favor publique uma nova oportunidade. Se mais de uma biomassa for utilizada, por favor, descreva-as no campo "descrição breve da biomassa".',

  'transformation.form.basic_information.producing_species_name.label': 'Espécies',
  'transformation.form.basic_information.producing_species_name.hint': 'O nome científico da principal fonte de espécies de biomassa',
  'transformation.form.basic_information.producing_species_name.hint.example': 'Por exemplo, Cacao theobroma',
  'transformation.form.basic_information.producing_species_name.errors.max': 'Não pode ser superior a 256 caracteres.',

  'transformation.form.basic_information.common_names.label': 'Nomes comuns',
  'transformation.form.basic_information.common_names.hint': 'Adicione uma vírgula para separar nomes diferentes.',
  'transformation.form.basic_information.common_names.hint.example': 'Por exemplo, para Cacao theobroma, "cocoa"',
  'transformation.form.basic_information.common_names.errors.max': 'Não pode ser superior a 256 caracteres.',
  'transformation.form.basic_information.common_names.errors.required': 'São necessários nomes comuns.',
  'transformation.form.basic_information.common_names.errors.min': 'Pelo menos um nome comum é necessário.',

  /* Modified Field - selection from options: natural, residual, produced */
  'transformation.form.basic_information.biomass_class.label': 'Classe de Biomassa',
  'transformation.form.basic_information.biomass_class.hint': 'Biomassa natural: Produzido pela natureza, sem intervenção humana\n' +
    'Biomassa residual: resíduos orgânicos provenientes de atividades humanas (por exemplo, resíduos sólidos urbanos)\n' +
    'Biomassa produzida: As culturas de energia, o cultivo de biomassa apenas para fontes de energia.',
  'transformation.form.basic_information.biomass_class.options.natural': 'Biomassa natural',
  'transformation.form.basic_information.biomass_class.options.residual': 'Biomassa residual',
  'transformation.form.basic_information.biomass_class.options.produced': 'Biomassa produzida',
  'transformation.form.basic_information.biomass_class.errors.max': 'Não pode ser mais do que 3 caracteres.',

  'transformation.form.basic_information.organic_material_name.label': 'Nome da matéria orgânica crua',
  'transformation.form.basic_information.organic_material_name.hint': 'Parte(s) ou componente(s) das principais espécies utilizadas no processo de transformação/produto. Adicione uma vírgula para separar nomes diferentes.',
  'transformation.form.basic_information.organic_material_name.hint.example': 'Por exemplo, casulos, cascas de cacau, nibs de cacau, mucilagem.',
  'transformation.form.basic_information.organic_material_name.errors.max': 'Não pode ser superior a 256 caracteres.',
  'transformation.form.basic_information.organic_material_name.errors.required': 'O nome do material orgânico é necessário.',

  'transformation.form.basic_information.source_type.label': 'Tipo de fonte',
  'transformation.form.basic_information.source_type.hint': 'Diferentes fontes de biomassa principal utilizadas em seu produto. Por exemplo, cascas de cacau correspondem a "resíduo agrícola"',
  'transformation.form.basic_information.source_type.errors.base': 'Tipo de fonte deve ser um inteiro.',

  'transformation.form.basic_information.source_class.label': 'Classe de fonte',
  'transformation.form.basic_information.source_class.hint': '"Cocoa husks" é derivado de "Cocoa e preparações de cacau" (classe de fonte). O código HS é usado como referência padrão.',
  'transformation.form.basic_information.source_class.errors.base': 'A classe fonte deve ser um inteiro.',

  'transformation.form.basic_information.source_sub_class.label': 'Subclasse de fonte',
  'transformation.form.basic_information.source_sub_class.hint': 'Subclasse de fonte corresponde a uma subclassificação de "classe de fonte" que aborda fontes específicas de biomassa. Por exemplo, cascas de cacau caem em "resíduo agrícola, classe de fonte "Cocoa e preparações de cacau", subclasse de fonte "Husks".',
  'transformation.form.basic_information.source_sub_class.errors.base': 'A classe de fonte deve ser um inteiro.',

  'transformation.form.basic_information.location.label': 'Localização',
  'transformation.form.basic_information.location.hint': 'A localização geográfica do projeto',
  'transformation.form.basic_information.location.errors.base': 'A localização deve ser um inteiro.',

  'transformation.form.basic_information.brief_biomass_description.label': 'Descrição breve da biomassa',
  'transformation.form.basic_information.brief_biomass_description.hint': 'Adicione informações adicionais relevantes para as espécies produtoras. Se mais de uma biomassa for utilizada, descreva-as neste campo.',
  'transformation.form.basic_information.brief_biomass_description.errors.max': 'Não pode ser superior a 350 caracteres.',
  'transformation.form.basic_information.brief_biomass_description.errors.required': 'É necessária uma breve descrição da biomassa.',

  'transformation.form.basic_information.current_uses_or_transformations.label': 'Uso ou transformação amplamente conhecidos',
  'transformation.form.basic_information.current_uses_or_transformations.hint': 'Se um produto ou transformação é amplamente utilizado com sua biomassa, digite-o aqui. Adicione uma vírgula para separar cada conceito diferente.',
  'transformation.form.basic_information.current_uses_or_transformations.hint.example': 'Por exemplo, O cacau é geralmente usado para preparar: chocolate, manteiga de cacau, cacau e cacau em pó. Se você produzir, por exemplo, "nanolignin" de cacau NÃO inclua aplicações novas ou não comuns aqui.',
  'transformation.form.basic_information.current_uses_or_transformations.errors.max': 'Não pode ser superior a 256 caracteres.',

  'transformation.form.basic_information.current_negative_impact.label': 'Impacto negativo atual se qualquer',
  'transformation.form.basic_information.current_negative_impact.hint': 'O impacto negativo social, ambiental ou econômico do uso ou não da biomassa ou da transformação.',
  'transformation.form.basic_information.current_negative_impact.errors.max': 'Não pode ser superior a 350 caracteres.',
  'transformation.form.basic_information.current_negative_impact.errors.required': 'São necessários impactos negativos atuais.',

  'transformation.form.basic_information.trl_eu.label': 'Nível de prontidão da tecnologia (TLR-EU)',
  'transformation.form.basic_information.trl_eu.hint': 'Selecione o estado da arte na questão do processo de transformação selecionado. Este banco de dados não inclui tecnologias abaixo de TLR5. TLR5: Tecnologia validada em ambiente relevante, TLR6: Tecnologia demonstrada em ambiente relevante, TLR7: Modelo de sistema ou demonstração protótipo em ambiente operacional, TLR8: Sistema/modelo produzido e qualificado. Um exemplo pode incluir o conhecimento gerado do TRL 7 sendo usado para fabricar um sistema/modelo real, que é posteriormente qualificado em um ambiente operacional. Na maioria dos casos, este TRL representa o fim do desenvolvimento. TLR9: Sistema real comprovado em ambiente operacional.',
  'transformation.form.basic_information.trl_eu.errors.base': 'O nível de prontidão da tecnologia deve ser inteiro.',

  /* TAB 2 - Transformation Process */
  'transformation.form.transformation_process.title': 'Processo de transformação',
  'transformation.form.transformation_process.heading': 'Preencha as seguintes seções para o seu processo de transformação de material orgânico selecionado. Se você gostaria de fornecer mais de um processo de transformação para o mesmo material orgânico, por favor publique uma nova oportunidade. Digite N/A se você não tiver informações.',

  'transformation.form.transformation_process.brief_description_of_transformation_process': 'Breve descrição do processo de transformação',
  'transformation.form.transformation_process.brief_description_of_transformation_process.hint': 'Adicione uma breve descrição em linguagem comum do processo de transformação.',
  'transformation.form.transformation_process.brief_description_of_transformation_process.errors.max': 'Breve descrição do processo de transformação não pode ser superior a 350 caracteres.',
  'transformation.form.transformation_process.brief_description_of_transformation_process.errors.required': 'Breve descrição do processo de transformação é necessária.',

  'transformation.form.transformation_process.evidences.label': 'Evidências documentais',
  'transformation.form.transformation_process.evidences.hint': 'Por exemplo, documentos, patentes, relatórios e dissertações. Descrição da URL. Descreva cada URL brevemente.',

  'transformation.form.transformation_process.evidence_url.label': 'URL de evidência de pesquisa',
  'transformation.form.transformation_process.evidence_url.hint': 'Por exemplo, URLs de artigos, patentes, relatórios, dissertações',
  'transformation.form.transformation_process.evidence_url.errors.uri': 'É necessária uma URL válida.',
  'transformation.form.transformation_process.evidence_url.errors.max': 'Não pode ser superior a 512 caracteres.',

  'transformation.form.transformation_process.publishing_year.label': 'Ano de publicação',
  'transformation.form.transformation_process.publishing_year.errors.base': 'O ano de publicação deve ser um inteiro.',
  'transformation.form.transformation_process.publishing_year.errors.positive': 'O ano de publicação deve ser positivo.',

  'transformation.form.transformation_process.url_description.label': 'Descrição da URL',
  'transformation.form.transformation_process.url_description.hint': 'Descreva cada URL brevemente',
  'transformation.form.transformation_process.url_description.errors.max': 'Não pode ser mais do que 1024 caracteres.',

  'transformation.form.transformation_process.developed_technologies.label': 'Processo de transformação desenvolvido',
  'transformation.form.transformation_process.developed_technologies.hint': 'Transformação é o conjunto de operações sequenciais que segue uma matéria-prima até que se torne o produto desejado com valor agregado (Levenspiel, 2004). Ref. Levenspiel, O. (2004). Ingeniería de las Reacciones Químicas (Third Edition). Limusa Wiley. Você pode adicionar mais de um processo de transformação. Adicione uma vírgula para separar diferentes processos de transformação.',
  'transformation.form.transformation_process.developed_technologies.hint.example': 'Por exemplo, Hidrólise ácida usando ácido oxálico.',
  'transformation.form.transformation_process.developed_technologies.errors.max': 'Não pode ser superior a 256 caracteres.',
  'transformation.form.transformation_process.developed_technologies.errors.required': 'Processo de transformação desenvolvido é necessário.',

  'transformation.form.transformation_process.products_or_services.label': 'Produto ou serviço resultante',
  'transformation.form.transformation_process.products_or_services.hint': 'Liste produtos/serviços atuais ou potenciais. Use vírgulas para separar cada produto/serviço em potencial.',
  'transformation.form.transformation_process.products_or_services.hint.example': 'Por exemplo, nanocelullose fibrilada',
  'transformation.form.transformation_process.products_or_services.errors.max': 'Não pode ser superior a 256 caracteres.',
  'transformation.form.transformation_process.products_or_services.errors.required': 'Produto ou serviço é necessário.',

  'transformation.form.transformation_process.industrial_applications.label': 'Aplicações industriais',
  'transformation.form.transformation_process.industrial_applications.hint': 'Liste as aplicações industriais e outras aplicações potenciais.',
  'transformation.form.transformation_process.industrial_applications.hint.example': 'Por exemplo, sistemas de filtragem de água potável, dispositivos ópticos, materiais biomédicos, biosensores ou eletrônica verde. Use vírgulas para separar cada aplicação industrial.',
  'transformation.form.transformation_process.industrial_applications.errors.max': 'Não pode ser superior a 256 caracteres.',
  'transformation.form.transformation_process.industrial_applications.errors.required': 'A aplicação industrial é necessária.',

  'transformation.form.transformation_process.associated_pollutants.label': 'Há algum material contaminante que possa afetar o processo de transformação ou produto resultante?',
  'transformation.form.transformation_process.associated_pollutants.hint': 'Uso de reagentes severos e geração de subprodutos com impacto ambiental negativo.',
  'transformation.form.transformation_process.associated_pollutants.hint.example': 'Por exemplo, ácido oxálico. Use vírgulas para separar cada poluente associado.',
  'transformation.form.transformation_process.associated_pollutants.errors.max': 'Não pode ser superior a 256 caracteres.',
  'transformation.form.transformation_process.associated_pollutants.errors.required': 'São necessários materiais contaminantes.',

  'transformation.form.transformation_process.patents_associated.label': 'Há patentes associadas a este processo ou tecnologia relacionada?',
  'transformation.form.transformation_process.patents_associated.hint': 'Este campo visa saber se existem interesses/oportunidades industriais potenciais ou atuais em tecnologia. Se existirem patentes, a parte interessada analisará bancos de dados especializados, como o PatentScope.',
  'transformation.form.transformation_process.patents_associated.errors.max': 'Não pode ser mais do que 3 caracteres.',

  'transformation.form.transformation_process.licenses_emitted.label': 'Esta patente foi licenciada para uma terceira parte?',
  'transformation.form.transformation_process.licenses_emitted.hint': 'Os acordos de licenciamento são contratos juridicamente vinculativos onde o proprietário da propriedade intelectual (IP) em uma tecnologia valiosa (o licenciante) permite a outra pessoa (o licenciado) a permissão para usar esse IP de maneiras (termos) que são enunciados no acordo WIPO. (n.d.). Technology Transfer Agreements. Consultado em 31 de outubro de 2022, de https://www.wipo.int/technology-transfer/en/agreements.html. Este campo visa saber se existem interesses/oportunidades industriais potenciais ou atuais em tecnologia. Se uma licença tiver sido emitida, ela indicará um interesse mais forte na tecnologia.',
  'transformation.form.transformation_process.licenses_emitted.errors.max': 'Não pode ser mais do que 3 caracteres.',

  'transformation.form.transformation_process.free_access.label': 'Existe acesso livre à propriedade intelectual sobre este processo de transformação?',
  'transformation.form.transformation_process.free_access.hint': 'Se você responder "Sim", marque as próximas duas perguntas como "Não"',
  'transformation.form.transformation_process.free_access.errors.max': 'Não pode ser mais do que 3 caracteres.',

  /* TAB 3 - Scalability */
  'transformation.form.scalability.title': 'Escalabilidade',
  'transformation.form.scalability.heading': 'Preencha as seguintes seções sobre a sustentabilidade do processo de transformação proposto. Se você gostaria de fornecer mais de um processo de transformação para o mesmo material orgânico, por favor publique uma nova oportunidade. Digite N/A se você não tiver informações.',

  'transformation.form.scalability.technical_scalability.label': 'Escalabilidade técnica',
  'transformation.form.scalability.technical_scalability.hint': 'Com base no seu know-how, este campo visa avaliar a viabilidade de escalar até estágios industriais/comerciais a tecnologia desenvolvida a partir do ponto de vista técnico. "A precisão da escalabilidade é vital para garantir que o trabalho realizado na escala laboratorial não leve a recursos desperdiçados e tempo ou em achados que são incapazes de se aplicar a aplicações em grande escala." (Lim et al., 2021). Lim, Y. F., Chan, Y. J., Abakr, Y. A., Sethu, V., Selvarajoo, A., Singh, A., A., Lee, J., & Gareth, M. (2021). Review of biowastes to energy in Malaysia: Current technology, scalability and socioeconomic analysis. Cleaner Engineering and Technology, 4, 100257. https://doi.org/10.1016/J.CLET.2021.10025. Você tem informações suficientes para montar as etapas comerciais e determinar possíveis problemas e soluções? Se você respondeu "Não", especifique as potenciais barreiras e soluções no seguinte campo.',
  'transformation.form.scalability.technical_scalability.errors.max': 'Não pode ser mais do que 3 caracteres.',

  'transformation.form.scalability.technical_scalability_challenges.label': 'Desafios técnicos de escalabilidade',
  'transformation.form.scalability.technical_scalability_challenges.hint': 'Descreva os principais desafios técnicos de escalabilidade e as possíveis soluções para eles.',
  'transformation.form.scalability.technical_scalability_challenges.errors.required': 'Os desafios técnicos de escalabilidade são necessários.',

  'transformation.form.scalability.required_equipment.label': 'Equipamento necessário',
  'transformation.form.scalability.required_equipment.hint': 'Você pode adicionar mais de um equipamento necessário. Por favor, liste o equipamento principal que você considera essencial ao preparar orçamentos ou planejamento para implementar a tecnologia. Adicione uma vírgula para separar diferentes peças de equipamentos. Por exemplo, biodigester, reator de pressão, ultra-som industrial, tanque de hidrólise. Apenas adicione equipamentos importantes / não padrão.',
  'transformation.form.scalability.required_equipment.errors.max': 'Não pode ser superior a 256 caracteres.',
  'transformation.form.scalability.required_equipment.errors.required': 'O equipamento necessário é necessário.',

  'transformation.form.scalability.required_supplies.label': 'Suprimentos necessários',
  'transformation.form.scalability.required_supplies.hint': 'Você pode adicionar mais de uma fonte. Use vírgulas para separar cada um. Por favor, liste os principais suprimentos necessários ao preparar orçamentos ou planejamento para implementar a tecnologia. Por exemplo, produtos químicos, biocatalisador especial e água. Apenas adicione suprimentos importantes / não padrão.',
  'transformation.form.scalability.required_supplies.errors.max': 'Não pode ser superior a 256 caracteres.',
  'transformation.form.scalability.required_supplies.errors.required': 'São necessários suprimentos necessários.',

  'transformation.form.scalability.required_skills.label': 'Habilidades necessárias',
  'transformation.form.scalability.required_skills.hint': 'Você pode adicionar mais de uma habilidade necessária. Use vírgulas para separar cada um. Por favor, liste as principais habilidades consideradas necessárias ao preparar orçamentos ou planejamento para implementar a tecnologia. Por exemplo, engenheiros químicos ou químicos com experiência em escala, avaliação do ciclo de vida, síntese orgânica, química verde, processamento termoquímico de biomassa, experiência em gramíneas lignocelulósicas, desenvolvimento e operação de grandes infraestruturas de agregação e conversão de biomassa para produzir substituto ambientalmente seguro do carvão para a indústria de geração de energia. Apenas adicione habilidades importantes / não padrão.',
  'transformation.form.scalability.required_skills.errors.max': 'Não pode ser superior a 256 caracteres.',
  'transformation.form.scalability.required_skills.errors.required': 'São necessárias habilidades necessárias.',

  'transformation.form.scalability.minimal_scale_production.label': 'Produção de escala mínima',
  'transformation.form.scalability.minimal_scale_production.hint': 'Com base no seu know-how, este campo visa estimar a produção de escala mínima para alcançar uma etapa industrial/comercial da tecnologia desenvolvida. Calcule a produção de escala mínima com base em critérios de receita, técnico e logístico.',
  'transformation.form.scalability.minimal_scale_production.min_value.label': 'Valor',
  'transformation.form.scalability.minimal_scale_production.min_value.errors.base': 'O valor mínimo deve ser um número.',
  'transformation.form.scalability.minimal_scale_production.min_value.errors.positive': 'O valor mínimo deve ser positivo.',
  'transformation.form.scalability.minimal_scale_production.min_unit.label': 'Unidade',
  'transformation.form.scalability.minimal_scale_production.min_unit.errors.max': 'Não pode ser superior a 128 caracteres.',

  'transformation.form.scalability.maximum_production_volume.label': 'Volume de produção máxima',
  'transformation.form.scalability.maximum_production_volume.hint': 'Com base no seu know-how, este campo visa estimar a produção de escala máxima para a viabilidade de escalas até uma fase industrial/comercial da tecnologia desenvolvida. A produção de escala máxima pode ser estimada com base nos critérios de sustentabilidade e biomassa disponíveis. Qual é a quantidade máxima de produto que uma indústria pode produzir visivelmente?',
  'transformation.form.scalability.maximum_production_volume.max_value.label': 'Valor',
  'transformation.form.scalability.maximum_production_volume.max_value.errors.base': 'O valor máximo deve ser um número.',
  'transformation.form.scalability.maximum_production_volume.max_value.errors.positive': 'O valor máximo deve ser positivo.',
  'transformation.form.scalability.maximum_production_volume.max_unit.label': 'Unidade',
  'transformation.form.scalability.maximum_production_volume.max_unit.errors.max': 'Não pode ser superior a 128 caracteres.',

  'transformation.form.scalability.logistical_scalability.label': 'Escalabilidade logística',
  'transformation.form.scalability.logistical_scalability.hint': 'Com base no seu know-how, este campo visa avaliar a viabilidade de escalar até estágios industriais/comerciais a tecnologia desenvolvida a partir de um ponto de vista logístico. Se você responder "Ainda não", especifique no seguinte campo as principais lacunas e barreiras e possíveis soluções.',
  'transformation.form.scalability.logistical_scalability.errors.max': 'Não pode ser superior a 3 caracteres',

  'transformation.form.scalability.logistical_scalability_challenges.label': 'Desafios de escalabilidade logística',
  'transformation.form.scalability.logistical_scalability_challenges.hint': 'Aqui descrever as principais barreiras ou desafios e possíveis alternativas para resolvê-los. Se você respondeu "Não" acima, especifique os principais desafios junto com possíveis soluções no seguinte campo.',
  'transformation.form.scalability.logistical_scalability_challenges.errors.required': 'Os desafios de escalabilidade logística são necessários.',

  'transformation.form.scalability.economical_scalability.label': 'Escalabilidade económica',
  'transformation.form.scalability.economical_scalability.hint': 'Com base no seu know-how, este campo visa estimar a viabilidade de escalar até uma fase industrial/comercial a tecnologia desenvolvida a partir de um ponto de vista econômico. Por favor, considere vários aspectos do processo econômico, custos de processamento, principais fatores de custo, análise de matéria-prima de biomassa, Etc.',
  'transformation.form.scalability.economical_scalability.errors.max': 'Não pode ser superior a 3 caracteres',

  'transformation.form.scalability.economical_scalability_challenges.label': 'Desafios de escalabilidade econômica',
  'transformation.form.scalability.economical_scalability_challenges.hint': 'Descreva os desafios de escalabilidade econômica e possíveis soluções para eles.',
  'transformation.form.scalability.economical_scalability_challenges.errors.required': 'São necessários desafios de escalabilidade econômica.',

  'transformation.form.scalability.annual_availability.label': 'Disponibilidade anual',
  'transformation.form.scalability.annual_availability.hint': 'Se aplicável, selecione os meses considerados a melhor estação de cultivo/especcie no país que você selecionou acima (seção de informação básica).',
  'transformation.form.scalability.annual_availability.errors.base': 'A disponibilidade anual deve ser um inteiro.',

  'transformation.form.scalability.climate.label': 'Clima ou bioma',
  'transformation.form.scalability.climate.hint': 'Por favor, selecione a categoria que melhor descreve a condição da cultura/especcie',
  'transformation.form.scalability.climate.errors.base': 'O clima deve ser inteiro.',

  'transformation.form.scalability.geographical_location.label': 'Localização geográfica',
  'transformation.form.scalability.geographical_location.hint': 'Por favor, especifique a localização geográfica da biomassa na seguinte ordem: população, cidade, estado, país',

  /* TAB 4 - Sustainable Development */
  'transformation.form.sustainability.title': 'Desenvolvimento sustentável',
  'transformation.form.sustainability.heading': 'O objetivo do desenvolvimento sustentável é atender às necessidades atuais sem comprometer as necessidades futuras. Nesta seção, forneça sua compreensão sobre como essa transformação de processo ajudaria ou dificultaria o desenvolvimento sustentável.',

  'transformation.form.sustainability.contributes_species_preservation.label': 'Esta transformação contribui ou pode contribuir para a conservação/preservação/revalorização da espécie?',
  'transformation.form.sustainability.contributes_species_preservation.hint': 'Por exemplo, com a recuperação de nanocelulose do casca de cacau, os resíduos de cacau podem ser reutilizados para embalagens in situ.',
  'transformation.form.sustainability.contributes_species_preservation.errors.max': 'Não pode ser mais do que 1024 caracteres.',

  'transformation.form.sustainability.contributes_circular_economy.label': 'A transformação contribui ou pode contribuir para a economia circular?',
  'transformation.form.sustainability.contributes_circular_economy.hint': 'Por exemplo, A transformação dos resíduos da indústria de cacau para nanocelulose, como material avançado, aumenta a rentabilidade desta indústria. Além disso, este processo de transformação reduz os resíduos subutilizados no ambiente, impedindo a poluição e levando a lucrar com este recurso renovável no nexo energético/água/alimentar. Além disso, o setor de energia pode usar nanocelulose e seus derivados como um verde nanomaterial dispositivos de conversão de energia eletroquímica. Outras oportunidades são aplicações farmacêuticas/biomédicas e remediação ambiental, pois os filtros nanocelulose podem purificar a água contaminada.',
  'transformation.form.sustainability.contributes_circular_economy.errors.max': 'Não pode ser mais do que 1024 caracteres.',

  'transformation.form.sustainability.sustainable_development_goals.label': 'Esta transformação contribui para qualquer Objetivo de Desenvolvimento Sustentável?',
  'transformation.form.sustainability.sustainable_development_goals.hint': 'Você pode selecionar mais de um ODS se necessário.',
  'transformation.form.sustainability.sustainable_development_goals.errors.base': 'Os objetivos de desenvolvimento sustentável devem ser números.',

  'transformation.form.sustainability.closes_productive_cycle_gap.label': 'Fecha as lacunas da circularidade?',
  'transformation.form.sustainability.closes_productive_cycle_gap.hint': 'Uma análise de lacuna de ciclo de vida identifica lacunas circulares sobre os impactos ambientais potenciais durante o ciclo de vida de um produto em termos de perdas de sistema entre um sistema fechado ideal e o status quo (Dieterle et al., 2018). Dieterle, M., Schäfer, P., & Viere, T. (2018). Life Cycle Gaps: Interpreting LCA Results with a Circular Economy Mindset. Procedia CIRP, 69, 764–768. https://doi.org/10.1016/J.PROCIR.2017.11.058',
  'transformation.form.sustainability.closes_productive_cycle_gap.errors.base': 'Deve ser verdade ou falso.',

  'transformation.form.sustainability.replaces_materials_fossil_origin.label': 'Ele substitui materiais de origem de combustíveis fósseis?',
  'transformation.form.sustainability.replaces_materials_fossil_origin.hint': 'O combustível de fósseis é um termo genérico para fontes de energia não renováveis, como carvão, produtos de carvão, gás natural, gás derivado, petróleo bruto, produtos petrolíferos e resíduos não renováveis. Estes combustíveis originam-se de plantas e animais que existiam no passado geológico (por exemplo, milhões de anos atrás) (Eurostat, 2019). Eurostat. (2019, 9 de Setembro). Glossary:Fossil fuel - Statistics Explained. http://ec.europa.eu/eurostat/statistics-explained/index.php?title=Glossary:Fossil_fuel',
  'transformation.form.sustainability.replaces_materials_fossil_origin.errors.base': 'Deve ser verdade ou falso.',

  'transformation.form.sustainability.reduces_greenhouse_effect_gasses.label': 'Reduz o efeito dos gases de efeito estufa?',
  'transformation.form.sustainability.reduces_greenhouse_effect_gasses.hint': 'Considere se o processo de transformação diminui a emissão de gases de efeito estufa emitidos em pelo menos uma das seguintes coisas que fazemos: fazer coisas (cemento, aço, plástico), ligar (eletricidade), coisas em crescimento (plantas, animais), ficando em torno (planos, caminhões, navios de carga), mantendo quente e fresco (aquecimento, resfriamento, refrigeração) (Gates, 2021 ). Gates, B. (2021). How to avoid a climate disaster: the solutions we have and the breakthroughs we need. Knopf.',
  'transformation.form.sustainability.reduces_greenhouse_effect_gasses.errors.base': 'Deve ser verdade ou falso.',

  /* TAB 5 - Profitability & Market Research */
  'transformation.form.market.title': 'Potencial de mercado',
  'transformation.form.market.heading': 'Nosso objetivo é reunir informações para avaliar se o processo é adequado para a construção de um modelo de negócio rentável.',

  'transformation.form.market.production_costs_estimation.label': 'Estimativa de custos de produção',
  'transformation.form.market.production_costs_estimation.hint': 'Estimar o custo da produção. Por favor, especifique a unidade e o produto no campo de unidades.',
  'transformation.form.market.production_costs_estimation.hint.example': 'Por exemplo, Kg de papel, L de óleo essencial',

  'transformation.form.market.production_costs_estimation.production_estimated_value.label': 'Valor por unidade',
  'transformation.form.market.production_costs_estimation.production_estimated_value.errors.base': 'Estimar o valor econômico por unidade do produto principal ou serviço derivado de sua transformação de processo. Você pode usar o volume/massa ou outra unidade de medição relevante. (por exemplo, Kg de papel, L de óleo essencial).',
  'transformation.form.market.production_costs_estimation.production_estimated_currency.label': 'Moeda',
  'transformation.form.market.production_costs_estimation.production_estimated_currency.errors.base': 'A moeda deve ser um inteiro.',
  'transformation.form.market.production_costs_estimation.production_estimated_unit.label': 'Unidade',
  'transformation.form.market.production_costs_estimation.production_estimated_unit.errors.max': 'Não pode ser superior a 128 caracteres.',

  'transformation.form.market.market_analysis.label': 'Resumo de análise de mercado',
  'transformation.form.market.market_analysis.hint': 'Por favor, dê um breve resumo de análise de mercado dos principais produtos ou serviços de sua transformação de processo.',

  'transformation.form.market.economical_value_unit_estimation.label': 'Estimativa do valor econômico por unidade',
  'transformation.form.market.economical_value_unit_estimation.hint': 'Adicione os campos para copiar os links associados à estimativa de custo do produto, estimativa do valor econômico por unidade e resumo de análise de mercado. TAMBÉN adicionar um campo ao lado da unidade com o ano de publicação.',
  'transformation.form.market.economical_value_unit_estimation.hint.example': 'Por exemplo, o preço de venda unitário é 850 USD / kg de CNC',
  'transformation.form.market.economical_value_unit_estimation.economical_estimated_value.label': 'Valor estimado',
  'transformation.form.market.economical_value_unit_estimation.economical_estimated_value.errors.base': 'O valor estimado deve ser um número.',
  'transformation.form.market.economical_value_unit_estimation.economical_estimated_currency.label': 'Moeda',
  'transformation.form.market.economical_value_unit_estimation.economical_estimated_currency.errors.base': 'A moeda deve ser um inteiro.',
  'transformation.form.market.economical_value_unit_estimation.economical_estimated_unit.label': 'Unidade',
  'transformation.form.market.economical_value_unit_estimation.economical_estimated_unit.errors.max': 'Não pode ser superior a 128 caracteres.',

  'transformation.form.market.structure_costs_analysis.label': 'Análise de custos de estrutura',
  'transformation.form.market.structure_costs_analysis.hint': 'Por favor, resumir a análise de custos de estrutura para os principais produtos ou serviços da transformação do seu processo.',

  'transformation.form.market.referent_region_businesses.label': 'Melhores empresas na indústria',
  'transformation.form.market.referent_region_businesses.hint': 'Liste algumas das melhores empresas que usam esse processo de transformação ou têm processos semelhantes. Por favor, separe com vírgulas ao adicionar mais de um.',
  'transformation.form.market.referent_region_businesses.errors.max': 'Não pode ser superior a 256 caracteres.',
  'transformation.form.market.referent_region_businesses.errors.required': 'O negócio de referência é necessário.',

  'transformation.form.market.donors_financing_institutions.label': 'Doadores potenciais e instituições de financiamento',
  'transformation.form.market.donors_financing_institutions.hint': 'Por favor, separe com vírgulas ao adicionar mais de um. Por exemplo, Banco Interamericano de Desenvolvimento, GIZ, Ministério da Producción-Gobierno do Peru.',
  'transformation.form.market.donors_financing_institutions.errors.max': 'Não pode ser superior a 256 caracteres.',
  'transformation.form.market.donors_financing_institutions.errors.required': 'É necessário o doador potencial e/ou instituição de financiamento.',

  /* TAB 6 - Other Considerations */
  'transformation.form.regulations.title': 'Outras considerações',
  'transformation.form.regulations.heading': 'Aqui você pode informar sobre outras considerações sobre o processo proposto para transformar a biomassa.',

  'transformation.form.regulations.indigenous_people_logistics.label': 'Papel das comunidades locais e indígenas na produção, coleta e/ou transformação da biomassa.',
  'transformation.form.regulations.indigenous_people_logistics.hint': 'Qual é o papel dos povos indígenas no processo de transformação, biomassa de origem ou benefício do produto ou dos serviços propostos?',
  'transformation.form.regulations.indigenous_people_logistics.errors.max': 'Não pode ser mais do que 1024 caracteres.',

  'transformation.form.regulations.permissions_access_resource.label': 'Permissões necessárias para o acesso a recursos biológicos e genéticos',
  'transformation.form.regulations.permissions_access_resource.hint': 'Existe uma lei ou regulamento para acessar recursos de biomassa? Se puder, especifique a legislação e em que país é aplicado. Adicione um resumo.',
  'transformation.form.regulations.permissions_access_resource.errors.max': 'Não pode ser mais do que 1024 caracteres.',

  'transformation.form.regulations.regulations.label': 'Regulamentos',
  'transformation.form.regulations.regulations.hint': 'Deve uma lei ou regulamento fundamental ser levado em conta para construir um modelo de negócio a partir do processo de transformação e para executar o negócio?',
  'transformation.form.regulations.regulations.errors.max': 'Não pode ser mais do que 1024 caracteres.',

  'transformation.form.regulations.certifications.label': 'Certificações',
  'transformation.form.regulations.certifications.hint': 'Deve uma certificação crítica ajudar a construir um modelo de negócio ou para executar o negócio? Por exemplo, o Programa de Biomassa Sustentável (SBP) é um sistema de certificação projetado para biomassa lenhosa.',
  'transformation.form.regulations.certifications.errors.max': 'Não pode ser mais do que 1024 caracteres.',

  'transformation.form.regulations.quality_risks_standards.label': 'Riscos de qualidade',
  'transformation.form.regulations.quality_risks_standards.hint': 'Como a matéria-prima orgânica pode causar riscos de qualidade? Adicione um resumo.',
  'transformation.form.regulations.quality_risks_standards.errors.max': 'Não pode ser mais do que 1024 caracteres.',

  'transformation.form.regulations.waste_management.label': 'Gestão de resíduos',
  'transformation.form.regulations.waste_management.hint': 'Como pode o desperdício da transformação do processo ser gerenciado para alcançar o desenvolvimento sustentável?',
  'transformation.form.regulations.waste_management.errors.max': 'Não pode ser mais do que 1024 caracteres.',

  'transformation.form.ui.error.header': 'Não foi possível salvar sua transformação.',
  'transformation.form.ui.next_btn': 'Próximo',
  'transformation.form.ui.previous_btn': 'Anterior',
  'transformation.form.ui.finish_btn': 'Terminar',
  'transformation.form.ui.add_btn': 'Adicionar',
  'transformation.form.ui.not_yet': 'Ainda não',
  'transformation.form.ui.yes': 'Sim',
  'transformation.form.ui.no': 'Não',
  'transformation.form.ui.dont.know': 'Não sei',
  'transformation.form.ui.save_and_exit': 'Salvar e sair',

  'transformation.confirm_modal.header': 'Já acabou com esta transformação?',
  'transformation.confirm_modal.description.1': 'Antes de sua transformação ser publicada, ele precisa ser verificado por um de nossos curadores especialistas. Ao clicar em confirmar, você enviará sua transformação para revisão e não será capaz de fazer quaisquer alterações enquanto isso acontece.',
  'transformation.confirm_modal.description.2': 'Um curador irá verificar sua transformação e pode solicitar alterações antes que a publicação seja finalizada.',
  'transformation.confirm_modal.description.3': 'Deseja continuar?',
  'transformation.confirm_modal.actions.close.label': 'Fechar',
  'transformation.confirm_modal.actions.confirm.label': 'Confirmar',
  'transformation.confirm_modal.error.header': 'Não foi possível solicitar curadoria para sua transformação',

  'transformation.view.evidences.age_warning': 'Esta publicação é de {age} anos atrás, pode conter informações desatualizadas.'
};

const TRANSFORMATION_VIEW = {
  'transformation_view.crowdsourcing.hints.bookmark': 'Favoritos',
  'transformation_view.crowdsourcing.hints.technically_viable': 'Tecnicamente viável',
  'transformation_view.crowdsourcing.hints.economically_viable': 'Economicamente viável',
  'transformation_view.crowdsourcing.hints.environmentally_viable': 'Ambientalmente viável',
  'transformation_view.crowdsourcing.hints.socially_viable': 'Socialmente viável',

  'transformation_view.request.error.header': 'Não foi possível buscar a transformação',
  'transformation_view.request.not_found': 'Não encontrado',

  'transformation_view.back_btn': 'VOLTAR',

  'transformation_view.sidebar_header.from': 'De: ',
  'transformation_view.sidebar_header.published_by': 'Publicado por: ',

  'transformation_view.save_btn': 'Salvar / Imprimir'
};

const SETTINGS = {
  'settings.user.form.success.header': 'A atualização do usuário foi bem sucedida!',

  'settings.user.form.header': 'Configurações de perfil',
  'settings.user.form.image_picker.header': 'Escolha uma imagem para sua imagem de perfil.',
  'settings.user.form.username': 'Nome de utilizador',
  'settings.user.form.given_name': 'Nome',
  'settings.user.form.family_name': 'Sobrenome',
  'settings.user.form.institution_name': 'Nome da instituição',
  'settings.user.form.institution_position': 'Posição na instituição',
  'settings.user.form.professional_affiliation': 'Afiliação profissional',
  'settings.user.form.location_country': 'Pais',
  'settings.user.form.location_province': 'Estado',
  'settings.user.form.location_city': 'Cidade',
  'settings.user.form.bio': 'Bio',
  'settings.user.form.email_notifications_enabled': 'Notificações de email habilitados',
  'settings.user.form.contact.divider': 'Contacto e redes sociais',
  'settings.user.form.socials.phone': 'Telefone',
  'settings.user.form.socials.website': 'Website',
  'settings.user.form.socials.facebook': 'Facebook',
  'settings.user.form.socials.twitter': 'Twitter',
  'settings.user.form.socials.instagram': 'Instagram',
  'settings.user.form.socials.linkedin': 'LinkedIn',
  'settings.user.form.yes': 'Sim',
  'settings.user.form.no': 'Não',

  'settings.user.form.ui.update': 'Atualizar',

  'settings.user.errors.username.length': 'O nome de usuário deve ser entre 3 e 20 caracteres',
  'settings.user.errors.username.disallowed': 'O nome de usuário não pode ser "me"',
  'settings.user.errors.username.pattern': 'O nome de usuário só pode conter letras, números e os caracteres: . _ -',
  'settings.user.errors.username.required': 'O nome de usuário não pode ser vazio',

  'settings.user.errors.given_name.length': 'O nome deve ser entre 1 e 45 caracteres',
  'settings.user.errors.given_name.required': 'O nome não pode ser vazio',

  'settings.user.errors.family_name.length': 'O sobrenome deve ser entre 1 e 45 caracteres',
  'settings.user.errors.family_name.required': 'O sobrenome não pode estar vazio',

  'settings.user.errors.institution_name.max': 'O nome da instituição não pode ser superior a 256 caracteres',
  'settings.user.errors.institution_name.required': 'O nome da instituição não pode ser vazio',

  'settings.user.errors.institution_position.max': 'A posição da instituição não pode ser superior a 256 caracteres',
  'settings.user.errors.institution_position.required': 'A posição da instituição não pode ser vazia',

  'settings.user.errors.professional_affiliation.max': 'A afiliação profissional não pode ser superior a 256 caracteres',
  'settings.user.errors.professional_affiliation.required': 'A afiliação profissional não pode estar vazia',

  'settings.user.errors.location_country.max': 'O país não pode ser superior a 256 caracteres',
  'settings.user.errors.location_country.required': 'O país não pode estar vazio',

  'settings.user.errors.location_province.max': 'O estado não pode ser superior a 256 caracteres',
  'settings.user.errors.location_province.required': 'O estado não pode estar vazio',

  'settings.user.errors.location_city.max': 'A cidade não pode ser superior a 256 caracteres',
  'settings.user.errors.location_city.required': 'A cidade não pode estar vazia',

  'settings.user.errors.phone.pattern': 'O telefone só pode conter um código de país +(1 a 3 dígitos) e número (máx. 12 dígitos)',

  'settings.user.errors.socials_website.max': 'O website não pode ser superior a 256 caracteres',
  'settings.user.errors.socials_website.uri': 'Insira um website válido (https://my-website.com)',

  'settings.user.errors.socials_facebook.max': 'O usuário do Facebook não pode ser superior a 256 caracteres',

  'settings.user.errors.socials_instagram.max': 'O usuário do Instagram não pode ser superior a 256 caracteres',

  'settings.user.errors.socials_twitter.max': 'O usuário do Twitter não pode ser superior a 256 caracteres',

  'settings.user.errors.socials_linkedin.max': 'O usuário do LinkedIn não pode ser superior a 256 caracteres',

  'settings.user.errors.bio.max': 'Bio não pode ser superior a 256 caracteres',

  'settings.errors.header': 'Não foi possível atualizar suas informações',
  'settings.user.menu.header': 'Configurações do usuário',
  'settings.user.menu.account': 'Conta',
  'settings.user.menu.privacy': 'Privacidade',
  'settings.user.menu.advanced': 'Avançado'
};

const BECOME_CURATOR = {
  'become_curator.form.description.label': 'Por que você quer ser curador?',
  'become_curator.form.actions.submit': 'Submeter-me',

  'become_curator.form.errors.description.max': 'Descrição não pode ser superior a 512 caracteres',
  'become_curator.form.errors.description.min': 'Descrição não pode ser menor que 100 caracteres',
  'become_curator.form.errors.description.required': 'Descrição não pode ser vazia',

  'become_curator.form.errors.header': 'Não foi possível processar sua aplicação',
  'become_curator.form.success.header': 'Seu aplicativo foi enviado com sucesso',

  'become_curator.link.back_to_applications': 'Voltar às aplicações',

  'become_curator.btn.create_new_application': 'Criar nova aplicação',
  'become_curator.past_applications.errors.header': 'Não foi possível recuperar suas aplicações anteriores',
  'become_curator.past_applications.empty.header': 'Você não tem aplicativos anteriores',

  'become_curator.past_applications.active.header': 'Aplicação ativa: ',
  'become_curator.past_applications.active.empty.header': 'Você não tem nenhuma aplicação ativa',

  'become_curator.past_applications.header': 'Aplicações anteriores: ',

  'become_curator.application.header': 'Razão da aplicação: ',
  'become_curator.application.date': 'Data: ',
  'become_curator.application.status': 'Status: ',
  'become_curator.application.status.accepted': 'Aceito',
  'become_curator.application.status.denied': 'Negado',
  'become_curator.application.status.active': 'Em revisão'
};

const ADMIN = {
  'admin.dashboard.curator_requests.link': 'Ir para pedidos de curador',

  'admin.dashboard.menu.header': 'Menu de Admininistrador',
  'admin.dashboard.menu.dashboard': 'Dashboard',
  'admin.dashboard.menu.curator_requests': 'Pedidos de curador',
  'admin.dashboard.menu.resource_management': 'Gestão de recursos',

  'admin.curator_requests.header': 'Pedidos de curador',
  'admin.curator_requests.error.header': 'Não foi possível obter a lista de aplicações curador',
  'admin.curator_requests.empty.header': 'Nenhuma aplicação atual',
  'admin.curator_requests.empty.description': 'Não há aplicativos curador no momento',
  'admin.curator_request.current_role': 'PAPEL ATUAL: ',
  'admin.curator_request.active.empty.header': 'Nenhuma aplicação ativa',
  'admin.curator_request.active.empty.description': 'Não há aplicativos ativos no momento',

  'admin.curator_request.process_application.confirm_modal.error.header': 'Não foi possível processar esta aplicação',
  'admin.curator_request.process_application.confirm_modal.header.approve': 'Tem certeza de que quer aprovar este aplicativo?',
  'admin.curator_request.process_application.confirm_modal.header.deny': 'Tem certeza de que quer negar este aplicativo?',
  'admin.curator_request.process_application.confirm_modal.content.approve.1': 'Se você aprovar este aplicativo, o usuário será promovido a curador e terá acesso a comentários e rever transformações inéditas',
  'admin.curator_request.process_application.confirm_modal.content.deny.1': 'Se você negar este aplicativo, o usuário permanecerá como colaborador e terá que enviar outro aplicativo para ser revisado novamente',

  'admin.curator_request.date': 'Data: ',
  'admin.curator_request.detail.header': 'Detalhe: ',
  'admin.curator_request.ui.approve': 'Aprovar',
  'admin.curator_request.ui.deny': 'Negar',

  'admin.resource_management.header': 'Gestão de recursos',
  'admin.resource_management.errors.header': 'Não foi possível buscar recursos',
  'admin.resource_management.info_message.title': 'De que se trata esta secção?',
  'admin.resource_management.info_message.description': 'Abaixo você encontrará todos os recursos selecionáveis correspondentes a formulários de documento de transformação. Eles são divididos em 8 categorias. Você pode adicionar ou editar recursos em todos os 3 idiomas suportados (inglês, espanhol e português). Tenha em mente que a edição de um determinado recurso também afetará todos os documentos de transformação publicados e inéditos que haviam selecionado esse recurso. Criar um novo recurso requer a entrada de cada um dos 3 idiomas.',
  'admin.resource_management.edit_modal.header': 'Editar {category} #{id}',
  'admin.resource_management.edit_modal.errors.header': 'Não foi possível editar recurso',
  'admin.resource_management.add_modal.header': 'Adicionar novo recurso {category}',
  'admin.resource_management.add_modal.errors.header': 'Não foi possível adicionar novos recursos',

  'admin.resource_management.table.id': 'ID',
  'admin.resource_management.table.resource_name': 'Nome do recurso',
  'admin.resource_management.table.short_name': 'Nome curto',
  'admin.resource_management.table.actions.add': 'Adicionar',

  'admin.resource_management.table.titles.climates': 'Climas',
  'admin.resource_management.table.titles.currencies': 'Moedas',
  'admin.resource_management.table.titles.source_classes': 'Classes de fonte',
  'admin.resource_management.table.titles.source_sub_classes': 'Sub-Classes de fonte',
  'admin.resource_management.table.titles.source_types': 'Tipos de fonte',
  'admin.resource_management.table.titles.trl_eu': 'Nível de prontidão da tecnologia',
  'admin.resource_management.table.titles.sustainable_development_goals': 'Objetivos de Desenvolvimento Sustentável',
  'admin.resource_management.table.titles.locations': 'Localizações',

  'admin.resource_management.table.titles.climate': 'Clima',
  'admin.resource_management.table.titles.currency': 'Moeda',
  'admin.resource_management.table.titles.currency_unit': 'Unidade de moeda',
  'admin.resource_management.table.titles.source_class': 'Clase de fonte',
  'admin.resource_management.table.titles.source_sub_class': 'Sub-Classe de fonte ',
  'admin.resource_management.table.titles.source_type': 'Tipo de fonte',
  'admin.resource_management.table.titles.sustainable_development_goal': 'Objectivo de Desenvolvimento Sustentável',
  'admin.resource_management.table.titles.location': 'Localização',
  'admin.resource_management.table.titles.location_short_name': 'Nome curto da localização',

  'admin.resource_management.modal.form.en.label': 'Inglês {title}',
  'admin.resource_management.modal.form.es.label': 'Espanhol {title}',
  'admin.resource_management.modal.form.pt.label': 'Português {title}'
};

const MY_CONTRIBUTIONS = {
  'my_contributions.error.get.header': 'Não foi possível obter a lista das suas contribuições.',
  'my_contributions.error.post.header': 'Não foi possível criar um novo documento de transformação.',

  'my_contributions.header': 'Minhas contribuições',

  'my_contributions.ongoing.title': 'Em andamento:',
  'my_contributions.ongoing.empty.header': 'Nenhuma transformação em andamento',
  'my_contributions.ongoing.empty.description': 'Não tem transformações em curso.',

  'my_contributions.changes_requested.title': 'Alterações solicitadas:',
  'my_contributions.changes_requested.empty.header': 'Sem alterações solicitadas',
  'my_contributions.changes_requested.empty.description': 'Nenhum curador solicitou mudanças em qualquer uma de suas transformações.',

  'my_contributions.awaiting_curation.title': 'Esperando pela curadoria:',
  'my_contributions.awaiting_curation.empty.header': 'Sem transformação esperando pela curadoria',
  'my_contributions.awaiting_curation.empty.description': 'Você não tem transformações que precisam ser curadas. Tente publicar uma transformação para receber feedback de um dos nossos curadores.',

  'my_contributions.in_curation.title': 'Em curadoria:',
  'my_contributions.in_curation.empty.header': 'Nenhuma transformação na cura',
  'my_contributions.in_curation.empty.description': 'Você não tem transformações que estão sendo curadas. Se você tem uma transformação na necessidade de curadoria, espere até que um dos curadores comece a trabalhar em sua transformação sugerida.',

  'my_contributions.add_btn': 'Adicionar novo'
};

const AWAITING_CURATIONS = {
  'awaiting_curations.errors.header': 'Não foi possível obter a lista de espera de transformações esperando pela cura.',

  'awaiting_curations.awaiting_curation.title': 'Transformações na necessidade de curadoria',
  'awaiting_curations.awaiting_curation.empty.header': 'Nenhuma transformação na necessidade de curadoria',
  'awaiting_curations.awaiting_curation.empty.description': 'Não há transformações que precisam ser curadas.',

  'awaiting_curations.awaiting_curation.post.error_modal.title': 'Não foi possível curar esta transformação.',
  'awaiting_curations.awaiting_curation.post.error_modal.buttons.close': 'Fechar',

  'awaiting_curations.in_curation.title': 'Suas transformações em curadoria',
  'awaiting_curations.in_curation.empty.header': 'Nenhuma transformação em curadoria',
  'awaiting_curations.in_curation.empty.description': 'Você não está curando nenhuma transformação atualmente. Você pode começar a curar uma clicando em qualquer uma das transformações na lista abaixo.'
};

const EDIT_CONTRIBUTION = {
  'edit_contribution.error.header': 'Não foi possível editar este documento de transformação.',
  'edit_contribution.error.status.description': 'As transformações só podem ser atualizadas quando estão em curso ou têm alterações solicitadas por um curador.'
};

const DELETE_CONTRIBUTION = {
  'delete_contribution.confirm_modal.header': 'Tem certeza de que deseja deletar este documento de transformação?',
  'delete_contribution.confirm_modal.description.1': 'Este processo não pode ser desfeito.',

  'delete_contribution.confirm_modal.actions.delete.label': 'Deletar',
  'delete_contribution.confirm_modal.actions.close.label': 'Cancelar',

  'delete_contribution.error.header': 'Não foi possível deletar este documento de transformação.'
};

const CURATE_CONTRIBUTION = {
  'curate_contribution.error.header': 'Não foi possível curar este documento de transformação.',

  'curate_contribution.form.error.header': 'Não foi possível curar esta transformação.',
  'curate_contribution.form.ui.save_and_exit': 'Salvar e sair',
  'curate_contribution.form.ui.delete': 'Deletar curadoria',
  'curate_contribution.form.ui.submit': 'Submeter curadoria',

  'curate_contribution.form.heading.message.header': 'Instruções do autor para esta seção',

  'curate_contribution.form.comment_basic_information.label': 'Algum comentário sobre as informações básicas?',
  'curate_contribution.form.comment_transformation_process.label': 'Algum comentário sobre o processo de transformação?',
  'curate_contribution.form.comment_scalability.label': 'Algum comentário sobre a escalabilidade?',
  'curate_contribution.form.comment_sustainability.label': 'Algum comentário sobre a sustentabilidade?',
  'curate_contribution.form.comment_market.label': 'Algum comentário sobre o mercado?',
  'curate_contribution.form.comment_regulations.label': 'Algum comentário sobre os regulamentos?',
  'curate_contribution.form.publish.label': 'Esta transformação está pronta para ser publicada?',

  'curate_contribution.form.errors.deny_comment_check': 'Pelo menos um comentário precisa ser feito se você sentir que essa transformação não está pronta para ser publicada.',

  'curate_contribution.modals.delete.header': 'Tem certeza de que deseja deletar esta curadoria em curso?',
  'curate_contribution.modals.delete.description.1': 'Isso só irá deletar sua curadoria contínua, ele não irá excluir a transformação.',
  'curate_contribution.modals.delete.description.2': 'Você sempre pode curar esta transformação novamente se nenhum outro curador lidar com isso antes de fazer.',
  'curate_contribution.modals.delete.actions.delete.label': 'Deletar',
  'curate_contribution.modals.delete.actions.close.label': 'Cancelar',
  'curate_contribution.modals.delete.error.header': 'Não foi possível deletar esta curadoria em curso.',

  'curate_contribution.modals.commit.header': 'Esta curadoria está pronta para ser submetida?',
  'curate_contribution.modals.commit.description.1': 'Se você decidir publicar essa transformação, o autor não verá nenhum dos seus comentários. Publicar uma transformação torna visível para qualquer pessoa na plataforma. Este processo pode levar até 30 segundos enquanto traduzimos o documento, por favor não feche esta janela até que este processo termine.',
  'curate_contribution.modals.commit.description.2': 'Se você decidir não publicar essa transformação, o autor verá seus comentários nas seções relevantes e terá que re-submeter seu documento para outra revisão. Você pode optar por rever novamente o documento ou deixar que outra pessoa lidar com isso.',
  'curate_contribution.modals.commit.actions.submit.label': 'Submeter',
  'curate_contribution.modals.commit.actions.close.label': 'Cancelar',
  'curate_contribution.modals.commit.error.header': 'Não foi possível submeter esta curadoria em curso.'
};

const DELETE_CURATION = {
  'delete_curation.confirm_modal.header': 'Tem certeza de que deseja deletar esta curadoria em curso?',
  'delete_curation.confirm_modal.description.1': 'Isso só irá deletar sua curadoria em curso, ele não irá deletar a transformação.',
  'delete_curation.confirm_modal.description.2': 'Você sempre pode curar esta transformação novamente se nenhum outro curador lidar com isso antes.',

  'delete_curation.confirm_modal.actions.delete.label': 'Deletar',
  'delete_curation.confirm_modal.actions.close.label': 'Cancelar',

  'delete_curation.error.header': 'Não foi possível deletar esta curadoria em curso.'
};

const FOOTER = {
  'footer.copyright': 'USFQ-BID',
  'footer.items.section.header': 'Pronto para se juntar à nossa comunidade Transformar?',
  'footer.items.section.text': 'Vamos contribuir para proteger nossa natureza e criar um futuro mais sustentável.',
  'footer.items.section.btn.signup': 'Cadastre-se',
  'footer.items.section.btn.login': 'Conecte-se',
  'footer.items.section.btn.publish': 'Publicar',
  'footer.items.bar.links.about': 'Sobre',
  'footer.items.bar.links.help': 'Ajuda',
  'footer.items.bar.links.contact': 'Contacto',
  'footer.items.bar.links.terms_and_conditions': 'Termos e condições'
};

const UI = {
  'ui.loading.loading': 'Carregando...',

  'ui.cancel': 'Cancelar',
  'ui.confirm': 'Confirmar',
  'ui.selected': 'Selecionado',
  'ui.progress': 'Progresso: ',
  'ui.ok': 'Ok',
  'ui.progress.page': '{currentPage} de {pages}'
};

const NOT_FOUND = {
  'not_found.page.subtitle': 'Oops, desculpe não podemos encontrar',
  'not_found.page.something_went_wrong': 'Aconteceu algo errado ou a página não existe.',
  'not_found.page.back': 'Voltar à página inicial',

  'not_found.user': 'Página não encontrada'
};

const COMING_SOON = {
  'coming_soon.header': 'Em breve',
  'coming_soon.subtitle': 'Em construção',
  'coming_soon.back.btn': 'Voltar para à página inicial'
};

const FORM = {
  'form.multi_month_picker.january': 'Janeiro',
  'form.multi_month_picker.february': 'Fevereiro',
  'form.multi_month_picker.march': 'Março',
  'form.multi_month_picker.april': 'Abril',
  'form.multi_month_picker.may': 'Maio',
  'form.multi_month_picker.june': 'Junho',
  'form.multi_month_picker.july': 'Julho',
  'form.multi_month_picker.august': 'Agosto',
  'form.multi_month_picker.september': 'Setembro',
  'form.multi_month_picker.october': 'Outubro',
  'form.multi_month_picker.november': 'Novembro',
  'form.multi_month_picker.december': 'Dezembro',

  'form.array_form_field.add': 'Adicionar',

  'form.boolean_form_field.default.yes': 'Sim',
  'form.boolean_form_field.default.no': 'Não',

  'form.yes_no_idk_form_field.default.yes': 'Sim',
  'form.yes_no_idk_form_field.default.no': 'Não',
  'form.yes_no_idk_form_field.default.idk': 'Não sei'
};

const SERVICES = {
  'services.api.errors.unknown.message': 'Um erro desconhecido ocorreu. Tente atualizar a página. Se esse erro continuar acontecendo, contate um administrador do sistema.',
  'services.api.errors.not_found.message': 'O recurso solicitado não foi encontrado.',
  'services.api.errors.validation.message': 'Os dados enviados não são válidos. Verifique se todas as informações que você adicionou no formulário estão corretas.',
  'services.api.errors.bad_request.message': 'O servidor não conseguiu responder à solicitação. Tente atualizar a página. Se esse erro continuar acontecendo, contate um administrador do sistema.',
  'services.api.errors.forbidden.message': 'Não pode ver isto.',
  'services.api.errors.unauthorized.message': 'Não pode ver isto. Você está conectado à sua conta?',
  'services.api.errors.invalidated.message': 'Parece que a sua sessão foi pontada. Tente atualizar a página.'
};

const USER_PROFILE = {
  'user_profile.error.header': 'Não foi possível obter as informações para este usuário.',
  'user.profile.side_profile.at': 'em',

  'user.profile.contributions.header': 'Oportunidades',
  'user.profile.contributions.header.subtitle': 'Confira as últimas oportunidades',
  'user.profile.contributions.empty.header': 'Sem oportunidades ainda disponíveis',
  'user.profile.contributions.empty.description': 'Este utilizador não publicou nenhuma oportunidade',

  'user.profile.curations.header': 'Curações',
  'user.profile.curations.header.subtitle': 'Veja as últimas curações',
  'user.profile.curations.empty.header': 'Nenhuma curadoria disponível ainda',
  'user.profile.curations.empty.description': 'Este usuário não tem curado nenhum documento'
};

const TERMS_AND_CONDITIONS = {
  'terms_and_conditions.title': 'Termos e Condições',

  'terms_and_conditions.content.1': 'O uso do site Transformar (o “Site Web”) é regido pelos termos e condições estabelecidos abaixo. Ao usar este Web Site, você concorda com tais termos e condições, pois eles podem ser modificados pelo Banco Interamericano de Desenvolvimento (“BID”) de tempos em tempos e postados neste Web Site. Determinados recursos ou funções do Site, ou vinculados pelo Site podem estar sujeitos a termos e condições adicionais ou diferentes (“Sites associados”). Os Sites Associados podem ou não estar sob o controle ou influência do BID. O BID não assume qualquer responsabilidade ou responsabilidade pelo conteúdo ou operação de Sites Associados que não sejam realmente operados pelo BID e enfatiza que os usuários estarão sujeitos aos termos e condições aplicáveis a tais Sites Associados, sejam ou não operados pelo BID.',
  'terms_and_conditions.content.2': 'O BID mantém este Site apenas para fins informativos. Os usuários podem baixar, copiar e reimprimir informações do Web Site para fins não comerciais, sujeitos a quaisquer termos e condições contidos nos materiais expressamente atribuídos a terceiros e desde que a Transformar seja citada como fonte do material originário. Os usuários não podem reimpressar ou distribuir material para o qual um terceiro é identificado como titular dos direitos autorais, a menos que a permissão para fazê-lo seja expressamente permitida para tal material de terceiros. Os usuários não podem colocar “quadros” em torno do material do site sem a autorização expressa por escrito do BID.',
  'terms_and_conditions.content.3': 'O conteúdo dos materiais expressamente atribuídos ou identificados como sendo produzidos por terceiros, é propriedade e responsabilidade de terceiros e não do BID. O uso destes documentos está sujeito aos termos e condições legais nele contidos, se houver.',
  'terms_and_conditions.content.4': 'Este site também contém links para sites e informações não sob o controle do BID. O BID fornece esses links e qualquer informação como conveniência para os usuários do seu site e não é responsável pelo seu conteúdo. A presença de um link não implica endosso ou aprovação de seu conteúdo pelo BID.',
  'terms_and_conditions.content.5': 'O BID e seus afiliados estabeleceram e/ou colaboraram com outras organizações no estabelecimento e manutenção de outros recursos baseados na web. Cada um desses outros recursos baseados na web está sujeito a termos e condições que podem ser além ou suplantar estes termos e condições.',
  'terms_and_conditions.content.6': 'Todas as informações no site são fornecidas “AS IS”, sem garantia expressa ou implícita de qualquer tipo, incluindo, mas não limitado a qualquer garantia de comercialização, adequação, não violação ou adequação para qualquer finalidade específica. O uso deste site está em risco exclusivo do usuário. Enquanto o BID tenta fornecer dados precisos, as informações disponíveis no Web Site podem usar recursos de dados de outras fontes, incluindo fontes públicas. Como tal, o BID não garante ou representa que qualquer informação esteja livre de erros, omissões e/ou imprecisões posicionais. O BID não garante a exatidão, integridade ou pontualidade de qualquer material no Web Site e em nenhuma circunstância, incluindo, mas não limitado a, negligência, o BID será responsável por quaisquer despesas ou danos diretos, indiretos, incidentais, especiais ou conseqüentes incorridos por causa de seu uso, incluindo despesas ou danos decorrentes de falhas de comunicação e / ou falhas de computador associadas ao uso do Web Site.',
  'terms_and_conditions.content.7': 'As informações relacionadas a uma determinada data são oferecidas apenas a partir de tal data e o BID não compromete nenhuma obrigação de atualizar qualquer material no Site. O BID pode, a seu critério, periodicamente adicionar, alterar, melhorar ou atualizar os materiais no Web Site sem aviso prévio, e reserva-se o direito de limitar ou descontinuar o Web Site ou negar qualquer acesso do usuário a ele sem aviso prévio.',
  'terms_and_conditions.content.8': 'Mapas e qualquer outra informação de natureza semelhante encontrada neste Web Site são apenas para referência, e pode não ter sido preparado para, ou ser adequado para legal, engenharia, pesquisa ou qualquer outra finalidade específica e / ou uso. Os limites, cores, denominações ou qualquer outra informação mostrada em qualquer mapa ou de outra forma não podem ser interpretados como uma opinião sobre o estado legal de qualquer território, ou qualquer endosso ou aceitação de tais limites.',
  'terms_and_conditions.content.9': 'Como condição de uso do Web Site, o usuário concorda em indenizar o BID de e contra todas as ações, reivindicações, perdas, danos, responsabilidades e despesas (incluindo honorários advocatícios razoáveis) decorrentes do uso desse usuário do Web Site, incluindo, sem limitação, quaisquer reivindicações alegando fatos que, se verdade, constituiriam uma violação pelo usuário desses termos e condições. Se o usuário estiver insatisfeito com qualquer material no Web Site ou com qualquer um dos termos e condições de uso do Web Site, o único e exclusivo remédio desse usuário é interromper usando o Web Site.',
  'terms_and_conditions.content.10': 'O Web Site é controlado, operado e administrado pelo BID de seus escritórios dentro dos Estados Unidos da América e todos os dados são armazenados nos Estados Unidos da América. O BID não faz nenhuma representação de que os materiais no Web Site sejam apropriados ou disponíveis para uso em outros locais fora dos Estados Unidos da América. O acesso aos materiais no site em qualquer local onde tal conteúdo possa ser ilegal é proibido. Se você acessar o site a partir de um local fora dos Estados Unidos da América, você é o único responsável pelo cumprimento das leis locais.',
  'terms_and_conditions.content.11': 'Qualquer informação submetida ao BID estará sujeita à nossa Política de Privacidade, que é incorporada nestes termos e condições, e está sujeita a alterações de tempos em tempos, conforme estabelecido na Política de Privacidade. O Web Site fornece um meio para relatar fraude suspeita e outra conduta imprópria. Esses relatórios podem optar por não divulgar suas identidades; no entanto, se o fizerem, suas identidades constituirão informações de identificação pessoal, sujeitas à nossa Política de Privacidade. Podemos entrar em contato com esses relatórios e identificá-los em conexão com as investigações da conduta que eles nos relatam.',
  'terms_and_conditions.content.12': 'As opiniões e achados dos autores ou compiladores aqui contidos não são necessariamente as do BID ou de seus países membros. Salvo indicação em contrário, o material no Web Site é propriedade do BID e está sujeito aos direitos autorais, marcas comerciais e outras leis de propriedade intelectual dos Estados Unidos da América e outras jurisdições. Todos os usos do Site estão sujeitos a essas leis.',
  'terms_and_conditions.content.13': 'Estes termos e condições serão governados, interpretados e aplicados de acordo com as leis do Distrito de Columbia, Estados Unidos da América, sem dar efeito ao conflito de disposições legais dessa jurisdição. Qualquer ação legal sobre o conteúdo do Site será submetida e finalmente resolvida por arbitragem realizada na língua inglesa em Washington, Distrito de Columbia, Estados Unidos da América, sob as regras de arbitragem comercial da American Arbitration Association, desde que o BID se reserva o direito de solicitar alívio temporário, preliminar e final injuntivo para impor qualquer uma das disposições destes termos e condições relacionadas com o uso de seus direitos autorais, marcas registradas, outros direitos de propriedade intelectual e dados. Todas as decisões de um único árbitro devem ser finais e vinculativas tanto ao BID quanto a você e executáveis em qualquer tribunal de jurisdição competente. O BID e você concordam de outra forma com a jurisdição exclusiva dos tribunais de Washington, Distrito de Columbia, Estados Unidos da América para a resolução de quaisquer disputas não sujeitas a arbitragem e para a execução de qualquer prêmio resultante de qualquer tal arbitragem.',
  'terms_and_conditions.content.14': 'Nenhuma renúncia pelo BID de qualquer disposição destes termos e condições deve ser vinculativa, exceto conforme estabelecido por escrito assinado por seu representante devidamente autorizado.',
  'terms_and_conditions.content.15': 'Nada no Web Site deve constituir ou ser considerado uma limitação ou uma renúncia dos privilégios e imunidades do BID, que são especificamente reservados.'
};

const strings = {
  ...PAGE_TITLES,
  ...NAVBAR,
  ...HOME,
  ...ABOUT,
  ...SIGNUP,
  ...LOGIN,
  ...AUTH,
  ...RESEND_VERIFICATION,
  ...FORGOT_PASSWORD,
  ...EXPLORE,
  ...TRANSFORMATION,
  ...TRANSFORMATION_VIEW,
  ...SETTINGS,
  ...BECOME_CURATOR,
  ...ADMIN,
  ...MY_CONTRIBUTIONS,
  ...AWAITING_CURATIONS,
  ...EDIT_CONTRIBUTION,
  ...DELETE_CONTRIBUTION,
  ...CURATE_CONTRIBUTION,
  ...DELETE_CURATION,
  ...FOOTER,
  ...UI,
  ...NOT_FOUND,
  ...COMING_SOON,
  ...FORM,
  ...SERVICES,
  ...USER_PROFILE,
  ...TERMS_AND_CONDITIONS
};

export default strings;
