// **.errors.internal -> Refers to errors caused by a misconfiguration in Cognito/Lambda.
// **.errors.external -> Refers to errors caused by an internal server error in Cognito.

export class AuthError extends Error {
  constructor(amplifyError) {
    super(amplifyError.message);
    this.name = `${this.constructor.name}_${amplifyError.name}`;
    this.originalError = amplifyError;

    this._friendlyMessageKey = '';
    this._hidden = false;
  }

  parseOriginalError(messageKeys) {
    const message = messageKeys[this.originalError.name];

    if (message === null) {
      this._hidden = true;
    }

    this._friendlyMessageKey = message || messageKeys.default;
  }

  getFriendlyMessage(t) {
    return t(this._friendlyMessageKey);
  }

  isHidden() {
    return this._hidden;
  }
}

// For more info, check: https://docs.aws.amazon.com/cognito-user-identity-pools/latest/APIReference/API_InitiateAuth.html#API_InitiateAuth_Errors
const SIGN_IN_ERROR_NAME_FRIENDLY_KEYS = {
  InternalErrorException: 'auth.login.errors.external',
  InvalidLambdaResponseException: 'auth.login.errors.internal',
  InvalidParameterException: 'auth.login.errors.internal',
  InvalidSmsRoleAccessPolicyException: 'auth.login.errors.internal',
  InvalidSmsRoleTrustRelationshipException: 'auth.login.errors.internal',
  InvalidUserPoolConfigurationException: 'auth.login.errors.internal',
  NotAuthorizedException: 'auth.login.errors.wrong_credentials',
  PasswordResetRequiredException: 'auth.login.errors.password_reset_required', // Might need a description to guide the user.
  ResourceNotFoundException: 'auth.login.errors.internal',
  TooManyRequestsException: 'auth.login.errors.rate_limit',
  UnexpectedLambdaException: 'auth.login.errors.internal',
  UserLambdaValidationException: 'auth.generic.errors.validation', // Might need a description.
  UserNotConfirmedException: 'auth.login.errors.confirmation_required',
  UserNotFoundException: 'auth.login.errors.wrong_credentials',
  default: 'auth.login.errors.unknown'
};

export class SignInError extends AuthError {
  constructor(amplifyError) {
    super(amplifyError);
    this.parseOriginalError(SIGN_IN_ERROR_NAME_FRIENDLY_KEYS);
  }
}

// For more info, check: https://docs.aws.amazon.com/cognito-user-identity-pools/latest/APIReference/API_SignUp.html#API_SignUp_Errors
const SIGN_UP_ERROR_NAME_FRIENDLY_KEYS = {
  CodeDeliveryFailureException: 'auth.signup.errors.internal',
  InternalErrorException: 'auth.signup.errors.external',
  InvalidEmailRoleAccessPolicyException: 'auth.signup.errors.internal',
  InvalidLambdaResponseException: 'auth.signup.errors.internal',
  InvalidParameterException: 'auth.signup.errors.internal',
  InvalidPasswordException: 'auth.signup.errors.invalid_password',
  InvalidSmsRoleAccessPolicyException: 'auth.signup.errors.internal',
  InvalidSmsRoleTrustRelationshipException: 'auth.signup.errors.internal',
  NotAuthorizedException: 'auth.generic.errors.unauthorized', // Cannot be due to wrong credentials because the user does not exist yet.
  ResourceNotFoundException: 'auth.signup.errors.internal',
  TooManyRequestsException: 'auth.signup.errors.rate_limit',
  UnexpectedLambdaException: 'auth.signup.errors.internal',
  UserLambdaValidationException: 'auth.signup.errors.username_exists', // The preSignUp trigger in the backend only checks for username uniqueness.
  UsernameExistsException: 'auth.signup.errors.email_exists', // We use email as username in Cognito.
  default: 'auth.signup.errors.unknown'
};

export class SignUpError extends AuthError {
  constructor(amplifyError) {
    super(amplifyError);
    this.parseOriginalError(SIGN_UP_ERROR_NAME_FRIENDLY_KEYS);
  }
}

// For more info, check: https://docs.aws.amazon.com/cognito-user-identity-pools/latest/APIReference/API_ConfirmSignUp.html#API_ConfirmSignUp_Errors
const CONFIRM_SIGN_UP_ERROR_NAME_FRIENDLY_KEYS = {
  AliasExistsException: 'auth.confirm_signup.errors.already_exists',
  CodeMismatchException: 'auth.confirm_signup.errors.wrong_code',
  ExpiredCodeException: 'auth.confirm_signup.errors.code_expired', // Might need to tell the user to regenerate a new one?
  ForbiddenException: 'auth.confirm_signup.errors.internal',
  InternalErrorException: 'auth.confirm_signup.errors.external',
  InvalidLambdaResponseException: 'auth.confirm_signup.errors.internal',
  InvalidParameterException: 'auth.confirm_signup.errors.internal',
  LimitExceededException: 'auth.confirm_signup.errors.rate_limit',
  NotAuthorizedException: 'auth.generic.errors.unauthorized', // Cannot be due to wrong credentials because the user should have been already signed up.
  ResourceNotFoundException: 'auth.confirm_signup.errors.internal',
  TooManyFailedAttemptsException: 'auth.confirm_signup.errors.rate_limit',
  TooManyRequestsException: 'auth.confirm_signup.errors.rate_limit',
  UnexpectedLambdaException: 'auth.confirm_signup.errors.internal',
  UserLambdaValidationException: 'auth.generic.errors.validation',
  UserNotFoundException: 'auth.confirm_signup.errors.email_not_found', // Not sure about where in the lifecycle should this function be called. In this case, I don't know if this message is needed.
  default: 'auth.confirm_signup.errors.unknown'
};

export class ConfirmSignUpError extends AuthError {
  constructor(amplifyError) {
    super(amplifyError);
    this.parseOriginalError(CONFIRM_SIGN_UP_ERROR_NAME_FRIENDLY_KEYS);
  }
}

// For more info, check: https://docs.aws.amazon.com/cognito-user-identity-pools/latest/APIReference/API_ResendConfirmationCode.html#API_ResendConfirmationCode_Errors
const RESEND_VERIFICATION_CODE_ERROR_NAME_FRIENDLY_KEYS = {
  CodeDeliveryFailureException: 'auth.resend_verification_code.errors.delivery_failure',
  ForbiddenException: 'auth.resend_verification_code.errors.internal',
  InternalErrorException: 'auth.resend_verification_code.errors.external',
  InvalidEmailRoleAccessPolicyException: 'auth.resend_verification_code.errors.internal',
  InvalidLambdaResponseException: 'auth.resend_verification_code.errors.internal',
  InvalidParameterException: 'auth.resend_verification_code.errors.internal',
  InvalidSmsRoleAccessPolicyException: 'auth.resend_verification_code.errors.internal',
  InvalidSmsRoleTrustRelationshipException: 'auth.resend_verification_code.errors.internal',
  LimitExceededException: 'auth.resend_verification_code.errors.rate_limit',
  NotAuthorizedException: 'auth.generic.errors.unauthorized', // Cannot be due to wrong credentials because no credentials are given to this function.
  ResourceNotFoundException: 'auth.resend_verification_code.errors.internal',
  TooManyRequestsException: 'auth.resend_verification_code.errors.rate_limit',
  UnexpectedLambdaException: 'auth.resend_verification_code.errors.internal',
  UserLambdaValidationException: 'auth.generic.errors.validation',
  UserNotFoundException: null, // Leave this null because we don't want to tell the user that an email is not found when trying to resend the code.
  default: 'auth.resend_verification_code.errors.unknown'
};

export class ResendVerificationCodeError extends AuthError {
  constructor(amplifyError) {
    super(amplifyError);
    this.parseOriginalError(RESEND_VERIFICATION_CODE_ERROR_NAME_FRIENDLY_KEYS);
  }
}

// For more info, check: https://docs.aws.amazon.com/cognito-user-identity-pools/latest/APIReference/API_GlobalSignOut.html#API_GlobalSignOut_Errors
const GLOBAL_SIGN_OUT_ERROR_NAME_FRIENDLY_KEYS = {
  ForbiddenException: 'auth.global_sign_out.errors.internal',
  InternalErrorException: 'auth.global_sign_out.errors.external',
  InvalidParameterException: 'auth.global_sign_out.errors.internal',
  NotAuthorizedException: 'auth.generic.errors.unauthorized', // Cannot be due to wrong credentials because user is already logged in prior.
  PasswordResetRequiredException: 'auth.global_sign_out.errors.password_reset_required',
  ResourceNotFoundException: 'auth.global_sign_out.errors.internal',
  TooManyRequestsException: 'auth.global_sign_out.errors.rate_limit',
  UserNotConfirmedException: 'auth.global_sign_out.errors.internal', // Cannot be due to user not confirmed because user is already logged in prior.
  default: 'auth.global_sign_out.errors.unknown'
};

export class GlobalSignOutError extends AuthError {
  constructor(amplifyError) {
    super(amplifyError);
    this.parseOriginalError(GLOBAL_SIGN_OUT_ERROR_NAME_FRIENDLY_KEYS);
  }
}

// For more info, check: https://docs.aws.amazon.com/cognito-user-identity-pools/latest/APIReference/API_ChangePassword.html#API_ChangePassword_Errors
const CHANGE_PASSWORD_ERROR_NAME_FRIENDLY_KEYS = {
  ForbiddenException: 'auth.change_password.errors.internal',
  InternalErrorException: 'auth.change_password.errors.external',
  InvalidParameterException: 'auth.change_password.errors.internal',
  InvalidPasswordException: 'auth.change_password.errors.invalid_password',
  LimitExceededException: 'auth.change_password.errors.rate_limit',
  NotAuthorizedException: 'auth.generic.errors.unauthorized', // Cannot be due to wrong credentials because user is already logged in prior.
  PasswordResetRequiredException: 'auth.change_password.errors.password_reset_required', // Need to go to forgot password.
  ResourceNotFoundException: 'auth.change_password.errors.internal',
  TooManyRequestsException: 'auth.change_password.errors.rate_limit',
  UserNotConfirmedException: 'auth.change_password.errors.internal', // Cannot be due to user not confirmed because user is already logged in prior.
  UserNotFoundException: 'auth.change_password.errors.internal', // Cannot be due to email not found because user is already logged in prior.
  default: 'auth.change_password.errors.unknown'
};

export class ChangePasswordError extends AuthError {
  constructor(amplifyError) {
    super(amplifyError);
    this.parseOriginalError(CHANGE_PASSWORD_ERROR_NAME_FRIENDLY_KEYS);
  }
}

// For more info, check: https://docs.aws.amazon.com/cognito-user-identity-pools/latest/APIReference/API_ForgotPassword.html#API_ForgotPassword_Errors
const FORGOT_PASSWORD_ERROR_NAME_FRIENDLY_KEYS = {
  CodeDeliveryFailureException: 'auth.change_password.errors.delivery_failure',
  ForbiddenException: 'auth.change_password.errors.internal',
  InternalErrorException: 'auth.change_password.errors.external',
  InvalidEmailRoleAccessPolicyException: 'auth.change_password.errors.internal',
  InvalidLambdaResponseException: 'auth.change_password.errors.internal',
  InvalidParameterException: 'auth.change_password.errors.internal',
  InvalidSmsRoleAccessPolicyException: 'auth.change_password.errors.internal',
  InvalidSmsRoleTrustRelationshipException: 'auth.change_password.errors.internal',
  LimitExceededException: 'auth.change_password.errors.rate_limit',
  NotAuthorizedException: 'auth.generic.errors.unauthorized', // Cannot be due to wrong credentials because user is requesting a password reset.
  ResourceNotFoundException: 'auth.change_password.errors.internal',
  TooManyRequestsException: 'auth.change_password.errors.rate_limit',
  UnexpectedLambdaException: 'auth.change_password.errors.internal',
  UserLambdaValidationException: 'auth.generic.errors.validation',
  UserNotFoundException: null, // Leave this null because we don't want to tell the user that an email is not found when trying to reset the password.
  default: 'auth.change_password.errors.unknown'
};

export class ForgotPasswordError extends AuthError {
  constructor(amplifyError) {
    super(amplifyError);
    this.parseOriginalError(FORGOT_PASSWORD_ERROR_NAME_FRIENDLY_KEYS);
  }
}

// For more info, check: https://docs.aws.amazon.com/cognito-user-identity-pools/latest/APIReference/API_ConfirmForgotPassword.html#API_ConfirmForgotPassword_Errors
const FORGOT_PASSWORD_SUBMIT_ERROR_NAME_FRIENDLY_KEYS = {
  CodeMismatchException: 'auth.forgot_password_submit.errors.wrong_code',
  ExpiredCodeException: 'auth.forgot_password_submit.errors.code_expired',
  ForbiddenException: 'auth.forgot_password_submit.errors.internal',
  InternalErrorException: 'auth.forgot_password_submit.errors.external',
  InvalidLambdaResponseException: 'auth.forgot_password_submit.errors.internal',
  InvalidParameterException: 'auth.forgot_password_submit.errors.internal',
  InvalidPasswordException: 'auth.forgot_password_submit.errors.invalid_password',
  LimitExceededException: 'auth.forgot_password_submit.errors.rate_limit',
  NotAuthorizedException: 'auth.generic.errors.unauthorized', // Cannot be due to wrong credentials because user is requesting a password reset.
  ResourceNotFoundException: 'auth.forgot_password_submit.errors.internal',
  TooManyFailedAttemptsException: 'auth.forgot_password_submit.errors.rate_limit',
  TooManyRequestsException: 'auth.forgot_password_submit.errors.rate_limit',
  UnexpectedLambdaException: 'auth.forgot_password_submit.errors.internal',
  UserLambdaValidationException: 'auth.generic.errors.validation',
  UserNotConfirmedException: 'auth.forgot_password_submit.errors.user_not_confirmed',
  UserNotFoundException: 'auth.forgot_password_submit.errors.wrong_code', // We mislead the user into thinking the code is wrong because the email does not exist which inherently means code exists to begin with.
  default: 'auth.forgot_password_submit.errors.unknown'
};

export class ForgotPasswordSubmitError extends AuthError {
  constructor(amplifyError) {
    super(amplifyError);
    this.parseOriginalError(FORGOT_PASSWORD_SUBMIT_ERROR_NAME_FRIENDLY_KEYS);
  }
}
