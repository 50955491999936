import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Message, Button } from 'semantic-ui-react';
import useLocale from '../../../hooks/i18n';

const ConfirmationModal = ({
  open,
  error,
  loading,
  onConfirm,
  onClose,
  header,
  contentArray,
  errorHeader,
  closeLabel,
  confirmLabel,
  confirmColor,
  closeColor
}) => {
  const { t } = useLocale();

  const handleIconClose = () => {
    if (!loading) {
      onClose();
    }
  };

  return (
    <Modal open={open} closeIcon onClose={handleIconClose}>
      <Modal.Header>{header}</Modal.Header>
      <Modal.Content>
        {contentArray &&
          contentArray.map((message, i) => <p key={i}>{message}</p>)}

        <Message
          hidden={!error}
          error
          header={errorHeader ?? ''}
          content={error}
        />
      </Modal.Content>
      <Modal.Actions>
        <Button color={closeColor ?? 'red'} onClick={onClose} loading={loading}>
          {closeLabel ?? t('ui.cancel')}
        </Button>
        <Button
          color={confirmColor ?? 'green'}
          onClick={onConfirm}
          loading={loading}
        >
          {confirmLabel ?? t('ui.confirm')}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

ConfirmationModal.propTypes = {
  open: PropTypes.bool,
  error: PropTypes.string,
  loading: PropTypes.bool,
  onConfirm: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  header: PropTypes.string,
  contentArray: PropTypes.arrayOf(PropTypes.string),
  errorHeader: PropTypes.string,
  closeLabel: PropTypes.string,
  confirmLabel: PropTypes.string,
  confirmColor: PropTypes.string,
  closeColor: PropTypes.string
};

ConfirmationModal.defaultProps = {
  open: false,
  error: null,
  loading: false,
  header: null,
  contentArray: null,
  errorHeader: null,
  closeLabel: null,
  confirmLabel: null,
  confirmColor: null,
  closeColor: null
};

export default ConfirmationModal;
