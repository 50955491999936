import React from 'react';
import PropTypes from 'prop-types';

const CardContainer = ({ children, className }) => {
  return <div className={`card-container ${className}`}>{children}</div>;
};

CardContainer.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string
};

CardContainer.defaultProps = {
  className: ''
};

export default CardContainer;
