import React from 'react';
import EditResourceModalLogic from './EditResourceModalLogic';
import PropTypes from 'prop-types';

const EditResourceModal = ({
  currentFormID,
  open,
  onClose,
  header,
  onConfirm,
  selectedResource,
  resourceId
}) => {
  return (
    <EditResourceModalLogic
      currentFormID={currentFormID}
      open={open}
      onConfirm={onConfirm}
      onClose={onClose}
      header={header}
      preloadedValues={selectedResource}
      resourceId={resourceId}
    />
  );
};

EditResourceModal.propTypes = {
  currentFormID: PropTypes.number.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  header: PropTypes.string.isRequired,
  onConfirm: PropTypes.func.isRequired,
  selectedResource: PropTypes.shape({}).isRequired,
  resourceId: PropTypes.number.isRequired
};

export default EditResourceModal;
