import React from 'react';
import PropTypes from 'prop-types';
import { Form, Icon, Popup } from 'semantic-ui-react';
import AutoResizeTextArea from '../autoResizeTextArea';

const PopupFormFieldArea = ({ id, name, label, hint, hintExample, ...props }) => {
  return (
    <Form.Field>
      <div className="field hint-container">
        <label htmlFor={id}>{label}</label>
        <Popup trigger={<Icon name="help circle" />} position="right center">
          <p>{hint}</p>
          {hintExample && <p>{hintExample}</p>}
        </Popup>
      </div>
      <AutoResizeTextArea
        id={id}
        name={name}
        {...props}
      />
    </Form.Field>
  );
};

PopupFormFieldArea.propTypes = {
  label: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  hint: PropTypes.string.isRequired,
  hintExample: PropTypes.string
};

PopupFormFieldArea.defaultProps = {
  hintExample: null
};

export default PopupFormFieldArea;
