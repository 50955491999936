/* eslint-disable react/jsx-handler-names */
import React from 'react';
import { Container } from 'semantic-ui-react';
import { useQuery } from '@tanstack/react-query';
import Loading from '../../components/common/loading';
import RequestErrorMessage from '../../components/common/requestErrorMessage';
import { PaginatedContainer } from '../../components/common/pagination';
import TransformationItemGroup from '../../components/transformation/transformationItemGroup';
import useAPI from '../../hooks/api';
import useLocale from '../../hooks/i18n';
import useTitle from '../../hooks/title';
import usePageQuery from '../../hooks/pageQuery';

const AwaitingCurationsPage = () => {
  const { t } = useLocale();
  const { client, getFullResponseErrorMessage } = useAPI();
  const awaitingCurationPage = usePageQuery('awaitingPage');
  const ongoingCurationPage = usePageQuery('ongoingPage');
  const awaitingCurationRequest = client.transformation.getAllUnpublishedTransformationsAwaitingCuration(awaitingCurationPage.currentPage);
  const ongoingCurationRequest = client.curation.listOngoingCurations(ongoingCurationPage.currentPage);
  const awaitingCurationQuery = useQuery(awaitingCurationRequest.key, awaitingCurationRequest.fn);
  const ongoingCurationQuery = useQuery(ongoingCurationRequest.key, ongoingCurationRequest.fn);

  const isLoading = awaitingCurationQuery.isLoading || ongoingCurationQuery.isLoading;
  const error = awaitingCurationQuery.error ?? ongoingCurationQuery.error;
  const paginatedAwaitingCurationTransformations = awaitingCurationQuery.data;
  const paginatedOngoingCurationTransformations = ongoingCurationQuery.data;

  useTitle(paginatedAwaitingCurationTransformations ?
    t('page_titles.awaiting_curation', { count: paginatedAwaitingCurationTransformations?.result.length ?? 0 }) :
    t('page_titles.loading')
  );

  if (isLoading) {
    return <Loading fullscreen />;
  }

  if (error) {
    return (
      <RequestErrorMessage
        header={t('awaiting_curations.errors.header')}
        error={getFullResponseErrorMessage(error)}
      />
    );
  }

  const { result: awaitingCurationTransformations, pagination: awaitingCurationPagination } = paginatedAwaitingCurationTransformations;
  const { result: ongoingCurationTransformations, pagination: ongoingCurationPagination } = paginatedOngoingCurationTransformations;

  return (
    <Container className="awaiting-curations-page">
      <PaginatedContainer
        className="curations-container"
        pagination={ongoingCurationPagination}
        onChange={ongoingCurationPage.updatePage}
        pageQueryParamName={ongoingCurationPage.name}
      >
        <TransformationItemGroup
          title={t('awaiting_curations.in_curation.title')}
          emptyHeader={t('awaiting_curations.in_curation.empty.header')}
          emptyDescription={t('awaiting_curations.in_curation.empty.description')}
          aggregatedTransformations={ongoingCurationTransformations}
          type="inCuration"
        />
      </PaginatedContainer>

      <PaginatedContainer
        className="curations-container"
        pagination={awaitingCurationPagination}
        onChange={awaitingCurationPage.updatePage}
        pageQueryParamName={awaitingCurationPage.name}
      >
        <TransformationItemGroup
          title={t('awaiting_curations.awaiting_curation.title')}
          emptyHeader={t('awaiting_curations.awaiting_curation.empty.header')}
          emptyDescription={t('awaiting_curations.awaiting_curation.empty.description')}
          aggregatedTransformations={awaitingCurationTransformations}
          type="awaitingCuration"
        />
      </PaginatedContainer>
    </Container>
  );
};

export default AwaitingCurationsPage;
