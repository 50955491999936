import React, { Fragment, useState } from 'react';
import CuratorRequestView from './CuratorRequestView';
import useAPI from '../../hooks/api';
import { useMutation } from '@tanstack/react-query';
import useLocale from '../../hooks/i18n';
import ConfirmationModal from '../../components/forms/transformationForm/ConfirmationModal';
import PropTypes from 'prop-types';

const CuratorRequestLogic = ({
  id,
  role,
  description,
  picture,
  username,
  date
}) => {
  const { t } = useLocale();
  const { client, getFullResponseErrorMessage, queryClient } = useAPI();
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [accept, setAccept] = useState(null);
  const [processApplicationError, setProcessApplicationError] = useState(null);

  const processApplication = useMutation(
    client.application.processCuratorApplicationById.bind(client.application)
  );

  const getModal = (accept) => {
    setAccept(accept);
    setOpenConfirmModal(true);
  };

  const handleClose = () => {
    setOpenConfirmModal(false);
    setProcessApplicationError(null);
    setAccept(null);
  };

  const handleConfirm = async () => {
    if (accept === null) {
      return;
    }

    try {
      await processApplication.mutateAsync({ id, accept });
      await queryClient.invalidateQueries({
        predicate: (query) => {
          return query.queryKey[0] === 'curator-applications';
        }
      });
      await queryClient.invalidateQueries(client.application.getCuratorApplicationById(id).key);
      setOpenConfirmModal(false);
    } catch (error) {
      setProcessApplicationError(getFullResponseErrorMessage(error));
    }
  };

  return (
    <Fragment>
      <CuratorRequestView
        key={id}
        role={role}
        description={description}
        picture={picture}
        date={date}
        username={username}
        onAccept={() => {
          getModal(true);
        }}
        onDeny={() => {
          getModal(false);
        }}
      />

      <ConfirmationModal
        open={openConfirmModal}
        onClose={handleClose}
        onConfirm={handleConfirm}
        loading={processApplication.isLoading}
        error={processApplicationError}
        errorHeader={t('admin.curator_request.process_application.confirm_modal.error.header')}
        header={`${accept ? t('admin.curator_request.process_application.confirm_modal.header.approve') : t('admin.curator_request.process_application.confirm_modal.header.deny')}`}
        contentArray={[`${accept ? t('admin.curator_request.process_application.confirm_modal.content.approve.1') : t('admin.curator_request.process_application.confirm_modal.content.deny.1')}`]}
      />
    </Fragment>
  );
};

CuratorRequestLogic.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  role: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  picture: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  username: PropTypes.string.isRequired
};

export default CuratorRequestLogic;
