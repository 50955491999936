import React from 'react';
import AddResourceModalLogic from './AddResourceModalLogic';
import PropTypes from 'prop-types';

const AddResourceModal = ({
  currentFormID,
  open,
  onClose,
  header,
  onConfirm
}) => {
  return (
    <AddResourceModalLogic
      currentFormID={currentFormID}
      open={open}
      onConfirm={onConfirm}
      onClose={onClose}
      header={header}
    />
  );
};

AddResourceModal.propTypes = {
  currentFormID: PropTypes.number.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  header: PropTypes.string.isRequired,
  onConfirm: PropTypes.func.isRequired
};

export default AddResourceModal;
