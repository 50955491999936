import React from 'react';
import PropTypes from 'prop-types';
import { Container, Form, Icon, Popup, Select } from 'semantic-ui-react';
import BubbleFormField from '../../../common/form/bubbleFormField';
import useLocale from '../../../../hooks/i18n';
import useFormHelper from '../../../../hooks/formHelper';
import PopupFormFieldArea from '../../../common/form/popupFormFieldArea';

const FORM_NAMES = {
  production_estimated_value: 'market.production_estimated_value',
  production_estimated_currency: 'market.production_estimated_currency',
  production_estimated_unit: 'market.production_estimated_unit',
  market_analysis: 'market.market_analysis',
  economical_estimated_value: 'market.economical_estimated_value',
  economical_estimated_currency: 'market.economical_estimated_currency',
  economical_estimated_unit: 'market.economical_estimated_unit',
  structure_costs_analysis: 'market.structure_costs_analysis',
  referent_region_businesses: 'market.referent_region_businesses',
  donors_financing_institutions: 'market.donors_financing_institutions'
};

const MarketFormView = ({ renderAs, editable, form, currencies }) => {
  const { t } = useLocale();
  const { resolveError, handleChangeWithValidation } = useFormHelper(form);
  const { getValues } = form;
  const ParentComponent = renderAs;

  const currencyOptions = currencies.map(({ id, name, unit }) => {
    return {
      key: id,
      value: id,
      text: `${name} (${unit})`
    };
  });

  return (
    <ParentComponent className="transformation-card market-form additional-info-form">
      <Container fluid={renderAs !== Form}>
        <div className="field">
          <label htmlFor="production_costs_estimation">
            {t('transformation.form.market.production_costs_estimation.label')}
          </label>
          <Popup trigger={<Icon name="help circle" />} position="right center">
            <p>
              {t('transformation.form.market.production_costs_estimation.hint')}
            </p>
            <p>{t('transformation.form.market.production_costs_estimation.hint.example')}</p>
          </Popup>
          <Form.Group inline id="production_costs_estimation" widths="equal">
            <Form.Input
              fluid
              name={FORM_NAMES.production_estimated_value}
              id={FORM_NAMES.production_estimated_value}
              type="number"
              placeholder="100.00"
              label={t('transformation.form.market.production_costs_estimation.production_estimated_value.label')}
              onChange={handleChangeWithValidation}
              error={resolveError(FORM_NAMES.production_estimated_value)}
              defaultValue={getValues(FORM_NAMES.production_estimated_value)}
              disabled={!editable}
            />
            <Form.Field>
              <label htmlFor={FORM_NAMES.production_estimated_currency}>
                {t('transformation.form.market.production_costs_estimation.production_estimated_currency.label')}
              </label>
              <Select
                fluid
                name={FORM_NAMES.production_estimated_currency}
                id={FORM_NAMES.production_estimated_currency}
                options={currencyOptions}
                placeholder="$"
                onChange={handleChangeWithValidation}
                error={resolveError(FORM_NAMES.production_estimated_currency)}
                defaultValue={getValues(FORM_NAMES.production_estimated_currency)}
                disabled={!editable}
              />
            </Form.Field>
            <Form.Input
              fluid
              name={FORM_NAMES.production_estimated_unit}
              id={FORM_NAMES.production_estimated_unit}
              label={t('transformation.form.market.production_costs_estimation.production_estimated_unit.label')}
              onChange={handleChangeWithValidation}
              placeholder="kg"
              error={resolveError(FORM_NAMES.production_estimated_unit)}
              defaultValue={getValues(FORM_NAMES.production_estimated_unit)}
              disabled={!editable}
            />
          </Form.Group>
        </div>

        <div className="field">
          <label htmlFor="economical_value_unit_estimation">
            {t('transformation.form.market.economical_value_unit_estimation.label')}
          </label>
          <Popup trigger={<Icon name="help circle" />} position="right center">
            <p>
              {t('transformation.form.market.economical_value_unit_estimation.hint')}
            </p>
            <p>{t('transformation.form.market.economical_value_unit_estimation.hint.example')}</p>
          </Popup>
          <Form.Group inline id="economical_value_unit_estimation" widths="equal">
            <Form.Input
              fluid
              name={FORM_NAMES.economical_estimated_value}
              id={FORM_NAMES.economical_estimated_value}
              type="number"
              placeholder="100.00"
              label={t('transformation.form.market.economical_value_unit_estimation.economical_estimated_value.label')}
              onChange={handleChangeWithValidation}
              error={resolveError(FORM_NAMES.economical_estimated_value)}
              defaultValue={getValues(FORM_NAMES.economical_estimated_value)}
              disabled={!editable}
            />
            <Form.Field>
              <label htmlFor={FORM_NAMES.production_estimated_currency}>
                {t('transformation.form.market.economical_value_unit_estimation.economical_estimated_currency.label')}
              </label>
              <Select
                fluid
                name={FORM_NAMES.economical_estimated_currency}
                id={FORM_NAMES.economical_estimated_currency}
                options={currencyOptions}
                placeholder="$"
                onChange={handleChangeWithValidation}
                error={resolveError(FORM_NAMES.economical_estimated_currency)}
                defaultValue={getValues(FORM_NAMES.economical_estimated_currency)}
                disabled={!editable}
              />
            </Form.Field>
            <Form.Input
              fluid
              name={FORM_NAMES.economical_estimated_unit}
              id={FORM_NAMES.economical_estimated_unit}
              label={t('transformation.form.market.economical_value_unit_estimation.economical_estimated_unit.label')}
              onChange={handleChangeWithValidation}
              placeholder="kg"
              error={resolveError(FORM_NAMES.economical_estimated_unit)}
              defaultValue={getValues(FORM_NAMES.economical_estimated_unit)}
              disabled={!editable}
            />
          </Form.Group>
        </div>

        <PopupFormFieldArea
          name={FORM_NAMES.structure_costs_analysis}
          id={FORM_NAMES.structure_costs_analysis}
          label={t('transformation.form.market.structure_costs_analysis.label')}
          hint={t('transformation.form.market.structure_costs_analysis.hint')}
          onChange={handleChangeWithValidation}
          error={resolveError(FORM_NAMES.structure_costs_analysis)}
          defaultValue={getValues(FORM_NAMES.structure_costs_analysis)}
          disabled={!editable}
        />

        <PopupFormFieldArea
          name={FORM_NAMES.market_analysis}
          id={FORM_NAMES.market_analysis}
          label={t('transformation.form.market.market_analysis.label')}
          hint={t('transformation.form.market.market_analysis.hint')}
          onChange={handleChangeWithValidation}
          error={resolveError(FORM_NAMES.market_analysis)}
          defaultValue={getValues(FORM_NAMES.market_analysis)}
          disabled={!editable}
        />

        <BubbleFormField
          name={FORM_NAMES.referent_region_businesses}
          id={FORM_NAMES.referent_region_businesses}
          label={t('transformation.form.market.referent_region_businesses.label')}
          hint={t('transformation.form.market.referent_region_businesses.hint')}
          onChange={handleChangeWithValidation}
          error={resolveError(FORM_NAMES.referent_region_businesses)}
          defaultValue={getValues(FORM_NAMES.referent_region_businesses)}
          disabled={!editable}
        />

        <BubbleFormField
          name={FORM_NAMES.donors_financing_institutions}
          id={FORM_NAMES.donors_financing_institutions}
          label={t('transformation.form.market.donors_financing_institutions.label')}
          hint={t('transformation.form.market.donors_financing_institutions.hint')}
          onChange={handleChangeWithValidation}
          error={resolveError(FORM_NAMES.donors_financing_institutions)}
          defaultValue={getValues(FORM_NAMES.donors_financing_institutions)}
          disabled={!editable}
        />
      </Container>
    </ParentComponent>
  );
};

MarketFormView.propTypes = {
  renderAs: PropTypes.oneOfType([PropTypes.string, PropTypes.elementType]),
  editable: PropTypes.bool,
  currencies: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    unit: PropTypes.string
  })).isRequired,
  form: PropTypes.shape({
    formState: PropTypes.shape({
      isSubmitting: PropTypes.bool
    }),
    handleSubmit: PropTypes.func,
    getValues: PropTypes.func
  }).isRequired
};

MarketFormView.defaultProps = {
  renderAs: Form,
  editable: true
};

export default MarketFormView;
