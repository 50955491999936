import React from 'react';
import PropTypes from 'prop-types';
import { Button, Form, Icon, Popup, Segment } from 'semantic-ui-react';
import { useFieldArray } from 'react-hook-form';
import PopupFormFieldInput from '../../common/form/popupFormFieldInput';
import PopupFormFieldArea from '../../common/form/popupFormFieldArea';
import useLocale from '../../../hooks/i18n';
import useFormHelper from '../../../hooks/formHelper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const getFieldPath = (form, index, field) => `${form}.${index}.${field}`;

const FORM_NAMES = {
  evidence_url: (form, index) => getFieldPath(form, index, 'evidence_url'),
  publishing_year: (form, index) =>
    getFieldPath(form, index, 'publishing_year'),
  url_description: (form, index) => getFieldPath(form, index, 'url_description')
};

const DocumentaryEvidenceInput = ({
  name,
  form,
  disabled,
  hint,
  hintExample
}) => {
  const { t } = useLocale();
  const { getValues, control } = form;
  const { fields, append, remove } = useFieldArray({
    control,
    name
  });
  const { handleChangeWithValidation, resolveError } = useFormHelper(form);

  const handleAdd = () => {
    append({
      evidence_url: '',
      publishing_year: null,
      url_description: ''
    });
  };

  const handleRemove = (index) => () => {
    remove(index);
  };

  return (
    <div className="field documentary-evidence-form">
      <div className="field hint-container">
        <label>
          {t('transformation.form.transformation_process.evidences.label')}
        </label>
        <Popup trigger={<Icon name="help circle" />} position="right center">
          <p>{hint}</p>
          {hintExample && <p>{hintExample}</p>}
        </Popup>
      </div>

      {fields.map((field, index) => (
        <Segment key={field.id}>
          <FontAwesomeIcon
            className="close-icon"
            icon="fa-solid fa-x"
            onClick={handleRemove(index)}
          />

          <PopupFormFieldInput
            name={FORM_NAMES.evidence_url(name, index)}
            id={FORM_NAMES.evidence_url(name, index)}
            label={t(
              'transformation.form.transformation_process.evidence_url.label'
            )}
            hint={t(
              'transformation.form.transformation_process.evidence_url.hint'
            )}
            onChange={handleChangeWithValidation}
            error={resolveError(FORM_NAMES.evidence_url(name, index))}
            defaultValue={getValues(FORM_NAMES.evidence_url(name, index))}
            disabled={disabled}
          />

          <Form.Input
            name={FORM_NAMES.publishing_year(name, index)}
            id={FORM_NAMES.publishing_year(name, index)}
            type="number"
            label={t(
              'transformation.form.transformation_process.publishing_year.label'
            )}
            onChange={handleChangeWithValidation}
            error={resolveError(FORM_NAMES.publishing_year(name, index))}
            defaultValue={getValues(FORM_NAMES.publishing_year(name, index))}
            disabled={disabled}
          />

          <PopupFormFieldArea
            name={FORM_NAMES.url_description(name, index)}
            id={FORM_NAMES.url_description(name, index)}
            label={t(
              'transformation.form.transformation_process.url_description.label'
            )}
            hint={t(
              'transformation.form.transformation_process.url_description.hint'
            )}
            onChange={handleChangeWithValidation}
            error={resolveError(FORM_NAMES.url_description(name, index))}
            defaultValue={getValues(FORM_NAMES.url_description(name, index))}
            disabled={disabled}
          />
        </Segment>
      ))}

      <Button
        color="blue"
        floated="right"
        onClick={handleAdd}
        disabled={disabled}
      >
        {t('transformation.form.ui.add_btn')}
      </Button>
    </div>
  );
};

DocumentaryEvidenceInput.propTypes = {
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  form: PropTypes.shape({
    formState: PropTypes.shape({
      isSubmitting: PropTypes.bool
    }),
    handleSubmit: PropTypes.func,
    getValues: PropTypes.func,
    control: PropTypes.shape({})
  }).isRequired,
  hint: PropTypes.string.isRequired,
  hintExample: PropTypes.string
};

DocumentaryEvidenceInput.defaultProps = {
  disabled: false,
  hintExample: null
};

export default DocumentaryEvidenceInput;
