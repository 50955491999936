import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@tanstack/react-query';
import TransformationItemContainer from '../TranformationItemContainer';
import ImageSection from '../sections/ImageSection';
import MainSection from '../sections/MainSection';
import DeleteSection from '../sections/DeleteSection';
import ConfirmationModal from '../../../forms/transformationForm/ConfirmationModal';
import useAPI from '../../../../hooks/api';
import useLocale from '../../../../hooks/i18n';
import { DYNAMIC_PROTECTED_ROUTES } from '../../../../constants/routes';

const InCurationTransformationItem = ({ aggregatedTransformation }) => {
  const { curation: { id: curationId }, transformation: { metadata, author, transformation_obj } } = aggregatedTransformation;

  const { t } = useLocale();
  const { client, getFullResponseErrorMessage, queryClient } = useAPI();

  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [deleteError, setDeleteError] = useState(null);
  const deleteMutation = useMutation((id) => client.curation.deleteOngoingCuration(id));

  const handleDeleteClicked = () => {
    setOpenConfirmModal(true);
  };

  const handleClose = () => {
    setOpenConfirmModal(false);
  };

  const handleConfirm = async () => {
    setDeleteError(null);

    try {
      await deleteMutation.mutateAsync(curationId);
      await queryClient.invalidateQueries(client.curation.getCuration(curationId).key);
      await queryClient.invalidateQueries({
        predicate: (query) => {
          const keyCuration = ['curation', 'ongoing'];
          const keyTransformation = ['transformations', 'awaiting-curation'];
          return keyCuration.every((e) => query.queryKey.includes(e)) || keyTransformation.every((e) => query.queryKey.includes(e));
        }
      });
    } catch (error) {
      setDeleteError(getFullResponseErrorMessage(error));
    }
  };

  return (
    <Fragment>
      <TransformationItemContainer>
        <ImageSection
          picture={metadata.picture}
        />
        <MainSection
          title={transformation_obj.basic_information?.producing_species_name}
          subtitle={transformation_obj.basic_information?.organic_material_name}
          description={transformation_obj.basic_information?.brief_biomass_description}
          author={author}
          linkTo={DYNAMIC_PROTECTED_ROUTES.editCuration(curationId)}
        />
        <DeleteSection
          onDelete={handleDeleteClicked}
        />
      </TransformationItemContainer>

      <ConfirmationModal
        open={openConfirmModal}
        onClose={handleClose}
        onConfirm={handleConfirm}
        loading={deleteMutation.isLoading}
        error={deleteError}
        contentArray={[t('delete_curation.confirm_modal.description.1'), t('delete_curation.confirm_modal.description.2')]}
        closeLabel={t('delete_curation.confirm_modal.actions.close.label')}
        confirmLabel={t('delete_curation.confirm_modal.actions.delete.label')}
        errorHeader={t('delete_curation.error.header')}
        confirmColor="red"
        closeColor="green"
        header={t('delete_curation.confirm_modal.header')}
      />
    </Fragment>
  );
};

InCurationTransformationItem.propTypes = {
  aggregatedTransformation: PropTypes.shape({
    curation: PropTypes.shape({
      id: PropTypes.number.isRequired
    }).isRequired,
    transformation: PropTypes.shape({
      transformation_obj: PropTypes.shape({
        basic_information: PropTypes.shape({
          producing_species_name: PropTypes.string,
          organic_material_name: PropTypes.string,
          brief_biomass_description: PropTypes.string
        })
      }).isRequired,
      author: PropTypes.shape({
        family_name: PropTypes.string.isRequired,
        given_name: PropTypes.string.isRequired,
        picture: PropTypes.string.isRequired,
        username: PropTypes.string.isRequired
      }).isRequired,
      metadata: PropTypes.shape({
        picture: PropTypes.string.isRequired
      }).isRequired
    }).isRequired
  }).isRequired
};

export default InCurationTransformationItem;
