import React from 'react';
import useLocale from '../../../hooks/i18n';

const UserNotFound = () => {
  const { t } = useLocale();

  return (
    <div className="user-not-found-container">
      <h2>{t('not_found.user')}</h2>
    </div>
  );
};

export default UserNotFound;
