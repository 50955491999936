export const PUBLIC_SITE_URL = 'https://transformar.bio';

export const TRANSFORMATION_IMAGES = new Array(20).fill(null).map((_, idx) => {
  return `${PUBLIC_SITE_URL}/static/img/transformations/${idx + 1}.jpg`;
});

export const USER_IMAGES = new Array(7).fill(null).map((_, idx) => {
  return [
    `${PUBLIC_SITE_URL}/static/img/users/green_${idx + 1}.png`,
    `${PUBLIC_SITE_URL}/static/img/users/white_${idx + 1}.png`,
    `${PUBLIC_SITE_URL}/static/img/users/photo_${idx + 1}.jpg`
  ];
}).flat(1);

export const DEFAULT_USER_IMAGE = 'https://transformar.bio/static/img/users/white_1.png';
