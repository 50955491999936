export const PUBLIC_ROUTES = {
  home: '/',
  about: '/about',
  signup: '/signup',
  login: '/login',
  user: '/user',
  explore: '/explore',
  forgotPassword: '/forgot-password',
  resendVerification: '/resend-verification',
  transformation: '/transformation',
  termsAndConditions: '/terms-and-conditions'
};

export const DYNAMIC_PUBLIC_ROUTES = {
  userProfile: (username) => {
    return `${PUBLIC_ROUTES.user}/${username}`;
  },
  transformationPage: (id) => {
    return `${PUBLIC_ROUTES.transformation}/${id}`;
  }
};

export const PROTECTED_ROUTES = {
  contributions: '/my-contributions',
  settings: '/settings',
  admin: '/admin',
  adminCuratorRequests: '/admin/curator-requests',
  adminResourceManagement: '/admin/resource-management',
  curations: '/awaiting-curation',
  becomeCurator: '/become-curator',
  newApplication: '/become-curator/new-application'
};

export const DYNAMIC_PROTECTED_ROUTES = {
  editContribution: (id) => {
    return `${PROTECTED_ROUTES.contributions}/${id}/edit`;
  },
  editCuration: (id) => {
    return `${PROTECTED_ROUTES.curations}/${id}/edit`;
  }
};
