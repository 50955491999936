import React from 'react';
import { Button, Grid, Image } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { PUBLIC_ROUTES } from '../../constants/routes';
import useLocale from '../../hooks/i18n';

const CuratorRequestView = ({
  username,
  picture,
  role,
  date,
  description,
  onAccept,
  onDeny
}) => {
  const { t } = useLocale();
  const formattedDate = new Date(date);

  return (
    <Grid className="curator-request">
      <Grid.Column width={3} verticalAlign="middle" className="user-column">
        <Grid.Row>
          <p className="username">{username}</p>
        </Grid.Row>
        <Grid.Row>
          <Image
            src={picture}
            circular
            className="avatar-image"
            as={Link}
            to={`${PUBLIC_ROUTES.user}/${username}`}
          />
        </Grid.Row>
      </Grid.Column>

      <Grid.Column width={8} className="description-column">
        <Grid.Row>
          <p className="description">{description}</p>
        </Grid.Row>
      </Grid.Column>

      <Grid.Column
        width={5}
        verticalAlign="middle"
        textAlign="center"
        className="actions-column"
      >
        <p>
          {t('admin.curator_request.current_role')}
          <span className="role">{role}</span>
        </p>
        <p>{formattedDate.toDateString()}</p>
        <div className="accept-decline-btn">
          <Button positive onClick={onAccept}>
            {t('admin.curator_request.ui.approve')}
          </Button>
          <Button negative onClick={onDeny}>
            {t('admin.curator_request.ui.deny')}
          </Button>
        </div>
      </Grid.Column>
    </Grid>
  );
};

CuratorRequestView.propTypes = {
  username: PropTypes.string.isRequired,
  picture: PropTypes.string.isRequired,
  role: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  onAccept: PropTypes.func.isRequired,
  onDeny: PropTypes.func.isRequired
};

export default CuratorRequestView;
