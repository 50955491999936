import React, { useState } from 'react';
import { Button, Container } from 'semantic-ui-react';
import { useQuery, useMutation } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import Loading from '../../components/common/loading';
import RequestErrorMessage from '../../components/common/requestErrorMessage';
import TransformationItemGroup from '../../components/transformation/transformationItemGroup';
import useLocale from '../../hooks/i18n';
import useTitle from '../../hooks/title';
import useAPI from '../../hooks/api';
import { DYNAMIC_PROTECTED_ROUTES } from '../../constants/routes';
import { TRANSFORMATION_STATUS } from '../../constants/transformation';

const MyContributionsPage = () => {
  const { t } = useLocale();
  useTitle(t('page_titles.my_contributions'));
  const { client, getFullResponseErrorMessage } = useAPI();
  const navigate = useNavigate();
  const request = client.transformation.getAllUnpublishedTransformations();
  const { isLoading, error: getError, data: transformations } = useQuery(request.key, request.fn);
  const createTransformationMutation = useMutation(client.transformation.postUnpublishedTransformation.bind(client.transformation));
  const [postError, setPostError] = useState(null);

  const handleAddNewClick = async () => {
    try {
      const data = await createTransformationMutation.mutateAsync(null);
      navigate(DYNAMIC_PROTECTED_ROUTES.editContribution(data.id_transformation));
    } catch (error) {
      setPostError(error);
    }
  };

  if (isLoading) {
    return (
      <Loading fullscreen />
    );
  }

  if (getError) {
    return (
      <RequestErrorMessage header={t('my_contributions.error.get.header')} error={getFullResponseErrorMessage(getError)} />
    );
  }

  if (postError) {
    return (
      <RequestErrorMessage header={t('my_contributions.error.post.header')} error={getFullResponseErrorMessage(postError)} />
    );
  }

  const transformationsByStatus = transformations.reduce((obj, cur) => {
    obj[cur.status]?.push(cur);
    return obj;
  }, {
    [TRANSFORMATION_STATUS.DRAFT]: [],
    [TRANSFORMATION_STATUS.CHANGES_REQUESTED]: [],
    [TRANSFORMATION_STATUS.AWAITING_CURATION]: [],
    [TRANSFORMATION_STATUS.IN_CURATION]: []
  });

  return (
    <Container className="my-contributions-page">
      <div className="my-contributions-header">
        <h1>
          {t('my_contributions.header')}
        </h1>
        <div className="add-btn-wrapper">
          <Button
            className="add-btn"
            color="green"
            basic
            onClick={handleAddNewClick}
            loading={createTransformationMutation.isLoading}
          >
            {t('my_contributions.add_btn')}
          </Button>
        </div>
      </div>
      <div className="contributions-list">
        <TransformationItemGroup
          className="contributions-group"
          title={t('my_contributions.ongoing.title')}
          emptyHeader={t('my_contributions.ongoing.empty.header')}
          emptyDescription={t('my_contributions.ongoing.empty.description')}
          aggregatedTransformations={transformationsByStatus[TRANSFORMATION_STATUS.DRAFT]}
          type="editableUnpublished"
        />

        <TransformationItemGroup
          className="contributions-group"
          title={t('my_contributions.changes_requested.title')}
          emptyHeader={t('my_contributions.changes_requested.empty.header')}
          emptyDescription={t('my_contributions.changes_requested.empty.description')}
          aggregatedTransformations={transformationsByStatus[TRANSFORMATION_STATUS.CHANGES_REQUESTED]}
          type="editableUnpublished"
        />

        <TransformationItemGroup
          className="contributions-group"
          title={t('my_contributions.awaiting_curation.title')}
          emptyHeader={t('my_contributions.awaiting_curation.empty.header')}
          emptyDescription={t('my_contributions.awaiting_curation.empty.description')}
          aggregatedTransformations={transformationsByStatus[TRANSFORMATION_STATUS.AWAITING_CURATION]}
          type="nonEditableUnpublished"
        />

        <TransformationItemGroup
          className="contributions-group"
          title={t('my_contributions.in_curation.title')}
          emptyHeader={t('my_contributions.in_curation.empty.header')}
          emptyDescription={t('my_contributions.in_curation.empty.description')}
          aggregatedTransformations={transformationsByStatus[TRANSFORMATION_STATUS.IN_CURATION]}
          type="nonEditableUnpublished"
        />
      </div>
    </Container>
  );
};

export default MyContributionsPage;
