import { useContext } from 'react';
import i18nContext from '../context/i18nContext';
import { isLocaleSupported, translate, AVAILABLE_LOCALES, LOCALE_FLAGS } from '../i18n';

const useLocale = () => {
  const { locale, setLocale } = useContext(i18nContext);

  const t = (key, values = {}) => {
    return translate(locale, key, values);
  };

  const updateLocale = (newLocale) => {
    if (!isLocaleSupported(newLocale)) {
      throw new Error(`Locale ${newLocale} is not supported!`);
    }

    setLocale(newLocale);
  };

  return {
    t,
    updateLocale,
    currentLocale: locale,
    AVAILABLE_LOCALES,
    LOCALE_FLAGS
  };
};

export default useLocale;
