import React from 'react';
import UserSettingsFormLogic from './UserSettingsFormLogic';
import { useMutation } from '@tanstack/react-query';
import useAPI from '../../../hooks/api';
import useAuth from '../../../hooks/auth';

const UserSettingsForm = () => {
  const { client, getFullResponseErrorMessage, queryClient } = useAPI();
  const { setMe } = useAuth();
  const userMutation = useMutation(client.user.patchCurrentUser.bind(client.user));

  const handleSubmit = async (data) => {
    try {
      const updatedUser = await userMutation.mutateAsync(data);
      await queryClient.invalidateQueries(client.user.getCurrentUser().key);
      await queryClient.invalidateQueries(client.user.getFullUser().key);
      setMe(updatedUser);
    } catch (error) {
      throw getFullResponseErrorMessage(error);
    }
  };

  return <UserSettingsFormLogic onSubmit={handleSubmit} />;
};

export default UserSettingsForm;
