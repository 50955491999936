import React from 'react';
import { Image } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CardContainer from '../../ui/cardContainer';
import useLocale from '../../../hooks/i18n';
import useAuth from '../../../hooks/auth';
import { Link, useParams } from 'react-router-dom';
import { PROTECTED_ROUTES } from '../../../constants/routes';
import edit from '../../../assets/icons/edit.svg';

const UserSideProfile = ({
  picture,
  givenName,
  familyName,
  bio,
  institutionPosition,
  institutionName,
  country,
  city,
  phone,
  email,
  website,
  facebook,
  instagram,
  twitter,
  linkedin
}) => {
  const { t } = useLocale();
  const { me } = useAuth();
  const { username } = useParams();

  return (
    <CardContainer className="user-side-profile">
      <div className="side-profile-main">
        <Image className="side-profile-main__avatar" src={picture} circular />
        {me?.username === username && (
          <Image
            as={Link}
            to={PROTECTED_ROUTES.settings}
            className="edit-profile-icon"
            src={edit}
            size="medium"
          />
        )}
        <h2 className="side-profile-main__name">{`${givenName} ${familyName}`}</h2>
        <p className="side-profile-main__position">{`${institutionPosition} ${t(
          'user.profile.side_profile.at'
        )} ${institutionName}`}
        </p>
      </div>
      <div className="user-contact">
        <div className="user-contact__item">
          <FontAwesomeIcon icon="fa-solid fa-globe" />
          <p>{`${city}, ${country}`}</p>
        </div>
        {phone && (
          <div className="user-contact__item">
            <FontAwesomeIcon icon="fa-solid fa-phone" />
            <p>{phone}</p>
          </div>
        )}
        <div className="user-contact__item user-contact__item--mail">
          <FontAwesomeIcon icon="fa-solid fa-envelope" />
          <p>{email}</p>
        </div>
      </div>

      {bio && <p className="user-side-profile__bio">{bio}</p>}

      {(website || facebook || instagram || twitter || linkedin) && (
        <div className="user-socials">
          {website && (
            <a href={website} target="_blank" rel="noreferrer">
              <FontAwesomeIcon icon="fa-solid fa-earth-americas" size="2x" />
            </a>
          )}
          {facebook && (
            <a href={facebook} target="_blank" rel="noreferrer">
              <FontAwesomeIcon icon="fa-brands fa-facebook" size="2x" />
            </a>
          )}
          {instagram && (
            <a href={instagram} target="_blank" rel="noreferrer">
              <FontAwesomeIcon icon="fa-brands fa-instagram" size="2x" />
            </a>
          )}
          {twitter && (
            <a href={twitter} target="_blank" rel="noreferrer">
              <FontAwesomeIcon icon="fa-brands fa-twitter" size="2x" />
            </a>
          )}
          {linkedin && (
            <a href={linkedin} target="_blank" rel="noreferrer">
              <FontAwesomeIcon icon="fa-brands fa-linkedin" size="2x" />
            </a>
          )}
        </div>
      )}
    </CardContainer>
  );
};

UserSideProfile.propTypes = {
  picture: PropTypes.string.isRequired,
  givenName: PropTypes.string.isRequired,
  familyName: PropTypes.string.isRequired,
  institutionPosition: PropTypes.string.isRequired,
  institutionName: PropTypes.string.isRequired,
  country: PropTypes.string.isRequired,
  city: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  bio: PropTypes.string,
  phone: PropTypes.string,
  website: PropTypes.string,
  facebook: PropTypes.string,
  instagram: PropTypes.string,
  twitter: PropTypes.string,
  linkedin: PropTypes.string
};

UserSideProfile.defaultProps = {
  bio: null,
  phone: null,
  website: null,
  facebook: null,
  instagram: null,
  twitter: null,
  linkedin: null
};

export default UserSideProfile;
