import React from 'react';
import PropTypes from 'prop-types';
import { Container, Message, Button, Divider } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import useLocale from '../../../hooks/i18n';
import { PUBLIC_ROUTES } from '../../../constants/routes';

const PostSignUp = ({ email, name }) => {
  const { t } = useLocale();

  return (
    <Container>
      <Message info size="big">
        <Message.Header>
          {t('signup.post.header', { name })}
        </Message.Header>
        <p>
          {t('signup.post.description', { email })}
        </p>
      </Message>

      <Divider hidden />

      <Container textAlign="center">
        <Button as={Link} to={PUBLIC_ROUTES.login} primary>
          {t('signup.post.buttons.login')}
        </Button>

        <Divider hidden />

        <Button as={Link} to={PUBLIC_ROUTES.resendVerification}>
          {t('signup.post.buttons.resend_code')}
        </Button>
      </Container>
    </Container>

  );
};

PostSignUp.propTypes = {
  email: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired
};

export default PostSignUp;
