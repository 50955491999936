import React from 'react';
import { Accordion, Menu } from 'semantic-ui-react';
import PropTypes from 'prop-types';

const MenuAccordionItem = ({ index, title, activeIndex, onAccordionItemClicked, content }) => {
  return (
    <Menu.Item className="navbar-mobile-menu-item">
      <Accordion.Title
        content={title}
        index={index}
        active={activeIndex === index}
        onClick={onAccordionItemClicked.bind(null, index)}
      />
      <Accordion.Content
        content={content}
        active={activeIndex === index}
      />
    </Menu.Item>
  );
};

MenuAccordionItem.propTypes = {
  index: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  activeIndex: PropTypes.number.isRequired,
  onAccordionItemClicked: PropTypes.func.isRequired,
  content: PropTypes.node.isRequired
};

export default MenuAccordionItem;
