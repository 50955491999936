/* eslint-disable max-lines */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';
import TransformationFormView from './TransformationFormView';
import useAPI from '../../../hooks/api';
import { useMutation } from '@tanstack/react-query';

const DocumentaryEvidenceSchema = Joi.object({
  id: Joi.number().integer().forbidden(),
  id_transformation_process: Joi.number().integer().forbidden(),
  evidence_url: Joi.string().uri().max(512).messages({
    'string.uri':
      'transformation.form.transformation_process.evidence_url.errors.uri',
    'string.max':
      'transformation.form.transformation_process.evidence_url.errors.max'
  }),
  publishing_year: Joi.number().integer().positive().messages({
    'number.base':
      'transformation.form.transformation_process.publishing_year.errors.base',
    'number.positive':
      'transformation.form.transformation_process.publishing_year.errors.positive'
  }),
  url_description: Joi.string().max(1024).trim().messages({
    'string.max':
      'transformation.form.transformation_process.url_description.errors.max'
  })
});

const BasicInformationSchema = Joi.object({
  id_source_type: Joi.number().integer().messages({
    'number.base':
      'transformation.form.basic_information.source_type.errors.base'
  }),
  id_source_class: Joi.number().integer().messages({
    'number.base':
      'transformation.form.basic_information.source_class.errors.base'
  }),
  id_source_sub_class: Joi.number().integer().messages({
    'number.base':
      'transformation.form.basic_information.source_sub_class.errors.base'
  }),
  id_location: Joi.number().integer().messages({
    'number.base': 'transformation.form.basic_information.location.errors.base'
  }),
  id_trl_eu: Joi.number().integer().messages({
    'number.base': 'transformation.form.basic_information.trl_eu.errors.base'
  }),
  producing_species_name: Joi.string().max(256).trim().messages({
    'string.max':
      'transformation.form.basic_information.producing_species_name.errors.max'
  }),
  common_names: Joi.array()
    .items(
      Joi.string().max(256).trim().required().messages({
        'string.max':
          'transformation.form.basic_information.common_names.errors.max',
        'string.empty':
          'transformation.form.basic_information.common_names.errors.required',
        'any.required':
          'transformation.form.basic_information.common_names.errors.required'
      })
    )
    .min(1)
    .messages({
      'array.min':
        'transformation.form.basic_information.common_names.errors.min'
    }),
  biomass_class: Joi.string().max(3).trim().messages({
    'string.max':
      'transformation.form.basic_information.biomass_class.errors.max'
  }),
  organic_material_name: Joi.string().max(256).trim().messages({
    'string.max':
      'transformation.form.basic_information.organic_material_name.errors.max',
    'string.empty':
      'transformation.form.basic_information.organic_material_name.errors.required',
    'any.required':
      'transformation.form.basic_information.organic_material_name.errors.required'
  }),
  brief_biomass_description: Joi.string().max(350).trim().messages({
    'string.max':
      'transformation.form.basic_information.brief_biomass_description.errors.max',
    'string.empty':
      'transformation.form.basic_information.brief_biomass_description.errors.required',
    'any.required':
      'transformation.form.basic_information.brief_biomass_description.errors.required'
  }),
  current_uses_or_transformations: Joi.array().items(
    Joi.string().max(256).trim().required().messages({
      'string.max':
        'transformation.form.basic_information.current_uses_or_transformations.errors.max'
    })
  ),
  current_negative_impact: Joi.string().max(350).trim().messages({
    'string.max':
      'transformation.form.basic_information.current_negative_impact.errors.max',
    'string.empty':
      'transformation.form.basic_information.current_negative_impact.errors.required',
    'any.required':
      'transformation.form.basic_information.current_negative_impact.errors.required'
  })
});

const TransformationProcessSchema = Joi.object({
  brief_description: Joi.string().max(350).trim().messages({
    'string.max':
      'transformation.form.transformation_process.brief_description_of_transformation_process.errors.max',
    'string.empty':
      'transformation.form.transformation_process.brief_description_of_transformation_process.errors.required',
    'any.required':
      'transformation.form.transformation_process.brief_description_of_transformation_process.errors.required'
  }),
  developed_technologies: Joi.array().items(
    Joi.string().max(256).trim().required().messages({
      'string.max':
        'transformation.form.transformation_process.developed_technologies.errors.max',
      'string.empty':
        'transformation.form.transformation_process.developed_technologies.errors.required',
      'any.required':
        'transformation.form.transformation_process.developed_technologies.errors.required'
    })
  ),
  products_or_services: Joi.array().items(
    Joi.string().max(256).trim().required().messages({
      'string.max':
        'transformation.form.transformation_process.products_or_services.errors.max',
      'string.empty':
        'transformation.form.transformation_process.products_or_services.errors.required',
      'any.required':
        'transformation.form.transformation_process.products_or_services.errors.required'
    })
  ),
  industrial_applications: Joi.array().items(
    Joi.string().max(256).trim().required().messages({
      'string.max':
        'transformation.form.transformation_process.industrial_applications.errors.max',
      'string.empty':
        'transformation.form.transformation_process.industrial_applications.errors.required',
      'any.required':
        'transformation.form.transformation_process.industrial_applications.errors.required'
    })
  ),
  associated_pollutants: Joi.array().items(
    Joi.string().max(256).trim().required().messages({
      'string.max':
        'transformation.form.transformation_process.associated_pollutants.errors.max',
      'string.empty':
        'transformation.form.transformation_process.associated_pollutants.errors.required',
      'any.required':
        'transformation.form.transformation_process.associated_pollutants.errors.required'
    })
  ),
  patents_associated: Joi.string().max(3).trim().messages({
    'string.max':
      'transformation.form.transformation_process.patents_associated.errors.max'
  }),
  licenses_emitted: Joi.string().max(3).trim().messages({
    'string.max':
      'transformation.form.transformation_process.licenses_emitted.errors.max'
  }),
  free_access: Joi.string().max(3).trim().messages({
    'string.max':
      'transformation.form.transformation_process.free_access.errors.max'
  }),
  evidences: Joi.array().items(DocumentaryEvidenceSchema)
});

const ScalabilitySchema = Joi.object({
  id_climate: Joi.number().integer().messages({
    'number.base': 'transformation.form.scalability.climate.errors.base'
  }),
  technical_scalability: Joi.string().max(3).trim().messages({
    'string.max':
      'transformation.form.scalability.technical_scalability.errors.max'
  }),
  technical_scalability_challenges: Joi.string().trim().messages({
    'string.empty':
      'transformation.form.scalability.technical_scalability_challenges.errors.required',
    'any.required':
      'transformation.form.scalability.technical_scalability_challenges.errors.required'
  }),
  required_equipment: Joi.array().items(
    Joi.string().max(256).trim().required().messages({
      'string.max':
        'transformation.form.scalability.required_equipment.errors.max',
      'string.empty':
        'transformation.form.scalability.required_equipment.errors.required',
      'any.required':
        'transformation.form.scalability.required_equipment.errors.required'
    })
  ),
  required_supplies: Joi.array().items(
    Joi.string().max(256).trim().required().messages({
      'string.max':
        'transformation.form.scalability.required_supplies.errors.max',
      'string.empty':
        'transformation.form.scalability.required_supplies.errors.required',
      'any.required':
        'transformation.form.scalability.required_supplies.errors.required'
    })
  ),
  required_skills: Joi.array().items(
    Joi.string().max(256).trim().required().messages({
      'string.max':
        'transformation.form.scalability.required_skills.errors.max',
      'string.empty':
        'transformation.form.scalability.required_skills.errors.required',
      'any.required':
        'transformation.form.scalability.required_skills.errors.required'
    })
  ),
  min_value: Joi.number()
    .positive()
    .allow(0, null)
    .empty('')
    .default(null)
    .messages({
      'number.base':
        'transformation.form.scalability.minimal_scale_production.min_value.errors.base',
      'number.positive':
        'transformation.form.scalability.minimal_scale_production.min_value.errors.positive'
    }),
  min_unit: Joi.string().max(128).trim().allow('').messages({
    'string.max':
      'transformation.form.scalability.minimal_scale_production.min_unit.errors.max'
  }),
  max_value: Joi.number()
    .positive()
    .allow(0, null)
    .empty('')
    .default(null)
    .messages({
      'number.base':
        'transformation.form.scalability.maximum_production_volume.max_value.errors.base',
      'number.positive':
        'transformation.form.scalability.maximum_production_volume.max_value.errors.positive'
    }),
  max_unit: Joi.string().max(128).trim().allow('').messages({
    'number.base':
      'transformation.form.scalability.maximum_production_volume.max_unit.errors.max'
  }),
  logistical_scalability: Joi.string().max(3).trim().messages({
    'string.max':
      'transformation.form.scalability.logistical_scalability.errors.max'
  }),
  logistical_scalability_challenges: Joi.string().trim().messages({
    'string.empty':
      'transformation.form.scalability.logistical_scalability_challenges.errors.required',
    'any.required':
      'transformation.form.scalability.logistical_scalability_challenges.errors.required'
  }),
  economical_scalability: Joi.string().max(3).trim().messages({
    'string.max':
      'transformation.form.scalability.economical_scalability.errors.max'
  }),
  economical_scalability_challenges: Joi.string().trim().messages({
    'string.empty':
      'transformation.form.scalability.economical_scalability_challenges.errors.required',
    'any.required':
      'transformation.form.scalability.economical_scalability_challenges.errors.required'
  }),
  annual_availability: Joi.number().integer().messages({
    'number.base':
      'transformation.form.scalability.annual_availability.errors.base'
  }),
  geographical_location: Joi.object().unknown(true) // Might need to check the JSON structure for this.
});

const SustainabilitySchema = Joi.object({
  id_sustainable_development_goals: Joi.array().items(
    Joi.number().integer().messages({
      'number.base':
        'transformation.form.sustainability.sustainable_development_goals.errors.base'
    })
  ),
  contributes_species_preservation: Joi.string().max(1024).trim().messages({
    'string.max':
      'transformation.form.sustainability.contributes_species_preservation.errors.max'
  }),
  contributes_circular_economy: Joi.string().max(1024).trim().messages({
    'string.max':
      'transformation.form.sustainability.contributes_circular_economy.errors.max'
  }),
  closes_productive_cycle_gap: Joi.boolean().messages({
    'boolean.base':
      'transformation.form.sustainability.closes_productive_cycle_gap.errors.base'
  }),
  replaces_materials_fossil_origin: Joi.boolean().messages({
    'boolean.base':
      'transformation.form.sustainability.replaces_materials_fossil_origin.errors.base'
  }),
  reduces_greenhouse_effect_gasses: Joi.boolean().messages({
    'boolean.base':
      'transformation.form.sustainability.reduces_greenhouse_effect_gasses.errors.base'
  })
});

const MarketSchema = Joi.object({
  production_estimated_value: Joi.number().messages({
    'number.base':
      'transformation.form.market.production_costs_estimation.production_estimated_value.errors.base'
  }),
  production_estimated_currency: Joi.number().integer().messages({
    'number.base':
      'transformation.form.market.production_costs_estimation.production_estimated_currency.errors.base'
  }),
  production_estimated_unit: Joi.string().max(128).trim().messages({
    'string.max':
      'transformation.form.market.production_costs_estimation.production_estimated_unit.errors.max'
  }),
  market_analysis: Joi.string().trim().allow(''),
  economical_estimated_value: Joi.number().messages({
    'number.base':
      'transformation.form.market.economical_value_unit_estimation.economical_estimated_value.errors.base'
  }),
  economical_estimated_currency: Joi.number().integer().messages({
    'number.base':
      'transformation.form.market.economical_value_unit_estimation.economical_estimated_currency.errors.base'
  }),
  economical_estimated_unit: Joi.string().max(128).trim().messages({
    'string.max':
      'transformation.form.market.economical_value_unit_estimation.economical_estimated_unit.errors.max'
  }),
  structure_costs_analysis: Joi.string().trim().allow(''),
  referent_region_businesses: Joi.array().items(
    Joi.string().max(256).trim().required().messages({
      'string.max':
        'transformation.form.market.referent_region_businesses.errors.max',
      'string.empty':
        'transformation.form.market.referent_region_businesses.errors.required',
      'any.required':
        'transformation.form.market.referent_region_businesses.errors.required'
    })
  ),
  donors_financing_institutions: Joi.array().items(
    Joi.string().max(256).trim().required().messages({
      'string.max':
        'transformation.form.market.donors_financing_institutions.errors.max',
      'string.empty':
        'transformation.form.market.donors_financing_institutions.errors.required',
      'any.required':
        'transformation.form.market.donors_financing_institutions.errors.required'
    })
  )
});

const RegulationsSchema = Joi.object({
  indigenous_people_logistics: Joi.string().max(1024).trim().messages({
    'string.max':
      'transformation.form.regulations.indigenous_people_logistics.errors.max'
  }),
  permissions_access_resource: Joi.string().max(1024).trim().messages({
    'string.max':
      'transformation.form.regulations.permissions_access_resource.errors.max'
  }),
  regulations: Joi.string().max(1024).trim().messages({
    'string.max': 'transformation.form.regulations.regulations.errors.max'
  }),
  certifications: Joi.string().max(1024).trim().messages({
    'string.max': 'transformation.form.regulations.certifications.errors.max'
  }),
  quality_risks_standards: Joi.string().max(1024).trim().messages({
    'string.max':
      'transformation.form.regulations.quality_risks_standards.errors.max'
  }),
  waste_management: Joi.string().max(1024).trim().messages({
    'string.max': 'transformation.form.regulations.waste_management.errors.max'
  })
});

const TransformationObjectSchema = Joi.object({
  basic_information: BasicInformationSchema,
  transformation_process: TransformationProcessSchema,
  scalability: ScalabilitySchema,
  sustainability: SustainabilitySchema,
  market: MarketSchema,
  regulations: RegulationsSchema
});

// For pages where all data is optional.
const DEFAULT_EMPTY_DATA = {
  basic_information: {},
  transformation_process: {},
  scalability: {},
  sustainability: {},
  market: {},
  regulations: {}
};

const TransformationFormLogic = ({
  onPublish,
  onSave,
  initialData,
  resources,
  curation,
  metadata,
  transformationId
}) => {
  const form = useForm({
    mode: 'onSubmit',
    resolver: joiResolver(TransformationObjectSchema, { stripUnknown: true }),
    defaultValues: initialData
  });

  const { client, queryClient, getFullResponseErrorMessage } = useAPI();
  const metadataMutation = useMutation((picture) =>
    client.transformation.patchUnpublishedTransformationMetadataByID(
      transformationId,
      picture
    )
  );

  const [error, setError] = useState(null);

  const [imagePickerOpen, setImagePickerOpen] = React.useState(false);
  const [imagePickerLoading, setImagePickerLoading] = React.useState(false);
  const [imagePickerError, setImagePickerError] = React.useState(null);

  const handleSaveImage = async (imageURL) => {
    setImagePickerLoading(true);
    try {
      await metadataMutation.mutateAsync(imageURL);
      await queryClient.invalidateQueries(client.transformation.getAllUnpublishedTransformations().key);
      setImagePickerLoading(false);
      setImagePickerOpen(false);
    } catch (err) {
      setImagePickerError(getFullResponseErrorMessage(err));
      setImagePickerLoading(false);
    }
  };

  const handleImagePickerClose = () => {
    setImagePickerOpen(false);
    setImagePickerError(null);
  };

  const handleFinish = async (data) => {
    setError(null);

    try {
      await onPublish({ ...DEFAULT_EMPTY_DATA, ...data });
    } catch (error) {
      setError(error);
    }
  };

  const handleSave = async (data, name) => {
    setError(null);

    if (!data[name]) {
      return;
    }

    try {
      await onSave({
        [name]: data[name]
      });
    } catch (error) {
      setError(error);
    }
  };

  return (
    <TransformationFormView
      onSaveImage={handleSaveImage}
      onImagePickerClose={handleImagePickerClose}
      imagePickerOpen={imagePickerOpen}
      imagePickerLoading={imagePickerLoading}
      imagePickerError={imagePickerError}
      setImagePickerOpen={setImagePickerOpen}
      metadata={metadata}
      onFinish={handleFinish}
      onSave={handleSave}
      resources={resources}
      form={form}
      error={error}
      curation={curation}
    />
  );
};

TransformationFormLogic.propTypes = {
  onPublish: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  initialData: PropTypes.shape({}),
  resources: PropTypes.shape({}).isRequired,
  metadata: PropTypes.shape({
    picture: PropTypes.string
  }),
  transformationId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  curation: PropTypes.shape({})
};

TransformationFormLogic.defaultProps = {
  initialData: {},
  metadata: {},
  curation: null
};

export default TransformationFormLogic;
