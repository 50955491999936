import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'semantic-ui-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ResourceTableRow = ({ id, text, onEditClick, extraColumnName }) => {
  return (
    <Table.Row>
      <Table.Cell>{id}</Table.Cell>
      <Table.Cell>{text}</Table.Cell>
      {onEditClick ? (
        <Table.Cell textAlign="center" className="edit-icon-column">
          <FontAwesomeIcon
            icon="fa-solid fa-pen"
            onClick={onEditClick}
          />
        </Table.Cell>
      ) : extraColumnName && (
        <Table.Cell textAlign="center">{extraColumnName}</Table.Cell>
      )}
    </Table.Row>
  );
};

ResourceTableRow.propTypes = {
  id: PropTypes.number.isRequired,
  text: PropTypes.string.isRequired,
  onEditClick: PropTypes.func,
  extraColumnName: PropTypes.string
};

ResourceTableRow.defaultProps = {
  onEditClick: null,
  extraColumnName: null
};

export default ResourceTableRow;
