import React from 'react';
import PropTypes from 'prop-types';
import SignUpFormLogic from './SignUpFormLogic';
import useAuth from '../../../hooks/auth';
import useLocale from '../../../hooks/i18n';

const SignUpForm = ({ onComplete }) => {
  const { signUp } = useAuth();
  const { t } = useLocale();

  const handleSubmit = async (data) => {
    try {
      await signUp(data);
      onComplete(data);
    } catch (signUpError) {
      throw signUpError.getFriendlyMessage(t);
    }
  };

  return (
    <SignUpFormLogic onSubmit={handleSubmit} />
  );
};

SignUpForm.propTypes = {
  onComplete: PropTypes.func.isRequired
};

export default SignUpForm;
