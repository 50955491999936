import React from 'react';
import PropTypes from 'prop-types';
import { Container, Form, Icon, Popup } from 'semantic-ui-react';
import MultiMonthPicker from '../../../common/form/multiMonthPicker';
import BubbleFormField from '../../../common/form/bubbleFormField';
import YesNoFormField from '../../../common/form/yesNoFormField';
import PopupFormFieldArea from '../../../common/form/popupFormFieldArea';
import useLocale from '../../../../hooks/i18n';
import useFormHelper from '../../../../hooks/formHelper';
import PopupFormFieldSelect from '../../../common/form/popupFormFieldSelect';

const FORM_NAMES = {
  technical_scalability: 'scalability.technical_scalability',
  technical_scalability_challenges: 'scalability.technical_scalability_challenges',
  required_equipment: 'scalability.required_equipment',
  required_supplies: 'scalability.required_supplies',
  required_skills: 'scalability.required_skills',
  min_value: 'scalability.min_value',
  min_unit: 'scalability.min_unit',
  max_value: 'scalability.max_value',
  max_unit: 'scalability.max_unit',
  logistical_scalability: 'scalability.logistical_scalability',
  logistical_scalability_challenges: 'scalability.logistical_scalability_challenges',
  economical_scalability: 'scalability.economical_scalability',
  economical_scalability_challenges: 'scalability.economical_scalability_challenges',
  annual_availability: 'scalability.annual_availability',
  climate: 'scalability.id_climate'
};

const ScalabilityFormView = ({ renderAs, editable, form, climates }) => {
  const { t } = useLocale();
  const { resolveError, handleChangeWithValidation } = useFormHelper(form);
  const { getValues } = form;
  const ParentComponent = renderAs;

  const climateOptions = climates.map(({ id, name }) => {
    return {
      key: id,
      value: id,
      text: name
    };
  });

  return (
    <ParentComponent className="transformation-card scalability-form">
      <Container fluid={renderAs !== Form}>
        <YesNoFormField
          name={FORM_NAMES.technical_scalability}
          id={FORM_NAMES.technical_scalability}
          label={t('transformation.form.scalability.technical_scalability.label')}
          hint={t('transformation.form.scalability.technical_scalability.hint')}
          noLabel={t('transformation.form.ui.not_yet')}
          yesLabel={t('transformation.form.ui.yes')}
          onChange={handleChangeWithValidation}
          error={resolveError(FORM_NAMES.technical_scalability)}
          defaultValue={getValues(FORM_NAMES.technical_scalability)}
          disabled={!editable}
        />

        <PopupFormFieldArea
          name={FORM_NAMES.technical_scalability_challenges}
          id={FORM_NAMES.technical_scalability_challenges}
          label={t('transformation.form.scalability.technical_scalability_challenges.label')}
          hint={t('transformation.form.scalability.technical_scalability_challenges.hint')}
          onChange={handleChangeWithValidation}
          error={resolveError(FORM_NAMES.technical_scalability_challenges)}
          defaultValue={getValues(FORM_NAMES.technical_scalability_challenges)}
          disabled={!editable}
        />

        <BubbleFormField
          name={FORM_NAMES.required_equipment}
          id={FORM_NAMES.required_equipment}
          label={t('transformation.form.scalability.required_equipment.label')}
          hint={t('transformation.form.scalability.required_equipment.hint')}
          onChange={handleChangeWithValidation}
          error={resolveError(FORM_NAMES.required_equipment)}
          defaultValue={getValues(FORM_NAMES.required_equipment)}
          disabled={!editable}
        />

        <BubbleFormField
          name={FORM_NAMES.required_supplies}
          id={FORM_NAMES.required_supplies}
          label={t('transformation.form.scalability.required_supplies.label')}
          hint={t('transformation.form.scalability.required_supplies.hint')}
          onChange={handleChangeWithValidation}
          error={resolveError(FORM_NAMES.required_supplies)}
          defaultValue={getValues(FORM_NAMES.required_supplies)}
          disabled={!editable}
        />

        <BubbleFormField
          name={FORM_NAMES.required_skills}
          id={FORM_NAMES.required_skills}
          label={t('transformation.form.scalability.required_skills.label')}
          hint={t('transformation.form.scalability.required_skills.hint')}
          onChange={handleChangeWithValidation}
          error={resolveError(FORM_NAMES.required_skills)}
          defaultValue={getValues(FORM_NAMES.required_skills)}
          disabled={!editable}
        />

        <Form.Field>
          <label htmlFor="minimal_scale_production">
            {t('transformation.form.scalability.minimal_scale_production.label')}
          </label>
          <Popup trigger={<Icon name="help circle" />} position="right center">
            <p>{t('transformation.form.scalability.minimal_scale_production.hint')}</p>
          </Popup>

          <Form.Group inline id="minimal_scale_production" className="scale-production-container" widths="equal">
            <Form.Input
              fluid
              name={FORM_NAMES.min_value}
              id={FORM_NAMES.min_value}
              type="number"
              min={0}
              placeholder="100.00"
              label={t('transformation.form.scalability.minimal_scale_production.min_value.label')}
              onChange={handleChangeWithValidation}
              error={resolveError(FORM_NAMES.min_value)}
              defaultValue={getValues(FORM_NAMES.min_value)}
              disabled={!editable}
            />
            <Form.Input
              fluid
              name={FORM_NAMES.min_unit}
              id={FORM_NAMES.min_unit}
              type="text"
              placeholder="kg"
              label={t('transformation.form.scalability.minimal_scale_production.min_unit.label')}
              onChange={handleChangeWithValidation}
              error={resolveError(FORM_NAMES.min_unit)}
              defaultValue={getValues(FORM_NAMES.min_unit)}
              disabled={!editable}
              className="no-padding-field"
            />
          </Form.Group>
        </Form.Field>

        <Form.Field>
          <label htmlFor="maximum_production_volume">
            {t('transformation.form.scalability.maximum_production_volume.label')}
          </label>
          <Popup trigger={<Icon name="help circle" />} position="right center">
            <p>{t('transformation.form.scalability.maximum_production_volume.hint')}</p>
          </Popup>

          <Form.Group inline id="maximum_production_volume" className="scale-production-container" widths="equal">
            <Form.Input
              fluid
              name={FORM_NAMES.max_value}
              id={FORM_NAMES.max_value}
              type="number"
              min={0}
              placeholder="100.00"
              label={t('transformation.form.scalability.maximum_production_volume.max_value.label')}
              onChange={handleChangeWithValidation}
              error={resolveError(FORM_NAMES.max_value)}
              defaultValue={getValues(FORM_NAMES.max_value)}
              disabled={!editable}
            />
            <Form.Input
              fluid
              name={FORM_NAMES.max_unit}
              id={FORM_NAMES.max_unit}
              type="text"
              placeholder="kg"
              label={t('transformation.form.scalability.maximum_production_volume.max_unit.label')}
              onChange={handleChangeWithValidation}
              error={resolveError(FORM_NAMES.max_unit)}
              defaultValue={getValues(FORM_NAMES.max_unit)}
              disabled={!editable}
              className="no-padding-field"
            />
          </Form.Group>
        </Form.Field>

        <YesNoFormField
          name={FORM_NAMES.logistical_scalability}
          id={FORM_NAMES.logistical_scalability}
          label={t('transformation.form.scalability.logistical_scalability.label')}
          hint={t('transformation.form.scalability.logistical_scalability.hint')}
          noLabel={t('transformation.form.ui.not_yet')}
          yesLabel={t('transformation.form.ui.yes')}
          onChange={handleChangeWithValidation}
          error={resolveError(FORM_NAMES.logistical_scalability)}
          defaultValue={getValues(FORM_NAMES.logistical_scalability)}
          disabled={!editable}
        />

        <PopupFormFieldArea
          name={FORM_NAMES.logistical_scalability_challenges}
          id={FORM_NAMES.logistical_scalability_challenges}
          label={t('transformation.form.scalability.logistical_scalability_challenges.label')}
          hint={t('transformation.form.scalability.logistical_scalability_challenges.hint')}
          onChange={handleChangeWithValidation}
          error={resolveError(FORM_NAMES.logistical_scalability_challenges)}
          defaultValue={getValues(FORM_NAMES.logistical_scalability_challenges)}
          disabled={!editable}
        />

        <YesNoFormField
          name={FORM_NAMES.economical_scalability}
          id={FORM_NAMES.economical_scalability}
          label={t('transformation.form.scalability.economical_scalability.label')}
          noLabel={t('transformation.form.ui.not_yet')}
          yesLabel={t('transformation.form.ui.yes')}
          hint={t('transformation.form.scalability.economical_scalability.hint')}
          onChange={handleChangeWithValidation}
          error={resolveError(FORM_NAMES.economical_scalability)}
          defaultValue={getValues(FORM_NAMES.economical_scalability)}
          disabled={!editable}
        />

        <PopupFormFieldArea
          name={FORM_NAMES.economical_scalability_challenges}
          id={FORM_NAMES.economical_scalability_challenges}
          label={t('transformation.form.scalability.economical_scalability_challenges.label')}
          hint={t('transformation.form.scalability.economical_scalability_challenges.hint')}
          onChange={handleChangeWithValidation}
          error={resolveError(FORM_NAMES.economical_scalability_challenges)}
          defaultValue={getValues(FORM_NAMES.economical_scalability_challenges)}
          disabled={!editable}
        />

        <MultiMonthPicker
          name={FORM_NAMES.annual_availability}
          id={FORM_NAMES.annual_availability}
          label={t('transformation.form.scalability.annual_availability.label')}
          hint={t('transformation.form.scalability.annual_availability.hint')}
          onChange={handleChangeWithValidation}
          defaultValue={getValues(FORM_NAMES.annual_availability)}
          disabled={!editable}
        />

        <PopupFormFieldSelect
          label={t('transformation.form.scalability.climate.label')}
          hint={t('transformation.form.scalability.climate.hint')}
          id={FORM_NAMES.climate}
          name={FORM_NAMES.climate}
          options={climateOptions}
          onChange={handleChangeWithValidation}
          error={resolveError(FORM_NAMES.climate)}
          defaultValue={getValues(FORM_NAMES.climate)}
          disabled={!editable}
        />

        {/* This should be a JSON, we need a component for this. */}
        {/* <PopupFormFieldInput*/}
        {/*  label={t('transformation.form.scalability.geographical_location.label')}*/}
        {/*  hint={t('transformation.form.scalability.geographical_location.hint')}*/}
        {/*  id="geographical_location"*/}
        {/* />*/}
      </Container>
    </ParentComponent>
  );
};

ScalabilityFormView.propTypes = {
  renderAs: PropTypes.oneOfType([PropTypes.string, PropTypes.elementType]),
  editable: PropTypes.bool,
  climates: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string
  })).isRequired,
  form: PropTypes.shape({
    formState: PropTypes.shape({
      isSubmitting: PropTypes.bool
    }),
    handleSubmit: PropTypes.func,
    getValues: PropTypes.func
  }).isRequired
};

ScalabilityFormView.defaultProps = {
  renderAs: Form,
  editable: true
};

export default ScalabilityFormView;
