import React, { useEffect, useState } from 'react';
import { Progress } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import useLocale from '../../../hooks/i18n';

const ProgressBar = ({ page, totalPages }) => {
  const { t } = useLocale();

  const [percent, setPercent] = useState(0);
  useEffect(() => {
    setPercent(Math.ceil((page * 100) / totalPages));
  }, [page, totalPages]);

  return (
    <div className="progress-bar-container">
      <p className="progress-bar__header">{t('ui.progress')}</p>
      <div className="progress-bar__left">
        <span className="progress-bar__page">
          {t('ui.progress.page', { currentPage: page, pages: totalPages })}
        </span>
        <div>
          <Progress
            percent={percent}
            color="blue"
            className="progress-bar"
            size="tiny"
          />
        </div>
      </div>
    </div>
  );
};

ProgressBar.propTypes = {
  page: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired
};

export default ProgressBar;
