import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Form,
  Message,
  Container,
  Header,
  Grid,
  Icon, Button
} from 'semantic-ui-react';
import { useNavigate } from 'react-router-dom';
import CurateBasicInfoFormView from './views/CurateBasicInfoFormView';
import CurateTransformationProcessFormView from './views/CurateTransformationProcessFormView';
import CurateScalabilityFormView from './views/CurateScalabilityFormView';
import CurateSustainabilityFormView from './views/CurateSustainabilityFormView';
import CurateMarketFormView from './views/CurateMarketFormView';
import CurateRegulationsFormView from './views/CurateRegulationsFormView';
import VerticalSteps from '../../common/verticalSteps';
import ProgressBar from '../../common/progressBar';
import PaginatedFormButtonRow from '../../common/form/paginatedFormButtonRow';
import useLocale from '../../../hooks/i18n';
import BackButton from '../../common/backButton';
import { PROTECTED_ROUTES } from '../../../constants/routes';

const pages = [
  {
    title: 'transformation.form.basic_information.title',
    heading: 'transformation.form.basic_information.heading',
    ViewComponent: CurateBasicInfoFormView,
    requiredResources: [
      'source_types',
      'source_classes',
      'source_sub_classes',
      'trl_eu',
      'locations'
    ]
  },
  {
    title: 'transformation.form.transformation_process.title',
    heading: 'transformation.form.transformation_process.heading',
    ViewComponent: CurateTransformationProcessFormView,
    requiredResources: []
  },
  {
    title: 'transformation.form.scalability.title',
    heading: 'transformation.form.scalability.heading',
    ViewComponent: CurateScalabilityFormView,
    requiredResources: ['climates']
  },
  {
    title: 'transformation.form.sustainability.title',
    heading: 'transformation.form.sustainability.heading',
    ViewComponent: CurateSustainabilityFormView,
    requiredResources: ['sustainable_development_goals']
  },
  {
    title: 'transformation.form.market.title',
    heading: 'transformation.form.market.heading',
    ViewComponent: CurateMarketFormView,
    requiredResources: ['currencies']
  },
  {
    title: 'transformation.form.regulations.title',
    heading: 'transformation.form.regulations.heading',
    ViewComponent: CurateRegulationsFormView,
    requiredResources: []
  }
];

const CurateTransformationFormView = ({
  onCommit,
  onSave,
  onDelete,
  form,
  transformationForm,
  error,
  resources
}) => {
  const { t } = useLocale();
  const navigate = useNavigate();
  const {
    handleSubmit,
    formState: { isSubmitting }
  } = form;
  const [page, setPage] = useState(0);
  const { title, heading, ViewComponent, requiredResources } = pages[page];

  const resourceProps = requiredResources.reduce((obj, cur) => {
    obj[cur] = resources[cur];
    return obj;
  }, {});

  const handleVerticalStepClick = (index) => async () => {
    await handleSubmit(onSave)(form.getValues());
    setPage(index);
  };

  const handleBackClick = async () => {
    await handleSubmit(onSave)(form.getValues());
    navigate(PROTECTED_ROUTES.curations);
  };

  const verticalStepsOptions = pages.map(({ title }, index) => {
    return {
      title: `${index + 1}. ${t(title)}`,
      completed: false,
      active: page === index,
      onClick: handleVerticalStepClick(index)
    };
  });

  const handlePrevious = async () => {
    await handleSubmit(onSave)(form.getValues());
    setPage((prevState) => {
      return Math.max(0, prevState - 1);
    });
  };

  const handleNext = async () => {
    await handleSubmit(onSave)(form.getValues());
    setPage((prevState) => {
      return Math.min(pages.length - 1, prevState + 1);
    });
  };

  const handleFinish = async () => {
    await handleSubmit(onSave)(form.getValues());
    await handleSubmit(onCommit)(form.getValues());
  };

  return (
    <div className="transformation-form-container curate-transformation-form">
      <Grid className="transformation-form-view" columns={2}>
        <Grid.Column computer={5} tablet={5} mobile={16}>
          <BackButton
            text={t('curate_contribution.form.ui.save_and_exit')}
            className="form-view__back-btn"
            onClick={handleBackClick}
          />
          <VerticalSteps
            loading={isSubmitting}
            options={verticalStepsOptions}
          />
          <ProgressBar page={page + 1} totalPages={pages.length} />

          <Button className="delete-button" color="red" onClick={onDelete}>
            {t('curate_contribution.form.ui.delete')}
          </Button>
        </Grid.Column>
        <Grid.Column computer={11} tablet={11} mobile={16}>
          <div className="form-tab-container">
            <Header className="form-tab__title" as="h1" textAlign="left">
              {t(title)}
            </Header>
            <Message
              hidden={!error}
              error
              header={t('curate_contribution.form.error.header')}
              content={error}
            />
            <Message info>
              <Message.Header>
                <Icon name="info circle" color="green" size="large" />
                <span>{t('curate_contribution.form.heading.message.header')}</span>
              </Message.Header>
              <p>{t(heading)}</p>
            </Message>

            <Form error={!!error}>
              <Container fluid>
                <ViewComponent
                  transformationForm={transformationForm}
                  form={form}
                  {...resourceProps}
                />
              </Container>
            </Form>

            <PaginatedFormButtonRow
              onPrevious={handlePrevious}
              onFinish={handleFinish}
              onNext={handleNext}
              previousVisible={page !== 0}
              finishVisible={page === pages.length - 1}
              finishLabelKey="curate_contribution.form.ui.submit"
              loading={isSubmitting}
            />
          </div>
        </Grid.Column>
      </Grid>
    </div>
  );
};

CurateTransformationFormView.propTypes = {
  onCommit: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  form: PropTypes.shape({
    formState: PropTypes.shape({
      isSubmitting: PropTypes.bool
    }),
    handleSubmit: PropTypes.func,
    getValues: PropTypes.func
  }).isRequired,
  transformationForm: PropTypes.shape({
    formState: PropTypes.shape({
      isSubmitting: PropTypes.bool
    }),
    handleSubmit: PropTypes.func,
    getValues: PropTypes.func
  }).isRequired,
  resources: PropTypes.shape({
    climates: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string
      })
    ),
    currencies: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        unit: PropTypes.string
      })
    ),
    locations: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        short_name: PropTypes.string
      })
    ),
    source_classes: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        class: PropTypes.string
      })
    ),
    source_sub_classes: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        sub_class: PropTypes.string
      })
    ),
    source_types: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        type: PropTypes.string
      })
    ),
    trl_eu: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string
      })
    ),
    sustainable_development_goals: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        goal: PropTypes.string
      })
    )
  }).isRequired,
  error: PropTypes.string
};

CurateTransformationFormView.defaultProps = {
  error: null
};

export default CurateTransformationFormView;
