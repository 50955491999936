import React from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import RequestErrorMessage from '../../components/common/requestErrorMessage';
import Loading from '../../components/common/loading';
import CurateTransformationForm from '../../components/forms/curateTransformationForm';
import useAPI from '../../hooks/api';
import useLocale from '../../hooks/i18n';
import useTitle from '../../hooks/title';
import useOnUnmount from '../../hooks/onUnmount';

const EditCurationPage = () => {
  const { t } = useLocale();
  const { id } = useParams();
  useTitle(t('page_titles.curate_contribution', { id }));
  const { client, getFullResponseErrorMessage, queryClient } = useAPI();
  const request = client.curation.getCuration(id);
  const { isLoading, error, data } = useQuery(request.key, request.fn);

  useOnUnmount(() => {
    queryClient.removeQueries(request.key);
  });

  if (isLoading) {
    return (
      <Loading fullscreen />
    );
  }

  if (error) {
    return (
      <RequestErrorMessage header={t('curate_contribution.error.header')} error={getFullResponseErrorMessage(error)} />
    );
  }

  const { transformation, curation } = data;

  return (
    <CurateTransformationForm transformation={transformation} curation={curation} />
  );
};

export default EditCurationPage;
