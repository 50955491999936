import React, { useState } from 'react';
import AdminPageWrapper from '../../components/admin/adminPageWrapper';
import {
  AddResourceModal,
  EditResourceModal,
  ResourceManagementTable,
  ResourceTableRow
} from '../../components/admin/resourceManagement';
import { Icon, Message } from 'semantic-ui-react';
import useAPI from '../../hooks/api';
import useLocale from '../../hooks/i18n';
import { useQuery } from '@tanstack/react-query';
import Loading from '../../components/common/loading';
import RequestErrorMessage from '../../components/common/requestErrorMessage';
import omit from 'lodash.omit';

const AdminResourceManagementPage = () => {
  const { t } = useLocale();
  const { client, getFullResponseErrorMessage } = useAPI();
  const { currentLocale, AVAILABLE_LOCALES } = useLocale();
  const { [currentLocale]: _, ...REST_OF_LOCALES } = AVAILABLE_LOCALES;

  const LOCALE_KEY_1 = currentLocale;
  const LOCALE_KEY_2 = Object.keys(REST_OF_LOCALES)[0];
  const LOCALE_KEY_3 = Object.keys(REST_OF_LOCALES)[1];

  const resourcesRequestLocaleCurrent =
    client.resource.getAllResources(LOCALE_KEY_1);
  const resourcesRequestLocaleSecond =
    client.resource.getAllResources(LOCALE_KEY_2);
  const resourcesRequestLocaleThird =
    client.resource.getAllResources(LOCALE_KEY_3);

  const queryLocaleCurrent = useQuery(
    resourcesRequestLocaleCurrent.key,
    resourcesRequestLocaleCurrent.fn
  );
  const queryLocaleSecond = useQuery(
    resourcesRequestLocaleSecond.key,
    resourcesRequestLocaleSecond.fn
  );
  const queryLocaleThird = useQuery(
    resourcesRequestLocaleThird.key,
    resourcesRequestLocaleThird.fn
  );

  const loadingQueries =
    queryLocaleCurrent.isLoading ||
    queryLocaleSecond.isLoading ||
    queryLocaleThird.isLoading;

  const [page, setPage] = useState(0);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openAddModal, setOpenAddModal] = useState(false);
  const [selectedResource, setSelectedResource] = useState(null);

  if (loadingQueries) {
    return <Loading fullscreen />;
  }

  if (queryLocaleCurrent.error) {
    return (
      <RequestErrorMessage
        header={t('admin.resource_management.errors.header')}
        error={getFullResponseErrorMessage(queryLocaleCurrent.error)}
      />
    );
  }

  if (queryLocaleSecond.error) {
    return (
      <RequestErrorMessage
        header={t('admin.resource_management.errors.header')}
        error={getFullResponseErrorMessage(queryLocaleSecond.error)}
      />
    );
  }

  if (queryLocaleThird.error) {
    return (
      <RequestErrorMessage
        header={t('admin.resource_management.errors.header')}
        error={getFullResponseErrorMessage(queryLocaleThird.error)}
      />
    );
  }

  const handleEditClicked = (resource) => {
    setOpenEditModal(true);
    setSelectedResource(resource);
  };

  const resourcesLocaleCurrent = queryLocaleCurrent.data;
  const resourcesLocaleSecond = queryLocaleSecond.data;
  const resourcesLocaleThird = queryLocaleThird.data;

  const {
    climates,
    currencies,
    locations,
    source_classes,
    source_sub_classes,
    source_types,
    trl_eu,
    sustainable_development_goals
  } = resourcesLocaleCurrent;

  const pages = [
    {
      title: t('admin.resource_management.table.titles.climates'),
      tableRow: climates.map((climate) => (
        <ResourceTableRow
          key={climate.id}
          id={climate.id}
          text={climate.name}
          onEditClick={() => {
            handleEditClicked(climate);
          }}
        />
      )),
      key: 'climates'
    },
    {
      title: t('admin.resource_management.table.titles.currencies'),
      tableRow: currencies.map((currency) => (
        <ResourceTableRow
          key={currency.id}
          id={currency.id}
          text={currency.name}
          onEditClick={() => {
            handleEditClicked(currency);
          }}
        />
      )),
      key: 'currencies'
    },
    {
      title: t('admin.resource_management.table.titles.source_classes'),
      tableRow: source_classes.map((sourceClass) => (
        <ResourceTableRow
          key={sourceClass.id}
          id={sourceClass.id}
          text={sourceClass.class}
          onEditClick={() => {
            handleEditClicked(sourceClass);
          }}
        />
      )),
      key: 'source_classes'
    },
    {
      title: t('admin.resource_management.table.titles.source_sub_classes'),
      tableRow: source_sub_classes.map((sourceSubclass) => (
        <ResourceTableRow
          key={sourceSubclass.id}
          id={sourceSubclass.id}
          text={sourceSubclass.sub_class}
          onEditClick={() => {
            handleEditClicked(sourceSubclass);
          }}
        />
      )),
      key: 'source_sub_classes'
    },
    {
      title: t('admin.resource_management.table.titles.source_types'),
      tableRow: source_types.map((type) => (
        <ResourceTableRow
          key={type.id}
          id={type.id}
          text={type.type}
          onEditClick={() => {
            handleEditClicked(type);
          }}
        />
      )),
      key: 'source_types'
    },
    {
      title: t('admin.resource_management.table.titles.trl_eu'),
      tableRow: trl_eu.map((trl) => (
        <ResourceTableRow
          key={trl.id}
          id={trl.id}
          text={trl.name}
          onEditClick={() => {
            handleEditClicked(trl);
          }}
        />
      )),
      key: 'trl_eu'
    },
    {
      title: t('admin.resource_management.table.titles.sustainable_development_goals'),
      tableRow: sustainable_development_goals.map(
        (sustainableDevelopmentGoal) => (
          <ResourceTableRow
            key={sustainableDevelopmentGoal.id}
            id={sustainableDevelopmentGoal.id}
            text={sustainableDevelopmentGoal.goal}
            onEditClick={() => {
              handleEditClicked(sustainableDevelopmentGoal);
            }}
          />
        )
      ),
      key: 'sustainable_development_goals'
    },
    {
      title: t('admin.resource_management.table.titles.locations'),
      tableRow: locations.map((location) => (
        <ResourceTableRow
          key={location.id}
          id={location.id}
          text={location.name}
          onEditClick={() => {
            handleEditClicked(location);
          }}
        />
      )),
      key: 'locations'
    }
  ];

  const handleConfirm = () => {
    setSelectedResource(null);
    setOpenEditModal(false);
  };

  const handleAddResourceModalClose = () => {
    setOpenAddModal(false);
  };

  const handleAddResourceModalConfirm = () => {
    setOpenAddModal(false);
  };

  const handleAddResourceClicked = () => {
    setOpenAddModal(true);
  };

  return (
    <AdminPageWrapper page="resource_management">
      <div className="resource-management">
        <h1>{t('admin.resource_management.header')}</h1>
        <Message info>
          <Message.Header>
            <Icon name="info circle" color="green" size="large" />
            <span>{t('admin.resource_management.info_message.title')}</span>
          </Message.Header>
          <p>{t('admin.resource_management.info_message.description')}</p>
        </Message>
        <ResourceManagementTable
          pages={pages}
          page={page}
          setPage={setPage}
          onAddResourceClicked={handleAddResourceClicked}
        />
      </div>
      {selectedResource && (
        <EditResourceModal
          open={openEditModal}
          onClose={(e) => {
            e.preventDefault();
            setSelectedResource(null);
            setOpenEditModal(false);
          }}
          onConfirm={handleConfirm}
          header={t('admin.resource_management.edit_modal.header', {
            category: pages[page].title,
            id: selectedResource.id
          })}
          currentFormID={page}
          selectedResource={{
            [LOCALE_KEY_1]: omit(selectedResource, ['id', 'id_language']),
            [LOCALE_KEY_2]: omit(
              resourcesLocaleSecond[pages[page].key].find(
                (resource) => resource.id === selectedResource.id
              ),
              ['id', 'id_language']
            ),
            [LOCALE_KEY_3]: omit(
              resourcesLocaleThird[pages[page].key].find(
                (resource) => resource.id === selectedResource.id
              ),
              ['id', 'id_language']
            )
          }}
          resourceId={selectedResource.id}
        />
      )}

      <AddResourceModal
        onClose={handleAddResourceModalClose}
        onConfirm={handleAddResourceModalConfirm}
        currentFormID={page}
        header={t('admin.resource_management.add_modal.header', {
          category: pages[page].title
        })}
        open={openAddModal}
      />

    </AdminPageWrapper>
  );
};

export default AdminResourceManagementPage;
