import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { DEFAULT_LOCALE } from '../i18n';

const LOCAL_STORAGE_LOCALE_KEY = 'locale';

const I18nContext = React.createContext(null);

const I18nContextProvider = ({ children }) => {
  const [locale, setLocale] = useState(localStorage.getItem(LOCAL_STORAGE_LOCALE_KEY) || DEFAULT_LOCALE);

  const persistSetLocale = (newLocale) => {
    setLocale(newLocale);
    localStorage.setItem(LOCAL_STORAGE_LOCALE_KEY, newLocale);
  };

  const context = {
    locale, setLocale: persistSetLocale
  };

  return (
    <I18nContext.Provider value={context}>
      {children}
    </I18nContext.Provider>
  );
};

I18nContextProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default I18nContext;

export {
  I18nContextProvider
};
