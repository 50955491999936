import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Segment } from 'semantic-ui-react';
import { ScalabilityFormView } from '../../transformationForm';
import AutoResizeTextArea from '../../../common/form/autoResizeTextArea';
import useLocale from '../../../../hooks/i18n';
import useFormHelper from '../../../../hooks/formHelper';

const TEXT_AREA_LABEL = 'comment_scalability';

const CurateScalabilityFormView = ({ transformationForm, form, climates }) => {
  const { t } = useLocale();
  const { handleChangeNoValidation, handleBlurValidate, resolveError } = useFormHelper(form);
  const { getValues } = form;

  return (
    <Fragment>
      <ScalabilityFormView
        renderAs={Segment}
        editable={false}
        form={transformationForm}
        climates={climates}
      />

      <AutoResizeTextArea
        name={TEXT_AREA_LABEL}
        id={TEXT_AREA_LABEL}
        label={t('curate_contribution.form.comment_scalability.label')}
        onChange={handleChangeNoValidation}
        onBlur={handleBlurValidate}
        error={resolveError(TEXT_AREA_LABEL)}
        defaultValue={getValues(TEXT_AREA_LABEL)}
      />
    </Fragment>
  );
};

CurateScalabilityFormView.propTypes = {
  transformationForm: PropTypes.shape({
    formState: PropTypes.shape({
      isSubmitting: PropTypes.bool
    }),
    handleSubmit: PropTypes.func,
    getValues: PropTypes.func
  }).isRequired,
  form: PropTypes.shape({
    formState: PropTypes.shape({
      isSubmitting: PropTypes.bool
    }),
    handleSubmit: PropTypes.func,
    getValues: PropTypes.func
  }).isRequired,
  climates: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string
  })).isRequired
};

export default CurateScalabilityFormView;
