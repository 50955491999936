import React from 'react';
import PropTypes from 'prop-types';
import { Message } from 'semantic-ui-react';

const RequestErrorMessage = ({ header, error }) => {
  return (
    <Message
      header={header}
      error
      content={error}
    />
  );
};

RequestErrorMessage.propTypes = {
  header: PropTypes.string.isRequired,
  error: PropTypes.string.isRequired
};

export default RequestErrorMessage;
