/* eslint-disable max-lines */

const PAGE_TITLES = {
  'page_titles.home': 'Home',
  'page_titles.login': 'Iniciar sesión',
  'page_titles.forgot_password': '¿Olvidó su contraseña?',
  'page_titles.my_contributions': 'Mis contribuciones',
  'page_titles.not_found': 'Página no encontrada',
  'page_titles.coming_soon': 'Próximamente',
  'page_titles.resend_verification': 'Reenviar su código de verificación',
  'page_titles.sign_up': 'Regístrate',
  'page_titles.user_settings': 'Configuración de usuario',
  'page_titles.awaiting_curation': '{count, plural, one {1 Transformación necesita} other {# Transformaciones necesitan} curación',
  'page_titles.admin_dashboard': 'Dashboard Administrador',
  'page_titles.curate_contribution': 'Curando transformación {id}',
  'page_titles.edit_contribution': 'Editando transformación {id}',
  'page_titles.become_curator': 'Conviértete en curador',
  'page_titles.become_curator_application': 'Formulario de aplicación para convertirse en curador',
  'page_titles.explore.no_query': 'Explorar transformaciones',
  'page_titles.explore.with_query': 'Buscando {query}',
  'page_titles.loading': 'Cargando...',
  'page_titles.terms_and_conditions': 'Términos y condiciones',
  'page.titles.about': 'Sobre nosotros'
};

const NAVBAR = {
  'navbar.home': 'Inicio',
  'navbar.explore': 'Explorar',
  'navbar.about': 'Sobre nosotros',
  'navbar.login': 'Iniciar sesión',
  'navbar.signup': 'Regístrate',
  'navbar.profile': 'Perfil',
  'navbar.admin': 'Administrador',
  'navbar.become_curator': 'Conviértete en curador',
  'navbar.settings': 'Ajustes',
  'navbar.sign_out': 'Salir',
  'navbar.curations': 'Curar',
  'navbar.publish': 'Publicar',
  'navbar.login_signup': 'Iniciar sesión / Registrarse',
  'navbar.get_involved': 'Involúcrate',

  'navbar.dropdown.profile': 'Perfil',
  'navbar.dropdown.settings': 'Ajustes',
  'navbar.dropdown.sign_out': 'Salir',
  'navbar.dropdown.curations': 'Curar',
  'navbar.dropdown.publish': 'Publicar',
  'navbar.dropdown.admin': 'Administrador',
  'navbar.dropdown.become_curator': 'Conviértete en curador',
  'navbar.dropdown.explore.opportunities.title': 'Oportunidades',
  'navbar.dropdown.explore.opportunities.description': 'Crowdsourcing, buscar y compartir',
  'navbar.dropdown.explore.publish.title': 'Publicar',
  'navbar.dropdown.explore.publish.description': 'Contribuir con transformaciones',
  'navbar.dropdown.about.faq.title': 'Preguntas frecuentes',
  'navbar.dropdown.about.faq.description': 'Soluciona tus dudas',
  'navbar.dropdown.about.contact.title': 'Contáctanos',
  'navbar.dropdown.about.contact.description': 'Nuestros canales de comunicación',
  'navbar.dropdown.get_involved.partnership.title': '¡Asóciate!',
  'navbar.dropdown.get_involved.partnership.description': 'Descubre cómo puedes ser parte del equipo.',

  'navbar.search.placeholder': 'Búsqueda en Transformar...',
  'navbar.mobile.my_profile': 'Mi perfil',
  'navbar.mobile.contribute': 'Contribuir',
  'navbar.mobile.admin': 'Administrador',
  'navbar.mobile.admin.dashboard': 'Dashboard',
  'navbar.mobile.admin.curator_requests': 'Solicitudes de curator',
  'navbar.mobile.admin.resource_management': 'Gestión de los recursos'
};

const HOME = {
  'home.first_section.header': 'Transformar, para un futuro sostenible.',
  'home.first_section.subheader': 'La investigación de hoy, la innovación de mañana.',
  'home.first_section.btn.publish': 'Publicar',
  'home.first_section.hero': 'Contribuir y beneficiarse del primer ecosistema de conocimiento abierto sobre oportunidades para la transformación sostenible de la biomasa en América Latina y el Caribe.',

  'home.second_section.header': 'Explorar o Publicar',
  'home.second_section.subheader': 'También puedes explorar y publicar oportunidades para transformar la biomasa en productos de base biológica, basados en investigación científica.',

  'home.second_section.card.header.1': 'Explorar',
  'home.second_section.card.description.1': 'Encuentre conocimientos científicos sobre oportunidades para transformar la biomasa en productos de base biológica.',
  'home.second_section.card.header.2': 'Publicar',
  'home.second_section.card.description.2': '¿Le gustaría compartir su conocimiento sobre cómo transformar la biomasa para usos industriales? Publica tu investigación y conviertete en un contribuyente.',
  'home.second_section.card.header.3': 'Verificar',
  'home.second_section.card.description.3': 'Cuanto más contribuyas, más alto aparecerá tu nombre e institución en nuestro ranking. ¡Te invitamos a crear una oportunidad increíble para revelarte al mundo!',

  'home.third_section.objective.header': 'Nuestro objetivo',
  'home.third_section.objective.description': 'Crear el mayor catálogo abierto de oportunidades para la transformación sostenible de la biomasa en productos de base biológica, por crowdsourcing de conocimiento científico que hoy se fragmenta y se dispersa en revistas y publicaciones técnicas. Este catálogo abierto está dirigido a beneficiar a diferentes tipos de interesados en América Latina y el Caribe:',
  'home.third_section.icons.private_firms': 'Firmas privadas',
  'home.third_section.icons.public_institutions': 'Instituciones públicas',
  'home.third_section.icons.startups': 'Startups',
  'home.third_section.icons.scientific_community': 'Comunidad científica',
  'home.third_section.icons.civil_society': 'Sociedad civil',

  'home.third_section.steps.item.header.1': '1. Regístrate',
  'home.third_section.steps.item.subheader.1': 'Únete a Transformar y accede a la información revisada y curada por expertos en materia temática.',
  'home.third_section.steps.item.btn.signed_in': 'Ya eres parte de la comunidad',
  'home.third_section.steps.item.btn.signup': 'Regístrate',
  'home.third_section.steps.item.btn.login': 'Iniciar sesión',
  'home.third_section.steps.item.header.2': '2. Explora las oportunidades',
  'home.third_section.steps.item.subheader.2': 'Sumérgete en un bosque virtual de oportunidades para transformar la biomasa en productos de base biológica a través de consultas de búsqueda.',
  'home.third_section.steps.item.header.3': '3. Comparte tu conocimiento',
  'home.third_section.steps.item.subheader.3': 'Comparte tu conocimiento y sé parte de la comunidad virtual de expertos. Estás contribuyendo a la compartición de conocimientos y toma de decisiones que puedan beneficiar a la sociedad civil, investigadores, empresas, instituciones y también a nuestros recursos biológicos renovables.',
  'home.third_section.steps.item.header.4': '4. Revisa y cura',
  'home.third_section.steps.item.subheader.4': 'Revisar y curar el contenido de oportunidades aportadas por otros, ayudando a mantener la calidad del catálogo.',
  'home.third_section.steps.item.header.5': '5. Tráelo a la vida',
  'home.third_section.steps.item.subheader.5': 'Utilice los datos para adelantarse a la curva. Hecho para estudios, negocios, proyectos y más.'
};

const ABOUT = {
  'about.first_section.header': 'El objetivo de Transformar es facilitar el acceso a las oportunidades científicas sobre la transformación de los recursos biológicos renovables en productos basados en biotecnología',
  'about.first_section.subheader': 'Juntos podemos construir y curar un catálogo abierto de oportunidades que informará a los responsables de las decisiones en América Latina y el Caribe.',

  'about.second_section.header': '¿Qué es Transformar?',
  'about.second_section.description': 'Transformar es una plaza pública digital para el intercambio abierto de conocimientos sobre oportunidades para la transformación sostenible de los recursos biológicos renovables en productos de base biológica. Su objetivo es apoyar la toma de decisiones de los diferentes actores de la bioeconomía en América Latina y el Caribe: instituciones públicas, comunidad de investigación, sector privado, sociedad civil, inversionistas, donadores e instituciones financieras.',

  'about.third_section.header': 'Organización',
  'about.third_section.subheader': 'Este proyecto fue diseñado y patrocinado por el Banco Interamericano de Desarrollo, y desarrollado y ahora acogido por la Universidad San Francisco de Quito.',

  'about.third_section.organizations.idb': 'Banco Interamericano de Desarrollo',
  'about.third_section.organizations.idb.description': 'El Banco Interamericano de Desarrollo está dedicado a mejorar vidas. Fundada en 1959, el BID es una fuente líder de financiamiento a largo plazo para el desarrollo económico, social e institucional en América Latina y el Caribe. El BID también realiza investigaciones de vanguardia y proporciona asesoramiento normativo, asistencia técnica y capacitación a clientes del sector público y privado de toda la región.',
  'about.third_section.organizations.usfq': 'Universidad San Francisco de Quito',
  'about.third_section.organizations.usfq.description': 'Equipo de proyecto',

  'about.fourth_section.faq.header': 'Preguntas frecuentes (FAQ)',
  'about.fourth_section.faq.subheader': 'Respondemos a sus preguntas. Aquí están las preguntas más frecuentes de nuestra comunidad.',
  'about.fourth_section.faq.pdf.subheader': 'Si eres un colaborador de Transformar por primera vez o un partidario de largo tiempo, entendemos que puedes tener preguntas sobre nuestra plataforma. Por eso hemos creado este recurso integral para ayudarte a encontrar las respuestas que necesitas con rapidez y facilidad.',
  'about.fourth_section.faq.pdf.btn.label': 'Descargar en PDF',
  'about.fourth_section.faq.pdf.filename': 'Preguntas frecuentes - Transformar',
  'about.fourth_section.faq.pdf.quality_criteria_guide.filename': 'Guía de calidad de datos - Transformar',

  'about.fourth_section.faq.content.first.question': '¿Cómo crear mi primera publicación?',
  'about.fourth_section.faq.content.first.answer': 'Para compartir tu primera publicación, debes iniciar sesión en Transformar con tu cuenta. Si aún no estás registrado, te invitamos a hacerlo. Una vez en la página principal, debe hacer clic en "Publicar" y se mostrará una ventana donde se puede ver: sus transformaciones en progreso, los cambios requeridos por los curadores, y sus envíos en el proceso de curación. A continuación, haga clic en "Añadir nuevo" y podrá acceder a los campos para completar su publicación.',
  'about.fourth_section.faq.content.second.question': '¿Qué significa hacer una nueva publicación?',
  'about.fourth_section.faq.content.second.answer.1': 'Su publicación le permite compartir oportunidades para el uso y transformación de la biomasa en productos de valor comercial. Para ello, se han propuesto 6 pestañas para completar la información de este objetivo.',
  'about.fourth_section.faq.content.second.answer.2': 'Las 6 fichas de información son:',
  'about.fourth_section.faq.content.second.answer.tabs.1': 'Información básica',
  'about.fourth_section.faq.content.second.answer.tabs.2': 'Proceso de transformación',
  'about.fourth_section.faq.content.second.answer.tabs.3': 'Escalabilidad',
  'about.fourth_section.faq.content.second.answer.tabs.4': 'Desarrollo sostenible',
  'about.fourth_section.faq.content.second.answer.tabs.5': 'Potencial de mercado',
  'about.fourth_section.faq.content.second.answer.tabs.6': 'Otras consideraciones',
  'about.fourth_section.faq.content.second.answer.3': 'Al llenar cada una de estas pestañas con la información solicitada, el progreso de su publicación será completado.',
  'about.fourth_section.faq.content.third.question': '¿Qué características debe tener mi proyecto para poder publicarlo en Transformar?',
  'about.fourth_section.faq.content.third.answer': 'Su proyecto debe compartir una oportunidad para el uso y transformación de la biomasa primaria y residual en productos de valor comercial, relevantes para los países de América Latina y el Caribe basados en abundante biomasa en la región. Su proyecto puede estar ya sea en la fase de investigación aplicada (prototipo) o en la fase de producción (comercial), o disponible para ser adoptado en producción. Por otra parte, debe haber documentado pruebas que le proporcionen apoyo suficiente y una madurez tecnológica superior o igual a la TRL5.',
  'about.fourth_section.faq.content.fourth.question': '¿Cómo puedo participar en el proceso de revisión de la calidad de las publicaciones (oportunidades)?',
  'about.fourth_section.faq.content.fourth.answer': 'Si deseas participar en el proceso de revisión y evaluación, debes aplicar para convertirte en un curador. Encontrarás esta opción en el menú principal. Una vez que tu solicitud haya sido aprobada, recibirás un correo electrónico de notificación. Ten en cuenta que participarás en el proceso de revisión para asegurar que la información haya aprobado un estándar de calidad mínimo. Sin embargo, el curador no es responsable de asegurar que la información en los artículos científicos mencionados sea correcta, ya que esta es la responsabilidad de los revisores de las revistas donde se publicó la investigación original.',
  'about.fourth_section.faq.content.fifth.question': '¿Cómo se realiza el proceso de revisión y evaluación de la calidad de la información?',
  'about.fourth_section.faq.content.fifth.answer': 'El curador de información procederá a revisar una oportunidad basada en los criterios de calidad de la plataforma. Estos criterios de calidad tienen como objetivo analizar la información sobre las oportunidades e identificar a aquellos con un potencial más significativo basado en criterios de viabilidad técnica y valor económico, social y ambiental. Estos criterios incluyen un grupo que evalúa si la contribución puede publicarse en Transformar. En cambio, un segundo grupo se centra en evaluar la calidad de la contribución en términos de su potencial.',
  'about.fourth_section.faq.content.fifth.answer.download_quality_criteria_guide': 'Descargue aquí la guía de criterios de calidad.',

  'about.fifth_section.contact.header': '¡Contáctanos!',
  'about.fifth_section.contact.subheader': '¿Alguna pregunta o comentario? ¡Escríbanos un mensaje!',
  'about.fifth_section.contact.socials.title': 'Sigue nuestras redes sociales',
  'about.fifth_section.contact.email.title': 'Envíanos un correo electrónico'
};

const SIGNUP = {
  'signup.header': 'Bienvenido a Transformar™',
  'signup.hero.text': 'Contribuye y benefíciate del primer ecosistema de conocimiento abierto sobre oportunidades para la transformación sostenible de la biomasa en América Latina y el Caribe.',
  'signup.already.registered': '¿Ya tienes una cuenta?',
  'signup.login': 'Iniciar sesión',

  'signup.form.email': 'Correo electrónico',
  'signup.form.username': 'Nombre de usuario',
  'signup.form.password': 'Contraseña',
  'signup.form.password_confirm': 'Confirmar contraseña',
  'signup.form.given_name': 'Nombre',
  'signup.form.family_name': 'Apellido',
  'signup.form.institution_name': 'Nombre de la institución',
  'signup.form.institution_position': 'Posición en la institución',
  'signup.form.professional_affiliation': 'Afiliación profesional',
  'signup.form.location_country': 'País',
  'signup.form.location_province': 'Estado',
  'signup.form.location_city': 'Ciudad',

  'signup.errors.email.invalid': 'El Correo electrónico inválido',
  'signup.errors.email.max': 'El correo electrónico no puede ser superior a 256 caracteres',
  'signup.errors.email.required': 'El correo electrónico no puede estar vacío',

  'signup.errors.username.length': 'El nombre de usuario debe ser entre 3 y 20 caracteres de largo',
  'signup.errors.username.disallowed': 'El nombre de usuario no puede ser "me"',
  'signup.errors.username.pattern': 'El nombre de usuario sólo puede contener letras, números y los caracteres: . _ -',
  'signup.errors.username.required': 'El nombre de usuario no puede estar vacío',

  'signup.errors.given_name.length': 'El nombre debe ser entre 1 y 45 caracteres de largo',
  'signup.errors.given_name.required': 'El nombre no puede estar vacío',

  'signup.errors.family_name.length': 'El apellido debe ser entre 1 y 45 caracteres de largo',
  'signup.errors.family_name.required': 'El apellido no puede estar vacío',

  'signup.errors.institution_name.max': 'El nombre de la institución no puede ser superior a 256 caracteres',
  'signup.errors.institution_name.required': 'El nombre de la institución no puede estar vacío',

  'signup.errors.institution_position.max': 'La posición en la institución no puede ser superior a 256 caracteres',
  'signup.errors.institution_position.required': 'La posición en la institución no puede estar vacía',

  'signup.errors.professional_affiliation.max': 'La afiliación profesional no puede ser superior a 256 caracteres',
  'signup.errors.professional_affiliation.required': 'La afiliación profesional no puede estar vacía',

  'signup.errors.location_country.max': 'El país no puede ser superior a 256 caracteres',
  'signup.errors.location_country.required': 'El país no puede estar vacío',

  'signup.errors.location_province.max': 'El estado no puede ser superior a 256 caracteres',
  'signup.errors.location_province.required': 'El estado no puede estar vacío',

  'signup.errors.location_city.max': 'La ciudad no puede ser superior a 256 caracteres',
  'signup.errors.location_city.required': 'La ciudad no puede estar vacía',

  'signup.errors.password.special_chars': 'La contraseña debe contener al menos 1 carácter especial',
  'signup.errors.password.lowercase': 'La contraseña debe contener al menos 1 minúsucla',
  'signup.errors.password.uppercase': 'La contraseña debe contener al menos 1 mayúscula',
  'signup.errors.password.white_spaces': 'La contraseña no puede contener espacios',
  'signup.errors.password.required': 'La contraseña no puede estar vacía',

  'signup.errors.password_confirm.different': 'Debe ser igual a la contraseña',
  'signup.errors.password_confirm.required': 'La confirmación de la contraseña no puede estar vacía',

  'signup.signup': 'Regístrate',

  'signup.errors.header': 'No pudimos registrarte',

  'signup.post.header': 'Gracias por registrarte {name}!',
  'signup.post.description': 'Por favor, comprueba tu buzón de entrada ({email}) por un correo electrónico de verificación y sigue las instrucciones incluidas.',
  'signup.post.buttons.login': '¿Ya estás verificado? Haz clic aquí para iniciar sesión',
  'signup.post.buttons.resend_code': '¿No recibiste ningún código de verificación?'
};

const LOGIN = {
  'login.header': '¡Bienvenido!',
  'login.hero.text': 'Inicia sesión en tu cuenta Transformar™ y sigue participando y beneficiándote de compartir conocimientos sobre oportunidades para la transformación de la biomasa.',

  'login.form.email': 'Correo electrónico',
  'login.form.password': 'Contraseña',
  'login.form.stay_signed_in': 'Manténte registrado',

  'login.errors.email.invalid': 'Correo electrónico inválido',
  'login.errors.email.required': 'El correo electrónico no puede estar vacío',

  'login.errors.password.required': 'La contraseña no puede estar vacía',

  'login.login': 'Iniciar sesión',

  'login.dont.have.account': '¿No tienes una cuenta?',
  'login.register': 'Regístrate aquí',

  'login.links.forgot_password': '¿Olvidaste tu contraseña?',
  'login.links.resend_verification': '¿No recibiste tu código de verificación?',

  'login.errors.header': 'No pudimos registrarte'
};

const AUTH = {
  'auth.generic.errors.validation': 'Una validación necesaria falló.',
  'auth.generic.errors.unauthorized': 'No está autorizado para hacer esto.',

  'auth.login.errors.external': 'Algo inesperado sucedió cuando intentaste registrarte. Por favor, intenta de nuevo más tarde.',
  'auth.login.errors.internal': 'Algo inesperado sucedió cuando intentaste registrarte. Si ves este mensaje, contáctate con un administrador del sistema.',
  'auth.login.errors.wrong_credentials': 'El correo electrónico o la contraseña pueden ser incorrectos. Revisa tus credenciales e inténtalo de nuevo.',
  'auth.login.errors.password_reset_required': 'Por favor, reinicia tu contraseña.',
  'auth.login.errors.rate_limit': 'Intentaste registrarte demasiadas veces, por favor, espera y vuelve a intentarlo más tarde.',
  'auth.login.errors.confirmation_required': 'Su correo electrónico todavía no ha sido verificado. Por favor, compruebe su buzón de entrada.',
  'auth.login.errors.unknown': 'Un error desconocido ocurrió cuando intentaste registrarte. Si ves este mensaje, contáctate con un administrador del sistema.',

  'auth.signup.errors.external': 'Algo inesperado sucedió cuando intentaste registrarte. Por favor, intenta de nuevo más tarde.',
  'auth.signup.errors.internal': 'Algo inesperado sucedió cuando intentaste registrarte. Si ves este mensaje, contáctate con un administrador del sistema.',
  'auth.signup.errors.invalid_password': 'La contraseña que intentaste usar no es lo suficientemente segura.',
  'auth.signup.errors.rate_limit': 'Intentaste registrarte demasiadas veces, por favor, espera y vuelve a intentarlo más tarde.',
  'auth.signup.errors.email_exists': 'Este correo electrónico ya está en uso.',
  'auth.signup.errors.username_exists': 'Este nombre de usuario ya está en uso.',
  'auth.signup.errors.unknown': 'Un error desconocido ocurrió cuando intentaste registrarte. Si ves este mensaje, contáctate con un administrador del sistema.',

  'auth.confirm_signup.errors.external': 'Algo inesperado sucedió cuando intentaste confirmar tu registro. Por favor, intenta de nuevo más tarde.',
  'auth.confirm_signup.errors.internal': 'Algo inesperado sucedió cuando intentaste confirmar tu registro. Si ves este mensaje, contáctate con un administrador del sistema.',
  'auth.confirm_signup.errors.already_exists': 'Este correo electrónico ya está en uso.',
  'auth.confirm_signup.errors.wrong_code': 'El código que proporcionó es incorrecto. Asegúrese de que ha insertado el código correcto e inténtelo de nuevo.',
  'auth.confirm_signup.errors.code_expired': 'El código proporcionado ha expirado. Solicite otro código de verificación e inténtelo de nuevo.',
  'auth.confirm_signup.errors.rate_limit': 'Intentaste confirmar tu registro demasiadas veces, por favor, espera y vuelve a intentarlo más tarde.',
  'auth.confirm_signup.errors.email_not_found': 'Este correo electrónico no es utilizado por ninguna cuenta.',
  'auth.confirm_signup.errors.unknown': 'Un error desconocido ocurrió cuando intentaste confirmar tu registro. Si ves este mensaje, contáctate con un administrador del sistema.',

  'auth.resend_verification_code.errors.external': 'Algo inesperado sucedió al tratar de reenviarte un código de verificación. Por favor, intenta de nuevo más tarde.',
  'auth.resend_verification_code.errors.internal': 'Algo inesperado sucedió al tratar de reenviarte un código de verificación. Si ves este mensaje, contáctate con un administrador del sistema.',
  'auth.resend_verification_code.errors.delivery_failure': 'No se puede enviar el código de verificación solicitado. Inténtalo de nuevo más tarde.',
  'auth.resend_verification_code.errors.rate_limit': 'Intentaste reenviar un código de verificación demasiadas veces, por favor, espera y vuelve a intentarlo más tarde.',
  'auth.resend_verification_code.errors.unknown': 'Un error desconocido ocurrió al intentar reenviarte un código de verificación. Si ves este mensaje, contáctate con un administrador del sistema.',

  'auth.global_sign_out.errors.external': 'Algo inesperado sucedió cuando intentaste desconectarte de todos los dispositivos. Por favor, intenta de nuevo más tarde.',
  'auth.global_sign_out.errors.internal': 'Algo inesperado sucedió cuando intentaste desconectarte de todos los dispositivos. Si ves este mensaje, contáctate con un administrador del sistema.',
  'auth.global_sign_out.errors.password_reset_required': 'Necesita restablecer su contraseña antes de desconectarse de todos los dispositivos.',
  'auth.global_sign_out.errors.rate_limit': 'Intentaste desconectarte de todos los dispositivos demasiadas veces, por favor, espera y vuelve a intentarlo más tarde.',
  'auth.global_sign_out.errors.unknown': 'Un error desconocido ocurrió cuando intentaste desconectarte de todos los dispositivos. Si ves este mensaje, contáctate con un administrador del sistema.',

  'auth.change_password.errors.external': 'Algo inesperado sucedió al intentar cambiar tu contraseña. Por favor, intenta de nuevo más tarde.',
  'auth.change_password.errors.internal': 'Algo inesperado sucedió al intentar cambiar tu contraseña. Si ves este mensaje, contáctate con un administrador del sistema.',
  'auth.change_password.errors.invalid_password': 'Tu nueva contraseña es inválida. Asegúrate de que sea lo suficientemente segura e inténtalo de nuevo.',
  'auth.change_password.errors.password_reset_required': 'Necesita restablecer su contraseña antes de cambiar su contraseña.',
  'auth.change_password.errors.rate_limit': 'Intentaste cambiar tu contraseña demasiadas veces, por favor, espera y vuelve a intentarlo más tarde.',
  'auth.change_password.errors.unknown': 'Un error desconocido ocurrió al intentar cambiar tu contraseña. Si ves este mensaje, contáctate con un administrador del sistema.',

  'auth.forgot_password.errors.external': 'Algo inesperado sucedió cuando intentaste restablecer tu contraseña. Por favor, intenta de nuevo más tarde.',
  'auth.forgot_password.errors.internal': 'Algo inesperado sucedió cuando intentaste restablecer tu contraseña. Si ves este mensaje, contáctate con un administrador del sistema.',
  'auth.forgot_password.errors.delivery_failure': 'El código de restablecimiento de contraseña no se puede enviar a su correo electrónico. Inténtalo de nuevo más tarde.',
  'auth.forgot_password.errors.rate_limit': 'Intentaste restablecer tu contraseña demasiadas veces, por favor, espera y vuelve a intentarlo más tarde.',
  'auth.forgot_password.errors.unknown': 'Un error desconocido ocurrió al intentar restablecer tu contraseña. Si ves este mensaje, contáctate con un administrador del sistema.',

  'auth.forgot_password_submit.errors.external': 'Algo inesperado sucedió cuando intentaste enviar tu contraseña reiniciada. Por favor, intenta de nuevo más tarde.',
  'auth.forgot_password_submit.errors.internal': 'Algo inesperado sucedió cuando intentaste enviar tu contraseña reiniciada. Si ves este mensaje, contáctate con un administrador del sistema.',
  'auth.forgot_password_submit.errors.wrong_code': 'El código que proporcionaste es incorrecto. Asegúrate de que has insertado el código correcto e inténtelo de nuevo.',
  'auth.forgot_password_submit.errors.code_expired': 'El código proporcionado ha expirado. Por favor, vuelva a restablecer su contraseña.',
  'auth.forgot_password_submit.errors.invalid_password': 'Tu nueva contraseña es inválida. Asegúrate de que sea lo suficientemente segura e inténtalo de nuevo.',
  'auth.forgot_password_submit.errors.rate_limit': 'Intentaste restablecer tu contraseña demasiadas veces, por favor, espera y vuelve a intentarlo más tarde.',
  'auth.forgot_password_submit.errors.user_not_confirmed': 'Tu correo electrónico todavía no está verificado. Por favor, comprueba tu bandeja de entrada por un correo electrónico de verificación.',
  'auth.forgot_password_submit.errors.unknown': 'Sucedió un error desconocido al intentar enviar el reinicio de su contraseña. Si ves este mensaje, contáctate con un administrador del sistema.'
};

const RESEND_VERIFICATION = {
  'resend_verification.header': 'Reenviar su código de verificación',
  'resend_verification.description': 'Introduzca su dirección de correo electrónico aquí. Si una cuenta está asociada a este correo electrónico, se enviará un mensaje de verificación a su buzón de entrada. Una vez que haya recibido el mensaje, siga las instrucciones dentro.',

  'resend_verification.form.email': 'Correo electrónico',
  'resend_verification.form.submit': 'Reenviar verificación',

  'resend_verification.success.header': 'Revisa tu buzón de entrada',
  'resend_verification.success.description': 'Si esta dirección de correo electrónico corresponde a un correo electrónico registrado válido, entonces usted recibirá un mensaje con instrucciones adicionales.',

  'resend_verification.errors.header': 'No se pudo reenviarle un código de verificación',

  'resend_verification.errors.email.invalid': 'Correo electrónico inválido',
  'resend_verification.errors.email.required': 'El correo electrónico no puede estar vacío'
};

const FORGOT_PASSWORD = {
  'forgot_password.header': '¿Olvidó su contraseña?',
  'forgot_password.description': 'Introduzca su dirección de correo electrónico aquí. Si una cuenta está asociada a este correo electrónico, se enviará un código a su buzón de entrada que se utilizará para restablecer su contraseña.',

  'forgot_password.form.email': 'Correo electrónico',
  'forgot_password.form.code': 'Código',
  'forgot_password.form.password': 'Contraseña',
  'forgot_password.form.password_confirm': 'Confirmar contraseña',
  'forgot_password.form.submit': 'Reiniciar contraseña',

  'forgot_password.errors.header': 'No se pudo restablecer su contraseña',

  'forgot_password.errors.email.invalid': 'Correo electrónico inválido',
  'forgot_password.errors.email.required': 'El correo electrónico no puede estar vacío',

  'forgot_password.errors.code.required': 'El código no puede estar vacío',

  'forgot_password.errors.password.special_chars': 'La contraseña debe contener al menos 1 carácter especial',
  'forgot_password.errors.password.lowercase': 'La contraseña debe contener al menos 1 minúsucla',
  'forgot_password.errors.password.uppercase': 'La contraseña debe contener al menos 1 mayúsucla',
  'forgot_password.errors.password.white_spaces': 'La contraseña no puede contener espacios',
  'forgot_password.errors.password.required': 'La contraseña no puede estar vacía',

  'forgot_password.errors.password_confirm.different': 'Debe ser igual a la contraseña',
  'forgot_password.errors.password_confirm.required': 'La confirmación de contraseña no puede estar vacía'
};

const EXPLORE = {
  'explore.errors.header': 'No se pudo buscar transformaciones',
  'explore.search_button.label': 'Buscar',
  'explore.published_transformations.header': 'Oportunidades',

  'explore.search.result.empty.header': 'No hay oportunidades.',
  'explore.search.result.empty.description': 'No se han encontrado oportunidades que satisfagan sus criterios de búsqueda.'
};

const TRANSFORMATION = {
  'transformation.item.defaults.title': 'Transformación sin nombre',
  'transformation.item.defaults.description': 'No hay descripción disponible.',
  'transformation.item.defaults.subtitle': 'No se proporciona ningún nombre orgánico crudo.',

  'transformation.form.error.header': 'No se pudo obtener los datos necesarios para el formulario.',
  'transformation.form.heading.message.header': 'Cómo llenar esta sección',

  'transformation.form.curation.header': 'Lo que nuestros curadores dijeron sobre esta sección',
  'transformation.form.curation.empty.text': 'Todo en esta sección está bien, no se necesitan actualizaciones.',

  'transformation.form.image.title': 'Imagen',
  'transformation.form.image.picker.header': 'Escoge una imagen para tu documento.',
  'transformation.form.image.picker.errors.header': 'Algo salió mal actualizando la imagen de la transformación',
  'transformation.form.image.picker.change_image': 'Cambiar imagen',
  'transformation.form.image.picker.click_to_add_image': 'Haz clic para añadir una imagen',

  /* TAB 1 - Basic Information */
  'transformation.form.basic_information.title': 'Información básica',
  'transformation.form.basic_information.heading': 'Proporcione la mayor información posible sobre el material orgánico/biomasa específico (por ejemplo, cáscaras de cacao) utilizado en el proceso/producto de UNA transformación. Si deseas proporcionar más de un proceso de transformación para el mismo material orgánico, por favor publica una nueva oportunidad. Si se utiliza más de una biomasa, describalas en el campo "breve descripción de biomasa".',

  'transformation.form.basic_information.producing_species_name.label': 'Especies',
  'transformation.form.basic_information.producing_species_name.hint': 'El nombre científico de la fuente principal de la biomasa',
  'transformation.form.basic_information.producing_species_name.hint.example': 'Por ejemplo, Cacao theobroma',
  'transformation.form.basic_information.producing_species_name.errors.max': 'No puede ser más de 256 caracteres.',

  'transformation.form.basic_information.common_names.label': 'Nombres comunes',
  'transformation.form.basic_information.common_names.hint': 'Añadir una coma para separar nombres diferentes.',
  'transformation.form.basic_information.common_names.hint.example': 'Por ejemplo, para Cacao theobroma, "cocoa"',
  'transformation.form.basic_information.common_names.errors.max': 'No puede ser más de 256 caracteres.',
  'transformation.form.basic_information.common_names.errors.required': 'Se requieren nombres comunes.',
  'transformation.form.basic_information.common_names.errors.min': 'Al menos se requiere un nombre común.',

  /* Modified Field - selection from options: natural, residual, produced */
  'transformation.form.basic_information.biomass_class.label': 'Clase de biomasa',
  'transformation.form.basic_information.biomass_class.hint': 'Biomasa natural: Producida por naturaleza, sin intervención humana\n' +
    'Biomasa residual: residuos orgánicos provenientes de actividades humanas (por ejemplo, residuos sólidos urbanos)\n' +
    'Biomasa producida: Los cultivos energéticos, el cultivo de biomasa sólo para fuentes de energía.',
  'transformation.form.basic_information.biomass_class.options.natural': 'Biomasa natural',
  'transformation.form.basic_information.biomass_class.options.residual': 'Biomasa residual',
  'transformation.form.basic_information.biomass_class.options.produced': 'Producción de biomasa',
  'transformation.form.basic_information.biomass_class.errors.max': 'No puede ser más de 3 caracteres.',

  'transformation.form.basic_information.organic_material_name.label': 'Nombre de la materia orgánica cruda',
  'transformation.form.basic_information.organic_material_name.hint': 'Parte(s) o componente(s) de las principales especies utilizadas en el proceso de transformación/producto. Añadir un coma para separar nombres diferentes.',
  'transformation.form.basic_information.organic_material_name.hint.example': 'Ejmplo: cascos de cacao, cáscaras de cacao, cocoas, mucilación.',
  'transformation.form.basic_information.organic_material_name.errors.max': 'No puede ser más de 256 caracteres.',
  'transformation.form.basic_information.organic_material_name.errors.required': 'Se requiere el nombre de material orgánico.',

  'transformation.form.basic_information.source_type.label': 'Tipo de fuente',
  'transformation.form.basic_information.source_type.hint': 'Diferentes fuentes de biomasa principal utilizadas en su producto. Por ejemplo, las cáscaras de cacao corresponden a "Residuo agrícola"',
  'transformation.form.basic_information.source_type.errors.base': 'El tipo de fuente debe ser un entero.',

  'transformation.form.basic_information.source_class.label': 'Clase de la fuente',
  'transformation.form.basic_information.source_class.hint': '"Cocoa husks" se deriva de "Cocoa y preparaciones de cacao" (clase de la fuente). El código HS se utiliza como referencia estándar.',
  'transformation.form.basic_information.source_class.errors.base': 'La clase de la fuente debe ser un entero.',

  'transformation.form.basic_information.source_sub_class.label': 'Subclase de la fuente',
  'transformation.form.basic_information.source_sub_class.hint': 'Subclase de la fuente corresponde a una subclasificación de "clase de fuente" que aborda fuentes específicas de biomasa. Por ejemplo, las cáscaras de cacao caen en "Residuo agrícola, Clase Fuente "Cocoa y preparaciones de cacao", Subclase Fuente "Husks".',
  'transformation.form.basic_information.source_sub_class.errors.base': 'La subclase de la fuente debe ser un entero.',

  'transformation.form.basic_information.location.label': 'Ubicación',
  'transformation.form.basic_information.location.hint': 'La ubicación geográfica del proyecto',
  'transformation.form.basic_information.location.errors.base': 'La ubicación debe ser un entero.',

  'transformation.form.basic_information.brief_biomass_description.label': 'Breve descripción de la biomasa',
  'transformation.form.basic_information.brief_biomass_description.hint': 'Añada información adicional relevante a la especie productora. Si se utiliza más de una biomasa, sírvase describirlas en este campo.',
  'transformation.form.basic_information.brief_biomass_description.errors.max': 'No puede ser más de 350 caracteres.',
  'transformation.form.basic_information.brief_biomass_description.errors.required': 'Se requiere una breve descripción de la biomasa.',

  'transformation.form.basic_information.current_uses_or_transformations.label': 'Uso o transformación ampliamente conocidos',
  'transformation.form.basic_information.current_uses_or_transformations.hint': 'Si un producto o transformación es ampliamente utilizado con su biomasa, escriba aquí. Añadir una coma para separar cada concepto diferente.',
  'transformation.form.basic_information.current_uses_or_transformations.hint.example': 'Por ejemplo, Cocoa generalmente se utiliza para preparar: chocolate, mantequilla de cacao, licor de cacao y polvo de cacao. Si usted produce por ejemplo "nanolignin" de cacao NO incluya aplicaciones novedosas o no comunes aquí.',
  'transformation.form.basic_information.current_uses_or_transformations.errors.max': 'No puede ser más de 256 caracteres.',

  'transformation.form.basic_information.current_negative_impact.label': 'Impacto negativo actual si lo hay',
  'transformation.form.basic_information.current_negative_impact.hint': 'El impacto negativo social, ambiental o económico del uso o no uso de biomasa o transformación.',
  'transformation.form.basic_information.current_negative_impact.errors.max': 'No puede ser más de 350 caracteres.',
  'transformation.form.basic_information.current_negative_impact.errors.required': 'Se requieren efectos negativos actuales.',

  'transformation.form.basic_information.trl_eu.label': 'Nivel de preparación tecnológica (TLR-EU)',
  'transformation.form.basic_information.trl_eu.hint': 'Por favor seleccione el estado del arte en la materia del proceso de transformación seleccionado. Esta base de datos no incluye tecnologías por debajo de TLR5. TLR5: Tecnología validada en entornos relevantes, TLR6: Tecnología demostrada en entornos relevantes, TLR7: Modelo de sistema o demostración prototipo en entorno operacional, TLR8: Sistema/modelo producido y calificado. Un ejemplo podría incluir los conocimientos generados por la TRL 7 que se utilizan para fabricar un sistema/modelo real, que posteriormente está calificado en un entorno operacional. En la mayoría de los casos, este TRL representa el fin del desarrollo. TLR9: Sistema real probado en entorno operacional.',
  'transformation.form.basic_information.trl_eu.errors.base': 'El nivel de preparación tecnológica debe ser un entero.',

  /* TAB 2 - Transformation Process */
  'transformation.form.transformation_process.title': 'Proceso de transformación',
  'transformation.form.transformation_process.heading': 'Por favor complete las siguientes secciones para su proceso de transformación de material orgánico seleccionado. Si desea proporcionar más de un proceso de transformación para el mismo material orgánico, por favor publique una nueva oportunidad. Escriba N/A si no tiene información.',

  'transformation.form.transformation_process.brief_description_of_transformation_process': 'Breve descripción del proceso de transformación',
  'transformation.form.transformation_process.brief_description_of_transformation_process.hint': 'Añadir una breve descripción en lenguaje común del proceso de transformación.',
  'transformation.form.transformation_process.brief_description_of_transformation_process.errors.max': 'Breve descripción del proceso de transformación no puede ser más de 350 caracteres.',
  'transformation.form.transformation_process.brief_description_of_transformation_process.errors.required': 'Breve descripción del proceso de transformación es necesaria.',

  'transformation.form.transformation_process.evidences.label': 'Evidencia documental',
  'transformation.form.transformation_process.evidences.hint': 'Por ejemplo, papers, patentes, informes y disertación. Descripción URL. Describir brevemente cada URL.',

  'transformation.form.transformation_process.evidence_url.label': 'URL de evidencia documental',
  'transformation.form.transformation_process.evidence_url.hint': 'Ejempo: URL de papers, patentes, informes, disertación',
  'transformation.form.transformation_process.evidence_url.errors.uri': 'Se requiere una URL válida.',
  'transformation.form.transformation_process.evidence_url.errors.max': 'No puede ser más de 512 caracteres.',

  'transformation.form.transformation_process.publishing_year.label': 'Año de publicación',
  'transformation.form.transformation_process.publishing_year.errors.base': 'El año de publicación debe ser un entero.',
  'transformation.form.transformation_process.publishing_year.errors.positive': 'El año de publicación debe ser positivo.',

  'transformation.form.transformation_process.url_description.label': 'Descripción de la URL',
  'transformation.form.transformation_process.url_description.hint': 'Describir cada URL brevemente',
  'transformation.form.transformation_process.url_description.errors.max': 'No puede ser más de 1024 caracteres.',

  'transformation.form.transformation_process.developed_technologies.label': 'Proceso de transformación desarrollado',
  'transformation.form.transformation_process.developed_technologies.hint': 'La transformación es el conjunto de operaciones secuenciales que siguen una materia prima hasta que se convierte en el producto deseado con valor añadido (Levenspiel, 2004). Ref. Levenspiel, O. (2004). Ingeniería de las Reacciones Químicas (Third Edition). Limusa Wiley. Puedes añadir más de un proceso de transformación. Añadir una coma para separar diferentes procesos de transformación.',
  'transformation.form.transformation_process.developed_technologies.hint.example': 'Por ejemplo, Hidrolisis ácido usando ácido oxálico.',
  'transformation.form.transformation_process.developed_technologies.errors.max': 'No puede ser más de 256 caracteres.',
  'transformation.form.transformation_process.developed_technologies.errors.required': 'Proceso de transformación desarrollado es necesario.',

  'transformation.form.transformation_process.products_or_services.label': 'Producto o servicio resultante',
  'transformation.form.transformation_process.products_or_services.hint': 'Listar productos/servicios actuales o potenciales. Use comas para separar cada producto/servicio potencial.',
  'transformation.form.transformation_process.products_or_services.hint.example': 'Por ejemplo, Nanocelulosa fibrilada',
  'transformation.form.transformation_process.products_or_services.errors.max': 'No puede ser más de 256 caracteres.',
  'transformation.form.transformation_process.products_or_services.errors.required': 'Se requiere producto o servicio.',

  'transformation.form.transformation_process.industrial_applications.label': 'Aplicaciones industriales',
  'transformation.form.transformation_process.industrial_applications.hint': 'Lista las aplicaciones industriales y otras aplicaciones potenciales.',
  'transformation.form.transformation_process.industrial_applications.hint.example': 'Por ejemplo, sistemas de filtración de agua potable, dispositivos ópticos, materiales biomédicos, biosensores o electrónica verde. Use comas para separar cada aplicación industrial.',
  'transformation.form.transformation_process.industrial_applications.errors.max': 'No puede ser más de 256 caracteres.',
  'transformation.form.transformation_process.industrial_applications.errors.required': 'Se requiere aplicación industrial.',

  'transformation.form.transformation_process.associated_pollutants.label': '¿Existe algún material contaminante que pueda afectar el proceso de transformación o producto resultante?',
  'transformation.form.transformation_process.associated_pollutants.hint': 'Uso de reactivos duros y generación de subproductos con impacto ambiental negativo.',
  'transformation.form.transformation_process.associated_pollutants.hint.example': 'Por ejemplo, ácido oxálico. Use comas para separar cada contaminante asociado.',
  'transformation.form.transformation_process.associated_pollutants.errors.max': 'No puede ser más de 256 caracteres.',
  'transformation.form.transformation_process.associated_pollutants.errors.required': 'Se requieren materiales contaminantes.',

  'transformation.form.transformation_process.patents_associated.label': '¿Existe alguna patente asociada a este proceso o tecnología relacionada?',
  'transformation.form.transformation_process.patents_associated.hint': 'Este campo tiene como objetivo saber si existe algún interés u oportunidad industrial potencial o actual en la tecnología. Si existen patentes, la parte interesada buscará bases de datos especializadas como PatentScope.',
  'transformation.form.transformation_process.patents_associated.errors.max': 'No puede ser más de 3 caracteres.',

  'transformation.form.transformation_process.licenses_emitted.label': '¿Esta patente ha sido autorizada a un tercero?',
  'transformation.form.transformation_process.licenses_emitted.hint': 'Los acuerdos de concesión de licencias son contratos jurídicamente vinculantes en los que el propietario de la propiedad intelectual (IP) en una tecnología valiosa (el licenciante) permite a otra persona (el licenciatario) el permiso para utilizar esa IP de maneras (términos) que se especifican en el acuerdo WIPO. (n.d.). Technology Transfer Agreements. Consultado el 31 de octubre de 2022, desde https://www.wipo.int/technology-transfer/en/agreements.html. Este campo tiene como objetivo saber si existe algún interés u oportunidad industrial potencial o actual en la tecnología. Si se ha emitido una licencia, indicará un mayor interés en la tecnología.',
  'transformation.form.transformation_process.licenses_emitted.errors.max': 'No puede ser más de 3 caracteres.',

  'transformation.form.transformation_process.free_access.label': '¿Hay libre acceso a la propiedad intelectual relacionada con este proceso de transformación?',
  'transformation.form.transformation_process.free_access.hint': 'Si respondes "Sí", marca las dos siguientes preguntas como "No"',
  'transformation.form.transformation_process.free_access.errors.max': 'No puede ser más de 3 caracteres.',

  /* TAB 3 - Scalability */
  'transformation.form.scalability.title': 'Escalabilidad',
  'transformation.form.scalability.heading': 'Por favor complete las siguientes secciones sobre la sostenibilidad del proceso de transformación propuesto. Si desea proporcionar más de un proceso de transformación para el mismo material orgánico, por favor publique una nueva oportunidad. Escriba N/A si no tiene información.',

  'transformation.form.scalability.technical_scalability.label': 'Escalabilidad técnica',
  'transformation.form.scalability.technical_scalability.hint': 'Basado en su conocimiento, este campo pretende evaluar la viabilidad de escalar hasta etapas industriales/comerciales la tecnología desarrollada desde el punto de vista técnico. "La exactitud de la escalabilidad es vital para asegurar que el trabajo realizado a escala de laboratorio no lleve a desperdiciar recursos ni tiempo ni hallazgos que no puedan aplicarse a aplicaciones a gran escala". (Lim et al., 2021). Lim, Y. F., Chan, Y. J., Abakr, Y. A., Sethu, V., Selvarajoo, A., Singh, A., Lee, J., & Gareth, M. (2021). Review of biowastes to energy in Malaysia: Current technology, scalability and socioeconomic analysis. Cleaner Engineering and Technology, 4, 100257. https://doi.org/10.1016/J.CLET.2021.10025. ¿Tiene suficiente información para montar las etapas comerciales y determinar posibles problemas y soluciones? Si usted respondió "No", por favor especifique las posibles barreras y soluciones en el campo siguiente.',
  'transformation.form.scalability.technical_scalability.errors.max': 'No puede ser más de 3 caracteres.',

  'transformation.form.scalability.technical_scalability_challenges.label': 'Retos de escalabilidad técnica',
  'transformation.form.scalability.technical_scalability_challenges.hint': 'Describir los principales retos de escalabilidad técnica y las posibles soluciones para ellos.',
  'transformation.form.scalability.technical_scalability_challenges.errors.required': 'Se requieren problemas de escalabilidad técnica.',

  'transformation.form.scalability.required_equipment.label': 'Equipo requerido',
  'transformation.form.scalability.required_equipment.hint': 'Puede añadir más de un equipo requerido. Sírvase enumerar el equipo principal que considera esencial al preparar presupuestos o planear implementar la tecnología. Añadir una coma para separar diferentes piezas de equipo. Por ejemplo, biodigester, reactor de presión, ultrasonido industrial, tanque de hidrolisis. Sólo añadir equipo importante/no estándar.',
  'transformation.form.scalability.required_equipment.errors.max': 'No puede ser más de 256 caracteres.',
  'transformation.form.scalability.required_equipment.errors.required': 'Se requiere equipo requerido.',

  'transformation.form.scalability.required_supplies.label': 'Suministros requeridos',
  'transformation.form.scalability.required_supplies.hint': 'Puedes añadir más de un suministro. Use comas para separar cada uno. Sírvase enumerar los principales suministros necesarios al preparar presupuestos o planificar la aplicación de la tecnología. Por ejemplo, productos químicos, biocatálisis especial y agua. Sólo añadir suministros importantes/no estándar.',
  'transformation.form.scalability.required_supplies.errors.max': 'No puede ser más de 256 caracteres.',
  'transformation.form.scalability.required_supplies.errors.required': 'Se requieren suministros requeridos.',

  'transformation.form.scalability.required_skills.label': 'Habilidades requeridas',
  'transformation.form.scalability.required_skills.hint': 'Puedes añadir más de una habilidad requerida. Use comas para separar cada uno. Sírvase enumerar las principales aptitudes que se consideran necesarias al preparar presupuestos o planificar la aplicación de la tecnología. Por ejemplo, ingenieros químicos o químicos con experiencia en ampliación, evaluación del ciclo de vida, síntesis orgánica, química verde, procesamiento termoquímico de biomasa, experiencia en hierbas lignocelulósicas, desarrollo y operación de grandes infraestructuras de agregación de biomasa y conversión para producir sustituto de carbón ambientalmente seguro para la industria de generación de energía. Sólo añadir habilidades importantes/no estándar.',
  'transformation.form.scalability.required_skills.errors.max': 'No puede ser más de 256 caracteres.',
  'transformation.form.scalability.required_skills.errors.required': 'Se requieren habilidades requeridas.',

  'transformation.form.scalability.minimal_scale_production.label': 'Producción de escala mínima',
  'transformation.form.scalability.minimal_scale_production.hint': 'Basado en su conocimiento, este campo pretende estimar la producción de escala mínima para alcanzar una etapa industrial/comercial de la tecnología desarrollada. Calcular la producción de escala mínima basada en criterios de ingresos, técnicos y logísticos.',
  'transformation.form.scalability.minimal_scale_production.min_value.label': 'Valor',
  'transformation.form.scalability.minimal_scale_production.min_value.errors.base': 'El valor mínimo debe ser un número.',
  'transformation.form.scalability.minimal_scale_production.min_value.errors.positive': 'El valor mínimo debe ser positivo.',
  'transformation.form.scalability.minimal_scale_production.min_unit.label': 'Unidad',
  'transformation.form.scalability.minimal_scale_production.min_unit.errors.max': 'No puede ser más de 128 caracteres.',

  'transformation.form.scalability.maximum_production_volume.label': 'Volumen de producción máximo',
  'transformation.form.scalability.maximum_production_volume.hint': 'Basado en su conocimiento, este campo pretende estimar la producción de escala máxima para la viabilidad de escalar hasta una etapa industrial/comercial de la tecnología desarrollada. La producción de escala máxima se puede calcular sobre la base de los criterios de sostenibilidad y biomasa disponibles. ¿Cuál es la cantidad máxima de producto que una industria puede producir con viabilidad?',
  'transformation.form.scalability.maximum_production_volume.max_value.label': 'Valor',
  'transformation.form.scalability.maximum_production_volume.max_value.errors.base': 'El valor máximo debe ser un número.',
  'transformation.form.scalability.maximum_production_volume.max_value.errors.positive': 'El valor máximo debe ser positivo.',
  'transformation.form.scalability.maximum_production_volume.max_unit.label': 'Unidad',
  'transformation.form.scalability.maximum_production_volume.max_unit.errors.max': 'No puede ser más de 128 caracteres.',

  'transformation.form.scalability.logistical_scalability.label': 'Escalabilidad logística',
  'transformation.form.scalability.logistical_scalability.hint': 'Basado en su conocimiento, este campo pretende evaluar la viabilidad de escalar hasta etapas industriales/comerciales la tecnología desarrollada desde un punto de vista logístico. Si contesta "Todavía no", por favor especifique en el campo siguiente las principales brechas y barreras y posibles soluciones.',
  'transformation.form.scalability.logistical_scalability.errors.max': 'No puede ser más de 3 caracteres',

  'transformation.form.scalability.logistical_scalability_challenges.label': 'Retos de escalabilidad logística',
  'transformation.form.scalability.logistical_scalability_challenges.hint': 'Aquí describir las principales barreras o retos y posibles alternativas para resolverlos. Si usted respondió "No" arriba, por favor especifique los principales desafíos junto con posibles soluciones en el campo siguiente.',
  'transformation.form.scalability.logistical_scalability_challenges.errors.required': 'Se requieren problemas de escalabilidad logística.',

  'transformation.form.scalability.economical_scalability.label': 'Escalabilidad económica',
  'transformation.form.scalability.economical_scalability.hint': 'Basado en su conocimiento, este campo pretende estimar la viabilidad de escalar hasta una etapa industrial/comercial la tecnología desarrollada desde un punto de vista económico. Tenga en cuenta diversos aspectos del proceso económico, los costos de procesamiento, los principales factores de coste, el análisis de materias primas de biomasa, etc.',
  'transformation.form.scalability.economical_scalability.errors.max': 'No puede ser más de 3 caracteres',

  'transformation.form.scalability.economical_scalability_challenges.label': 'Retos de escalabilidad económica',
  'transformation.form.scalability.economical_scalability_challenges.hint': 'Describir los retos de escalabilidad económica y posibles soluciones para ellos.',
  'transformation.form.scalability.economical_scalability_challenges.errors.required': 'Se requieren problemas de escalabilidad económica.',

  'transformation.form.scalability.annual_availability.label': 'Disponibilidad anual',
  'transformation.form.scalability.annual_availability.hint': 'Si es aplicable, seleccione los meses considerados la mejor temporada de cultivo/especie en el país que haya seleccionado anteriormente (sección de información básica).',
  'transformation.form.scalability.annual_availability.errors.base': 'La disponibilidad anual debe ser un entero.',

  'transformation.form.scalability.climate.label': 'Clima o bioma',
  'transformation.form.scalability.climate.hint': 'Por favor seleccione la categoría que mejor describe la condición de cultivo/especie',
  'transformation.form.scalability.climate.errors.base': 'El clima debe ser un entero.',

  'transformation.form.scalability.geographical_location.label': 'Ubicación geográfica',
  'transformation.form.scalability.geographical_location.hint': 'Por favor, especifique la ubicación geográfica de la biomasa en el siguiente orden: población, ciudad, estado, país',

  /* TAB 4 - Sustainable Development */
  'transformation.form.sustainability.title': 'Desarrollo sostenible',
  'transformation.form.sustainability.heading': 'El objetivo del desarrollo sostenible es satisfacer las necesidades actuales sin comprometer las necesidades futuras. En esta sección, por favor proporcionen su comprensión sobre cómo esta transformación del proceso ayudaría o dificultaría el desarrollo sostenible.',

  'transformation.form.sustainability.contributes_species_preservation.label': '¿Esta transformación contribuye o puede contribuir a la conservación/preservación/revalorización de la especie?',
  'transformation.form.sustainability.contributes_species_preservation.hint': 'Por ejemplo, con la recuperación de nanocelulosa de la cáscara de cacao, los residuos de cacao pueden ser reutilizados para el embalaje in situ.',
  'transformation.form.sustainability.contributes_species_preservation.errors.max': 'No puede ser más de 1024 caracteres.',

  'transformation.form.sustainability.contributes_circular_economy.label': '¿La transformación contribuye o puede contribuir a la economía circular?',
  'transformation.form.sustainability.contributes_circular_economy.hint': 'Por ejemplo, La transformación de los residuos de la industria del cacao a la nanocelulosa, como material avanzado, aumenta la rentabilidad de esta industria. Además, este proceso de transformación reduce los desechos subutilizados en el medio ambiente, evitando la contaminación y generando beneficios de este recurso renovable en el nexo energético/agua/alimentos. Además, el sector energético puede utilizar la nanocelulosa y sus derivados como dispositivos de conversión de energía electroquímica de nanomaterial verde. Otras oportunidades son aplicaciones farmacéuticas/biomédicas y remediación ambiental, ya que los filtros de nanocelulosa pueden purificar el agua contaminada.',
  'transformation.form.sustainability.contributes_circular_economy.errors.max': 'No puede ser más de 1024 caracteres.',

  'transformation.form.sustainability.sustainable_development_goals.label': '¿Esta transformación contribuye a cualquier Objetivo de Desarrollo Sostenible?',
  'transformation.form.sustainability.sustainable_development_goals.hint': 'Puede seleccionar más de un ODS si es necesario.',
  'transformation.form.sustainability.sustainable_development_goals.errors.base': 'Los objetivos de desarrollo sostenible deben ser números.',

  'transformation.form.sustainability.closes_productive_cycle_gap.label': '¿Cierra las brechas de circularidad?',
  'transformation.form.sustainability.closes_productive_cycle_gap.hint': 'Un análisis de la brecha del ciclo de vida identifica brechas de circularidad sobre los posibles impactos ambientales durante el ciclo de vida de un producto en términos de pérdidas del sistema entre un sistema cerrado ideal y el status quo (Dieterle et al., 2018). Dieterle, M., Schäfer, P., & Viere, T. (2018). Life Cycle Gaps: Interpreting LCA Results with a Circular Economy Mindset. Procedia CIRP, 69, 764-768. https://doi.org/10.1016/J.PROCIR.2017.11.058',
  'transformation.form.sustainability.closes_productive_cycle_gap.errors.base': 'Debe ser cierto o falso.',

  'transformation.form.sustainability.replaces_materials_fossil_origin.label': '¿Sustituye materiales de origen de combustibles fósiles?',
  'transformation.form.sustainability.replaces_materials_fossil_origin.hint': 'El combustible fósil es un término genérico para fuentes de energía no renovables como carbón, productos de carbón, gas natural, gas derivado, petróleo crudo, productos derivados del petróleo y desechos no renovables. Estos combustibles provienen de plantas y animales que existían en el pasado geológico (por ejemplo, hace millones de años) (Eurostat, 2019). Eurostat. (2019, 9 de septiembre). Glossary:Fossil fuel - Statistics Explained. https://ec.europa.eu/eurostat/statistics-explained/index.php?title=Glossary:Fossil_fuel',
  'transformation.form.sustainability.replaces_materials_fossil_origin.errors.base': 'Debe ser cierto o falso.',

  'transformation.form.sustainability.reduces_greenhouse_effect_gasses.label': '¿Reduce los gases de efecto invernadero?',
  'transformation.form.sustainability.reduces_greenhouse_effect_gasses.hint': 'Considere si el proceso de transformación disminuye la emisión de gases de efecto invernadero emitidos en al menos una de las siguientes cosas que hacemos: hacer cosas (cemento, acero, plástico), enchufar (electricidad), cultivar cosas (plantas, animales), moverse (planos, camiones, buques de carga), mantener caliente y fresco (calentamiento, refrigeración, refrigeración) (Gates, 2021 ). Gates, B. (2021). How to avoid a climate disaster: the solutions we have and the breakthroughs we need. Knopf.',
  'transformation.form.sustainability.reduces_greenhouse_effect_gasses.errors.base': 'Debe ser cierto o falso.',

  /* TAB 5 - Profitability & Market Research */
  'transformation.form.market.title': 'Potential de mercado',
  'transformation.form.market.heading': 'Nos proponemos reunir información para evaluar si el proceso es adecuado para construir un modelo de negocio rentable.',

  'transformation.form.market.production_costs_estimation.label': 'Estimación de costos de producción',
  'transformation.form.market.production_costs_estimation.hint': 'Estimar el costo de producción. Por favor, especifique la unidad y el producto en el campo de unidades.',
  'transformation.form.market.production_costs_estimation.hint.example': 'Por ejemplo, Kg de papel, L de aceite esencial',

  'transformation.form.market.production_costs_estimation.production_estimated_value.label': 'Valor por unidad',
  'transformation.form.market.production_costs_estimation.production_estimated_value.errors.base': 'Estimación de la valor por unidad económico del producto principal o servicio derivado de su transformación de proceso. Puede utilizar volumen/masa u otra unidad relevante de medición. (Por ejemplo, Kg de papel, L de aceite esencial).',
  'transformation.form.market.production_costs_estimation.production_estimated_currency.label': 'Moneda',
  'transformation.form.market.production_costs_estimation.production_estimated_currency.errors.base': 'La moneda debe ser un entero.',
  'transformation.form.market.production_costs_estimation.production_estimated_unit.label': 'Unidad',
  'transformation.form.market.production_costs_estimation.production_estimated_unit.errors.max': 'No puede ser más de 128 caracteres.',

  'transformation.form.market.market_analysis.label': 'Resumen del análisis de mercado',
  'transformation.form.market.market_analysis.hint': 'Por favor, dé un breve resumen de análisis de mercado de los principales productos o servicios de su transformación de proceso.',

  'transformation.form.market.economical_value_unit_estimation.label': 'Estimación del valor económico por unidad',
  'transformation.form.market.economical_value_unit_estimation.hint': 'Agregue los campos para copiar los enlaces asociados a la estimación de costos de producto, estimación de valor económico por unidad y resumen de análisis de mercado. TAMBIÉN agregue un campo junto a unidad con el año de publicación.',
  'transformation.form.market.economical_value_unit_estimation.hint.example': 'Por ejemplo, el precio de venta unitario es de 850 USD/kg de CNC',
  'transformation.form.market.economical_value_unit_estimation.economical_estimated_value.label': 'Valor estimado',
  'transformation.form.market.economical_value_unit_estimation.economical_estimated_value.errors.base': 'El valor estimado debe ser un número.',
  'transformation.form.market.economical_value_unit_estimation.economical_estimated_currency.label': 'Moneda',
  'transformation.form.market.economical_value_unit_estimation.economical_estimated_currency.errors.base': 'La moneda debe ser un entero.',
  'transformation.form.market.economical_value_unit_estimation.economical_estimated_unit.label': 'Unidad',
  'transformation.form.market.economical_value_unit_estimation.economical_estimated_unit.errors.max': 'No puede ser más de 128 caracteres.',

  'transformation.form.market.structure_costs_analysis.label': 'Análisis de costos de estructura',
  'transformation.form.market.structure_costs_analysis.hint': 'Sírvase resumir el análisis de costos de estructura para los principales productos o servicios de su transformación de proceso.',

  'transformation.form.market.referent_region_businesses.label': 'Las mejores empresas de la industria',
  'transformation.form.market.referent_region_businesses.hint': 'Lista algunas de las mejores empresas que utilizan este proceso de transformación o tienen procesos similares. Por favor, separados con comas al añadir más de uno.',
  'transformation.form.market.referent_region_businesses.errors.max': 'No puede ser más de 256 caracteres.',
  'transformation.form.market.referent_region_businesses.errors.required': 'Se requiere un negocio referente.',

  'transformation.form.market.donors_financing_institutions.label': 'Donadores potenciales e instituciones financiadoras',
  'transformation.form.market.donors_financing_institutions.hint': 'Por favor, separados con comas al añadir más de uno. Por ejemplo, Banco Interamericano de Desarrollo, GIZ, Ministerio de la Producción-Gobierno del Perú.',
  'transformation.form.market.donors_financing_institutions.errors.max': 'No puede ser más de 256 caracteres.',
  'transformation.form.market.donors_financing_institutions.errors.required': 'Se requiere donador potencial y/o institución financiadora.',

  /* TAB 6 - Other Considerations */
  'transformation.form.regulations.title': 'Otras consideraciones',
  'transformation.form.regulations.heading': 'Aquí puede informar sobre otras consideraciones sobre el proceso propuesto para transformar la biomasa.',

  'transformation.form.regulations.indigenous_people_logistics.label': 'Función de las comunidades locales e indígenas en la producción, recogida y/o transformación de la biomasa.',
  'transformation.form.regulations.indigenous_people_logistics.hint': '¿Cuál es el papel de las poblaciones indígenas en el proceso de transformación, biomasa fuente o beneficio del producto o servicios propuestos?',
  'transformation.form.regulations.indigenous_people_logistics.errors.max': 'No puede ser más de 1024 caracteres.',

  'transformation.form.regulations.permissions_access_resource.label': 'Permisos necesarios para el acceso a los recursos biológicos y genéticos',
  'transformation.form.regulations.permissions_access_resource.hint': '¿Existe una ley o reglamento para acceder a los recursos de biomasa? Si puede especificar la legislación y en qué país se aplica. Añadir un resumen.',
  'transformation.form.regulations.permissions_access_resource.errors.max': 'No puede ser más de 1024 caracteres.',

  'transformation.form.regulations.regulations.label': 'Reglamento',
  'transformation.form.regulations.regulations.hint': '¿Debería tenerse en cuenta una ley o regulación fundamental para construir un modelo de negocio del proceso de transformación y para dirigir el negocio?',
  'transformation.form.regulations.regulations.errors.max': 'No puede ser más de 1024 caracteres.',

  'transformation.form.regulations.certifications.label': 'Certificaciones',
  'transformation.form.regulations.certifications.hint': '¿Debería una certificación crítica ayudar a construir un modelo de negocio o a ejecutar el negocio? Por ejemplo, el Programa de Biomasa Sostenible (SBP) es un sistema de certificación diseñado para la biomasa leñosa.',
  'transformation.form.regulations.certifications.errors.max': 'No puede ser más de 1024 caracteres.',

  'transformation.form.regulations.quality_risks_standards.label': 'Riesgos de calidad',
  'transformation.form.regulations.quality_risks_standards.hint': '¿Cómo puede la materia orgánica cruda causar riesgos de calidad? Añadir un resumen.',
  'transformation.form.regulations.quality_risks_standards.errors.max': 'No puede ser más de 1024 caracteres.',

  'transformation.form.regulations.waste_management.label': 'Gestión de desechos',
  'transformation.form.regulations.waste_management.hint': '¿Cómo se puede gestionar el desperdicio de la transformación del proceso para alcanzar el desarrollo sostenible?',
  'transformation.form.regulations.waste_management.errors.max': 'No puede ser más de 1024 caracteres.',

  'transformation.form.ui.error.header': 'No se pudo guardar su transformación.',
  'transformation.form.ui.next_btn': 'Siguiente',
  'transformation.form.ui.previous_btn': 'Anterior',
  'transformation.form.ui.finish_btn': 'Terminar',
  'transformation.form.ui.add_btn': 'Añadir',
  'transformation.form.ui.not_yet': 'Aún no',
  'transformation.form.ui.yes': 'Sí',
  'transformation.form.ui.no': 'No',
  'transformation.form.ui.dont.know': 'No sé',
  'transformation.form.ui.save_and_exit': 'Guardar y salir',

  'transformation.confirm_modal.header': '¿Terminaste con esta transformación?',
  'transformation.confirm_modal.description.1': 'Antes de que tu transformación sea publicada, necesita ser revisada por uno de nuestros expertos curadores. Al hacer clic en confirmar se enviará su transformación para revisión y no será capaz de hacer ningún cambio mientras esto sucede.',
  'transformation.confirm_modal.description.2': 'Un curador revisará tu transformación y podrá solicitar cambios antes de finalizar la publicación.',
  'transformation.confirm_modal.description.3': '¿Quieres continuar?',
  'transformation.confirm_modal.actions.close.label': 'Cerrar',
  'transformation.confirm_modal.actions.confirm.label': 'Confirmar',
  'transformation.confirm_modal.error.header': 'No se pudo pedir curación para tu transformación',

  'transformation.view.evidences.age_warning': 'Esta publicación es de {age} años atrás, puede contener información obsoleta.'
};

const TRANSFORMATION_VIEW = {
  'transformation_view.crowdsourcing.hints.bookmark': 'Favoritos',
  'transformation_view.crowdsourcing.hints.technically_viable': 'Técnicamente viable',
  'transformation_view.crowdsourcing.hints.economically_viable': 'Económicamente viable',
  'transformation_view.crowdsourcing.hints.environmentally_viable': 'Ambientalmente viable',
  'transformation_view.crowdsourcing.hints.socially_viable': 'Socialmente viable',

  'transformation_view.request.error.header': 'No se pudo conseguir la transformación',
  'transformation_view.request.not_found': 'No encontrado',

  'transformation_view.back_btn': 'ATRÁS',

  'transformation_view.sidebar_header.from': 'De: ',
  'transformation_view.sidebar_header.published_by': 'Publicado por: ',

  'transformation_view.save_btn': 'Guardar / Imprimir'
};

const SETTINGS = {
  'settings.user.form.success.header': '¡La actualización de usuario fue exitosa!',

  'settings.user.form.header': 'Ajustes del perfil',
  'settings.user.form.image_picker.header': 'Escoge una imagen para tu imagen de perfil.',
  'settings.user.form.username': 'Nombre de usuario',
  'settings.user.form.given_name': 'Nombre',
  'settings.user.form.family_name': 'Apellido',
  'settings.user.form.institution_name': 'Nombre de la institución',
  'settings.user.form.institution_position': 'Posición en la institución',
  'settings.user.form.professional_affiliation': 'Afiliación profesional',
  'settings.user.form.location_country': 'País',
  'settings.user.form.location_province': 'Estado',
  'settings.user.form.location_city': 'Ciudad',
  'settings.user.form.bio': 'Bio',
  'settings.user.form.email_notifications_enabled': 'Notificaciones por correo electrónico habilitadas',
  'settings.user.form.contact.divider': 'Contacto y redes sociales',
  'settings.user.form.socials.phone': 'Teléfono',
  'settings.user.form.socials.website': 'Sitio web',
  'settings.user.form.socials.facebook': 'Facebook',
  'settings.user.form.socials.twitter': 'Twitter',
  'settings.user.form.socials.instagram': 'Instagram',
  'settings.user.form.socials.linkedin': 'LinkedIn',
  'settings.user.form.yes': 'Sí',
  'settings.user.form.no': 'No',

  'settings.user.form.ui.update': 'Actualizar',

  'settings.user.errors.username.length': 'Nombre de usuario debe ser entre 3 y 20 caracteres de largo',
  'settings.user.errors.username.disallowed': 'El nombre de usuario no puede ser "me"',
  'settings.user.errors.username.pattern': 'El nombre de usuario sólo puede contener letras, números y caracteres: . _ -',
  'settings.user.errors.username.required': 'El nombre de usuario no puede estar vacío',

  'settings.user.errors.given_name.length': 'El nombre debe ser entre 1 y 45 caracteres de largo',
  'settings.user.errors.given_name.required': 'El nombre no puede estar vacío',

  'settings.user.errors.family_name.length': 'El apellido debe ser entre 1 y 45 caracteres de largo',
  'settings.user.errors.family_name.required': 'El apellido no puede estar vacío',

  'settings.user.errors.institution_name.max': 'El nombre de la institución no puede ser superior a 256 caracteres',
  'settings.user.errors.institution_name.required': 'El nombre de la institución no puede estar vacío',

  'settings.user.errors.institution_position.max': 'La posición en la institución no puede ser superior a 256 caracteres',
  'settings.user.errors.institution_position.required': 'La posición en la institución no puede estar vacía',

  'settings.user.errors.professional_affiliation.max': 'La afiliación profesional no puede ser superior a 256 caracteres',
  'settings.user.errors.professional_affiliation.required': 'La afiliación profesional no puede estar vacía',

  'settings.user.errors.location_country.max': 'País no puede ser superior a 256 caracteres',
  'settings.user.errors.location_country.required': 'País no puede estar vacío',

  'settings.user.errors.location_province.max': 'El estado no puede ser superior a 256 caracteres',
  'settings.user.errors.location_province.required': 'El estado no puede estar vacío',

  'settings.user.errors.location_city.max': 'La ciudad no puede ser superior a 256 caracteres',
  'settings.user.errors.location_city.required': 'La ciudad no puede estar vacía',

  'settings.user.errors.phone.pattern': 'El teléfono sólo puede contener un código de país +(1 a 3 dígitos) y número (máximo 12 dígitos)',

  'settings.user.errors.socials_website.max': 'El sitio web no puede ser superior a 256 caracteres',
  'settings.user.errors.socials_website.uri': 'Ingrese un sitio web válido (https://my-website.com)',

  'settings.user.errors.socials_facebook.max': 'El usuario de Facebook no puede ser superior a 256 caracteres',

  'settings.user.errors.socials_instagram.max': 'El usuario de Instagram no puede ser superior a 256 caracteres',

  'settings.user.errors.socials_twitter.max': 'El usuario de Twitter no puede ser superior a 256 caracteres',

  'settings.user.errors.socials_linkedin.max': 'El usuario de LinkedIn no puede ser superior a 256 caracteres',

  'settings.user.errors.bio.max': 'La bio no puede ser superior a 256 caracteres',

  'settings.errors.header': 'No se pudo actualizar su información',
  'settings.user.menu.header': 'Ajustes de usuario',
  'settings.user.menu.account': 'Cuenta',
  'settings.user.menu.privacy': 'Privacidad',
  'settings.user.menu.advanced': 'Avanzado'
};

const BECOME_CURATOR = {
  'become_curator.form.description.label': '¿Por qué quieres ser curador?',
  'become_curator.form.actions.submit': 'Enviar',

  'become_curator.form.errors.description.max': 'Descripción no puede ser más de 512 caracteres',
  'become_curator.form.errors.description.min': 'Descripción no puede ser más corto que 100 caracteres',
  'become_curator.form.errors.description.required': 'Descripción no puede estar vacía',

  'become_curator.form.errors.header': 'No se pudo procesar su aplicación',
  'become_curator.form.success.header': 'Su solicitud fue enviada con éxito',

  'become_curator.link.back_to_applications': 'Volver a aplicaciones',

  'become_curator.btn.create_new_application': 'Crear nueva aplicación',
  'become_curator.past_applications.errors.header': 'No se pudo recuperar sus aplicaciones anteriores',
  'become_curator.past_applications.empty.header': 'Usted no tiene aplicaciones anteriores',

  'become_curator.past_applications.active.header': 'Aplicación activa: ',
  'become_curator.past_applications.active.empty.header': 'Usted no tiene ninguna aplicación activa',

  'become_curator.past_applications.header': 'Aplicaciones anteriores: ',

  'become_curator.application.header': 'Razón de aplicación: ',
  'become_curator.application.date': 'Fecha: ',
  'become_curator.application.status': 'Estado: ',
  'become_curator.application.status.accepted': 'Aceptado',
  'become_curator.application.status.denied': 'Negado',
  'become_curator.application.status.active': 'En revisión'
};

const ADMIN = {
  'admin.dashboard.curator_requests.link': 'Ir a solicitudes de curador',

  'admin.dashboard.menu.header': 'Menú de administrador',
  'admin.dashboard.menu.dashboard': 'Dashboard',
  'admin.dashboard.menu.curator_requests': 'Solicitudes de curator',
  'admin.dashboard.menu.resource_management': 'Gestión de los recursos',

  'admin.curator_requests.header': 'Solicitudes de curator',
  'admin.curator_requests.error.header': 'No se pudo obtener la lista de aplicaciones de curador',
  'admin.curator_requests.empty.header': 'No aplicaciones actuales',
  'admin.curator_requests.empty.description': 'No hay aplicaciones de curador en este momento',
  'admin.curator_request.current_role': 'ROL ACTUAL: ',
  'admin.curator_request.active.empty.header': 'No aplicaciones activas',
  'admin.curator_request.active.empty.description': 'No hay aplicaciones activas en este momento',

  'admin.curator_request.process_application.confirm_modal.error.header': 'No se pudo procesar esta aplicación',
  'admin.curator_request.process_application.confirm_modal.header.approve': '¿Estás seguro de que quieres aprobar esta aplicación?',
  'admin.curator_request.process_application.confirm_modal.header.deny': '¿Estás seguro de que quieres negar esta aplicación?',
  'admin.curator_request.process_application.confirm_modal.content.approve.1': 'Si aprueba esta aplicación, el usuario será ascendido a curador y tendrá acceso a comentarios y revisiones de transformaciones sin publicar',
  'admin.curator_request.process_application.confirm_modal.content.deny.1': 'Si usted niega esta aplicación, el usuario permanecerá como contribuyente y tendrá que presentar otra solicitud para ser revisado de nuevo',

  'admin.curator_request.date': 'Fecha: ',
  'admin.curator_request.detail.header': 'Detalle: ',
  'admin.curator_request.ui.approve': 'Aprobar',
  'admin.curator_request.ui.deny': 'Negar',

  'admin.resource_management.header': 'Gestión de los recursos',
  'admin.resource_management.errors.header': 'No se pudo conseguir los recursos',
  'admin.resource_management.info_message.title': '¿De qué se trata esta sección?',
  'admin.resource_management.info_message.description': 'A continuación encontrará todos los recursos seleccionables correspondientes a los formularios de documentos de transformación. Se dividen en 8 categorías. Puede agregar o editar recursos en los 3 idiomas compatibles (inglés, español y portuguese). Tenga en cuenta que la edición de un recurso dado también afectará a todos los documentos de transformación publicados e inéditos que habían seleccionado ese recurso. Crear un nuevo recurso requiere la entrada de cada uno de los 3 idiomas.',
  'admin.resource_management.edit_modal.header': 'Editar {category} #{id}',
  'admin.resource_management.edit_modal.errors.header': 'No se pudo editar el recurso',
  'admin.resource_management.add_modal.header': 'Agregar recurso {category}',
  'admin.resource_management.add_modal.errors.header': 'No se pudo añadir nuevo recurso',

  'admin.resource_management.table.id': 'ID',
  'admin.resource_management.table.resource_name': 'Nombre del recurso',
  'admin.resource_management.table.short_name': 'Nombre corto',
  'admin.resource_management.table.actions.add': 'Añadir',

  'admin.resource_management.table.titles.climates': 'Climas',
  'admin.resource_management.table.titles.currencies': 'Monedas',
  'admin.resource_management.table.titles.source_classes': 'Clases de fuente',
  'admin.resource_management.table.titles.source_sub_classes': 'Subclases de fuente',
  'admin.resource_management.table.titles.source_types': 'Tipos de fuente',
  'admin.resource_management.table.titles.trl_eu': 'Nivel de preparación tecnológica',
  'admin.resource_management.table.titles.sustainable_development_goals': 'Objetivos de Desarrollo Sostenible',
  'admin.resource_management.table.titles.locations': 'Ubicaciones',

  'admin.resource_management.table.titles.climate': 'Clima',
  'admin.resource_management.table.titles.currency': 'Moneda',
  'admin.resource_management.table.titles.currency_unit': 'Unidad de moneda',
  'admin.resource_management.table.titles.source_class': 'Clase de fuente',
  'admin.resource_management.table.titles.source_sub_class': 'Subclase de fuente',
  'admin.resource_management.table.titles.source_type': 'Tipo de fuente',
  'admin.resource_management.table.titles.sustainable_development_goal': 'Objetivo de Desarrollo Sostenible',
  'admin.resource_management.table.titles.location': 'Ubicación',
  'admin.resource_management.table.titles.location_short_name': 'Nombre corto de la ubicación',

  'admin.resource_management.modal.form.en.label': 'Inglés {title}',
  'admin.resource_management.modal.form.es.label': 'Español {title}',
  'admin.resource_management.modal.form.pt.label': 'Portugués {title}'
};

const MY_CONTRIBUTIONS = {
  'my_contributions.error.get.header': 'No se pudo conseguir la lista de sus contribuciones.',
  'my_contributions.error.post.header': 'No se pudo crear un nuevo documento de transformación.',

  'my_contributions.header': 'Mis contribuciones',

  'my_contributions.ongoing.title': 'En progreso:',
  'my_contributions.ongoing.empty.header': 'Sin transformaciones en progreso',
  'my_contributions.ongoing.empty.description': 'No tienes transformaciones en progreso.',

  'my_contributions.changes_requested.title': 'Cambios solicitados:',
  'my_contributions.changes_requested.empty.header': 'No se solicitan cambios',
  'my_contributions.changes_requested.empty.description': 'Ningún curador ha solicitado cambios en cualquiera de sus transformaciones.',

  'my_contributions.awaiting_curation.title': 'Esperando curación:',
  'my_contributions.awaiting_curation.empty.header': 'Sin transformaciones esperando curación',
  'my_contributions.awaiting_curation.empty.description': 'No tienes transformaciones que necesitan ser curadas. Trate de publicar una transformación para recibir comentarios de uno de nuestros curadores.',

  'my_contributions.in_curation.title': 'En curación:',
  'my_contributions.in_curation.empty.header': 'Sin transformaciones en curación',
  'my_contributions.in_curation.empty.description': 'No tienes transformaciones que están siendo curadas. Si usted tiene una transformación en necesidad de curación, espere hasta que uno de los curadores comience a trabajar en su transformación sugerida.',

  'my_contributions.add_btn': 'Añadir nuevo'
};

const AWAITING_CURATIONS = {
  'awaiting_curations.errors.header': 'No se pudo conseguir la lista de transformaciones de curación en espera.',

  'awaiting_curations.awaiting_curation.title': 'Transformaciones en necesidad de curación',
  'awaiting_curations.awaiting_curation.empty.header': 'No hay transformaciones en necesidad de curación',
  'awaiting_curations.awaiting_curation.empty.description': 'No hay transformaciones que necesitan ser curadas.',

  'awaiting_curations.awaiting_curation.post.error_modal.title': 'No se pudo curar esta transformación.',
  'awaiting_curations.awaiting_curation.post.error_modal.buttons.close': 'Cerrar',

  'awaiting_curations.in_curation.title': 'Sus transformaciones en curación',
  'awaiting_curations.in_curation.empty.header': 'Sin transformaciones en curación',
  'awaiting_curations.in_curation.empty.description': 'Usted no está curando ninguna transformación actualmente. Puede empezar a curar una haciendo clic en cualquiera de las transformaciones en la lista de abajo.'
};

const EDIT_CONTRIBUTION = {
  'edit_contribution.error.header': 'No se pudo editar este documento de transformación.',
  'edit_contribution.error.status.description': 'Las transformaciones sólo se pueden actualizar cuando están en progreso o tienen cambios solicitados por un curador.'
};

const DELETE_CONTRIBUTION = {
  'delete_contribution.confirm_modal.header': '¿Estás seguro de querer eliminar este documento de transformación?',
  'delete_contribution.confirm_modal.description.1': 'Este proceso no se puede deshacer.',

  'delete_contribution.confirm_modal.actions.delete.label': 'Eliminar',
  'delete_contribution.confirm_modal.actions.close.label': 'Cancelar',

  'delete_contribution.error.header': 'No se pudo eliminar este documento de transformación.'
};

const CURATE_CONTRIBUTION = {
  'curate_contribution.error.header': 'No se pudo curar este documento de transformación.',

  'curate_contribution.form.error.header': 'No se pudo curar esta transformación.',
  'curate_contribution.form.ui.save_and_exit': 'Guardar y salir',
  'curate_contribution.form.ui.delete': 'Eliminar curación',
  'curate_contribution.form.ui.submit': 'Enviar curación',

  'curate_contribution.form.heading.message.header': 'Instrucciones del autor para esta sección',

  'curate_contribution.form.comment_basic_information.label': '¿Algún comentario sobre la información básica?',
  'curate_contribution.form.comment_transformation_process.label': '¿Algún comentario sobre el proceso de transformación?',
  'curate_contribution.form.comment_scalability.label': '¿Algún comentario sobre la escalabilidad?',
  'curate_contribution.form.comment_sustainability.label': '¿Algún comentario sobre la sostenibilidad?',
  'curate_contribution.form.comment_market.label': '¿Algún comentario sobre el mercado?',
  'curate_contribution.form.comment_regulations.label': '¿Algún comentario sobre las regulaciones?',
  'curate_contribution.form.publish.label': '¿Esta transformación está lista para ser publicada?',

  'curate_contribution.form.errors.deny_comment_check': 'Al menos un comentario debe ser añadido si sientes que esta transformación no está lista para ser publicada.',

  'curate_contribution.modals.delete.header': '¿Estás seguro de que quieres eliminar esta curación en curso?',
  'curate_contribution.modals.delete.description.1': 'Esto sólo eliminará esta curación en progreso, no eliminará la transformación.',
  'curate_contribution.modals.delete.description.2': 'Siempre puedes curar esta transformación de nuevo si ningún otro curador la maneja antes.',
  'curate_contribution.modals.delete.actions.delete.label': 'Eliminar',
  'curate_contribution.modals.delete.actions.close.label': 'Cancelar',
  'curate_contribution.modals.delete.error.header': 'No se pudo eliminar esta curación en progreso.',

  'curate_contribution.modals.commit.header': '¿Esta curación está lista para ser enviada?',
  'curate_contribution.modals.commit.description.1': 'Si decide publicar esta transformación, el autor no verá ninguno de sus comentarios. Publicar una transformación hace que sea visible para cualquiera en la plataforma. Este proceso puede tomar hasta 30 segundos mientras traducimos el documento, por favor no cierre esta ventana hasta que este proceso termine.',
  'curate_contribution.modals.commit.description.2': 'Si decide no publicar esta transformación, el autor verá sus comentarios en las secciones pertinentes y tendrá que volver a presentar su documento para otra revisión. Usted puede optar por revisar el documento nuevamente o dejar que alguien más lo maneje.',
  'curate_contribution.modals.commit.actions.submit.label': 'Enviar',
  'curate_contribution.modals.commit.actions.close.label': 'Cancelar',
  'curate_contribution.modals.commit.error.header': 'No se pudo enviar esta curación en progreso.'
};

const DELETE_CURATION = {
  'delete_curation.confirm_modal.header': '¿Estás seguro de que quieres eliminar esta curación en progreso?',
  'delete_curation.confirm_modal.description.1': 'Esto sólo eliminará esta curación en progreso, no eliminará la transformación.',
  'delete_curation.confirm_modal.description.2': 'Siempre puedes curar esta transformación de nuevo si ningún otro curador la maneja antes.',

  'delete_curation.confirm_modal.actions.delete.label': 'Eliminar',
  'delete_curation.confirm_modal.actions.close.label': 'Cancelar',

  'delete_curation.error.header': 'No se pudo eliminar esta curación en progreso.'
};

const FOOTER = {
  'footer.copyright': 'USFQ-BID',
  'footer.items.section.header': '¿Listo para unirte a nuestra comunidad Transformar?',
  'footer.items.section.text': 'Contribuyamos a proteger nuestra naturaleza y crear un futuro más sostenible.',
  'footer.items.section.btn.signup': 'Regístrate',
  'footer.items.section.btn.login': 'Iniciar sesión',
  'footer.items.section.btn.publish': 'Publicar',
  'footer.items.bar.links.about': 'Acerca de',
  'footer.items.bar.links.help': 'Ayuda',
  'footer.items.bar.links.contact': 'Contacto',
  'footer.items.bar.links.terms_and_conditions': 'Términos y condiciones'
};

const UI = {
  'ui.loading.loading': 'Cargando...',

  'ui.cancel': 'Cancelar',
  'ui.confirm': 'Confirmar',
  'ui.selected': 'Seleccionado',
  'ui.progress': 'Progreso: ',
  'ui.ok': 'Ok',
  'ui.progress.page': '{currentPage} de {pages}'
};

const NOT_FOUND = {
  'not_found.page.subtitle': 'Oops, lo siento no podemos encontrar',
  'not_found.page.something_went_wrong': 'Algo salió mal o la página no existe.',
  'not_found.page.back': 'Volver a la página principal',

  'not_found.user': 'Usuario no encontrado'
};

const COMING_SOON = {
  'coming_soon.header': 'Próximamente',
  'coming_soon.subtitle': 'En construcción',
  'coming_soon.back.btn': 'Volver a la página principal'
};

const FORM = {
  'form.multi_month_picker.january': 'Enero',
  'form.multi_month_picker.february': 'Febrero',
  'form.multi_month_picker.march': 'Marzo',
  'form.multi_month_picker.april': 'Abril',
  'form.multi_month_picker.may': 'Mayo',
  'form.multi_month_picker.june': 'Junio',
  'form.multi_month_picker.july': 'Julio',
  'form.multi_month_picker.august': 'Agosto',
  'form.multi_month_picker.september': 'Septiembre',
  'form.multi_month_picker.october': 'Octubre',
  'form.multi_month_picker.november': 'Noviembre',
  'form.multi_month_picker.december': 'Diciembre',

  'form.array_form_field.add': 'Añadir',

  'form.boolean_form_field.default.yes': 'Sí',
  'form.boolean_form_field.default.no': 'No',

  'form.yes_no_idk_form_field.default.yes': 'Sí',
  'form.yes_no_idk_form_field.default.no': 'No',
  'form.yes_no_idk_form_field.default.idk': 'No lo sé'
};

const SERVICES = {
  'services.api.errors.unknown.message': 'Ha ocurrido un error desconocido. Intenta refrescar la página. Si este error sigue sucediendo contáctate con un administrador del sistema.',
  'services.api.errors.not_found.message': 'No se encontró el recurso solicitado.',
  'services.api.errors.validation.message': 'Los datos enviados no son válidos. Por favor, comprueba que toda la información que has añadido en el formulario es correcta.',
  'services.api.errors.bad_request.message': 'El servidor no pudo responder a la solicitud. Intenta refrescar la página. Si este error sigue sucediendo contáctate con un administrador del sistema.',
  'services.api.errors.forbidden.message': 'No se te permite ver esto.',
  'services.api.errors.unauthorized.message': 'No se te permite ver esto. ¿Estás conectado a tu cuenta?',
  'services.api.errors.invalidated.message': 'Parece que tu sesión ha terminado. Intenta refrescar la página.'
};

const USER_PROFILE = {
  'user_profile.error.header': 'No se pudo obtener la información para este usuario.',
  'user.profile.side_profile.at': 'en',

  'user.profile.contributions.header': 'Oportunidades',
  'user.profile.contributions.header.subtitle': 'Mira las últimas oportunidades',
  'user.profile.contributions.empty.header': 'No hay oportunidades disponibles todavía',
  'user.profile.contributions.empty.description': 'Este usuario no ha publicado ninguna oportunidad',

  'user.profile.curations.header': 'Curaciones',
  'user.profile.curations.header.subtitle': 'Mira las últimas curaciones',
  'user.profile.curations.empty.header': 'No hay curaciones disponibles todavía',
  'user.profile.curations.empty.description': 'Este usuario no ha curado ninguna oportunidad'
};

const TERMS_AND_CONDITIONS = {
  'terms_and_conditions.title': 'Términos y condiciones',

  'terms_and_conditions.content.1': 'El uso del sitio web de Transformar (el Sitio Web) se rige por los términos y condiciones establecidos a continuación. Al utilizar este Sitio Web, usted acepta tales términos y condiciones, ya que pueden ser modificados por el Banco Interamericano de Desarrollo (“BID”) de vez en cuando y publicados en este Sitio Web. Algunas características o funciones del Sitio Web, o vinculadas al Sitio Web, pueden estar sujetas a términos y condiciones adicionales o diferentes (“Sitios asociados”). Los sitios asociados pueden o no estar bajo el control o la influencia del BID. El BID rechaza cualquier responsabilidad o responsabilidad por el contenido o funcionamiento de los Sitios Asociados que no funcionan realmente por el BID y subraya que los usuarios estarán sujetos a los términos y condiciones aplicables a dichos Sitios Asociados, ya sea o no operados por el BID.',
  'terms_and_conditions.content.2': 'El BID mantiene este Sitio Web sólo con fines informativos. Los usuarios pueden descargar, copiar y reimprimir información del Sitio Web para fines no comerciales, con sujeción a los términos y condiciones contenidos en los materiales expresamente atribuidos a terceros y siempre que Transformar sea citado como fuente del material originario. Los usuarios no pueden reimprimir o distribuir material para el cual se identifica a un tercero como titular de derechos de autor, a menos que el permiso para hacerlo se permita expresamente para dicho material de terceros. Los usuarios no pueden colocar “frames” alrededor del material del Sitio Web sin la autorización expresa por escrito del BID.',
  'terms_and_conditions.content.3': 'El contenido de materiales expresamente atribuidos o identificados como producidos por terceros, son propiedad y responsabilidad de dichos terceros y no del BID. El uso de estos documentos está sujeto a los términos y condiciones legales contenidos en ellos, en su caso.',
  'terms_and_conditions.content.4': 'Este Sitio Web también contiene enlaces a sitios web e información no bajo el control del BID. El BID proporciona estos enlaces y cualquier información como conveniencia para los usuarios de su Sitio Web y no es responsable de sus contenidos. La presencia de un enlace no implica aprobación o aprobación de su contenido por el BID.',
  'terms_and_conditions.content.5': 'El BID y sus afiliados han establecido o colaboran con otras organizaciones en el establecimiento y mantenimiento de otros recursos basados en la web. Cada uno de estos otros recursos basados en la web está sujeto a términos y condiciones que pueden ser además o suplantar estos términos y condiciones.',
  'terms_and_conditions.content.6': 'Toda la información en el Sitio Web se proporciona “AS IS”, sin garantía expresa o implícita de ningún tipo, incluyendo pero no limitada a ninguna garantía de mercadeo, idoneidad, no infringimiento o aptitud para cualquier propósito particular. El uso de este Sitio Web es el único riesgo del usuario. Si bien el BID intenta proporcionar datos precisos, la información disponible en el Sitio Web puede utilizar recursos de datos de otras fuentes, incluidas fuentes públicas. Como tal, el BID no garantiza ni representa que ninguna información esté libre de errores, omisiones e inexactitudes posicionales. El BID no garantiza la exactitud, integridad o puntualidad de ninguno de los materiales del Sitio Web y bajo ninguna circunstancia, incluyendo pero no limitado a, negligencia, el BID será responsable de cualquier gasto o daño directo, indirecto, incidental, especial o consecuencial incurrido por causa de su uso, incluyendo gastos o daños derivados de fallos de comunicación y/o fallos informáticos relacionados con el uso del Sitio Web.',
  'terms_and_conditions.content.7': 'La información relacionada con una fecha determinada se ofrece sólo a partir de dicha fecha y el BID no tiene la obligación de actualizar ningún material en el Sitio Web. El BID puede, a su discreción, añadir periódicamente, cambiar, mejorar o actualizar los materiales en el Sitio Web sin previo aviso, y se reserva el derecho de limitar o interrumpir el Sitio Web o denegar cualquier acceso de usuario sin previo aviso.',
  'terms_and_conditions.content.8': 'Los mapas y cualquier otra información de naturaleza similar que se encuentra en este Sitio Web son sólo para referencia, y puede que no se hayan preparado para, o sean adecuados para legal, ingeniería, encuesta o cualquier otro propósito y/o uso específico. Los límites, colores, denominaciones o cualquier otra información que se muestre en cualquier mapa o de otro modo no pueden interpretarse como una opinión sobre la condición jurídica de cualquier territorio, o cualquier aprobación o aceptación de dichos límites.',
  'terms_and_conditions.content.9': 'Como condición de uso del Sitio Web, el usuario acepta indemnizar al BID de y contra cualquier y contra todas las acciones, reclamaciones, pérdidas, daños, pasivos y gastos (incluidos los honorarios de abogados razonables) derivados del uso de dicho usuario del Sitio Web, incluyendo sin limitación cualquier reclamación alegando hechos que si es cierto constituiría una violación por el usuario de estos términos y condiciones. Si el usuario está insatisfecho con cualquier material en el Sitio Web o con cualquiera de los términos y condiciones de uso del Sitio Web, el único y exclusivo remedio del usuario es interrumpir utilizando el Sitio Web.',
  'terms_and_conditions.content.10': 'El Sitio Web es controlado, operado y administrado por el BID desde sus oficinas dentro de los Estados Unidos de América y todos los datos se almacenan en los Estados Unidos de América. El BID no hace ninguna representación de que los materiales en el Sitio Web sean apropiados o disponibles para su uso en otros lugares fuera de los Estados Unidos de América. Se prohíbe el acceso a los materiales del Sitio Web en cualquier lugar donde ese contenido sea ilegal. Si accede al Sitio Web desde un lugar fuera de los Estados Unidos de América, es el único responsable del cumplimiento de las leyes locales.',
  'terms_and_conditions.content.11': 'Cualquier información presentada al BID estará sujeta a nuestra Política de Privacidad, que se incorpora en estos términos y condiciones, y está sujeta a cambios de vez en cuando según lo dispuesto en la Política de Privacidad. El Sitio Web proporciona un medio para informar sobre presuntos fraudes y otras conductas indebidas. Los informes pueden optar por no revelar sus identidades; sin embargo, si lo hacen, sus identidades constituirán información de identificación personal, sujeta a nuestra política de privacidad. Podemos ponernos en contacto con los que informan y identificarlos en relación con las investigaciones de la conducta que nos informan.',
  'terms_and_conditions.content.12': 'Las opiniones y conclusiones de autores o compiladores que figuran en este documento no son necesariamente las del BID o sus países miembros. Excepto como se ha señalado otra cosa, el material del Sitio Web es propiedad del BID y está sujeto a los derechos de autor, marca y otras leyes de propiedad intelectual de los Estados Unidos de América y otras jurisdicciones. Todos los usos del Sitio Web están sujetos a estas leyes.',
  'terms_and_conditions.content.13': 'Estos términos y condiciones se regirán, interpretarán y aplicarán de conformidad con las leyes del Distrito de Columbia, Estados Unidos de América, sin dar efecto al conflicto de las disposiciones de esa jurisdicción. Cualquier acción legal sobre el contenido del Sitio Web será presentada y resuelta por arbitraje realizado en inglés en Washington, District of Columbia, Estados Unidos de América, conforme a las normas de arbitraje comercial de la American Arbitration Association, siempre que el BID se reserva el derecho de solicitar un alivio interjuntivo temporal, preliminar y definitivo para hacer cumplir cualquiera de las disposiciones de estos términos y condiciones relativas al uso de sus derechos de autor, marcas comerciales, otros derechos de propiedad intelectual y seguridad de datos. Todas las decisiones de un único árbitro serán definitivas y vinculantes tanto para el BID como para usted y ejecutables en cualquier tribunal de jurisdicción competente. El BID y usted aceptan de otro modo la jurisdicción exclusiva de los tribunales de Washington, District of Columbia, Estados Unidos de América para la resolución de cualquier controversia que no esté sujeta al arbitraje y para la ejecución de cualquier laudo resultante de cualquier arbitraje de ese tipo.',
  'terms_and_conditions.content.14': 'Ninguna renuncia del BID de ninguna disposición de estos términos y condiciones será vinculante excepto como se establece por escrito firmada por su representante debidamente autorizado.',
  'terms_and_conditions.content.15': 'Nada en el Sitio Web constituirá o se considerará una limitación o renuncia a los privilegios e inmunidades del BID, que están específicamente reservados.'
};

const strings = {
  ...PAGE_TITLES,
  ...NAVBAR,
  ...HOME,
  ...ABOUT,
  ...SIGNUP,
  ...LOGIN,
  ...AUTH,
  ...RESEND_VERIFICATION,
  ...FORGOT_PASSWORD,
  ...EXPLORE,
  ...TRANSFORMATION,
  ...TRANSFORMATION_VIEW,
  ...SETTINGS,
  ...BECOME_CURATOR,
  ...ADMIN,
  ...MY_CONTRIBUTIONS,
  ...AWAITING_CURATIONS,
  ...EDIT_CONTRIBUTION,
  ...DELETE_CONTRIBUTION,
  ...CURATE_CONTRIBUTION,
  ...DELETE_CURATION,
  ...FOOTER,
  ...UI,
  ...NOT_FOUND,
  ...COMING_SOON,
  ...FORM,
  ...SERVICES,
  ...USER_PROFILE,
  ...TERMS_AND_CONDITIONS
};

export default strings;
