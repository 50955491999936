import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Grid, Image } from 'semantic-ui-react';
import useLocale from '../../../../hooks/i18n';
import { DYNAMIC_PUBLIC_ROUTES } from '../../../../constants/routes';
import { KEYCODES } from '../../../../constants/codes';

const MainSection = ({ title, subtitle, author, description, linkTo, onClick }) => {
  const { t } = useLocale();

  const sectionTitle = title ?? t('transformation.item.defaults.title');
  const sectionSubTitle = subtitle ?? t('transformation.item.defaults.subtitle');
  const sectionDescription = description ?? t('transformation.item.defaults.description');

  const handleOptionalHeaderClick = () => {
    onClick?.();
  };

  const handleOptionalHeaderEnter = (e) => {
    if (e.keyCode === KEYCODES.ENTER) {
      e.preventDefault();
      handleOptionalHeaderClick();
    }
  };

  return (
    <Grid.Column computer={11} tablet={10} mobile={10} className="main-content">
      <Grid>
        <Grid.Row className="header">
          <Grid.Column width={10}>
            {
              linkTo ? (
                <Link to={linkTo}>
                  <h2>{sectionTitle}</h2>
                  <p className="subtitle">{sectionSubTitle}</p>
                </Link>
              ) : (
                <div className={onClick ? 'clickable-header' : ''} onClick={handleOptionalHeaderClick} role="button" tabIndex={0} onKeyDown={handleOptionalHeaderEnter}>
                  <h2>{sectionTitle}</h2>
                  <p className="subtitle">{sectionSubTitle}</p>
                </div>
              )
            }
          </Grid.Column>

          <Grid.Column width={6} verticalAlign="middle">
            <div className="user-avatar">
              <Link to={DYNAMIC_PUBLIC_ROUTES.userProfile(author.username)}>
                <span>
                  {author.given_name} {author.family_name}
                </span>
                <Image src={author.picture} avatar />
              </Link>
            </div>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row className="description">
          <Grid.Column computer={15} mobile={16} tablet={16}>
            <p>
              {sectionDescription}
            </p>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Grid.Column>
  );
};

MainSection.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  description: PropTypes.string,
  author: PropTypes.shape({
    family_name: PropTypes.string,
    given_name: PropTypes.string,
    picture: PropTypes.string,
    username: PropTypes.string
  }).isRequired,
  linkTo: PropTypes.string,
  onClick: PropTypes.func
};

MainSection.defaultProps = {
  title: null,
  subtitle: null,
  description: null,
  linkTo: null,
  onClick: null
};

export default MainSection;
