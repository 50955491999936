import React from 'react';
import { Dropdown } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import useQueryParams from '../../../hooks/queryParams';
import { useNavigate } from 'react-router-dom';

const NavbarDropdownMenuItem = ({ link, title, description, sectionQueryParam }) => {
  const { navigateWithQueryParams } = useQueryParams();
  const navigate = useNavigate();

  const handleItemClick = () => {
    if (sectionQueryParam) {
      navigateWithQueryParams(link, {
        scrollTo: sectionQueryParam
      });
    } else {
      navigate(link);
    }
  };

  return (
    <Dropdown.Item
      className="navbar__dropdown_item"
      onClick={handleItemClick}
    >
      <h3>{title}</h3>
      {description && <p>{description}</p>}
    </Dropdown.Item>
  );
};

NavbarDropdownMenuItem.propTypes = {
  link: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  sectionQueryParam: PropTypes.string
};

NavbarDropdownMenuItem.defaultProps = {
  description: null,
  sectionQueryParam: null
};

export default NavbarDropdownMenuItem;
