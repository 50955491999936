import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';

const BackButton = ({ text, className, linkTo, onClick }) => {
  return (
    <button className={`back-button ${className}`} onClick={onClick}>
      <FontAwesomeIcon icon="fa-solid fa-arrow-left" />
      {text && !linkTo && <span className="back-button__text">{text}</span>}
      {text && linkTo && (
        <Link className="back-button__text" to={linkTo}>
          {text}
        </Link>
      )}
    </button>
  );
};

BackButton.propTypes = {
  text: PropTypes.string,
  className: PropTypes.string,
  linkTo: PropTypes.string,
  onClick: PropTypes.func
};

BackButton.defaultProps = {
  text: null,
  className: '',
  linkTo: null,
  onClick: null
};

export default BackButton;
