import React from 'react';
import { Button, Icon, Menu, Table } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import useLocale from '../../../../hooks/i18n';

const ResourceManagementTable = ({ pages, setPage, page, onAddResourceClicked }) => {
  const { t } = useLocale();
  const handleIndexClick = (index) => () => {
    setPage(index);
  };

  const handlePrevious = () => {
    setPage((prevState) => {
      return Math.max(0, prevState - 1);
    });
  };

  const handleNext = () => {
    setPage((prevState) => {
      return Math.min(pages.length - 1, prevState + 1);
    });
  };

  const menuPaginationItems = [];
  for (let i = 0; i < pages.length; i++) {
    menuPaginationItems.push(
      <Menu.Item
        key={i}
        as="a"
        active={page === i}
        onClick={handleIndexClick(i)}
      >
        {i + 1}
      </Menu.Item>
    );
  }

  return (
    <Table celled>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell colSpan="3">
            <Menu pagination className="resource-pagination-menu">
              <Menu.Item as="a" icon onClick={handlePrevious}>
                <Icon name="chevron left" />
              </Menu.Item>
              {menuPaginationItems}
              <Menu.Item as="a" icon onClick={handleNext}>
                <Icon name="chevron right" />
              </Menu.Item>
            </Menu>
          </Table.HeaderCell>
        </Table.Row>
        <Table.Row>
          <Table.HeaderCell colSpan={3} textAlign="center">
            {pages[page].title}
          </Table.HeaderCell>
        </Table.Row>
        <Table.Row>
          <Table.HeaderCell>{t('admin.resource_management.table.id')}</Table.HeaderCell>
          <Table.HeaderCell>{t('admin.resource_management.table.resource_name')}</Table.HeaderCell>
          <Table.HeaderCell />
        </Table.Row>
      </Table.Header>
      <Table.Body>{pages[page].tableRow}</Table.Body>
      <Table.Footer>
        <Table.Row>
          <Table.HeaderCell />
          <Table.HeaderCell colSpan={2}>
            <Button
              className="new-resource-btn"
              color="green"
              onClick={onAddResourceClicked}
              content={t('admin.resource_management.table.actions.add')}
            />
          </Table.HeaderCell>
        </Table.Row>
      </Table.Footer>
    </Table>
  );
};

ResourceManagementTable.propTypes = {
  pages: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string,
    tableRow: PropTypes.node,
    key: PropTypes.string
  })).isRequired,
  setPage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  onAddResourceClicked: PropTypes.func.isRequired
};

export default ResourceManagementTable;
