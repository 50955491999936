/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable max-lines */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import BasicInfoFormView from './views/BasicInfoFormView';
import ScalabilityFormView from './views/ScalabilityFormView';
import SustainabilityFormView from './views/SustainabilityFormView';
import TransformationProcessFormView from './views/TransformationProcessFormView';
import MarketFormView from './views/MarketFormView';
import RegulationsFormView from './views/RegulationsFormView';
import useLocale from '../../../hooks/i18n';
import { Grid, Header, Icon, Message } from 'semantic-ui-react';
import PaginatedFormButtonRow from '../../common/form/paginatedFormButtonRow';
import VerticalSteps from '../../common/verticalSteps';
import ProgressBar from '../../common/progressBar';
import BackButton from '../../common/backButton';
import { PROTECTED_ROUTES } from '../../../constants/routes';
import { useNavigate } from 'react-router-dom';
import ImageAssetPicker from '../../common/imageAssetPicker';
import { TRANSFORMATION_IMAGES } from '../../../constants/assets';
import addImgIcon from '../../../assets/icons/add_image.svg';

const pages = [
  {
    title: 'transformation.form.basic_information.title',
    heading: 'transformation.form.basic_information.heading',
    ViewComponent: BasicInfoFormView,
    requiredResources: [
      'source_types',
      'source_classes',
      'source_sub_classes',
      'trl_eu',
      'locations'
    ],
    name: 'basic_information',
    curationAccessor: 'comment_basic_information'
  },
  {
    title: 'transformation.form.transformation_process.title',
    heading: 'transformation.form.transformation_process.heading',
    ViewComponent: TransformationProcessFormView,
    requiredResources: [],
    name: 'transformation_process',
    curationAccessor: 'comment_transformation_process'
  },
  {
    title: 'transformation.form.scalability.title',
    heading: 'transformation.form.scalability.heading',
    ViewComponent: ScalabilityFormView,
    requiredResources: ['climates'],
    name: 'scalability',
    curationAccessor: 'comment_scalability'
  },
  {
    title: 'transformation.form.sustainability.title',
    heading: 'transformation.form.sustainability.heading',
    ViewComponent: SustainabilityFormView,
    requiredResources: ['sustainable_development_goals'],
    name: 'sustainability',
    curationAccessor: 'comment_sustainability'
  },
  {
    title: 'transformation.form.market.title',
    heading: 'transformation.form.market.heading',
    ViewComponent: MarketFormView,
    requiredResources: ['currencies'],
    name: 'market',
    curationAccessor: 'comment_market'
  },
  {
    title: 'transformation.form.regulations.title',
    heading: 'transformation.form.regulations.heading',
    ViewComponent: RegulationsFormView,
    requiredResources: [],
    name: 'regulations',
    curationAccessor: 'comment_regulations'
  }
];

const TransformationFormView = ({
  onFinish,
  onSave,
  resources,
  form,
  error,
  curation,
  metadata,
  onSaveImage,
  onImagePickerClose,
  imagePickerError,
  imagePickerLoading,
  imagePickerOpen,
  setImagePickerOpen
}) => {
  const { t } = useLocale();
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const {
    title,
    heading,
    ViewComponent,
    requiredResources,
    name: formName,
    curationAccessor
  } = pages[page];
  const {
    handleSubmit,
    formState: { isSubmitting }
  } = form;

  const [selectedImageURL, setSelectedImageURL] = React.useState(
    metadata.picture ?? null
  );

  const resourceProps = requiredResources.reduce((obj, cur) => {
    obj[cur] = resources[cur];
    return obj;
  }, {});

  const handleVerticalStepClick = (index) => async () => {
    await handleSubmit(onSave)(formName, form.getValues());
    setPage(index);
  };

  const handleBackClick = async () => {
    await handleSubmit(onSave)(formName, form.getValues());
    navigate(PROTECTED_ROUTES.contributions);
  };

  const verticalStepsOptions = pages.map(({ title }, index) => {
    return {
      title: `${index + 1}. ${t(title)}`,
      completed: false,
      active: page === index,
      onClick: handleVerticalStepClick(index)
    };
  });

  const handlePrevious = async () => {
    await handleSubmit(onSave)(formName, form.getValues());
    setPage((prevState) => {
      return Math.max(0, prevState - 1);
    });
  };

  const handleNext = async () => {
    await handleSubmit(onSave)(formName, form.getValues());
    setPage((prevState) => {
      return Math.min(pages.length - 1, prevState + 1);
    });
  };

  const handleFinish = async () => {
    await handleSubmit(onFinish)(form.getValues());
  };

  const handleSaveImage = async (imageURL) => {
    await onSaveImage(imageURL);
  };

  return (
    <div className="transformation-form-container">
      <Grid className="transformation-form-view" columns={2}>
        <Grid.Column computer={5} tablet={5} mobile={16} className="transformation-form-menu-steps">
          <BackButton
            text={t('transformation.form.ui.save_and_exit')}
            className="form-view__back-btn"
            onClick={handleBackClick}
          />
          <VerticalSteps
            loading={isSubmitting}
            options={verticalStepsOptions}
          />
          <ProgressBar page={page + 1} totalPages={pages.length} />
        </Grid.Column>
        <Grid.Column computer={11} tablet={11} mobile={16} className="transformation-form-tab">
          <div className="form-tab-container">
            <Header className="form-tab__title" as="h1" textAlign="left">
              {t(title)}
            </Header>

            <Message
              hidden={!error}
              error
              header={t('transformation.form.ui.error.header')}
              content={error}
            />

            {/* TEMPORARY UNTIL WE FIND A PRETTIER WAY */}
            {Object.keys(form.formState.errors).length > 0 && (
              <Message
                hidden={!form.formState.errors}
                error
                header="Validation Error (This is a temporary message, it will become prettier in the future)"
                content={JSON.stringify(form.formState.errors)}
              />
            )}

            <Message info>
              <Message.Header>
                <Icon name="info circle" color="green" size="large" />
                <span>{t('transformation.form.heading.message.header')}</span>
              </Message.Header>
              <p>{t(heading)}</p>
            </Message>

            {curation && (
              <Message info>
                <Message.Header>
                  <Icon name="info circle" color="blue" size="large" />
                  <span>{t('transformation.form.curation.header')}</span>
                </Message.Header>
                <p>
                  {curation[curationAccessor] ||
                    t('transformation.form.curation.empty.text')}
                </p>
              </Message>
            )}

            {page === 0 && (
              <div className="ui form image-picker-container transformation-card">
                <div className="field">
                  <label htmlFor="transformation-image-picker">{t('transformation.form.image.title')}</label>
                  <ImageAssetPicker
                    header={t('transformation.form.image.picker.header')}
                    errorHeader={t('transformation.form.image.picker.errors.header')}
                    id="transformation-image-picker"
                    error={imagePickerError}
                    open={imagePickerOpen}
                    loading={imagePickerLoading}
                    onClose={onImagePickerClose}
                    images={TRANSFORMATION_IMAGES}
                    onConfirm={handleSaveImage}
                    selectedImageURL={selectedImageURL}
                    setSelectedImageURL={setSelectedImageURL}
                    disabled={!!curation}
                    trigger={
                      !selectedImageURL ? (
                        <div
                          className="choose-image-placeholder"
                          onClick={() => {
                            setImagePickerOpen(true);
                          }}
                          role="button"
                          tabIndex="0"
                        >
                          <img
                            src={addImgIcon}
                            alt="choose transformation icon"
                          />
                          <p>
                            {t(
                              'transformation.form.image.picker.click_to_add_image'
                            )}
                          </p>
                        </div>
                      ) : (
                        <div
                          className="transformation-shown-image-container"
                          onClick={() => {
                            setImagePickerOpen(true);
                          }}
                          role="button"
                          tabIndex="0"
                        >
                          <img
                            className="transformation-shown-image"
                            src={selectedImageURL}
                            alt="transformation"
                          />
                          <p className="image-text">{t('transformation.form.image.picker.change_image')}</p>
                        </div>
                      )
                    }
                  />
                </div>
              </div>
            )}
            <ViewComponent form={form} {...resourceProps} />

            <PaginatedFormButtonRow
              onPrevious={handlePrevious}
              onFinish={handleFinish}
              onNext={handleNext}
              previousVisible={page !== 0}
              finishVisible={page === pages.length - 1}
              loading={isSubmitting}
            />
          </div>
        </Grid.Column>
      </Grid>
    </div>
  );
};

TransformationFormView.propTypes = {
  onFinish: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  resources: PropTypes.shape({
    climates: PropTypes.arrayOf(PropTypes.shape({})),
    currencies: PropTypes.arrayOf(PropTypes.shape({})),
    locations: PropTypes.arrayOf(PropTypes.shape({})),
    source_classes: PropTypes.arrayOf(PropTypes.shape({})),
    source_sub_classes: PropTypes.arrayOf(PropTypes.shape({})),
    source_types: PropTypes.arrayOf(PropTypes.shape({})),
    trl_eu: PropTypes.arrayOf(PropTypes.shape({})),
    sustainable_development_goals: PropTypes.arrayOf(PropTypes.shape({}))
  }).isRequired,
  form: PropTypes.shape({
    formState: PropTypes.shape({
      isSubmitting: PropTypes.bool,
      errors: PropTypes.shape({})
    }),
    handleSubmit: PropTypes.func,
    getValues: PropTypes.func
  }).isRequired,
  metadata: PropTypes.shape({
    picture: PropTypes.string
  }),
  error: PropTypes.string,
  curation: PropTypes.shape({}),
  onSaveImage: PropTypes.func.isRequired,
  imagePickerLoading: PropTypes.bool.isRequired,
  imagePickerError: PropTypes.string,
  imagePickerOpen: PropTypes.bool.isRequired,
  setImagePickerOpen: PropTypes.func.isRequired,
  onImagePickerClose: PropTypes.func.isRequired
};

TransformationFormView.defaultProps = {
  metadata: {},
  error: null,
  imagePickerError: null,
  curation: null
};

export default TransformationFormView;
