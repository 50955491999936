import React from 'react';
import LoginFormLogic from './LoginFormLogic';
import useAuth from '../../../hooks/auth';
import useLocale from '../../../hooks/i18n';

const LoginForm = () => {
  const { signIn } = useAuth();
  const { t } = useLocale();

  const handleSubmit = async ({ email, password, staySignedIn }) => {
    try {
      await signIn(email, password, { staySignedIn });
    } catch (signInError) {
      throw signInError.getFriendlyMessage(t);
    }
  };

  return (
    <LoginFormLogic onSubmit={handleSubmit} />
  );
};

export default LoginForm;
