import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import Navbar from '../navbar';
import Footer from '../footer';

const PageWrapper = ({ children }) => {
  const [isMobileNavbarShown, setIsMobileNavbarShown] = useState(false);
  const handleBarsClicked = () => {
    setIsMobileNavbarShown((prevState) => !prevState);
  };

  return (
    <Fragment>
      <Navbar
        isMobileNavbarShown={isMobileNavbarShown}
        onBarsClicked={handleBarsClicked}
      />
      <div
        className={`page-wrapper ${
          isMobileNavbarShown ? 'mobile-navbar-shown' : ''
        }`}
      >
        {children}
      </div>
      <Footer />
    </Fragment>
  );
};

PageWrapper.propTypes = {
  children: PropTypes.node.isRequired
};

export default PageWrapper;
