import React from 'react';
import UserNavbar from './user/UserNavbar';
import GuestNavbar from './guest/GuestNavbar';
import useAuth from '../../../hooks/auth';
import PropTypes from 'prop-types';

const Navbar = ({ isMobileNavbarShown, onBarsClicked }) => {
  const { signedIn, me } = useAuth();

  if (signedIn) {
    return (
      <UserNavbar
        isMobileNavbarShown={isMobileNavbarShown}
        onBarsClicked={onBarsClicked}
        user={me}
      />
    );
  }

  return (
    <GuestNavbar
      isMobileNavbarShown={isMobileNavbarShown}
      onBarsClicked={onBarsClicked}
    />
  );
};

Navbar.propTypes = {
  onBarsClicked: PropTypes.func.isRequired,
  isMobileNavbarShown: PropTypes.bool.isRequired
};

export default Navbar;
