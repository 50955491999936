import React from 'react';
import PropTypes from 'prop-types';
import TransformationItemContainer from '../TranformationItemContainer';
import ImageSection from '../sections/ImageSection';
import MainSection from '../sections/MainSection';
import CrowdSourcingSection from '../sections/CrowdSourcingSection';
import { DYNAMIC_PUBLIC_ROUTES } from '../../../../constants/routes';

const PublishedTransformationItem = ({ aggregatedTransformation }) => {
  const { transformation, author, likes, user_liked } = aggregatedTransformation;

  return (
    <TransformationItemContainer>
      <ImageSection
        picture={transformation.picture}
      />
      <MainSection
        title={transformation.producing_species_name}
        subtitle={transformation.organic_material_name}
        description={transformation.brief_biomass_description}
        author={author}
        linkTo={DYNAMIC_PUBLIC_ROUTES.transformationPage(transformation.id_transformation)}
      />
      <CrowdSourcingSection
        id={transformation.id_transformation}
        likes={likes}
        userLiked={user_liked}
      />
    </TransformationItemContainer>
  );
};

PublishedTransformationItem.propTypes = {
  aggregatedTransformation: PropTypes.shape({
    transformation: PropTypes.shape({
      id_transformation: PropTypes.number.isRequired,
      picture: PropTypes.string.isRequired,
      producing_species_name: PropTypes.string,
      organic_material_name: PropTypes.string,
      brief_biomass_description: PropTypes.string
    }).isRequired,
    author: PropTypes.shape({
      family_name: PropTypes.string.isRequired,
      given_name: PropTypes.string.isRequired,
      picture: PropTypes.string.isRequired,
      username: PropTypes.string.isRequired
    }).isRequired,
    likes: PropTypes.shape({}).isRequired,
    user_liked: PropTypes.shape({}).isRequired
  }).isRequired
};

export default PublishedTransformationItem;
