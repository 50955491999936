import TransformationPublishForm from './TransformationPublishForm';
import TransformationFormLogic from './TransformationFormLogic';
import TransformationFormView from './TransformationFormView';

import BasicInfoFormView from './views/BasicInfoFormView';
import TransformationProcessFormView from './views/TransformationProcessFormView';
import ScalabilityFormView from './views/ScalabilityFormView';
import SustainabilityFormView from './views/SustainabilityFormView';
import MarketFormView from './views/MarketFormView';
import RegulationsFormView from './views/RegulationsFormView';

export {
  TransformationFormLogic,
  TransformationFormView,
  BasicInfoFormView,
  TransformationProcessFormView,
  ScalabilityFormView,
  SustainabilityFormView,
  MarketFormView,
  RegulationsFormView
};

export default TransformationPublishForm;

