import axios from 'axios';
import { ResponseErrorFactory } from '../../errors/api';
import UserService from './UserService';
import TransformationService from './TransformationService';
import CurationService from './CurationService';
import ResourceService from './ResourceService';
import ApplicationService from './ApplicationService';

class Client {
  constructor(baseURL, config = {}) {
    this.headers = {
      Authorization: 'Bearer GUEST'
    };

    this.instance = axios.create({
      ...config,
      headers: this.headers,
      baseURL
    });
    this.token = null;

    this.user = new UserService(this);
    this.transformation = new TransformationService(this);
    this.curation = new CurationService(this);
    this.application = new ApplicationService(this);
    this.resource = new ResourceService(this);
  }

  setToken(token) {
    this.token = token;
    this.headers = {
      ...this.headers,
      Authorization: `Bearer ${token ?? 'GUEST'}`
    };
    this.instance.defaults.headers = this.headers;
  }

  handleResponseError(message, error) {
    console.error(message, error);
    throw ResponseErrorFactory.create(error);
  }
}

export default Client;
