import React from 'react';
import PropTypes from 'prop-types';
import { Container, Form } from 'semantic-ui-react';
import useLocale from '../../../../hooks/i18n';
import useFormHelper from '../../../../hooks/formHelper';
import PopupFormFieldArea from '../../../common/form/popupFormFieldArea';

const FORM_NAMES = {
  indigenous_people_logistics: 'regulations.indigenous_people_logistics',
  permissions_access_resource: 'regulations.permissions_access_resource',
  regulations: 'regulations.regulations',
  certifications: 'regulations.certifications',
  quality_risks_standards: 'regulations.quality_risks_standards',
  waste_management: 'regulations.waste_management'
};

const RegulationsFormView = ({ renderAs, editable, form }) => {
  const { t } = useLocale();
  const { handleChangeWithValidation, resolveError } = useFormHelper(form);
  const { getValues } = form;
  const ParentComponent = renderAs;

  return (
    <ParentComponent className="transformation-card market-form considerations-form">
      <Container fluid={renderAs !== Form}>

        <PopupFormFieldArea
          id={FORM_NAMES.indigenous_people_logistics}
          name={FORM_NAMES.indigenous_people_logistics}
          label={t('transformation.form.regulations.indigenous_people_logistics.label')}
          hint={t('transformation.form.regulations.indigenous_people_logistics.hint')}
          onChange={handleChangeWithValidation}
          error={resolveError(FORM_NAMES.indigenous_people_logistics)}
          defaultValue={getValues(FORM_NAMES.indigenous_people_logistics)}
          disabled={!editable}
        />

        <PopupFormFieldArea
          name={FORM_NAMES.permissions_access_resource}
          id={FORM_NAMES.permissions_access_resource}
          label={t('transformation.form.regulations.permissions_access_resource.label')}
          hint={t('transformation.form.regulations.permissions_access_resource.hint')}
          onChange={handleChangeWithValidation}
          error={resolveError(FORM_NAMES.permissions_access_resource)}
          defaultValue={getValues(FORM_NAMES.permissions_access_resource)}
          disabled={!editable}
        />

        <PopupFormFieldArea
          id={FORM_NAMES.regulations}
          name={FORM_NAMES.regulations}
          label={t('transformation.form.regulations.regulations.label')}
          hint={t('transformation.form.regulations.regulations.hint')}
          onChange={handleChangeWithValidation}
          error={resolveError(FORM_NAMES.regulations)}
          defaultValue={getValues(FORM_NAMES.regulations)}
          disabled={!editable}
        />

        <PopupFormFieldArea
          id={FORM_NAMES.certifications}
          name={FORM_NAMES.certifications}
          label={t('transformation.form.regulations.certifications.label')}
          hint={t('transformation.form.regulations.certifications.hint')}
          onChange={handleChangeWithValidation}
          error={resolveError(FORM_NAMES.certifications)}
          defaultValue={getValues(FORM_NAMES.certifications)}
          disabled={!editable}
        />

        <PopupFormFieldArea
          id={FORM_NAMES.quality_risks_standards}
          name={FORM_NAMES.quality_risks_standards}
          label={t('transformation.form.regulations.quality_risks_standards.label')}
          hint={t('transformation.form.regulations.quality_risks_standards.hint')}
          onChange={handleChangeWithValidation}
          error={resolveError(FORM_NAMES.quality_risks_standards)}
          defaultValue={getValues(FORM_NAMES.quality_risks_standards)}
          disabled={!editable}
        />

        <PopupFormFieldArea
          id={FORM_NAMES.waste_management}
          name={FORM_NAMES.waste_management}
          label={t('transformation.form.regulations.waste_management.label')}
          hint={t('transformation.form.regulations.waste_management.hint')}
          onChange={handleChangeWithValidation}
          error={resolveError(FORM_NAMES.waste_management)}
          defaultValue={getValues(FORM_NAMES.waste_management)}
          disabled={!editable}
        />
      </Container>
    </ParentComponent>
  );
};

RegulationsFormView.propTypes = {
  renderAs: PropTypes.oneOfType([PropTypes.string, PropTypes.elementType]),
  editable: PropTypes.bool,
  form: PropTypes.shape({
    formState: PropTypes.shape({
      isSubmitting: PropTypes.bool
    }),
    handleSubmit: PropTypes.func,
    getValues: PropTypes.func
  }).isRequired
};

RegulationsFormView.defaultProps = {
  renderAs: Form,
  editable: true
};

export default RegulationsFormView;
