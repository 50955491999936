import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';
import { joiPasswordExtendCore } from 'joi-password';
import ForgotPasswordFormView from './ForgotPasswordFormView';

const joiPassword = Joi.extend(joiPasswordExtendCore);

const ForgotPasswordSchema = Joi.object({
  email: Joi.string().email({ tlds: { allow: false } }).trim().required()
    .messages({
      'string.email': 'forgot_password.errors.email.invalid',
      'string.empty': 'forgot_password.errors.email.required',
      'any.required': 'forgot_password.errors.email.required'
    }),
  code: Joi.string().empty()
    .messages({
      'string.empty': 'forgot_password.errors.code.required'
    }),
  password: joiPassword.string().min(8).minOfSpecialCharacters(1).minOfLowercase(1).minOfUppercase(1).noWhiteSpaces()
    .messages({
      'password.minOfSpecialCharacters': 'signup.errors.password.special_chars',
      'password.minOfLowercase': 'signup.errors.password.lowercase',
      'password.minOfUppercase': 'signup.errors.password.uppercase',
      'password.noWhiteSpaces': 'signup.errors.password.white_spaces',
      'string.empty': 'signup.errors.password.required'
    }),
  password_confirm: Joi.any().equal(Joi.ref('password'))
    .messages({
      'any.only': 'signup.errors.password_confirm.different',
      'string.empty': 'signup.errors.password_confirm.required'
    })
});

const ForgotPasswordFormLogic = ({ onForgotPassword, onForgotPasswordSubmit }) => {
  const form = useForm({
    mode: 'onSubmit',
    resolver: joiResolver(ForgotPasswordSchema)
  });
  const [error, setError] = useState(null);

  const handleSubmit = async (data, current) => {
    setError(null);
    const onSubmit = current === 0 ? onForgotPassword : onForgotPasswordSubmit;

    // Because it's a multi-step single form, these fields cannot be required inside the schema.
    // So we need to check non-empty in submission.
    if (current !== 0) {
      if (!data.code) {
        return form.setError('code', { type: 'required', message: 'forgot_password.errors.code.required' }, { shouldFocus: true });
      }

      if (!data.password) {
        return form.setError('password', { type: 'required', message: 'forgot_password.errors.password.required' }, { shouldFocus: true });
      }

      if (!data.password_confirm) {
        return form.setError('password_confirm', { type: 'required', message: 'forgot_password.errors.password_confirm.required' }, { shouldFocus: true });
      }
    }

    try {
      await onSubmit(data);
    } catch (error) {
      setError(error);
    }
  };

  return (
    <ForgotPasswordFormView
      form={form}
      onSubmit={handleSubmit}
      error={error}
    />
  );
};

ForgotPasswordFormLogic.propTypes = {
  onForgotPassword: PropTypes.func.isRequired,
  onForgotPasswordSubmit: PropTypes.func.isRequired
};

export default ForgotPasswordFormLogic;
