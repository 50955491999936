import objectPath from 'object-path';
import useLocale from './i18n';

const useFormHelper = (form) => {
  const { t } = useLocale();

  const { setValue, trigger, clearErrors } = form;
  const { errors } = form.formState;

  const handleChangeNoValidation = (_, { name, value, type, checked }) => {
    const newValue = type === 'checkbox' ? checked : value;
    setValue(name, newValue);
    clearErrors(name);
  };

  const handleChangeWithValidation = async (_, { name, value, type, checked }) => {
    const newValue = type === 'checkbox' ? checked : value;
    setValue(name, newValue);
    await trigger(name);
  };

  const handleChangeWithMultiValidation = (...fieldsToValidate) => async (e, props) => {
    await handleChangeWithValidation(e, props);
    for (const field of fieldsToValidate) {
      await trigger(field);
    }
  };

  const handleBlurValidate = async ({ target: { name } }) => {
    await trigger(name);
  };

  const resolveError = (name) => {
    if (!errors) {
      return null;
    }

    const errorObj = objectPath.get(errors, name);
    if (!errorObj || !errorObj.message) {
      return null;
    }

    const key = errorObj.message;
    try {
      return t(key);
    } catch (_) {
      return key;
    }
  };

  return {
    handleChangeNoValidation,
    handleChangeWithValidation,
    handleChangeWithMultiValidation,
    handleBlurValidate,
    resolveError
  };
};

export default useFormHelper;
