import React from 'react';
import PropTypes from 'prop-types';
import { Button, Form, Message } from 'semantic-ui-react';
import useLocale from '../../../hooks/i18n';
import useFormHelper from '../../../hooks/formHelper';
import CardContainer from '../../ui/cardContainer';
import symbol from '../../../assets/logo/symbol_green.svg';
import { Link } from 'react-router-dom';
import { PUBLIC_ROUTES } from '../../../constants/routes';

const SignupForm = ({ form, onSubmit, error }) => {
  const { t } = useLocale();
  const {
    handleChangeNoValidation,
    handleChangeWithMultiValidation,
    handleBlurValidate,
    resolveError
  } = useFormHelper(form);
  const {
    handleSubmit,
    formState: { isSubmitting }
  } = form;

  return (
    <CardContainer className="signup-container">
      <Form
        className="signup-form"
        onSubmit={handleSubmit(onSubmit)}
        error={!!error}
      >
        <Message error header={t('signup.errors.header')} content={error} />

        <div className="header-container">
          <img className="login-symbol" src={symbol} alt="symbol logo" />
          <h1 className="ui-header">{t('signup.header')}</h1>
          <p className="hero-text ui-text">
            {t('signup.hero.text')}
          </p>
        </div>

        <Form.Input
          name="email"
          fluid
          required
          label={t('signup.form.email')}
          placeholder="your@email.com"
          onChange={handleChangeNoValidation}
          onBlur={handleBlurValidate}
          error={resolveError('email')}
        />
        <Form.Input
          name="username"
          fluid
          required
          label={t('signup.form.username')}
          onChange={handleChangeNoValidation}
          onBlur={handleBlurValidate}
          error={resolveError('username')}
        />

        <Form.Group widths="equal">
          <Form.Input
            name="password"
            type="password"
            fluid
            required
            label={t('signup.form.password')}
            onChange={handleChangeWithMultiValidation('password_confirm')}
            error={resolveError('password')}
          />
          <Form.Input
            name="password_confirm"
            type="password"
            fluid
            required
            label={t('signup.form.password_confirm')}
            onChange={handleChangeWithMultiValidation('password')}
            error={resolveError('password_confirm')}
          />
        </Form.Group>

        <Form.Group widths="equal">
          <Form.Input
            name="given_name"
            fluid
            required
            label={t('signup.form.given_name')}
            onChange={handleChangeNoValidation}
            onBlur={handleBlurValidate}
            error={resolveError('given_name')}
          />
          <Form.Input
            name="family_name"
            fluid
            required
            label={t('signup.form.family_name')}
            onChange={handleChangeNoValidation}
            onBlur={handleBlurValidate}
            error={resolveError('family_name')}
          />
        </Form.Group>

        <Form.Group widths="equal">
          <Form.Input
            name="extra_institution_name"
            fluid
            required
            label={t('signup.form.institution_name')}
            onChange={handleChangeNoValidation}
            onBlur={handleBlurValidate}
            error={resolveError('extra_institution_name')}
          />
          <Form.Input
            name="extra_institution_position"
            fluid
            required
            label={t('signup.form.institution_position')}
            onChange={handleChangeNoValidation}
            onBlur={handleBlurValidate}
            error={resolveError('extra_institution_position')}
          />
        </Form.Group>
        <Form.Input
          name="extra_professional_affiliation"
          fluid
          required
          label={t('signup.form.professional_affiliation')}
          onChange={handleChangeNoValidation}
          onBlur={handleBlurValidate}
          error={resolveError('extra_professional_affiliation')}
        />

        <Form.Group widths="equal">
          <Form.Input
            name="extra_location_country"
            fluid
            required
            label={t('signup.form.location_country')}
            onChange={handleChangeNoValidation}
            onBlur={handleBlurValidate}
            error={resolveError('extra_location_country')}
          />
          <Form.Input
            name="extra_location_province"
            fluid
            required
            label={t('signup.form.location_province')}
            onChange={handleChangeNoValidation}
            onBlur={handleBlurValidate}
            error={resolveError('extra_location_province')}
          />
          <Form.Input
            name="extra_location_city"
            fluid
            required
            label={t('signup.form.location_city')}
            onChange={handleChangeNoValidation}
            onBlur={handleBlurValidate}
            error={resolveError('extra_location_city')}
          />
        </Form.Group>

        <Button
          className="submit-signup-btn"
          type="submit"
          color="green"
          loading={isSubmitting}
        >
          {t('signup.signup')}
        </Button>

        <p className="links">
          {t('signup.already.registered')}
          <Link to={PUBLIC_ROUTES.login}>{t('signup.login')}</Link>
        </p>
      </Form>
    </CardContainer>
  );
};

SignupForm.propTypes = {
  form: PropTypes.shape({
    formState: PropTypes.shape({
      isSubmitting: PropTypes.bool
    }),
    handleSubmit: PropTypes.func
  }).isRequired,
  onSubmit: PropTypes.func.isRequired,
  error: PropTypes.string
};

SignupForm.defaultProps = {
  error: null
};

export default SignupForm;
