import React from 'react';
import PropTypes from 'prop-types';
import { Form, Container, Message, Button } from 'semantic-ui-react';
import useLocale from '../../../../hooks/i18n';
import useFormHelper from '../../../../hooks/formHelper';

const ForgotPasswordFormViewTwo = ({ form, onSubmit, error }) => {
  const { t } = useLocale();
  const { handleChangeNoValidation, handleBlurValidate, resolveError, handleChangeWithMultiValidation } = useFormHelper(form);
  const { handleSubmit, formState: { isSubmitting } } = form;

  return (
    <Form onSubmit={handleSubmit(onSubmit)} error={!!error}>
      <Container>
        <Message error header={t('forgot_password.errors.header')} content={error} />

        <Form.Input
          name="code"
          fluid
          label={t('forgot_password.form.code')}
          onChange={handleChangeNoValidation}
          onBlur={handleBlurValidate}
          error={resolveError('code')}
        />

        <Form.Group widths="equal">
          <Form.Input
            name="password"
            type="password"
            fluid
            label={t('forgot_password.form.password')}
            onChange={handleChangeWithMultiValidation('password_confirm')}
            error={resolveError('password')}
          />

          <Form.Input
            name="password_confirm"
            type="password"
            label={t('forgot_password.form.password_confirm')}
            onChange={handleChangeWithMultiValidation('password')}
            error={resolveError('password_confirm')}
          />
        </Form.Group>

        <Button type="submit" color="blue" loading={isSubmitting}>
          {t('forgot_password.form.submit')}
        </Button>
      </Container>
    </Form>
  );
};

ForgotPasswordFormViewTwo.propTypes = {
  form: PropTypes.shape({
    formState: PropTypes.shape({
      isSubmitting: PropTypes.bool
    }),
    handleSubmit: PropTypes.func
  }).isRequired,
  onSubmit: PropTypes.func.isRequired,
  error: PropTypes.string
};

ForgotPasswordFormViewTwo.defaultProps = {
  error: null
};

export default ForgotPasswordFormViewTwo;
