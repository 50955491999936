import React from 'react';
import { Button } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { PROTECTED_ROUTES, PUBLIC_ROUTES } from '../../../constants/routes';
import LinkWithScroll from '../linkWithScroll';
import logoBasic from '../../../assets/logo/logo-basic.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TRANSFORMAR_SOCIALS } from './Footer';
import useLocale from '../../../hooks/i18n';
import PropTypes from 'prop-types';

const FooterFull = ({ signedIn }) => {
  const { t } = useLocale();
  return (
    <div className="footer">
      <div className="footer-delimiter">
        <div className="footer-items">
          <div className="footer-items__section">
            <h1 className="section__header">
              {t('footer.items.section.header')}
            </h1>
            <p className="section__text">{t('footer.items.section.text')}</p>
            {!signedIn ? (
              <div className="login-signup-buttons">
                <Button
                  as={Link}
                  to={PUBLIC_ROUTES.login}
                  className="section__btn section__btn--white"
                >
                  {t('footer.items.section.btn.login')}
                </Button>
                <Button
                  as={Link}
                  to={PUBLIC_ROUTES.signup}
                  color="green"
                  className="section__btn"
                >
                  {t('footer.items.section.btn.signup')}
                </Button>
              </div>
            ) : (
              <Button
                as={Link}
                color="green"
                className="section__btn section__btn--publish"
                to={PROTECTED_ROUTES.contributions}
              >
                {t('footer.items.section.btn.publish')}
              </Button>
            )}
          </div>
          <div className="footer-items__bar">
            <div className="bar__links-container">
              <div className="bar__links">
                <Link to={PUBLIC_ROUTES.about}>
                  {t('footer.items.bar.links.about')}
                </Link>
                <LinkWithScroll
                  path={PUBLIC_ROUTES.about}
                  scrollTo="faq"
                  content={t('footer.items.bar.links.help')}
                />
              </div>
              <div className="bar__links">
                <LinkWithScroll
                  path={PUBLIC_ROUTES.about}
                  scrollTo="contact"
                  content={t('footer.items.bar.links.contact')}
                />
                <Link
                  to={PUBLIC_ROUTES.termsAndConditions}
                  className="terms-and-conditions-link"
                >
                  {t('footer.items.bar.links.terms_and_conditions')}
                </Link>
              </div>
            </div>
            <img className="bar__logo" src={logoBasic} alt="logo" />
            <div className="bar__socials">
              <a
                href={TRANSFORMAR_SOCIALS.linkedin}
                target="_blank"
                rel="noreferrer"
              >
                <FontAwesomeIcon icon="fa-brands fa-linkedin" />
              </a>
              <a
                href={TRANSFORMAR_SOCIALS.twitter}
                target="_blank"
                rel="noreferrer"
              >
                <FontAwesomeIcon icon="fa-brands fa-twitter" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

FooterFull.propTypes = {
  signedIn: PropTypes.bool.isRequired
};

export default FooterFull;
