import React from 'react';
import { Menu } from 'semantic-ui-react';
import useLocale from '../../../hooks/i18n';
import PropTypes from 'prop-types';

const TransformationViewSidebarMenu = ({
  currentSection,
  setCurrentSection,
  scrollToSection
}) => {
  const { t } = useLocale();
  return (
    <Menu vertical secondary fluid className="transformation-menu">
      <Menu.Item
        active={currentSection === 0}
        onClick={() => {
          setCurrentSection(0);
          scrollToSection(0);
        }}
      >
        {`1. ${t('transformation.form.basic_information.title')}`}
      </Menu.Item>
      <Menu.Item
        active={currentSection === 1}
        onClick={() => {
          setCurrentSection(1);
          scrollToSection(1);
        }}
      >
        {`2. ${t('transformation.form.transformation_process.title')}`}
      </Menu.Item>
      <Menu.Item
        active={currentSection === 2}
        onClick={() => {
          setCurrentSection(2);
          scrollToSection(2);
        }}
      >
        {`3. ${t('transformation.form.scalability.title')}`}
      </Menu.Item>
      <Menu.Item
        active={currentSection === 3}
        onClick={() => {
          setCurrentSection(3);
          scrollToSection(3);
        }}
      >
        {`4. ${t('transformation.form.sustainability.title')}`}
      </Menu.Item>
      <Menu.Item
        active={currentSection === 4}
        onClick={() => {
          setCurrentSection(4);
          scrollToSection(4);
        }}
      >
        {`5. ${t('transformation.form.market.title')}`}
      </Menu.Item>
      <Menu.Item
        active={currentSection === 5}
        onClick={() => {
          setCurrentSection(5);
          scrollToSection(5);
        }}
      >
        {`6. ${t('transformation.form.regulations.title')}`}
      </Menu.Item>
    </Menu>
  );
};

TransformationViewSidebarMenu.propTypes = {
  currentSection: PropTypes.number.isRequired,
  setCurrentSection: PropTypes.func.isRequired,
  scrollToSection: PropTypes.func.isRequired
};

export default TransformationViewSidebarMenu;
