import IntlMessageFormat from 'intl-messageformat';
import * as strings from './strings';

export const DEFAULT_LOCALE = 'en';

export const AVAILABLE_LOCALES = {
  en: 'English',
  es: 'Español',
  pt: 'Português'
};

export const LOCALE_FLAGS = {
  en: 'us',
  es: 'mx',
  pt: 'br'
};

export const isLocaleSupported = (locale) => {
  return Object.keys(AVAILABLE_LOCALES).includes(locale);
};

const getMessage = (locale, key) => {
  const messagesForLocale = strings[locale];

  if (!messagesForLocale) {
    throw new Error(`No messages with locale ${locale} exist!`);
  }

  const message = messagesForLocale[key] || strings[DEFAULT_LOCALE][key];

  if (!message) {
    throw new Error(`No message with key ${key} for locale ${locale} exists!`);
  }

  return new IntlMessageFormat(message);
};

export const translate = (locale, key, values = {}) => {
  return getMessage(locale, key).format(values) || '';
};
