import React, { Fragment, useState } from 'react';
import { Icon, Menu } from 'semantic-ui-react';
import SearchTransformationInput from '../../ui/searchTransformationInput';
import { useLocation } from 'react-router-dom';
import { PUBLIC_ROUTES } from '../../../constants/routes';
import PropTypes from 'prop-types';

const NavbarSearchBar = ({ inverted }) => {
  const [isSearchVisible, setIsSearchVisible] = useState(false);
  const location = useLocation();

  return (
    location.pathname !== PUBLIC_ROUTES.explore && (
      <Fragment>
        <Menu.Item
          className={`search-bar ${
            isSearchVisible ? 'transitioned' : 'removed'
          }`}
        >
          <SearchTransformationInput
            onBlur={() => {
              setIsSearchVisible(false);
            }}
          />
        </Menu.Item>
        <Menu.Item className={`search-icon ${isSearchVisible && 'removed'}`}>
          <Icon
            name="search"
            color={!inverted ? 'green' : null}
            size="large"
            onClick={() => {
              setIsSearchVisible(true);
            }}
          />
        </Menu.Item>
      </Fragment>
    )
  );
};

NavbarSearchBar.propTypes = {
  inverted: PropTypes.bool
};

NavbarSearchBar.defaultProps = {
  inverted: null
};

export default NavbarSearchBar;
