import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@tanstack/react-query';
import useAuth from '../../../hooks/auth';
import useAPI from '../../../hooks/api';
import useLocale from '../../../hooks/i18n';
import { Popup } from 'semantic-ui-react';
import bookmarkOn from '../../../assets/icons/crowdsourcing/bookmark_on.svg';
import bookmarkOff from '../../../assets/icons/crowdsourcing/bookmark_off.svg';
import economicallyOn from '../../../assets/icons/crowdsourcing/economically_valuable_on.svg';
import economicallyOff from '../../../assets/icons/crowdsourcing/economically_valuable_off.svg';
import environmentallyOn from '../../../assets/icons/crowdsourcing/environmentally_valuable_on.svg';
import environmentallyOff from '../../../assets/icons/crowdsourcing/environmentally_valuable_off.svg';
import sociallyOn from '../../../assets/icons/crowdsourcing/socially_valuable_on.svg';
import sociallyOff from '../../../assets/icons/crowdsourcing/socially_valuable_off.svg';
import technicallyOn from '../../../assets/icons/crowdsourcing/technically_valuable_on.svg';
import technicallyOff from '../../../assets/icons/crowdsourcing/technically_valuable_off.svg';

const items = {
  bookmark: { onIcon: bookmarkOn, offIcon: bookmarkOff, alt: 'bookmark icon', className: 'bookmark', hint: 'transformation_view.crowdsourcing.hints.bookmark' },
  economically_viable: { onIcon: economicallyOn, offIcon: economicallyOff, alt: 'economically viable icon', className: '', hint: 'transformation_view.crowdsourcing.hints.economically_viable' },
  technically_viable: { onIcon: technicallyOn, offIcon: technicallyOff, alt: 'technically viable icon', className: '', hint: 'transformation_view.crowdsourcing.hints.technically_viable' },
  environmentally_viable: { onIcon: environmentallyOn, offIcon: environmentallyOff, alt: 'environmentally viable icon', className: '', hint: 'transformation_view.crowdsourcing.hints.environmentally_viable' },
  socially_viable: { onIcon: sociallyOn, offIcon: sociallyOff, alt: 'socially viable icon', className: '', hint: 'transformation_view.crowdsourcing.hints.socially_viable' }
};

const TransformationLikeItem = ({ type, likes, liked, id }) => {
  const { t } = useLocale();
  const item = items[type];
  const [mutating, setMutating] = useState(false);
  const [icon, setIcon] = useState(liked ? item.onIcon : item.offIcon);
  const { signedIn } = useAuth();
  const { client, queryClient } = useAPI();
  const mutation = useMutation(liked ?
    () => client.transformation.unlikePublishedTransformation(id, type) :
    () => client.transformation.likePublishedTransformation(id, type)
  );
  const currentLikes = mutating ?
    (liked ? likes - 1 : likes + 1) :
    likes;

  useEffect(() => {
    setIcon(liked ? item.onIcon : item.offIcon);
  }, [item.offIcon, item.onIcon, liked]);

  const handleMouseEnter = () => {
    if (!mutating) {
      setIcon(liked ? item.offIcon : item.onIcon);
    }
  };
  const handleMouseLeave = () => {
    if (!mutating) {
      setIcon(liked ? item.onIcon : item.offIcon);
    }
  };

  const handleToggleLike = async () => {
    if (signedIn && !mutating) {
      try {
        setMutating(true);
        await mutation.mutateAsync(null);
        await queryClient.invalidateQueries({
          predicate: (query) => {
            // Clear all transformations and full users from cache.
            // May need to find a better way to accomplish this, maybe update local data instead of re-fetching everything?
            return query.queryKey[0] === 'transformations' || (query.queryKey[0] === 'user' && query.queryKey[1] === 'full');
          }
        });
      } catch (error) {
        console.error(error);
      } finally {
        setMutating(false);
      }
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      return handleToggleLike();
    }
  };

  return (
    <Popup
      content={t(item.hint)}
      position="top center"
      size="tiny"
      trigger={
        <div
          className="transformation-like-item"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          onClick={handleToggleLike}
          onKeyDown={handleKeyDown}
          role="button"
          tabIndex={0}
        >
          <img src={icon} alt={item.alt} className={item.className} />
          <span>{currentLikes}</span>
        </div>
      }
    />
  );
};

TransformationLikeItem.propTypes = {
  type: PropTypes.oneOf([
    'bookmark',
    'economically_viable',
    'environmentally_viable',
    'socially_viable',
    'technically_viable'
  ]).isRequired,
  likes: PropTypes.number.isRequired,
  liked: PropTypes.bool.isRequired,
  id: PropTypes.number.isRequired
};

export default TransformationLikeItem;
