import React from 'react';
import { Dropdown, Grid, Image, Menu } from 'semantic-ui-react';
import {
  DYNAMIC_PUBLIC_ROUTES,
  PROTECTED_ROUTES,
  PUBLIC_ROUTES
} from '../../../../constants/routes';
import { Link } from 'react-router-dom';
import imagoTypeWhite from '../../../../assets/logo/imagotype_white.svg';
import imagoTypeGreen from '../../../../assets/logo/imagotype_green.svg';
import NavbarDropdownMenu from '../NavbarDropdownMenu';
import NavbarSearchBar from '../NavbarSearchBar';
import LocaleSwitcher from '../../localeSwitcher';
import useLocale from '../../../../hooks/i18n';
import PropTypes from 'prop-types';

const UserComputerNavbar = ({ pathname, user, onSignOut }) => {
  const { t } = useLocale();
  const explore_items = [
    {
      link: PUBLIC_ROUTES.explore,
      title: t('navbar.dropdown.explore.opportunities.title'),
      description: t('navbar.dropdown.explore.opportunities.description')
    },
    {
      link: PROTECTED_ROUTES.contributions,
      title: t('navbar.dropdown.explore.publish.title'),
      description: t('navbar.dropdown.explore.publish.description')
    }
  ];

  const about_items = [
    {
      link: PUBLIC_ROUTES.about,
      title: t('navbar.dropdown.about.faq.title'),
      description: t('navbar.dropdown.about.faq.description'),
      sectionQueryParam: 'faq'
    },
    {
      link: PUBLIC_ROUTES.about,
      title: t('navbar.dropdown.about.contact.title'),
      description: t('navbar.dropdown.about.contact.description'),
      sectionQueryParam: 'contact'
    }
  ];

  const involved_items = [
    {
      link: PROTECTED_ROUTES.becomeCurator,
      title: t('navbar.dropdown.get_involved.partnership.title'),
      description: t('navbar.dropdown.get_involved.partnership.description')
    }
  ];
  return (
    <Grid.Row only="computer" className="computer-row-navbar">
      <Grid.Column>
        <Menu
          className={`menu-navbar ${
            pathname === PUBLIC_ROUTES.home ? 'menu-navbar--custom-inverted' : ''
          }`}
          size="large"
          color="grey"
          borderless
        >
          <Menu.Item as={Link} to={PUBLIC_ROUTES.home} header>
            <img
              className="green-logo"
              src={pathname === PUBLIC_ROUTES.home ? imagoTypeWhite : imagoTypeGreen}
              alt="green-logo"
            />
          </Menu.Item>

          <Menu.Menu position="right" className="navbar-right-menu">
            <NavbarDropdownMenu
              linkTo={PUBLIC_ROUTES.explore}
              inverted={pathname === PUBLIC_ROUTES.home}
              text={t('navbar.explore')}
              items={explore_items}
            />

            <NavbarDropdownMenu
              linkTo={PUBLIC_ROUTES.about}
              inverted={pathname === PUBLIC_ROUTES.home}
              text={t('navbar.about')}
              items={about_items}
            />

            {user.role === 'CONTRIBUTOR' && (
              <NavbarDropdownMenu
                linkTo={PROTECTED_ROUTES.becomeCurator}
                inverted={pathname === PUBLIC_ROUTES.home}
                text={t('navbar.get_involved')}
                items={involved_items}
              />
            )}

            <NavbarSearchBar inverted={pathname === PUBLIC_ROUTES.home} />

            <Menu.Item>
              <LocaleSwitcher />
            </Menu.Item>

            <Image src={user.picture} avatar />
            <Dropdown text={user.username}>
              <Dropdown.Menu>
                <Dropdown.Item
                  text={t('navbar.dropdown.profile')}
                  as={Link}
                  to={DYNAMIC_PUBLIC_ROUTES.userProfile(user.username)}
                />
                <Dropdown.Item
                  text={t('navbar.dropdown.settings')}
                  as={Link}
                  to={PROTECTED_ROUTES.settings}
                />
                <Dropdown.Item
                  text={t('navbar.dropdown.publish')}
                  as={Link}
                  to={PROTECTED_ROUTES.contributions}
                />
                {user.role === 'CONTRIBUTOR' && (
                  <Dropdown.Item
                    as={Link}
                    to={PROTECTED_ROUTES.becomeCurator}
                    text={t('navbar.dropdown.become_curator')}
                  />
                )}
                {(user.role === 'EXPERT' || user.role === 'ADMINISTRATOR') && (
                  <Dropdown.Item
                    as={Link}
                    to={PROTECTED_ROUTES.curations}
                    text={t('navbar.dropdown.curations')}
                  />
                )}
                {user.role === 'ADMINISTRATOR' && (
                  <Dropdown.Item
                    as={Link}
                    to={PROTECTED_ROUTES.admin}
                    text={t('navbar.dropdown.admin')}
                  />
                )}
                <Dropdown.Divider />
                <Dropdown.Item
                  text={t('navbar.dropdown.sign_out')}
                  onClick={onSignOut}
                />
              </Dropdown.Menu>
            </Dropdown>
          </Menu.Menu>
        </Menu>
      </Grid.Column>
    </Grid.Row>
  );
};

UserComputerNavbar.propTypes = {
  pathname: PropTypes.string.isRequired,
  user: PropTypes.shape({
    username: PropTypes.string,
    picture: PropTypes.string,
    role: PropTypes.string
  }).isRequired,
  onSignOut: PropTypes.func.isRequired
};

export default UserComputerNavbar;
