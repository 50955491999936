import React, { useState } from 'react';
import { Navigate } from 'react-router-dom';
import SignUpForm from '../../components/forms/signUpForm';
import PostSignUp from '../../components/auth/postSignUp';
import useAuth from '../../hooks/auth';
import useLocale from '../../hooks/i18n';
import useTitle from '../../hooks/title';
import { PUBLIC_ROUTES } from '../../constants/routes';

const SignUpPage = () => {
  const { t } = useLocale();
  useTitle(t('page_titles.sign_up'));
  const { signedIn } = useAuth();
  const [signUpData, setSignUpData] = useState(null);

  const handleSignUpComplete = (data) => {
    setSignUpData(data);
  };

  if (signedIn) {
    return <Navigate to={PUBLIC_ROUTES.home} replace />;
  }

  if (!signUpData) {
    return (
      <div className="signup-page">
        <SignUpForm onComplete={handleSignUpComplete} />
      </div>
    );
  }

  return <PostSignUp email={signUpData.email} name={signUpData.given_name} />;
};

export default SignUpPage;
