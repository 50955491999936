class CurationService {
  constructor(client) {
    this.client = client;
  }

  async postCuration(transformationId) {
    try {
      const response = await this.client.instance.post('/curations', null, {
        params: {
          transformation: transformationId
        }
      });
      return response.data.data;
    } catch (error) {
      this.client.handleResponseError(`Error creating new curation for transformation with id: ${transformationId}`, error);
    }
  }

  async #getCuration(id) {
    try {
      const response = await this.client.instance.get(`/curations/${id}`);
      return response.data.data;
    } catch (error) {
      this.client.handleResponseError(`Error fetching curation with id: ${id}`, error);
    }
  }

  getCuration(id) {
    return {
      key: ['curation', id],
      fn: () => this.#getCuration(id)
    };
  }

  async #listOngoingCurations(page) {
    try {
      const response = await this.client.instance.get('/curations/ongoing', {
        params: { page }
      });
      return response.data.data;
    } catch (error) {
      this.client.handleResponseError(`Error fetching ongoing curations with page: ${page}`, error);
    }
  }

  listOngoingCurations(page = 1) {
    return {
      key: ['curation', 'ongoing', page],
      fn: () => this.#listOngoingCurations(page)
    };
  }

  async deleteOngoingCuration(id) {
    try {
      const response = await this.client.instance.delete(`/curations/ongoing/${id}`);
      return response.data.data;
    } catch (error) {
      this.client.handleResponseError(`Error deleting curation with id: ${id}`, error);
    }
  }

  async putOngoingCuration(id, data) {
    try {
      const response = await this.client.instance.put(`/curations/ongoing/${id}`, data);
      return response.data.data;
    } catch (error) {
      this.client.handleResponseError(`Error updating curation with id: ${id}`, error);
    }
  }

  async commitOngoingCuration(id) {
    try {
      const response = await this.client.instance.post(`/curations/ongoing/${id}/commit`);
      return response.data.data;
    } catch (error) {
      this.client.handleResponseError(`Error committing curation with id: ${id}`, error);
    }
  }
}

export default CurationService;
