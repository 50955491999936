import React from 'react';
import { Navigate } from 'react-router-dom';
import LoginForm from '../../components/forms/loginForm';
import useAuth from '../../hooks/auth';
import useLocale from '../../hooks/i18n';
import useTitle from '../../hooks/title';
import { PUBLIC_ROUTES } from '../../constants/routes';


const LoginPage = () => {
  const { t } = useLocale();
  useTitle(t('page_titles.login'));
  const { signedIn } = useAuth();

  // This makes the user get redirected to the home page automatically after they've signed in
  // because the token is set on sign in.
  if (signedIn) {
    return <Navigate to={PUBLIC_ROUTES.home} replace />;
  }

  return (
    <div className="login-page">
      <LoginForm />
    </div>
  );
};

export default LoginPage;
