import { useContext } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import AppContext from '../context/AppContext';
import useLocale from './i18n';

const useAPI = () => {
  const { client } = useContext(AppContext);
  const { t } = useLocale();
  const queryClient = useQueryClient();

  const getResponseErrorFriendlyMessage = (error) => {
    try {
      return t(error.friendlyMessageKey);
    } catch (error) {
      return t('services.api.errors.unknown.message');
    }
  };

  const getFullResponseErrorMessage = (error) => {
    try {
      return `${t(error.friendlyMessageKey)}\n${error.message}`;
    } catch (error) {
      return t('services.api.errors.unknown.message');
    }
  };

  return {
    client,
    queryClient,
    getResponseErrorFriendlyMessage,
    getFullResponseErrorMessage
  };
};

export default useAPI;
