import { useContext } from 'react';
import { Auth } from 'aws-amplify';
import { useQueryClient } from '@tanstack/react-query';
import AppContext from '../context/AppContext';
import { devLog } from '../utils/logging';
import { SignInError, SignUpError, ConfirmSignUpError, ResendVerificationCodeError, GlobalSignOutError, ChangePasswordError, ForgotPasswordError, ForgotPasswordSubmitError } from '../errors/auth';
import SessionCookieStorage from '../aws/sessionCookieStorage';

const useAuth = () => {
  const { token, setTokenAsync, me, setMe } = useContext(AppContext);
  const queryClient = useQueryClient();

  const signIn = async (username, password, options = { }) => {
    if (!('staySignedIn' in options)) {
      options.staySignedIn = false;
    }

    devLog.info(`Will login with the following credentials -> ${username}:${password} and options: `, options);

    try {
      // Clear the _CognitoAuth cookies first to avoid keeping 2 users in cookie storage which causes a header too large error.
      SessionCookieStorage.clear();

      Auth.configure({
        storage: options.staySignedIn ? window.localStorage : SessionCookieStorage
      });

      const result = await Auth.signIn(username, password);

      devLog.info('Login result payload:', result);
      await setTokenAsync(result.getSignInUserSession().getAccessToken().getJwtToken());
    } catch (error) {
      devLog.error('Error when trying to login:', error);
      throw new SignInError(error);
    }
  };

  const signUp = async (data) => {
    devLog.info('Will sign up with the following payload:', data);

    try {
      const { email, username, password, given_name, family_name, picture, locale, extras } = data;
      const result = await Auth.signUp({
        username: email,
        password,
        attributes: {
          preferred_username: username,
          email,
          given_name,
          family_name,
          picture,
          locale
        },
        validationData: extras
      });
      devLog.info('Signup result payload:', result);
    } catch (error) {
      devLog.error('Error when trying to sign up:', error);
      throw new SignUpError(error);
    }
  };

  const confirmSignUp = async (email, code) => {
    devLog.info(`Will confirm sign up for ${email} with code ${code}`);

    try {
      const result = await Auth.confirmSignUp(email, code);
      devLog.info('Signup confirmation result payload:', result);
    } catch (error) {
      devLog.error('Error when trying to confirm sign up:', error);
      throw new ConfirmSignUpError(error);
    }
  };

  const resendVerificationCode = async (email) => {
    devLog.info(`Will resend verification code to ${email}`);

    try {
      await Auth.resendSignUp(email);
      devLog.info('Verification code resent.');
    } catch (error) {
      devLog.error('Error when trying to resent verification code:', error);

      const authError = new ResendVerificationCodeError(error);
      // Don't throw in the case that the email does not exist in the user pool.
      if (!authError.isHidden()) {
        throw authError;
      }
    }
  };

  const signOut = async () => {
    devLog.info('Will sign out from this device.');

    try {
      await Auth.signOut();
      await setTokenAsync(null);
      queryClient.removeQueries();
      devLog.info('Successfully signed out from this device.');
    } catch (error) {
      devLog.error('Error when trying to sign out from this device:', error);
      throw error;
    }
  };

  const globalSignOut = async () => {
    devLog.info('Will sign out from all devices.');

    try {
      await Auth.signOut({ global: true });
      await setTokenAsync(null);
      devLog.info('Successfully signed out from all devices.');
    } catch (error) {
      devLog.error('Error when trying to sign out from all devices:', error);
      throw new GlobalSignOutError(error);
    }
  };

  const changePassword = async (oldPassword, newPassword) => {
    devLog.info(`Will change passwords: ${oldPassword} -> ${newPassword}`);

    try {
      const user = await Auth.currentAuthenticatedUser();
      const result = await Auth.changePassword(user, oldPassword, newPassword);
      devLog.info('Password change result payload:', result);
    } catch (error) {
      devLog.error('Error when trying to update password:', error);
      throw new ChangePasswordError(error);
    }
  };

  const forgotPassword = async (email) => {
    devLog.info(`Initiating forgot password for: ${email}`);

    try {
      const result = await Auth.forgotPassword(email);
      devLog.info('Forgot password result payload:', result);
    } catch (error) {
      devLog.error('Error when trying to use forgot password:', error);

      const authError = new ForgotPasswordError(error);
      // Don't throw in the case that the email does not exist in the user pool.
      if (!authError.isHidden()) {
        throw authError;
      }
    }
  };

  const forgotPasswordSubmit = async (email, code, newPassword) => {
    devLog.info(`Submitting forgot password for: ${email} with code ${code} and password ${newPassword}`);

    try {
      const result = await Auth.forgotPasswordSubmit(email, code, newPassword);
      devLog.info('Forgot password submit result payload:', result);
    } catch (error) {
      devLog.error('Error when trying to submit forgot password:', error);
      throw new ForgotPasswordSubmitError(error);
    }
  };

  return {
    token,
    signedIn: !!token && !!me,
    me,
    setMe,

    signIn,
    signUp,
    confirmSignUp,
    resendVerificationCode,
    signOut,
    globalSignOut,
    changePassword,
    forgotPassword,
    forgotPasswordSubmit
  };
};

export default useAuth;
