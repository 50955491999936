/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Grid } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import useQueryParams from '../../../../hooks/queryParams';
import GuestComputerNavbar from './GuestComputerNavbar';
import GuestMobileNavbar from './GuestMobileNavbar';

const GuestNavbar = ({ onBarsClicked, isMobileNavbarShown }) => {
  const { pathname } = useLocation();
  const { queryParams } = useQueryParams();

  useEffect(() => {
    // close mobile navbar whenever route changes or queryParam changes
    if (isMobileNavbarShown) {
      onBarsClicked();
    }
  }, [pathname, queryParams]);

  return (
    <Grid className="navbar guest-navbar">
      <GuestComputerNavbar pathname={pathname} />
      <GuestMobileNavbar
        onBarsClicked={onBarsClicked}
        isVisible={isMobileNavbarShown}
        pathname={pathname}
      />
    </Grid>
  );
};

GuestNavbar.propTypes = {
  onBarsClicked: PropTypes.func.isRequired,
  isMobileNavbarShown: PropTypes.bool.isRequired
};

export default GuestNavbar;
