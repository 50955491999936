import React from 'react';
import { Container, Segment } from 'semantic-ui-react';
import useLocale from '../../hooks/i18n';
import useTitle from '../../hooks/title';
import ForgotPasswordForm from '../../components/forms/forgotPasswordForm';

const ForgotPasswordPage = () => {
  const { t } = useLocale();
  useTitle(t('page_titles.forgot_password'));

  return (
    <Container text>
      <Segment>
        <h1>{t('forgot_password.header')}</h1>
        <p>{t('forgot_password.description')}</p>

        <ForgotPasswordForm />
      </Segment>
    </Container>
  );
};

export default ForgotPasswordPage;
