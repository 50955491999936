import React, { Fragment } from 'react';
import UserCuratorApplication from './index';
import useAPI from '../../../hooks/api';
import { useQuery } from '@tanstack/react-query';
import Loading from '../../common/loading';
import RequestErrorMessage from '../../common/requestErrorMessage';
import { Button, Header } from 'semantic-ui-react';
import useLocale from '../../../hooks/i18n';
import { Link } from 'react-router-dom';
import { PROTECTED_ROUTES } from '../../../constants/routes';
import usePageQuery from '../../../hooks/pageQuery';
import { PaginatedContainer } from '../../common/pagination';

const UserPastCuratorApplicationsLogic = () => {
  const { t } = useLocale();
  const { client, getFullResponseErrorMessage } = useAPI();
  const { currentPage, updatePage } = usePageQuery();
  const curatorApplicationsRequest = client.application.getAllCuratorApplicationsForUser(currentPage);
  const {
    isLoading: applicationsLoading,
    error: applicationsError,
    data: paginatedUserCuratorApplications
  } = useQuery(curatorApplicationsRequest.key, curatorApplicationsRequest.fn);
  const activeApplicationRequest = client.application.getActiveCuratorApplicationForUser();
  const {
    isLoading: activeApplicationLoading,
    error: activeApplicationError,
    data: activeApplication
  } = useQuery(activeApplicationRequest.key, activeApplicationRequest.fn);

  if (applicationsLoading || activeApplicationLoading) {
    return <Loading fullscreen />;
  }

  if (applicationsError || activeApplicationError) {
    return (
      <RequestErrorMessage
        header={t('become_curator.past_applications.errors.header')}
        error={getFullResponseErrorMessage(applicationsError || activeApplicationError)}
      />
    );
  }

  const { result: userCuratorApplications, pagination } = paginatedUserCuratorApplications;
  const filteredUserCuratorApplications = userCuratorApplications.filter(({ accepted }) => accepted !== null);

  return (
    <Fragment>
      <div className="header-btn-container">
        <Header as="h1" className="active-applications-header">
          {t('become_curator.past_applications.active.header')}
        </Header>
        {!activeApplication && (
          <Button
            className="new-application-btn"
            as={Link}
            to={PROTECTED_ROUTES.newApplication}
            color="green"
            basic
          >
            {t('become_curator.btn.create_new_application')}
          </Button>
        )}
      </div>
      {activeApplication ? (
        <UserCuratorApplication
          key={activeApplication.id}
          date={new Date(activeApplication.created_at).toDateString()}
          description={activeApplication.description}
          accepted={activeApplication.accepted}
        />
      ) : (
        <div>{t('become_curator.past_applications.active.empty.header')}</div>
      )}

      {filteredUserCuratorApplications.length > 0 && (
        <Fragment>
          <Header as="h1">
            {t('become_curator.past_applications.header')}
          </Header>
          <PaginatedContainer pagination={pagination} onChange={updatePage}>
            {
              filteredUserCuratorApplications.map((app) => (
                <UserCuratorApplication
                  key={app.id}
                  date={new Date(app.created_at).toDateString()}
                  description={app.description}
                  accepted={app.accepted}
                />
              ))
            }
          </PaginatedContainer>
        </Fragment>
      )}
    </Fragment>
  );
};

export default UserPastCuratorApplicationsLogic;
