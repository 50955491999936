import React from 'react';
import { Step } from 'semantic-ui-react';
import PropTypes from 'prop-types';

const VerticalSteps = ({ options, loading }) => {
  return (
    <div className="vertical-step-container">
      <Step.Group vertical className="vertical-step" fluid>
        {options.map(({ title, onClick, completed, active }) => (
          <Step
            key={title}
            onClick={onClick}
            className={completed ? 'completed-step' : ''}
            active={active}
            disabled={loading}
          >
            <Step.Content>
              <Step.Title>{title}</Step.Title>
            </Step.Content>
          </Step>
        ))}
      </Step.Group>
    </div>
  );
};

VerticalSteps.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      completed: PropTypes.bool,
      active: PropTypes.bool,
      onClick: PropTypes.func
    })
  ).isRequired,
  loading: PropTypes.bool
};

VerticalSteps.defaultProps = {
  loading: false
};

export default VerticalSteps;
