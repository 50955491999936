import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Segment } from 'semantic-ui-react';
import { SustainabilityFormView } from '../../transformationForm';
import AutoResizeTextArea from '../../../common/form/autoResizeTextArea';
import useLocale from '../../../../hooks/i18n';
import useFormHelper from '../../../../hooks/formHelper';

const TEXT_AREA_LABEL = 'comment_sustainability';

const CurateSustainabilityFormView = ({ transformationForm, form, sustainable_development_goals }) => {
  const { t } = useLocale();
  const { handleChangeNoValidation, handleBlurValidate, resolveError } = useFormHelper(form);
  const { getValues } = form;

  return (
    <Fragment>
      <SustainabilityFormView
        renderAs={Segment}
        editable={false}
        form={transformationForm}
        sustainable_development_goals={sustainable_development_goals}
      />

      <AutoResizeTextArea
        name={TEXT_AREA_LABEL}
        id={TEXT_AREA_LABEL}
        label={t('curate_contribution.form.comment_sustainability.label')}
        onChange={handleChangeNoValidation}
        onBlur={handleBlurValidate}
        error={resolveError(TEXT_AREA_LABEL)}
        defaultValue={getValues(TEXT_AREA_LABEL)}
      />
    </Fragment>
  );
};

CurateSustainabilityFormView.propTypes = {
  transformationForm: PropTypes.shape({
    formState: PropTypes.shape({
      isSubmitting: PropTypes.bool
    }),
    handleSubmit: PropTypes.func,
    getValues: PropTypes.func
  }).isRequired,
  form: PropTypes.shape({
    formState: PropTypes.shape({
      isSubmitting: PropTypes.bool
    }),
    handleSubmit: PropTypes.func,
    getValues: PropTypes.func
  }).isRequired,
  sustainable_development_goals: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      goal: PropTypes.string
    })
  ).isRequired
};

export default CurateSustainabilityFormView;
