import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const NavbarDropdownMenuTrigger = ({ text, linkTo }) => {
  return (
    <Link to={linkTo}>
      {text}
    </Link>
  );
};

NavbarDropdownMenuTrigger.propTypes = {
  text: PropTypes.string.isRequired,
  linkTo: PropTypes.string.isRequired
};

export default NavbarDropdownMenuTrigger;
