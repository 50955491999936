import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { useQuery, useMutation } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import RequestErrorMessage from '../../common/requestErrorMessage';
import TransformationFormLogic from './TransformationFormLogic';
import ConfirmationModal from './ConfirmationModal';
import useLocale from '../../../hooks/i18n';
import useAPI from '../../../hooks/api';
import Loading from '../../common/loading';
import { PROTECTED_ROUTES } from '../../../constants/routes';

const TransformationPublishForm = ({ transformationId, initialData, curation, metadata }) => {
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [postRequestCurationError, setPostRequestCurationError] = useState(null);
  const { t, currentLocale } = useLocale();
  const navigate = useNavigate();
  const { client, queryClient, getFullResponseErrorMessage } = useAPI();
  const resourceRequest = client.resource.getAllResources(currentLocale);
  const { isLoading, error, data: resources } = useQuery(resourceRequest.key, resourceRequest.fn);
  const updateTransformationMutation = useMutation((data) => client.transformation.patchUnpublishedTransformation(transformationId, data));
  const postRequestCurationMutation = useMutation(client.transformation.postRequestCuration.bind(client.transformation));

  const handleSave = async (data) => {
    try {
      await updateTransformationMutation.mutateAsync(data);
      await queryClient.invalidateQueries(client.transformation.getAllUnpublishedTransformations().key);
    } catch (error) {
      throw getFullResponseErrorMessage(error);
    }
  };

  const handlePublish = async (data) => {
    await handleSave(data);
    setOpenConfirmModal(true);
  };

  const handleConfirmCurationRequest = async () => {
    setPostRequestCurationError(null);

    try {
      await postRequestCurationMutation.mutateAsync(transformationId);
      await queryClient.invalidateQueries(client.transformation.getAllUnpublishedTransformations().key);
    } catch (error) {
      setPostRequestCurationError(getFullResponseErrorMessage(error));
      return;
    }

    setOpenConfirmModal(false);
    navigate(PROTECTED_ROUTES.contributions);
  };

  const handleCloseCurationRequest = () => {
    setOpenConfirmModal(false);
    setPostRequestCurationError(null);
  };

  if (isLoading) {
    return (
      <Loading fullscreen />
    );
  }

  if (error) {
    return (
      <RequestErrorMessage header={t('transformation.form.error.header')} error={getFullResponseErrorMessage(error)} />
    );
  }

  return (
    <Fragment>
      <TransformationFormLogic
        transformationId={transformationId}
        onPublish={handlePublish}
        onSave={handleSave}
        initialData={initialData}
        resources={resources}
        curation={curation}
        metadata={metadata}
      />
      <ConfirmationModal
        open={openConfirmModal}
        error={postRequestCurationError}
        loading={postRequestCurationMutation.isLoading}
        onConfirm={handleConfirmCurationRequest}
        onClose={handleCloseCurationRequest}
        header={t('transformation.confirm_modal.header')}
        errorHeader={t('transformation.confirm_modal.error.header')}
        closeLabel={t('transformation.confirm_modal.actions.close.label')}
        confirmLabel={t('transformation.confirm_modal.actions.confirm.label')}
        contentArray={[t('transformation.confirm_modal.description.1'), t('transformation.confirm_modal.description.2'), t('transformation.confirm_modal.description.3')]}
      />
    </Fragment>
  );
};

TransformationPublishForm.propTypes = {
  transformationId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  initialData: PropTypes.shape({}),
  metadata: PropTypes.shape({
    picture: PropTypes.string
  }),
  curation: PropTypes.shape({})
};

TransformationPublishForm.defaultProps = {
  initialData: {},
  metadata: {},
  curation: null
};

export default TransformationPublishForm;
