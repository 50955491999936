import React, { useState } from 'react';
import Joi from 'joi';
import ResourceModalForm from './ResourceModalForm';
import { useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import PropTypes from 'prop-types';
import { useMutation } from '@tanstack/react-query';
import useAPI from '../../../hooks/api';
import useLocale from '../../../hooks/i18n';

const ClimateSchema = Joi.object({
  name: Joi.string().max(256).trim()
});

const CurrencySchema = Joi.object({
  name: Joi.string().max(256).trim(),
  unit: Joi.string().max(3).trim()
});

const SourceClassSchema = Joi.object({
  class: Joi.string().max(256).trim()
});

const SourceSubClassSchema = Joi.object({
  sub_class: Joi.string().max(256).trim()
});

const SourceTypeSchema = Joi.object({
  type: Joi.string().max(256).trim()
});

const TechnologyReadinessLevelSchema = Joi.object({
  name: Joi.string().max(150).trim()
});

const SustainableDevelopmentGoalSchema = Joi.object({
  goal: Joi.string().max(150).trim()
});

const LocationSchema = Joi.object({
  name: Joi.string().max(256).trim(),
  short_name: Joi.string().max(2).trim()
});

export const resourceSchemas = [
  ClimateSchema,
  CurrencySchema,
  SourceClassSchema,
  SourceSubClassSchema,
  SourceTypeSchema,
  TechnologyReadinessLevelSchema,
  SustainableDevelopmentGoalSchema,
  LocationSchema
];

const updateResourceFunctions = [
  'updateClimateByID',
  'updateCurrencyByID',
  'updateSourceClassByID',
  'updateSourceSubClassByID',
  'updateSourceTypeByID',
  'updateTRLByID',
  'updateSustainableDevelopmentGoalByID',
  'updateLocationByID'
];

export const createCompleteSchema = (Schema) => {
  return Joi.object({
    en: Schema,
    pt: Schema,
    es: Schema
  });
};

const EditResourceModalLogic = ({
  currentFormID,
  open,
  onClose,
  onConfirm,
  header,
  preloadedValues,
  resourceId
}) => {
  const { t } = useLocale();
  const form = useForm({
    mode: 'onSubmit',
    resolver: joiResolver(createCompleteSchema(resourceSchemas[currentFormID])),
    defaultValues: preloadedValues
  });

  const { client, queryClient } = useAPI();
  const [error, setError] = useState(null);

  const resourceMutationEN = useMutation((data) =>
    client.resource[updateResourceFunctions[currentFormID]](
      resourceId,
      'en',
      data
    )
  );
  const resourceMutationES = useMutation((data) =>
    client.resource[updateResourceFunctions[currentFormID]](
      resourceId,
      'es',
      data
    )
  );
  const resourceMutationPT = useMutation((data) =>
    client.resource[updateResourceFunctions[currentFormID]](
      resourceId,
      'pt',
      data
    )
  );

  const handleConfirm = async (data) => {
    try {
      console.log('Will update the following data: ', data);
      await resourceMutationEN.mutateAsync(data.en);
      await resourceMutationES.mutateAsync(data.es);
      await resourceMutationPT.mutateAsync(data.pt);
      await queryClient.invalidateQueries(client.resource.getAllResources('en').key);
      await queryClient.invalidateQueries(client.resource.getAllResources('es').key);
      await queryClient.invalidateQueries(client.resource.getAllResources('pt').key);
      onConfirm();
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <ResourceModalForm
      onClose={onClose}
      onConfirm={handleConfirm}
      currentFormID={currentFormID}
      header={header}
      open={open}
      form={form}
      error={error}
      errorHeader={t('admin.resource_management.edit_modal.errors.header')}
    />
  );
};

EditResourceModalLogic.propTypes = {
  currentFormID: PropTypes.number.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  header: PropTypes.string.isRequired,
  onConfirm: PropTypes.func.isRequired,
  preloadedValues: PropTypes.shape({}).isRequired,
  resourceId: PropTypes.number.isRequired
};

export default EditResourceModalLogic;
