import React from 'react';
import PropTypes from 'prop-types';
import { Button, Container, Grid } from 'semantic-ui-react';
import useLocale from '../../../../hooks/i18n';

const PaginatedFormButtonRow = ({
  previousVisible,
  finishVisible,
  onPrevious,
  onNext,
  onFinish,
  loading,
  finishLabelKey
}) => {
  const { t } = useLocale();

  return (
    <Container fluid>
      <Grid className="button-row">
        <Grid.Row columns={2}>
          <Grid.Column width={8}>
            {previousVisible && (
              <Button color="green" basic onClick={onPrevious} loading={loading}>
                {t('transformation.form.ui.previous_btn')}
              </Button>
            )}
          </Grid.Column>

          <Grid.Column width={8} className="right-button">
            {!finishVisible ? (
              <Button color="green" onClick={onNext} loading={loading}>
                {t('transformation.form.ui.next_btn')}
              </Button>
            ) : (
              <Button color="green" onClick={onFinish} loading={loading}>
                {t(finishLabelKey)}
              </Button>
            )}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Container>
  );
};

PaginatedFormButtonRow.propTypes = {
  onPrevious: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
  onFinish: PropTypes.func.isRequired,
  previousVisible: PropTypes.bool,
  finishVisible: PropTypes.bool,
  loading: PropTypes.bool,
  finishLabelKey: PropTypes.string
};

PaginatedFormButtonRow.defaultProps = {
  previousVisible: false,
  finishVisible: false,
  loading: false,
  finishLabelKey: 'transformation.form.ui.finish_btn'
};

export default PaginatedFormButtonRow;
