import React, { Fragment } from 'react';
import { Grid } from 'semantic-ui-react';
import useLocale from '../../../../hooks/i18n';
import PropTypes from 'prop-types';

const TransformationViewSectionConsiderations = React.forwardRef(({ regulations }, ref) => {
  const { t } = useLocale();
  return (
    <Fragment>
      {regulations.indigenous_people_logistics ? (
        <Grid.Row>
          <Grid.Column>
            <h3 ref={ref}>{t('transformation.form.regulations.indigenous_people_logistics.label')}</h3>
            <p>{regulations.indigenous_people_logistics}</p>
          </Grid.Column>
        </Grid.Row>
      ) : (
        <div className="considerations-ref" ref={ref} />
      )}
      {regulations.permissions_access_resource && (
        <Grid.Row>
          <Grid.Column>
            <h3>{t('transformation.form.regulations.permissions_access_resource.label')}</h3>
            <p>{regulations.permissions_access_resource}</p>
          </Grid.Column>
        </Grid.Row>
      )}
      {regulations.regulations && (
        <Grid.Row>
          <Grid.Column>
            <h3>{t('transformation.form.regulations.regulations.label')}</h3>
            <p>{regulations.regulations}</p>
          </Grid.Column>
        </Grid.Row>
      )}
      {regulations.certifications && (
        <Grid.Row>
          <Grid.Column>
            <h3>{t('transformation.form.regulations.certifications.label')}</h3>
            <p>{regulations.certifications}</p>
          </Grid.Column>
        </Grid.Row>
      )}
      {regulations.quality_risks_standards && (
        <Grid.Row>
          <Grid.Column>
            <h3>{t('transformation.form.regulations.quality_risks_standards.label')}</h3>
            <p>{regulations.quality_risks_standards}</p>
          </Grid.Column>
        </Grid.Row>
      )}
      {regulations.waste_management && (
        <Grid.Row>
          <Grid.Column>
            <h3>{t('transformation.form.regulations.waste_management.label')}</h3>
            <p>{regulations.waste_management}</p>
          </Grid.Column>
        </Grid.Row>
      )}
    </Fragment>
  );
});

TransformationViewSectionConsiderations.propTypes = {
  regulations: PropTypes.shape({
    indigenous_people_logistics: PropTypes.string,
    permissions_access_resource: PropTypes.string,
    regulations: PropTypes.string,
    certifications: PropTypes.string,
    quality_risks_standards: PropTypes.string,
    waste_management: PropTypes.string
  }).isRequired
};

export default TransformationViewSectionConsiderations;
