import React from 'react';
import { PUBLIC_ROUTES } from '../../../../constants/routes';
import useLocale from '../../../../hooks/i18n';
import { Button, Grid, Menu } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import imagoTypeWhite from '../../../../assets/logo/imagotype_white.svg';
import imagoTypeGreen from '../../../../assets/logo/imagotype_green.svg';
import NavbarDropdownMenu from '../NavbarDropdownMenu';
import NavbarSearchBar from '../NavbarSearchBar';
import LocaleSwitcher from '../../localeSwitcher';
import PropTypes from 'prop-types';

const GuestComputerNavbar = ({ pathname }) => {
  const { t } = useLocale();
  const explore_items = [
    {
      link: PUBLIC_ROUTES.explore,
      title: t('navbar.dropdown.explore.opportunities.title'),
      description: t('navbar.dropdown.explore.opportunities.description')
    }
  ];

  const about_items = [
    {
      link: PUBLIC_ROUTES.about,
      title: t('navbar.dropdown.about.faq.title'),
      description: t('navbar.dropdown.about.faq.description'),
      sectionQueryParam: 'faq'
    },
    {
      link: PUBLIC_ROUTES.about,
      title: t('navbar.dropdown.about.contact.title'),
      description: t('navbar.dropdown.about.contact.description'),
      sectionQueryParam: 'contact'
    }
  ];
  return (
    <Grid.Row only="computer" className="computer-row-navbar">
      <Grid.Column>
        <Menu
          className={`menu-navbar ${
            pathname === PUBLIC_ROUTES.home ? 'menu-navbar--custom-inverted' : ''
          }`}
          color="grey"
          size="large"
          borderless
        >
          <Menu.Item as={Link} to={PUBLIC_ROUTES.home} header>
            <img
              className="green-logo"
              src={
                pathname === PUBLIC_ROUTES.home ?
                  imagoTypeWhite :
                  imagoTypeGreen
              }
              alt="green-logo"
            />
          </Menu.Item>

          <Menu.Menu position="right" className="navbar-right-menu">
            <NavbarDropdownMenu
              inverted={pathname === PUBLIC_ROUTES.home}
              text={t('navbar.explore')}
              items={explore_items}
              linkTo={PUBLIC_ROUTES.explore}
            />

            <NavbarDropdownMenu
              inverted={pathname === PUBLIC_ROUTES.home}
              text={t('navbar.about')}
              items={about_items}
              linkTo={PUBLIC_ROUTES.about}
            />

            <NavbarSearchBar inverted={pathname === PUBLIC_ROUTES.home} />

            <Menu.Item>
              <LocaleSwitcher />
            </Menu.Item>

            <Menu.Item>
              <Button
                className="navbar__login-btn"
                as={Link}
                to={PUBLIC_ROUTES.login}
              >
                {t('navbar.login')}
              </Button>
            </Menu.Item>
            <Menu.Item>
              <Button
                className="navbar__signup-btn"
                color="green"
                as={Link}
                to={PUBLIC_ROUTES.signup}
              >
                {t('navbar.signup')}
              </Button>
            </Menu.Item>
          </Menu.Menu>
        </Menu>
      </Grid.Column>
    </Grid.Row>
  );
};

GuestComputerNavbar.propTypes = {
  pathname: PropTypes.string.isRequired
};

export default GuestComputerNavbar;
