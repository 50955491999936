import React from 'react';
import { Button, Grid, Image, Label, Message, Modal } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import useLocale from '../../../hooks/i18n';

const ImageAssetPicker = ({
  open,
  loading,
  images,
  onConfirm,
  onClose,
  disabled,
  trigger,
  error,
  setSelectedImageURL,
  selectedImageURL,
  errorHeader,
  header
}) => {
  const { t } = useLocale();
  const handleSelectImage = (imageURL) => {
    if (imageURL !== selectedImageURL) {
      setSelectedImageURL(imageURL);
    }
  };

  const handleIconClose = () => {
    if (!loading) {
      onClose();
    }
  };

  return (
    <Modal
      onClose={handleIconClose}
      open={open}
      className="image-asset-picker"
      trigger={!disabled && trigger}
    >
      <Modal.Header>{header}</Modal.Header>
      <Modal.Content>
        {errorHeader && (
          <Message
            hidden={!error}
            error
            header={errorHeader}
            content={error}
          />
        )}
        <Grid columns={4} className="asset-picker-grid">
          {images.map((image, i) => (
            <Grid.Column key={i}>
              {selectedImageURL === image && (
                <Label
                  className="selected-label"
                  color="green"
                  ribbon
                  content={t('ui.selected')}
                />
              )}
              <Image
                className={`image-asset ${
                  image === selectedImageURL && 'selected-image'
                }`}
                size="small"
                rounded
                src={image}
                alt="transformation"
                onClick={() => {
                  handleSelectImage(image);
                }}
              />
            </Grid.Column>
          ))}
        </Grid>
      </Modal.Content>
      <Modal.Actions>
        <Button color="black" onClick={onClose} loading={loading}>
          {t('ui.cancel')}
        </Button>
        <Button
          content={t('ui.confirm')}
          labelPosition="right"
          icon="checkmark"
          onClick={() => {
            onConfirm(selectedImageURL);
          }}
          loading={loading}
          positive
        />
      </Modal.Actions>
    </Modal>
  );
};

ImageAssetPicker.propTypes = {
  open: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  images: PropTypes.arrayOf(PropTypes.string).isRequired,
  onConfirm: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  error: PropTypes.string,
  disabled: PropTypes.bool,
  trigger: PropTypes.node.isRequired,
  setSelectedImageURL: PropTypes.func.isRequired,
  selectedImageURL: PropTypes.string,
  header: PropTypes.string.isRequired,
  errorHeader: PropTypes.string
};

ImageAssetPicker.defaultProps = {
  error: null,
  disabled: null,
  selectedImageURL: null,
  errorHeader: null
};

export default ImageAssetPicker;
