/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';

const useOnUnmount = (callback) => {
  useEffect(() => {
    return callback;
  }, []);
};

export default useOnUnmount;
