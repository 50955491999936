import React from 'react';
import { Grid, Menu } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { PUBLIC_ROUTES } from '../../../../constants/routes';
import PropTypes from 'prop-types';
import imagoTypeWhite from '../../../../assets/logo/imagotype_white.svg';
import imagoTypeGreen from '../../../../assets/logo/imagotype_green.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import UserMobileNavbarContent from './UserMobileNavbarContent';
const UserMobileNavbar = ({
  pathname,
  isVisible,
  user,
  onBarsClicked,
  onSignOut
}) => {
  return (
    <Grid.Row only="tablet mobile" className="tablet-mobile-row-navbar">
      <Grid.Column>
        <Menu
          className={`menu-navbar ${
            pathname === PUBLIC_ROUTES.home &&
            !isVisible &&
            'menu-navbar--custom-inverted'
          }`}
          size="large"
          color="grey"
          borderless
        >
          <Menu.Item as={Link} to={PUBLIC_ROUTES.home} header>
            <img
              className="green-logo"
              src={
                pathname === PUBLIC_ROUTES.home && !isVisible ? imagoTypeWhite : imagoTypeGreen
              }
              alt="green-logo"
            />
          </Menu.Item>

          <Menu.Menu position="right">
            <Menu.Item className="bars" onClick={onBarsClicked}>
              {isVisible ? (
                <FontAwesomeIcon icon="fa-solid fa-xmark" />
              ) : (
                <FontAwesomeIcon icon="fa-solid fa-bars" />
              )}
            </Menu.Item>
          </Menu.Menu>
        </Menu>
        {isVisible && (
          <UserMobileNavbarContent user={user} onSignOut={onSignOut} />
        )}
      </Grid.Column>
    </Grid.Row>
  );
};

UserMobileNavbar.propTypes = {
  pathname: PropTypes.string.isRequired,
  isVisible: PropTypes.bool.isRequired,
  onBarsClicked: PropTypes.func.isRequired,
  onSignOut: PropTypes.func.isRequired,
  user: PropTypes.shape({
    username: PropTypes.string,
    picture: PropTypes.string,
    role: PropTypes.string
  }).isRequired
};

export default UserMobileNavbar;
