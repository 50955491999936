class ApplicationService {
  constructor(client) {
    this.client = client;
  }

  async postApplicationToBecomeCurator(description) {
    try {
      const response = await this.client.instance.post(
        '/applications/curator',
        { description }
      );
      return response.data.data;
    } catch (error) {
      this.client.handleResponseError(
        'Error creating new application to become curator',
        error
      );
    }
  }

  async #getAllCuratorApplicationsForUser(page) {
    try {
      const response = await this.client.instance.get('/applications/curator', {
        params: { page }
      });
      return response.data.data;
    } catch (error) {
      this.client.handleResponseError(
        "Error fetching all user's curator applications",
        error
      );
    }
  }

  getAllCuratorApplicationsForUser(page = 1) {
    return {
      key: ['curator-applications-for-user', page],
      fn: () => this.#getAllCuratorApplicationsForUser(page)
    };
  }

  async #getActiveCuratorApplicationForUser() {
    try {
      const response = await this.client.instance.get(
        '/applications/curator/active'
      );
      return response.data.data;
    } catch (error) {
      if (error.response?.status === 404) {
        return null;
      }

      this.client.handleResponseError(
        "Error fetching user's active curator application",
        error
      );
    }
  }

  getActiveCuratorApplicationForUser() {
    return {
      key: ['active-curator-application'],
      fn: () => this.#getActiveCuratorApplicationForUser()
    };
  }

  async #getAllCuratorApplications(active, page) {
    try {
      const response = await this.client.instance.get('/admin/applications/curator', {
        params: { page, active }
      });
      return response.data.data;
    } catch (error) {
      this.client.handleResponseError(
        'Error fetching all curator applications',
        error
      );
    }
  }

  getAllCuratorApplications(active = false, page = 1) {
    return {
      key: ['curator-applications', active, page],
      fn: () => this.#getAllCuratorApplications(active, page)
    };
  }

  async #getCuratorApplicationById(id) {
    try {
      const response = await this.client.instance.get(
        `/admin/applications/curator/${id}`
      );
      return response.data.data;
    } catch (error) {
      this.client.handleResponseError(
        `Error fetching curator application with id: ${id}`,
        error
      );
    }
  }

  getCuratorApplicationById(id) {
    return {
      key: ['curator-application', id],
      fn: () => this.#getCuratorApplicationById(id)
    };
  }

  async processCuratorApplicationById({ id, accept }) {
    try {
      const response = await this.client.instance.post(
        `/admin/applications/curator/${id}/process`,
        null,
        {
          params: {
            accept
          }
        }
      );
      return response.data.data;
    } catch (error) {
      this.client.handleResponseError(
        `Error processing curator application with id: ${id}`,
        error
      );
    }
  }
}

export default ApplicationService;
