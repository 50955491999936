import React from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from 'semantic-ui-react';
import NavbarDropdownMenuItem from './NavbarDropdownMenuItem';
import NavbarDropdownMenuTrigger from './NavbarDropdownMenuTrigger';

const NavbarDropdownMenu = ({ items, text, inverted, linkTo }) => {
  return (
    <Dropdown
      className={`navbar__dropdown-container button ${
        inverted && 'custom-inverted'
      }`}
      trigger={<NavbarDropdownMenuTrigger text={text} linkTo={linkTo} />}
      button
      open={false}
      icon={null}
    >
      <Dropdown.Menu>
        {items.map((item, i) => (
          <NavbarDropdownMenuItem
            key={i}
            link={item.link}
            title={item.title}
            description={item.description}
            sectionQueryParam={item.sectionQueryParam}
          />
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

NavbarDropdownMenu.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      link: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      description: PropTypes.string
    })
  ).isRequired,
  text: PropTypes.string.isRequired,
  inverted: PropTypes.bool,
  linkTo: PropTypes.string.isRequired
};

NavbarDropdownMenu.defaultProps = {
  inverted: null
};

export default NavbarDropdownMenu;
