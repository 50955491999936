import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button } from 'semantic-ui-react';
import { useMutation } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import TransformationItemContainer from '../TranformationItemContainer';
import ImageSection from '../sections/ImageSection';
import MainSection from '../sections/MainSection';
import useAPI from '../../../../hooks/api';
import useLocale from '../../../../hooks/i18n';
import { DYNAMIC_PROTECTED_ROUTES } from '../../../../constants/routes';

const AwaitingCurationTransformationItem = ({ aggregatedTransformation }) => {
  const { id_transformation, metadata, author, transformation_obj } = aggregatedTransformation;

  const { t } = useLocale();
  const navigate = useNavigate();
  const { client, getFullResponseErrorMessage, queryClient } = useAPI();
  const createCurationMutation = useMutation((id) => client.curation.postCuration(id));

  const [modalOpen, setModalOpen] = useState(false);
  const [error, setError] = useState(null);

  const handleClick = async () => {
    try {
      const data = await createCurationMutation.mutateAsync(id_transformation);
      navigate(DYNAMIC_PROTECTED_ROUTES.editCuration(data.id));
      await queryClient.invalidateQueries({
        predicate: (query) => {
          const keyCuration = ['curation', 'ongoing'];
          const keyTransformation = ['transformations', 'awaiting-curation'];
          return keyCuration.every((e) => query.queryKey.includes(e)) || keyTransformation.every((e) => query.queryKey.includes(e));
        }
      });
    } catch (error) {
      setError(error);
      setModalOpen(true);
    }
  };

  const handleModalClose = () => {
    setModalOpen(false);
    setError(null);
  };

  return (
    <Fragment>
      <TransformationItemContainer>
        <ImageSection
          picture={metadata.picture}
        />
        <MainSection
          title={transformation_obj.basic_information?.producing_species_name}
          subtitle={transformation_obj.basic_information?.organic_material_name}
          description={transformation_obj.basic_information?.brief_biomass_description}
          author={author}
          onClick={handleClick}
        />
      </TransformationItemContainer>

      <Modal
        open={modalOpen}
        onClose={handleModalClose}
      >
        <Modal.Header>
          {t('awaiting_curations.awaiting_curation.post.error_modal.title')}
        </Modal.Header>

        <Modal.Content>
          {getFullResponseErrorMessage(error)}
        </Modal.Content>

        <Modal.Actions>
          <Button onClick={handleModalClose}>
            {t('awaiting_curations.awaiting_curation.post.error_modal.buttons.close')}
          </Button>
        </Modal.Actions>
      </Modal>
    </Fragment>
  );
};

AwaitingCurationTransformationItem.propTypes = {
  aggregatedTransformation: PropTypes.shape({
    id_transformation: PropTypes.number.isRequired,
    transformation_obj: PropTypes.shape({
      basic_information: PropTypes.shape({
        producing_species_name: PropTypes.string,
        organic_material_name: PropTypes.string,
        brief_biomass_description: PropTypes.string
      })
    }).isRequired,
    author: PropTypes.shape({
      family_name: PropTypes.string.isRequired,
      given_name: PropTypes.string.isRequired,
      picture: PropTypes.string.isRequired,
      username: PropTypes.string.isRequired
    }).isRequired,
    metadata: PropTypes.shape({
      picture: PropTypes.string.isRequired
    }).isRequired
  }).isRequired
};

export default AwaitingCurationTransformationItem;
