/* eslint-disable max-lines */

const PAGE_TITLES = {
  'page_titles.home': 'Home',
  'page_titles.login': 'Login',
  'page_titles.forgot_password': 'Forgot your Password?',
  'page_titles.my_contributions': 'My Contributions',
  'page_titles.not_found': 'Page Not Found',
  'page_titles.coming_soon': 'Coming Soon',
  'page_titles.resend_verification': 'Resend your Verification Code',
  'page_titles.sign_up': 'Sign Up',
  'page_titles.user_settings': 'User Settings',
  'page_titles.awaiting_curation': '{count, plural, one {1 Transformation is} other {# Transformations are}} Waiting to Be Curated',
  'page_titles.admin_dashboard': 'Admin Dashboard',
  'page_titles.curate_contribution': 'Curating Transformation {id}',
  'page_titles.edit_contribution': 'Editing Transformation {id}',
  'page_titles.become_curator': 'Become a Curator',
  'page_titles.become_curator_application': 'Application Form to Become a Curator',
  'page_titles.explore.no_query': 'Explore Transformations',
  'page_titles.explore.with_query': 'Searching {query}',
  'page_titles.loading': 'Loading...',
  'page_titles.terms_and_conditions': 'Terms and Conditions',
  'page.titles.about': 'About Us'
};

const NAVBAR = {
  'navbar.home': 'Home',
  'navbar.explore': 'Explore',
  'navbar.about': 'About Us',
  'navbar.login': 'Log in',
  'navbar.signup': 'Sign Up',
  'navbar.profile': 'Profile',
  'navbar.admin': 'Admin',
  'navbar.become_curator': 'Become Curator',
  'navbar.settings': 'Settings',
  'navbar.sign_out': 'Sign out',
  'navbar.curations': 'Curate',
  'navbar.publish': 'Publish',
  'navbar.login_signup': 'Log In / Sign Up',
  'navbar.get_involved': 'Get Involved',

  'navbar.dropdown.profile': 'Profile',
  'navbar.dropdown.settings': 'Settings',
  'navbar.dropdown.sign_out': 'Sign out',
  'navbar.dropdown.curations': 'Curate',
  'navbar.dropdown.publish': 'Publish',
  'navbar.dropdown.admin': 'Admin',
  'navbar.dropdown.become_curator': 'Become Curator',
  'navbar.dropdown.explore.opportunities.title': 'Opportunities',
  'navbar.dropdown.explore.opportunities.description': 'Crowdsourcing, search and share',
  'navbar.dropdown.explore.publish.title': 'Publish',
  'navbar.dropdown.explore.publish.description': 'Contribute with transformations',
  'navbar.dropdown.about.faq.title': 'FAQ',
  'navbar.dropdown.about.faq.description': 'Clear your doubts',
  'navbar.dropdown.about.contact.title': 'Contact us',
  'navbar.dropdown.about.contact.description': 'Our communication channels',
  'navbar.dropdown.get_involved.partnership.title': 'Partner Up!',
  'navbar.dropdown.get_involved.partnership.description': 'Learn how you can be part of the team.',

  'navbar.search.placeholder': 'Search in Transformar...',
  'navbar.mobile.my_profile': 'My Profile',
  'navbar.mobile.contribute': 'Contribute',
  'navbar.mobile.admin': 'Admin',
  'navbar.mobile.admin.dashboard': 'Dashboard',
  'navbar.mobile.admin.curator_requests': 'Curator Requests',
  'navbar.mobile.admin.resource_management': 'Resource Management'
};

const HOME = {
  'home.first_section.header': 'Transformar, for a sustainable future.',
  'home.first_section.subheader': 'Today\'s research, tomorrow\'s innovation.',
  'home.first_section.btn.publish': 'Publish',
  'home.first_section.hero': 'Contribute to and benefit from the first open knowledge ecosystem on opportunities for the sustainable transformation of biomass in Latin America and the Caribbean.',

  'home.second_section.header': 'Explore or Publish',
  'home.second_section.subheader': 'You can explore and also publish opportunities for transforming biomass into bio-based products, based on scientific research.',

  'home.second_section.card.header.1': 'Explore',
  'home.second_section.card.description.1': 'Find scientific knowledge about opportunities for transforming biomass into bio-based products.',
  'home.second_section.card.header.2': 'Publish',
  'home.second_section.card.description.2': 'Would you like to share your knowledge about how to transform biomass for industrial uses? Publish your research and become a contributor.',
  'home.second_section.card.header.3': 'Verify',
  'home.second_section.card.description.3': 'The more you contribute, the higher your name and institution will appear in our ranking. We invite you to build an incredible opportunity to reveal yourself to the world!',

  'home.third_section.objective.header': 'Our Objective',
  'home.third_section.objective.description': 'Create the largest open catalog of opportunities for the sustainable transformation of biomass into bio-based products, by crowdsourcing scientific knowledge that today is fragmented and scattered across journals and technical publications. This open catalog is aimed at benefiting different types of stakeholders in Latin America and the Caribbean:',
  'home.third_section.icons.private_firms': 'Private Firms',
  'home.third_section.icons.public_institutions': 'Public Institutions',
  'home.third_section.icons.startups': 'Startups',
  'home.third_section.icons.scientific_community': 'Scientific Community',
  'home.third_section.icons.civil_society': 'Civil Society',

  'home.third_section.steps.item.header.1': '1. Signup',
  'home.third_section.steps.item.subheader.1': 'Join Transformar and have access to information reviewed and curated by subject matter experts.',
  'home.third_section.steps.item.btn.signed_in': 'You are already part of the community',
  'home.third_section.steps.item.btn.signup': 'Sign Up',
  'home.third_section.steps.item.btn.login': 'Login',
  'home.third_section.steps.item.header.2': '2. Explore opportunities',
  'home.third_section.steps.item.subheader.2': 'Dive into a virtual forest of opportunities for transforming biomass into bio-based products through search queries.',
  'home.third_section.steps.item.header.3': '3. Share your knowledge',
  'home.third_section.steps.item.subheader.3': 'Share your knowledge and be part of the virtual community of experts. You are contributing to knowledge sharing and decision making that may benefit civil society, researchers, firms, institutions and also our renewable biological resources.',
  'home.third_section.steps.item.header.4': '4. Review & Curate',
  'home.third_section.steps.item.subheader.4': 'Review and curate the content on opportunities contributed by others, helping to keep the quality of the catalog.',
  'home.third_section.steps.item.header.5': '5. Bring it to life',
  'home.third_section.steps.item.subheader.5': 'Use the data to get yourself ahead of the curve. Made for studies, businesses, projects and more.'
};

const ABOUT = {
  'about.first_section.header': 'Transformar aims to facilitate access to science-based opportunities about the transformation of renewable biological resources into bio-based products',
  'about.first_section.subheader': 'Together we can build and curate an open catalog of opportunities that will inform decision makers in Latin America and the Caribbean.',

  'about.second_section.header': 'What is Transformar?',
  'about.second_section.description': 'Transformar is a digital public square for open knowledge sharing about opportunities for the sustainable transformation of renewable biological resources into bio-based products. It aims to support decision making of the different stakeholders of the bioeconomy in Latin America and the Caribbean: public institutions, research community, private sector, civil society, investors, donors and financial institutions.',

  'about.third_section.header': 'Organization',
  'about.third_section.subheader': 'This project was designed and sponsored by the Inter-American Development Bank, and developed and now hosted by the University San Francisco de Quito.',

  'about.third_section.organizations.idb': 'Inter American Development Bank',
  'about.third_section.organizations.idb.description': 'The Inter-American Development Bank is devoted to improving lives. Established in 1959, the IDB is a leading source of long-term financing for economic, social and institutional development in Latin America and the Caribbean. The IDB also conducts cutting-edge research and provides policy advice, technical assistance and training to public- and  private sector clients throughout the region.',
  'about.third_section.organizations.usfq': 'University San Francisco de Quito',
  'about.third_section.organizations.usfq.description': 'Project Team',

  'about.fourth_section.faq.header': 'Frequently Asked Questions (FAQ)',
  'about.fourth_section.faq.subheader': 'We answer your questions. Here are the most frequently asked questions by our community.',
  'about.fourth_section.faq.pdf.subheader': 'Whether you\'re a first-time Transformar’s contributor or a long-time supporter, we understand that you may have questions about our platform. That\'s why we\'ve created this comprehensive resource to help you find the answers you need quickly and easily.',
  'about.fourth_section.faq.pdf.btn.label': 'Download as PDF',
  'about.fourth_section.faq.pdf.filename': 'Frequently Asked Questions - Transformar',
  'about.fourth_section.faq.pdf.quality_criteria_guide.filename': 'Quality Data Guide - Transformar',

  'about.fourth_section.faq.content.first.question': 'How to create my first publication?',
  'about.fourth_section.faq.content.first.answer': 'To share your first publication, you must log in to Transformar with your account. If you are not yet registered, we invite you to do so. Once on the main page, you must click on "Publish" and a window will be displayed where you can see: your transformations in progress, the changes required by curators, and your submissions in the curation process. Next, click on "Add new" and you will be able to access the fields to complete your publication.',
  'about.fourth_section.faq.content.second.question': 'What does it mean to make a new publication?',
  'about.fourth_section.faq.content.second.answer.1': 'Your publication allows you to share opportunities for the use and transformation of biomass into products of commercial value. For this, 6 tabs have been proposed to complete information for this objective to be met.',
  'about.fourth_section.faq.content.second.answer.2': 'The 6 information tabs are:',
  'about.fourth_section.faq.content.second.answer.tabs.1': 'Basic information',
  'about.fourth_section.faq.content.second.answer.tabs.2': 'Transformation process',
  'about.fourth_section.faq.content.second.answer.tabs.3': 'Scalability',
  'about.fourth_section.faq.content.second.answer.tabs.4': 'Sustainable Development',
  'about.fourth_section.faq.content.second.answer.tabs.5': 'Market Potential',
  'about.fourth_section.faq.content.second.answer.tabs.6': 'Other Considerations',
  'about.fourth_section.faq.content.second.answer.3': 'By filling each of these tabs with the requested information, the progress of your publication will be completed.',
  'about.fourth_section.faq.content.third.question': 'What characteristics must my project have to be able to publish in Transformar?',
  'about.fourth_section.faq.content.third.answer': 'Your project must share an opportunity for the use and transformation of primary and residual biomass into products of commercial value, relevant to the countries of Latin America and the Caribbean based on abundant biomass in the region. Your project can be either in the applied research phase (prototype) or in the production phase (commercial), or available to be adopted in production. On the other hand, it must have documented evidence that gives it sufficient support and a technology maturity greater than or equal to TRL5.',
  'about.fourth_section.faq.content.fourth.question': 'How can I participate in the review process of the quality of the publications (opportunities)? ',
  'about.fourth_section.faq.content.fourth.answer': 'If you wish to participate in the review and evaluation process, you must apply to become a reviewer. You will find this option in the main menu. Once your application has been approved, you will receive a notification email. Please note that you will participate in the review process to ensure the information has passed a minimum quality standard. However, the reviewer is not responsible for ensuring that the information in the scientific articles mentioned is correct, as this is the responsibility of the reviewers in the journals where the original research was published.',
  'about.fourth_section.faq.content.fifth.question': 'How is the process of reviewing and evaluating the quality of the information carried out? ',
  'about.fourth_section.faq.content.fifth.answer': 'The information reviewer will proceed to review an opportunity based on the quality criteria of the platform. These quality criteria aim to analyze the information on the opportunities and identify those with more significant potential based on criteria of technical feasibility and economic, social, and environmental value. These criteria include a group that evaluates whether the contribution can be published in Transformar. In contrast, a second group focuses on assessing the quality of the contribution in terms of its potential.',
  'about.fourth_section.faq.content.fifth.answer.download_quality_criteria_guide': 'Download the quality criteria guide here.',

  'about.fifth_section.contact.header': 'Contact Us!',
  'about.fifth_section.contact.subheader': 'Any questions or remarks? Just write us a message!',
  'about.fifth_section.contact.socials.title': 'Follow our social media',
  'about.fifth_section.contact.email.title': 'Send us an email'
};

const SIGNUP = {
  'signup.header': 'Welcome to Transformar™',
  'signup.hero.text': 'Contribute to and benefit from the first open knowledge ecosystem on opportunities for the sustainable transformation of biomass in Latin America and the Caribbean.',
  'signup.already.registered': 'Already have an account? ',
  'signup.login': 'Log in',

  'signup.form.email': 'Email',
  'signup.form.username': 'Username',
  'signup.form.password': 'Password',
  'signup.form.password_confirm': 'Confirm Password',
  'signup.form.given_name': 'Given Name',
  'signup.form.family_name': 'Family Name',
  'signup.form.institution_name': 'Name of Institution',
  'signup.form.institution_position': 'Position at Institution',
  'signup.form.professional_affiliation': 'Professional Affiliation',
  'signup.form.location_country': 'Country',
  'signup.form.location_province': 'State',
  'signup.form.location_city': 'City',

  'signup.errors.email.invalid': 'Invalid email',
  'signup.errors.email.max': 'Email cannot be longer than 256 characters',
  'signup.errors.email.required': 'Email cannot be empty',

  'signup.errors.username.length': 'Username must be between 3 and 20 characters long',
  'signup.errors.username.disallowed': 'Username cannot be "me"',
  'signup.errors.username.pattern': 'Username can only contain letters, numbers and the characters: . _ -',
  'signup.errors.username.required': 'Username cannot be empty',

  'signup.errors.given_name.length': 'Given name must be between 1 and 45 characters long',
  'signup.errors.given_name.required': 'Given name cannot be empty',

  'signup.errors.family_name.length': 'Family name must be between 1 and 45 characters long',
  'signup.errors.family_name.required': 'Family name cannot be empty',

  'signup.errors.institution_name.max': 'Institution name cannot be longer than 256 characters',
  'signup.errors.institution_name.required': 'Institution name cannot be empty',

  'signup.errors.institution_position.max': 'Institution position cannot be longer than 256 characters',
  'signup.errors.institution_position.required': 'Institution position cannot be empty',

  'signup.errors.professional_affiliation.max': 'Professional affiliation cannot be longer than 256 characters',
  'signup.errors.professional_affiliation.required': 'Professional affiliation cannot be empty',

  'signup.errors.location_country.max': 'Country cannot be longer than 256 characters',
  'signup.errors.location_country.required': 'Country cannot be empty',

  'signup.errors.location_province.max': 'State cannot be longer than 256 characters',
  'signup.errors.location_province.required': 'State cannot be empty',

  'signup.errors.location_city.max': 'City cannot be longer than 256 characters',
  'signup.errors.location_city.required': 'City cannot be empty',

  'signup.errors.password.special_chars': 'Password should contain at least 1 special character',
  'signup.errors.password.lowercase': 'Password should contain at least 1 lowercase character',
  'signup.errors.password.uppercase': 'Password should contain at least 1 uppercase character',
  'signup.errors.password.white_spaces': 'Password cannot contain spaces',
  'signup.errors.password.required': 'Password cannot be empty',

  'signup.errors.password_confirm.different': 'Must be the same as password',
  'signup.errors.password_confirm.required': 'Password confirm cannot be empty',

  'signup.signup': 'Sign Up',

  'signup.errors.header': 'Could not sign you up',

  'signup.post.header': 'Thanks for registering {name}!',
  'signup.post.description': 'Please check your inbox ({email}) for a verification email and follow the included instructions.',
  'signup.post.buttons.login': 'Already verified? Click here to login',
  'signup.post.buttons.resend_code': 'Didn\'t receive any verification code?'
};

const LOGIN = {
  'login.header': 'Welcome Back!',
  'login.hero.text': 'Login to your Transformar™ account and continue to participate and benefit from knowledge sharing on opportunities for the transformation of biomass.',

  'login.form.email': 'Email',
  'login.form.password': 'Password',
  'login.form.stay_signed_in': 'Stay signed in',

  'login.errors.email.invalid': 'Invalid email',
  'login.errors.email.required': 'Email cannot be empty',

  'login.errors.password.required': 'Password cannot be empty',

  'login.login': 'Login',

  'login.dont.have.account': 'Don\'t have an account?',
  'login.register': 'Register here',

  'login.links.forgot_password': 'Forgot your password?',
  'login.links.resend_verification': 'Didn\'t receive your verification code?',

  'login.errors.header': 'Could not sign you in'
};

const AUTH = {
  'auth.generic.errors.validation': 'A required validation failed.',
  'auth.generic.errors.unauthorized': 'You are not authorized to do this.',

  'auth.login.errors.external': 'Something wrong happened when trying to log you in. Please try again later.',
  'auth.login.errors.internal': 'Something wrong happened when trying to log you in. If you see this message, please contact a system administrator.',
  'auth.login.errors.wrong_credentials': 'Email or password may be incorrect. Check your credentials and try again.',
  'auth.login.errors.password_reset_required': 'Please reset your password.',
  'auth.login.errors.rate_limit': 'You tried logging in too much, please wait and try again later.',
  'auth.login.errors.confirmation_required': 'Your email has not been verified yet. Please check your inbox.',
  'auth.login.errors.unknown': 'An unknown error happened when trying to log you in. If you see this message, please contact a system administrator.',

  'auth.signup.errors.external': 'Something wrong happened when trying to sign you up. Please try again later.',
  'auth.signup.errors.internal': 'Something wrong happened when trying to sign you up. If you see this message, please contact a system administrator.',
  'auth.signup.errors.invalid_password': 'The password you tried to use is not secure enough.',
  'auth.signup.errors.rate_limit': 'You tried sign up too much, please wait and try again later.',
  'auth.signup.errors.email_exists': 'This email is already in use.',
  'auth.signup.errors.username_exists': 'This username is already in use.',
  'auth.signup.errors.unknown': 'An unknown error happened when trying to sign you up. If you see this message, please contact a system administrator.',

  'auth.confirm_signup.errors.external': 'Something wrong happened when trying to confirm your sign up. Please try again later.',
  'auth.confirm_signup.errors.internal': 'Something wrong happened when trying to confirm your sign up. If you see this message, please contact a system administrator.',
  'auth.confirm_signup.errors.already_exists': 'This email is already in use.',
  'auth.confirm_signup.errors.wrong_code': 'The code you provided is incorrect. Make sure you have inserted the right code and try again.',
  'auth.confirm_signup.errors.code_expired': 'The code provided has expired. Request another verification code and try again.',
  'auth.confirm_signup.errors.rate_limit': 'You tried to confirm your sign up too much, please wait and try again later.',
  'auth.confirm_signup.errors.email_not_found': 'This email is not used by any account.',
  'auth.confirm_signup.errors.unknown': 'An unknown error happened when trying to confirm your sign up. If you see this message, please contact a system administrator.',

  'auth.resend_verification_code.errors.external': 'Something wrong happened when trying to resend you a verification code. Please try again later.',
  'auth.resend_verification_code.errors.internal': 'Something wrong happened when trying to resend you a verification code. If you see this message, please contact a system administrator.',
  'auth.resend_verification_code.errors.delivery_failure': 'The requested verification code could not be sent. Try again later.',
  'auth.resend_verification_code.errors.rate_limit': 'You tried to resend a verification code too much, please wait and try again later.',
  'auth.resend_verification_code.errors.unknown': 'An unknown error happened when trying to resend you a verification code. If you see this message, please contact a system administrator.',

  'auth.global_sign_out.errors.external': 'Something wrong happened when trying to sign you out from all devices. Please try again later.',
  'auth.global_sign_out.errors.internal': 'Something wrong happened when trying to sign you out from all devices. If you see this message, please contact a system administrator.',
  'auth.global_sign_out.errors.password_reset_required': 'You need to reset your password before signing out from all devices.',
  'auth.global_sign_out.errors.rate_limit': 'You tried to sign out from all devices too much, please wait and try again later.',
  'auth.global_sign_out.errors.unknown': 'An unknown error happened when trying to sign you out from all devices. If you see this message, please contact a system administrator.',

  'auth.change_password.errors.external': 'Something wrong happened when trying to change your password. Please try again later.',
  'auth.change_password.errors.internal': 'Something wrong happened when trying to change your password. If you see this message, please contact a system administrator.',
  'auth.change_password.errors.invalid_password': 'Your new password is invalid. Make sure it\'s secure enough and try again.',
  'auth.change_password.errors.password_reset_required': 'You need to reset your password before changing your password.',
  'auth.change_password.errors.rate_limit': 'You tried to change your password too much, please wait and try again later.',
  'auth.change_password.errors.unknown': 'An unknown error happened when trying to change your password. If you see this message, please contact a system administrator.',

  'auth.forgot_password.errors.external': 'Something wrong happened when trying to reset your password. Please try again later.',
  'auth.forgot_password.errors.internal': 'Something wrong happened when trying to reset your password. If you see this message, please contact a system administrator.',
  'auth.forgot_password.errors.delivery_failure': 'The password reset code could not be sent to your email. Try again later.',
  'auth.forgot_password.errors.rate_limit': 'You tried to reset your password too much, please wait and try again later.',
  'auth.forgot_password.errors.unknown': 'An unknown error happened when trying to reset your password. If you see this message, please contact a system administrator.',

  'auth.forgot_password_submit.errors.external': 'Something wrong happened when trying to submit your password reset. Please try again later.',
  'auth.forgot_password_submit.errors.internal': 'Something wrong happened when trying to submit your password reset. If you see this message, please contact a system administrator.',
  'auth.forgot_password_submit.errors.wrong_code': 'The code you provided is incorrect. Make sure you have inserted the right code and try again.',
  'auth.forgot_password_submit.errors.code_expired': 'The code provided has expired. Please reset your password again.',
  'auth.forgot_password_submit.errors.invalid_password': 'Your new password is invalid. Make sure it\'s secure enough and try again.',
  'auth.forgot_password_submit.errors.rate_limit': 'You tried to reset your password too much, please wait and try again later.',
  'auth.forgot_password_submit.errors.user_not_confirmed': 'Your email is not yet verified. Please check your inbox for a verification email.',
  'auth.forgot_password_submit.errors.unknown': 'An unknown error happened when trying to submit your password reset. If you see this message, please contact a system administrator.'
};

const RESEND_VERIFICATION = {
  'resend_verification.header': 'Resend your verification code',
  'resend_verification.description': 'Enter your email address here. If an account is associated to this email, a verification message will be sent to your inbox. Once you\'ve received the message, follow the instructions inside.',

  'resend_verification.form.email': 'Email',
  'resend_verification.form.submit': 'Resend Verification',

  'resend_verification.success.header': 'Check your inbox',
  'resend_verification.success.description': 'If this email corresponds to a valid registered email then you should receive a message with further instructions.',

  'resend_verification.errors.header': 'Could not resend you a verification code',

  'resend_verification.errors.email.invalid': 'Invalid email',
  'resend_verification.errors.email.required': 'Email cannot be empty'
};

const FORGOT_PASSWORD = {
  'forgot_password.header': 'Forgot your password?',
  'forgot_password.description': 'Enter your email address here. If an account is associated to this email, a code will be sent to your inbox which will be used to reset your password.',

  'forgot_password.form.email': 'Email',
  'forgot_password.form.code': 'Code',
  'forgot_password.form.password': 'Password',
  'forgot_password.form.password_confirm': 'Confirm Password',
  'forgot_password.form.submit': 'Reset Password',

  'forgot_password.errors.header': 'Could not reset your password',

  'forgot_password.errors.email.invalid': 'Invalid email',
  'forgot_password.errors.email.required': 'Email cannot be empty',

  'forgot_password.errors.code.required': 'Code cannot be empty',

  'forgot_password.errors.password.special_chars': 'Password should contain at least 1 special character',
  'forgot_password.errors.password.lowercase': 'Password should contain at least 1 lowercase character',
  'forgot_password.errors.password.uppercase': 'Password should contain at least 1 uppercase character',
  'forgot_password.errors.password.white_spaces': 'Password cannot contain spaces',
  'forgot_password.errors.password.required': 'Password cannot be empty',

  'forgot_password.errors.password_confirm.different': 'Must be the same as password',
  'forgot_password.errors.password_confirm.required': 'Password confirm cannot be empty'
};

const EXPLORE = {
  'explore.errors.header': 'Could not fetch transformations',
  'explore.search_button.label': 'Search',
  'explore.published_transformations.header': 'Opportunities',

  'explore.search.result.empty.header': 'No opportunities found.',
  'explore.search.result.empty.description': 'No opportunities have been found that satisfy your search criteria.'
};

const TRANSFORMATION = {
  'transformation.item.defaults.title': 'Unnamed Transformation',
  'transformation.item.defaults.description': 'No description available.',
  'transformation.item.defaults.subtitle': 'No raw organic name provided.',

  'transformation.form.error.header': 'Could not get the required data for the form.',
  'transformation.form.heading.message.header': 'How to fill this section',

  'transformation.form.curation.header': 'What our curators said about this section',
  'transformation.form.curation.empty.text': 'Everything in this section is OK, no need for updates.',

  'transformation.form.image.title': 'Image',
  'transformation.form.image.picker.header': 'Pick an image for your document.',
  'transformation.form.image.picker.errors.header': 'Something went wrong updating transformation image',
  'transformation.form.image.picker.change_image': 'Change image',
  'transformation.form.image.picker.click_to_add_image': 'Click to add an image',

  /* TAB 1 - Basic Information */
  'transformation.form.basic_information.title': 'Basic Information',
  'transformation.form.basic_information.heading': 'Please provide as much information as possible regarding the specific organic material/biomass (e.g. cocoa husks) used in ONE transformation process/product. If you would like to provide more than one transformation process for the same organic material, please publish a new opportunity. If more than one biomass is used, please describe them in the field "brief biomass description".',

  'transformation.form.basic_information.producing_species_name.label': 'Species',
  'transformation.form.basic_information.producing_species_name.hint': 'The scientific name of the main species source of biomass',
  'transformation.form.basic_information.producing_species_name.hint.example': 'e.g. Cacao theobroma',
  'transformation.form.basic_information.producing_species_name.errors.max': 'Cannot be longer than 256 characters.',

  'transformation.form.basic_information.common_names.label': 'Common Names',
  'transformation.form.basic_information.common_names.hint': 'Add a comma to separate different names.',
  'transformation.form.basic_information.common_names.hint.example': 'e.g. for Cacao theobroma, "cocoa"',
  'transformation.form.basic_information.common_names.errors.max': 'Cannot be longer than 256 characters.',
  'transformation.form.basic_information.common_names.errors.required': 'Common names are required.',
  'transformation.form.basic_information.common_names.errors.min': 'At least one common name is required.',

  /* Modified Field - selection from options: natural, residual, produced */
  'transformation.form.basic_information.biomass_class.label': 'Biomass Class',
  'transformation.form.basic_information.biomass_class.hint': 'Natural Biomass: Produced by nature, without human intervention\n' +
    'Residual Biomass: Organic residues that come from human activities (e.g. Urban solid residues)\n' +
    'Produced biomass: The energy crops, biomass cultivation only for energy sources.',
  'transformation.form.basic_information.biomass_class.options.natural': 'Natural Biomass',
  'transformation.form.basic_information.biomass_class.options.residual': 'Residual Biomass',
  'transformation.form.basic_information.biomass_class.options.produced': 'Produced Biomass',
  'transformation.form.basic_information.biomass_class.errors.max': 'Cannot be longer than 3 characters.',

  'transformation.form.basic_information.organic_material_name.label': 'Name of Raw Organic Material',
  'transformation.form.basic_information.organic_material_name.hint': 'Part(s) or component(s) of the main species used in the transformation process/product. Add a comma to separate different names.',
  'transformation.form.basic_information.organic_material_name.hint.example': 'e.g. cocoa hulls, cocoa husks, cocoa nibs, mucilage.',
  'transformation.form.basic_information.organic_material_name.errors.max': 'Cannot be longer than 256 characters.',
  'transformation.form.basic_information.organic_material_name.errors.required': 'Organic material name is required.',

  'transformation.form.basic_information.source_type.label': 'Source Type',
  'transformation.form.basic_information.source_type.hint': 'Different sources of main biomass used in your product. e.g. cocoa husks correspond to  "Agricultural residue"',
  'transformation.form.basic_information.source_type.errors.base': 'Source type must be an integer.',

  'transformation.form.basic_information.source_class.label': 'Source Class',
  'transformation.form.basic_information.source_class.hint': '"Cocoa husks" is derived from "Cocoa and cocoa preparations" (Source Class). The HS code is used as the standard reference.',
  'transformation.form.basic_information.source_class.errors.base': 'Source class must be an integer.',

  'transformation.form.basic_information.source_sub_class.label': 'Source Subclass',
  'transformation.form.basic_information.source_sub_class.hint': 'Source Subclass corresponds to a subclassification of "Source Class" that addresses specific biomass sources. e.g. cocoa husks fall into "Agricultural residue,  Source Class "Cocoa and cocoa preparations", Source Subclass "Husks".',
  'transformation.form.basic_information.source_sub_class.errors.base': 'Source class must be an integer.',

  'transformation.form.basic_information.location.label': 'Location',
  'transformation.form.basic_information.location.hint': 'The geographic location of the project',
  'transformation.form.basic_information.location.errors.base': 'Location must be an integer.',

  'transformation.form.basic_information.brief_biomass_description.label': 'Brief Biomass Description',
  'transformation.form.basic_information.brief_biomass_description.hint': 'Add additional information relevant to the producing species. If more than one biomass is used, please describe them in this field.',
  'transformation.form.basic_information.brief_biomass_description.errors.max': 'Cannot be longer than 350 characters.',
  'transformation.form.basic_information.brief_biomass_description.errors.required': 'Brief biomass description is required.',

  'transformation.form.basic_information.current_uses_or_transformations.label': 'Broadly known use or transformation',
  'transformation.form.basic_information.current_uses_or_transformations.hint': 'If a product or transformation is broadly used with your biomass, type it here. Add a comma to separate each different concept.',
  'transformation.form.basic_information.current_uses_or_transformations.hint.example': 'e.g Cocoa is generally used to prepare: chocolate, cocoa butter, cocoa liquor, and cocoa powder. If you produce e.g. "nanolignin"  from cocoa DO NOT include novel or non-common applications here.',
  'transformation.form.basic_information.current_uses_or_transformations.errors.max': 'Cannot be longer than 256 characters.',

  'transformation.form.basic_information.current_negative_impact.label': 'Current Negative Impact If Any',
  'transformation.form.basic_information.current_negative_impact.hint': 'The social, environmental or economic negative impact of the use or not use of biomass or transformation.',
  'transformation.form.basic_information.current_negative_impact.errors.max': 'Cannot be longer than 350 characters.',
  'transformation.form.basic_information.current_negative_impact.errors.required': 'Current negative impacts are required.',

  'transformation.form.basic_information.trl_eu.label': 'Technology Readiness Level (TLR-EU)',
  'transformation.form.basic_information.trl_eu.hint': 'Please select state of the art in the matter of the selected transformation process. This database do not include technologies below TLR5.  TLR5: Technology validated in relevant environment, TLR6: Technology demonstrated in relevant environment, TLR7: System model or prototype demonstration in operational environment, TLR8: System/model produced and qualified. An example might include the knowledge generated from TRL 7 being used to manufacture an actual system/model, which is subsequently qualified in an operational environment. In most cases, this TRL represents the end of development. TLR9: Actual system proven in operational environment.',
  'transformation.form.basic_information.trl_eu.errors.base': 'Technology Readiness Level must be an integer.',

  /* TAB 2 - Transformation Process */
  'transformation.form.transformation_process.title': 'Transformation Process',
  'transformation.form.transformation_process.heading': 'Please fill out the following sections for your selected organic material transformation process. If you would like to provide more than one transformation process for the same organic material, please publish a new opportunity. Type N/A if you do not have information.',

  'transformation.form.transformation_process.brief_description_of_transformation_process': 'Brief Description of the Transformation Process',
  'transformation.form.transformation_process.brief_description_of_transformation_process.hint': 'Add a brief description in common language of the transformation process.',
  'transformation.form.transformation_process.brief_description_of_transformation_process.errors.max': 'Brief Description of Transformation Process cannot be longer than 350 characters.',
  'transformation.form.transformation_process.brief_description_of_transformation_process.errors.required': 'Brief Description of Transformation Process is required.',

  'transformation.form.transformation_process.evidences.label': 'Documentary Evidence',
  'transformation.form.transformation_process.evidences.hint': 'e.g. papers, patents, reports, and dissertations. URL description. Describe each URL briefly.',

  'transformation.form.transformation_process.evidence_url.label': 'Research Evidence URL',
  'transformation.form.transformation_process.evidence_url.hint': 'e.g. URLs of papers, patents, reports, dissertations',
  'transformation.form.transformation_process.evidence_url.errors.uri': 'A valid URL is required.',
  'transformation.form.transformation_process.evidence_url.errors.max': 'Cannot be longer than 512 characters.',

  'transformation.form.transformation_process.publishing_year.label': 'Publication Year',
  'transformation.form.transformation_process.publishing_year.errors.base': 'Publication year must be an integer.',
  'transformation.form.transformation_process.publishing_year.errors.positive': 'Publication year must be positive.',

  'transformation.form.transformation_process.url_description.label': 'URL Description',
  'transformation.form.transformation_process.url_description.hint': 'Describe each URL briefly',
  'transformation.form.transformation_process.url_description.errors.max': 'Cannot be longer than 1024 characters.',

  'transformation.form.transformation_process.developed_technologies.label': 'Transformation Process Developed',
  'transformation.form.transformation_process.developed_technologies.hint': 'Transformation is the set of sequential operations that follows a raw material until it becomes the desired product with added value (Levenspiel, 2004). Ref. Levenspiel, O. (2004). Ingeniería de las Reacciones Químicas (Third Edition). Limusa Wiley. You can add more than one transformation process. Add a comma to separate different transformation processes.',
  'transformation.form.transformation_process.developed_technologies.hint.example': 'e.g. Acid hydrolysis using oxalic acid.',
  'transformation.form.transformation_process.developed_technologies.errors.max': 'Cannot be longer than 256 characters.',
  'transformation.form.transformation_process.developed_technologies.errors.required': 'Transformation Process Developed is required.',

  'transformation.form.transformation_process.products_or_services.label': 'Resulting Product or Service',
  'transformation.form.transformation_process.products_or_services.hint': 'List current or potential products/services. Use commas to separate each potential product/service.',
  'transformation.form.transformation_process.products_or_services.hint.example': 'e.g. Fibrillated nanocelullose',
  'transformation.form.transformation_process.products_or_services.errors.max': 'Cannot be longer than 256 characters.',
  'transformation.form.transformation_process.products_or_services.errors.required': 'Product or service is required.',

  'transformation.form.transformation_process.industrial_applications.label': 'Industrial Applications',
  'transformation.form.transformation_process.industrial_applications.hint': 'List the Industrial applications and other potential applications.',
  'transformation.form.transformation_process.industrial_applications.hint.example': 'e.g. drinking water filtration systems, optical devices, biomedical materials, biosensors, or green electronics. Use commas to separate each industrial application.',
  'transformation.form.transformation_process.industrial_applications.errors.max': 'Cannot be longer than 256 characters.',
  'transformation.form.transformation_process.industrial_applications.errors.required': 'Industrial application is required.',

  'transformation.form.transformation_process.associated_pollutants.label': 'Are there any contaminant materials that could affect the transformation process or resulting product?',
  'transformation.form.transformation_process.associated_pollutants.hint': 'Use of harsh reagents and generation of by-products with negative environmental impact.',
  'transformation.form.transformation_process.associated_pollutants.hint.example': 'e.g. Oxalic acid. Use commas to separate each associated pollutant.',
  'transformation.form.transformation_process.associated_pollutants.errors.max': 'Cannot be longer than 256 characters.',
  'transformation.form.transformation_process.associated_pollutants.errors.required': 'Contaminant materials are required.',

  'transformation.form.transformation_process.patents_associated.label': 'Are there any patents associated to this process or related technology?',
  'transformation.form.transformation_process.patents_associated.hint': 'This field aims to know if there are potential or current industrial interests/opportunities in technology. If patents exist, the interested part will look into specialized databases such as PatentScope.',
  'transformation.form.transformation_process.patents_associated.errors.max': 'Cannot be longer than 3 characters.',

  'transformation.form.transformation_process.licenses_emitted.label': 'Has this patent been licensed to a Third-Party?',
  'transformation.form.transformation_process.licenses_emitted.hint': 'Licensing agreements are legally binding contracts where the owner of intellectual property (IP) in a valuable technology (the licensor) permits someone else (the licensee) the permission to use that IP in ways (terms) that are spelled out in the agreement WIPO. (n.d.). Technology Transfer Agreements. Retrieved October 31, 2022, from https://www.wipo.int/technology-transfer/en/agreements.html. This field aims to know if there are potential or current industrial interests/opportunities in technology. If a license has been emitted, it will indicate a stronger interest in technology.',
  'transformation.form.transformation_process.licenses_emitted.errors.max': 'Cannot be longer than 3 characters.',

  'transformation.form.transformation_process.free_access.label': 'Is there free access to the intellectual property regarding this transformation process?',
  'transformation.form.transformation_process.free_access.hint': 'If you answer "Yes", mark the next two questions as "No"',
  'transformation.form.transformation_process.free_access.errors.max': 'Cannot be longer than 3 characters.',

  /* TAB 3 - Scalability */
  'transformation.form.scalability.title': 'Scalability',
  'transformation.form.scalability.heading': 'Please fill out the following sections about the sustainability of the proposed transformation process. If you would like to provide more than one transformation process for the same organic material, please publish a new opportunity. Type N/A if you do not have information.',

  'transformation.form.scalability.technical_scalability.label': 'Technical Scalability',
  'transformation.form.scalability.technical_scalability.hint': 'Based on your know-how, this field aims to evaluate the feasibility of scaling up to industrial/commercial stages the developed technology from the technical point of view. "The scalability accuracy is vital to ensure that work conducted on the laboratory scale does not lead to wasted resources and time or in findings that are unable to apply to large-scale applications." (Lim et al., 2021).  Lim, Y. F., Chan, Y. J., Abakr, Y. A., Sethu, V., Selvarajoo, A., Singh, A., Lee, J., & Gareth, M. (2021). Review of biowastes to energy in Malaysia: Current technology, scalability and socioeconomic analysis. Cleaner Engineering and Technology, 4, 100257. https://doi.org/10.1016/J.CLET.2021.10025. Do you have enough information to assemble the commercial stages and determine possible problems and solutions? If you answered "No," please specify the potential barriers and solutions in the following field. ',
  'transformation.form.scalability.technical_scalability.errors.max': 'Cannot be longer than 3 characters.',

  'transformation.form.scalability.technical_scalability_challenges.label': 'Technical Scalability Challenges',
  'transformation.form.scalability.technical_scalability_challenges.hint': 'Describe the main technical scalability challenges and the possible solutions to them.',
  'transformation.form.scalability.technical_scalability_challenges.errors.required': 'Technical scalability challenges are required.',

  'transformation.form.scalability.required_equipment.label': 'Required Equipment',
  'transformation.form.scalability.required_equipment.hint': 'You can add more than one required piece of equipment. Please list the main equipment you consider essential when preparing budgets or planning to implement the technology. Add a comma to separate different pieces of equipment. e.g. biodigester, pressure reactor, industrial ultrasound, hydrolysis tank. Only add important/not standard equipment.',
  'transformation.form.scalability.required_equipment.errors.max': 'Cannot be longer than 256 characters.',
  'transformation.form.scalability.required_equipment.errors.required': 'Required equipment is required.',

  'transformation.form.scalability.required_supplies.label': 'Required Supplies',
  'transformation.form.scalability.required_supplies.hint': 'You can add more than one supply.  Use commas to separate each. Please list the necessary main supplies when preparing budgets or planning to implement the technology. e.g. chemicals, special bio-catalyst, and water. Only add important/not standard supplies.',
  'transformation.form.scalability.required_supplies.errors.max': 'Cannot be longer than 256 characters.',
  'transformation.form.scalability.required_supplies.errors.required': 'Required supplies are required.',

  'transformation.form.scalability.required_skills.label': 'Required Skills',
  'transformation.form.scalability.required_skills.hint': 'You can add more than one required skill. Use commas to separate each. Please list the main skills considered necessary when preparing budgets or planning to implement the technology. e.g. chemist or chemical engineers with experience in scale-up, life cycle assessment, organic synthesis, green chemistry, thermochemical processing of biomass, experience on lignocellulosic grasses, development and operation of large-scale biomass aggregation and conversion infrastructures to produce environmentally safe coal substitute for the power generation industry. Only add important/not standard skills.',
  'transformation.form.scalability.required_skills.errors.max': 'Cannot be longer than 256 characters.',
  'transformation.form.scalability.required_skills.errors.required': 'Required skills are required.',

  'transformation.form.scalability.minimal_scale_production.label': 'Minimal Scale Production',
  'transformation.form.scalability.minimal_scale_production.hint': 'Based on your know-how, this field aims to estimate the minimal scale production to reach an industrial/commercial stage of the developed technology. Calculate the minimal scale production based on revenue, technical, and logistical criteria.',
  'transformation.form.scalability.minimal_scale_production.min_value.label': 'Value',
  'transformation.form.scalability.minimal_scale_production.min_value.errors.base': 'Minimal value must be a number.',
  'transformation.form.scalability.minimal_scale_production.min_value.errors.positive': 'Minimal value must be positive.',
  'transformation.form.scalability.minimal_scale_production.min_unit.label': 'Unit',
  'transformation.form.scalability.minimal_scale_production.min_unit.errors.max': 'Cannot be longer than 128 characters.',

  'transformation.form.scalability.maximum_production_volume.label': 'Maximum Production Volume',
  'transformation.form.scalability.maximum_production_volume.hint': 'Based on your know-how, this field aims to estimate the maximal scale production for the feasibility of scaling up to an industrial/commercial stage of the developed technology. The maximal scale production can be estimated based on the sustainability and available biomass criteria. What is the maximum amount of product that an industry can viably produce?',
  'transformation.form.scalability.maximum_production_volume.max_value.label': 'Value',
  'transformation.form.scalability.maximum_production_volume.max_value.errors.base': 'Maximum value must be a number.',
  'transformation.form.scalability.maximum_production_volume.max_value.errors.positive': 'Maximum value must be positive.',
  'transformation.form.scalability.maximum_production_volume.max_unit.label': 'Unit',
  'transformation.form.scalability.maximum_production_volume.max_unit.errors.max': 'Cannot be longer than 128 characters.',

  'transformation.form.scalability.logistical_scalability.label': 'Logistical Scalability',
  'transformation.form.scalability.logistical_scalability.hint': 'Based on your know-how, this field aims to evaluate the feasibility of scaling up to industrial/commercial stages the developed technology from a logistics point of view. If you answer "Not yet," please specify in the following field the main gaps and barriers and possible solutions.',
  'transformation.form.scalability.logistical_scalability.errors.max': 'Cannot be longer than 3 characters',

  'transformation.form.scalability.logistical_scalability_challenges.label': 'Logistical Scalability Challenges',
  'transformation.form.scalability.logistical_scalability_challenges.hint': 'Here describe the main barriers or challenges and possible alternatives to solve them. If you answered "No" above, please specify the main challenges along with possible solutions in the following field. ',
  'transformation.form.scalability.logistical_scalability_challenges.errors.required': 'Logistical scalability challenges are required.',

  'transformation.form.scalability.economical_scalability.label': 'Economic Scalability',
  'transformation.form.scalability.economical_scalability.hint': 'Based on your know-how, this field aims to estimate the feasibility of scaling up to an industrial/commercial stage the developed technology from an economic point of view. Please consider various aspects of the economic process, processing costs, main cost drivers, biomass raw material analysis, Etc.',
  'transformation.form.scalability.economical_scalability.errors.max': 'Cannot be longer than 3 characters',

  'transformation.form.scalability.economical_scalability_challenges.label': 'Economic Scalability Challenges',
  'transformation.form.scalability.economical_scalability_challenges.hint': 'Describe the economic scalability challenges and possible solutions to them.',
  'transformation.form.scalability.economical_scalability_challenges.errors.required': 'Economical scalability challenges are required.',

  'transformation.form.scalability.annual_availability.label': 'Annual Availability',
  'transformation.form.scalability.annual_availability.hint': 'If applicable, please select the months considered the best crop/specie\'s growing season in the country you selected above (Basic Information Section).',
  'transformation.form.scalability.annual_availability.errors.base': 'Annual availability must be an integer.',

  'transformation.form.scalability.climate.label': 'Climate or Biome',
  'transformation.form.scalability.climate.hint': 'Please select the category that best describes the crop/specie\'s growing condition',
  'transformation.form.scalability.climate.errors.base': 'Climate must be an integer.',

  'transformation.form.scalability.geographical_location.label': 'Geographical Location',
  'transformation.form.scalability.geographical_location.hint': 'Please specify the geographical location of the biomass in the following order: town, city, state, country',

  /* TAB 4 - Sustainable Development */
  'transformation.form.sustainability.title': 'Sustainable Development',
  'transformation.form.sustainability.heading': 'The goal of sustainable development is to meet the present necessities without compromising the future needs. In this section, please provide your understanding on how this process transformation would help or hinder on sustainable development.',

  'transformation.form.sustainability.contributes_species_preservation.label': 'Does this transformation contribute or could contribute to the conservation/preservation/revalorization of the species?',
  'transformation.form.sustainability.contributes_species_preservation.hint': 'For example, with the recovery of nanocellulose from the cocoa husk, cocoa residues can be reutilized for in situ packaging.',
  'transformation.form.sustainability.contributes_species_preservation.errors.max': 'Cannot be longer than 1024 characters.',

  'transformation.form.sustainability.contributes_circular_economy.label': 'Does the transformation contribute or could contribute to the circular economy?',
  'transformation.form.sustainability.contributes_circular_economy.hint': 'e.g., The transformation of the cocoa industry residues to nanocellulose, as advanced material, increases the rentability of this industry. In addition, this transformation process reduces the underused wastes in the environment, preventing pollution and leading to profit from this renewable resource in the energy/water/food nexus. Furthermore,  the energy sector can use nanocellulose and its derivatives as a green nanomaterial electrochemical energy conversion devices. Other opportunities are pharmaceutical/biomedical applications and environmental remediation, as nanocellulose filters can purify contaminated water.',
  'transformation.form.sustainability.contributes_circular_economy.errors.max': 'Cannot be longer than 1024 characters.',

  'transformation.form.sustainability.sustainable_development_goals.label': 'Does this transformation contribute to any Sustainable Development Goal?',
  'transformation.form.sustainability.sustainable_development_goals.hint': 'You can select more than one SDG if needed.',
  'transformation.form.sustainability.sustainable_development_goals.errors.base': 'Sustainable development goals must be numbers.',

  'transformation.form.sustainability.closes_productive_cycle_gap.label': 'Does it close circularity gaps?',
  'transformation.form.sustainability.closes_productive_cycle_gap.hint': 'A life cycle gap analysis identifies circularity gaps about the potential environmental impacts during a product\'s life cycle in terms of system losses between an ideal closed system and the status quo (Dieterle et al., 2018). Dieterle, M., Schäfer, P., & Viere, T. (2018). Life Cycle Gaps: Interpreting LCA Results with a Circular Economy Mindset. Procedia CIRP, 69, 764–768. https://doi.org/10.1016/J.PROCIR.2017.11.058',
  'transformation.form.sustainability.closes_productive_cycle_gap.errors.base': 'Must be true or false.',

  'transformation.form.sustainability.replaces_materials_fossil_origin.label': 'Does it replace materials of fossil fuel origin?',
  'transformation.form.sustainability.replaces_materials_fossil_origin.hint': 'Fossil fuel is a generic term for non-renewable energy sources such as coal, coal products, natural gas, derived gas, crude oil, petroleum products, and non-renewable wastes. These fuels originate from plants and animals that existed in the geological past (for example, millions of years ago) (Eurostat, 2019).  Eurostat. (2019, September 9). Glossary:Fossil fuel - Statistics Explained. https://ec.europa.eu/eurostat/statistics-explained/index.php?title=Glossary:Fossil_fuel',
  'transformation.form.sustainability.replaces_materials_fossil_origin.errors.base': 'Must be true or false.',

  'transformation.form.sustainability.reduces_greenhouse_effect_gasses.label': 'Does it reduce greenhouse gases effect?',
  'transformation.form.sustainability.reduces_greenhouse_effect_gasses.hint': 'Consider if the transformation process decreases the emission of greenhouse gases emitted in at least one of the following things we do: making things (cement, steel, plastic), plugging in (electricity), growing things (plants, animals), getting around (planes, trucks, cargo ships), keeping warm and cool (heating, cooling, refrigeration) (Gates, 2021 ). Gates, B. (2021). How to avoid a climate disaster: the solutions we have and the breakthroughs we need. Knopf.',
  'transformation.form.sustainability.reduces_greenhouse_effect_gasses.errors.base': 'Must be true or false.',

  /* TAB 5 - Profitability & Market Research */
  'transformation.form.market.title': 'Market Potential',
  'transformation.form.market.heading': 'We aim to gather information to evaluate if the process is suitable for building a profitable business model.',

  'transformation.form.market.production_costs_estimation.label': 'Production Costs Estimation',
  'transformation.form.market.production_costs_estimation.hint': 'Estimate the cost of production. Please specify the unit and the product in the units field.',
  'transformation.form.market.production_costs_estimation.hint.example': 'e.g., Kg of paper, L of essential oil',

  'transformation.form.market.production_costs_estimation.production_estimated_value.label': 'Value per Unit',
  'transformation.form.market.production_costs_estimation.production_estimated_value.errors.base': 'Estimate the economic Value per Unit of the main product or service derived from your process transformation. You can use volume/mass or another relevant unit of measurement. (e.g., Kg of paper, L of essential oil).',
  'transformation.form.market.production_costs_estimation.production_estimated_currency.label': 'Currency',
  'transformation.form.market.production_costs_estimation.production_estimated_currency.errors.base': 'Currency must be an integer.',
  'transformation.form.market.production_costs_estimation.production_estimated_unit.label': 'Unit',
  'transformation.form.market.production_costs_estimation.production_estimated_unit.errors.max': 'Cannot be longer than 128 characters.',

  'transformation.form.market.market_analysis.label': 'Market Analysis Summary',
  'transformation.form.market.market_analysis.hint': 'Please give a brief market analysis summary of the main products or services of your process transformation.',

  'transformation.form.market.economical_value_unit_estimation.label': 'Economic Value per Unit Estimation',
  'transformation.form.market.economical_value_unit_estimation.hint': 'Add the fields to copy the links associated to product cost estimation, economical value per unit estimation and market analysis summary. ALSO add a field next to Unit with the publication year.',
  'transformation.form.market.economical_value_unit_estimation.hint.example': 'e.g. Unit selling price is 850 USD/kg of CNC',
  'transformation.form.market.economical_value_unit_estimation.economical_estimated_value.label': 'Estimated Value',
  'transformation.form.market.economical_value_unit_estimation.economical_estimated_value.errors.base': 'Estimated value must be a number.',
  'transformation.form.market.economical_value_unit_estimation.economical_estimated_currency.label': 'Currency',
  'transformation.form.market.economical_value_unit_estimation.economical_estimated_currency.errors.base': 'Currency must be an integer.',
  'transformation.form.market.economical_value_unit_estimation.economical_estimated_unit.label': 'Unit',
  'transformation.form.market.economical_value_unit_estimation.economical_estimated_unit.errors.max': 'Cannot be longer than 128 characters.',

  'transformation.form.market.structure_costs_analysis.label': 'Structure Costs Analysis ',
  'transformation.form.market.structure_costs_analysis.hint': 'Please summarize the structure costs analysis for your process transformation\'s main products or services.',

  'transformation.form.market.referent_region_businesses.label': 'Best Companies in the Industry',
  'transformation.form.market.referent_region_businesses.hint': 'List some of the best companies that use this transformation process or have similar processes. Please separate with commas when adding more than one.',
  'transformation.form.market.referent_region_businesses.errors.max': 'Cannot be longer than 256 characters.',
  'transformation.form.market.referent_region_businesses.errors.required': 'Referent business is required.',

  'transformation.form.market.donors_financing_institutions.label': 'Potential Donors and Financing Institutions',
  'transformation.form.market.donors_financing_institutions.hint': 'Please separate with commas when adding more than one. e.g. Inter-American Development Bank, GIZ, Ministerio de la Producción-Gobierno del Perú.',
  'transformation.form.market.donors_financing_institutions.errors.max': 'Cannot be longer than 256 characters.',
  'transformation.form.market.donors_financing_institutions.errors.required': 'Donor and/or financing institution is required.',

  /* TAB 6 - Other Considerations */
  'transformation.form.regulations.title': 'Other Considerations',
  'transformation.form.regulations.heading': 'Here you can inform about other considerations regarding the proposed process for transforming biomass.',

  'transformation.form.regulations.indigenous_people_logistics.label': 'Role of local and indigenous communities in the production, collection and/or transformation of biomass.',
  'transformation.form.regulations.indigenous_people_logistics.hint': 'What is the role of indigenous people in the process of transformation, source biomass, or benefit from the product or services proposed?',
  'transformation.form.regulations.indigenous_people_logistics.errors.max': 'Cannot be longer than 1024 characters.',

  'transformation.form.regulations.permissions_access_resource.label': 'Permits needed for access to biological and genetic resources',
  'transformation.form.regulations.permissions_access_resource.hint': 'Does a law or regulation exist for accessing biomass resources? If you can, please specify the legislation and in which country is applied. Add a summary.',
  'transformation.form.regulations.permissions_access_resource.errors.max': 'Cannot be longer than 1024 characters.',

  'transformation.form.regulations.regulations.label': 'Regulations',
  'transformation.form.regulations.regulations.hint': 'Should a fundamental law or regulation be taken into account to build a business model from the transformation process  and to run the business?',
  'transformation.form.regulations.regulations.errors.max': 'Cannot be longer than 1024 characters.',

  'transformation.form.regulations.certifications.label': 'Certifications',
  'transformation.form.regulations.certifications.hint': 'Should a critical certification help to build a business model or to run the business? e.g., The Sustainable Biomass Program (SBP) is a certification system designed for woody biomass.',
  'transformation.form.regulations.certifications.errors.max': 'Cannot be longer than 1024 characters.',

  'transformation.form.regulations.quality_risks_standards.label': 'Quality Risks',
  'transformation.form.regulations.quality_risks_standards.hint': 'How can the raw organic material cause quality risks? Add a summary.',
  'transformation.form.regulations.quality_risks_standards.errors.max': 'Cannot be longer than 1024 characters.',

  'transformation.form.regulations.waste_management.label': 'Waste Management',
  'transformation.form.regulations.waste_management.hint': 'How can the waste of the process transformation be managed to attain sustainable development?',
  'transformation.form.regulations.waste_management.errors.max': 'Cannot be longer than 1024 characters.',

  'transformation.form.ui.error.header': 'Could not save your transformation.',
  'transformation.form.ui.next_btn': 'Next',
  'transformation.form.ui.previous_btn': 'Previous',
  'transformation.form.ui.finish_btn': 'Finish',
  'transformation.form.ui.add_btn': 'Add',
  'transformation.form.ui.not_yet': 'Not Yet',
  'transformation.form.ui.yes': 'Yes',
  'transformation.form.ui.no': 'No',
  'transformation.form.ui.dont.know': 'Unsure',
  'transformation.form.ui.save_and_exit': 'Save and exit',

  'transformation.confirm_modal.header': 'Are you done with this transformation?',
  'transformation.confirm_modal.description.1': 'Before your transformation gets published it needs to be checked by one of our expert curators. By clicking confirm you will submit your transformation for reviewal and will not be able to make any changes while this happens.',
  'transformation.confirm_modal.description.2': 'A curator will review your transformation and may request changes before publication is finalized.',
  'transformation.confirm_modal.description.3': 'Do you wish to continue?',
  'transformation.confirm_modal.actions.close.label': 'Close',
  'transformation.confirm_modal.actions.confirm.label': 'Confirm',
  'transformation.confirm_modal.error.header': 'Could not request curation for your transformation',

  'transformation.view.evidences.age_warning': 'This publication is from {age} years ago, it may contain outdated information.'
};

const TRANSFORMATION_VIEW = {
  'transformation_view.crowdsourcing.hints.bookmark': 'Favorites',
  'transformation_view.crowdsourcing.hints.technically_viable': 'Technically Viable',
  'transformation_view.crowdsourcing.hints.economically_viable': 'Economically Viable',
  'transformation_view.crowdsourcing.hints.environmentally_viable': 'Environmentally Viable',
  'transformation_view.crowdsourcing.hints.socially_viable': 'Socially Viable',

  'transformation_view.request.error.header': 'Could not fetch transformation',
  'transformation_view.request.not_found': 'Not found',

  'transformation_view.back_btn': 'GO BACK',

  'transformation_view.sidebar_header.from': 'From: ',
  'transformation_view.sidebar_header.published_by': 'Published by: ',

  'transformation_view.save_btn': 'Save / Print'
};

const SETTINGS = {
  'settings.user.form.success.header': 'User update was successful!',

  'settings.user.form.header': 'Profile Settings',
  'settings.user.form.image_picker.header': 'Pick an image for your profile picture.',
  'settings.user.form.username': 'Username',
  'settings.user.form.given_name': 'Given Name',
  'settings.user.form.family_name': 'Family Name',
  'settings.user.form.institution_name': 'Name of Institution',
  'settings.user.form.institution_position': 'Position at Institution',
  'settings.user.form.professional_affiliation': 'Professional Affiliation',
  'settings.user.form.location_country': 'Country',
  'settings.user.form.location_province': 'State',
  'settings.user.form.location_city': 'City',
  'settings.user.form.bio': 'Bio',
  'settings.user.form.email_notifications_enabled': 'Email Notifications Enabled',
  'settings.user.form.contact.divider': 'Contact & Socials',
  'settings.user.form.socials.phone': 'Phone',
  'settings.user.form.socials.website': 'Website',
  'settings.user.form.socials.facebook': 'Facebook',
  'settings.user.form.socials.twitter': 'Twitter',
  'settings.user.form.socials.instagram': 'Instagram',
  'settings.user.form.socials.linkedin': 'LinkedIn',
  'settings.user.form.yes': 'Yes',
  'settings.user.form.no': 'No',

  'settings.user.form.ui.update': 'Update',

  'settings.user.errors.username.length': 'Username must be between 3 and 20 characters long',
  'settings.user.errors.username.disallowed': 'Username cannot be "me"',
  'settings.user.errors.username.pattern': 'Username can only contain letters, numbers and the characters: . _ -',
  'settings.user.errors.username.required': 'Username cannot be empty',

  'settings.user.errors.given_name.length': 'Given name must be between 1 and 45 characters long',
  'settings.user.errors.given_name.required': 'Given name cannot be empty',

  'settings.user.errors.family_name.length': 'Family name must be between 1 and 45 characters long',
  'settings.user.errors.family_name.required': 'Family name cannot be empty',

  'settings.user.errors.institution_name.max': 'Institution name cannot be longer than 256 characters',
  'settings.user.errors.institution_name.required': 'Institution name cannot be empty',

  'settings.user.errors.institution_position.max': 'Institution position cannot be longer than 256 characters',
  'settings.user.errors.institution_position.required': 'Institution position cannot be empty',

  'settings.user.errors.professional_affiliation.max': 'Professional affiliation cannot be longer than 256 characters',
  'settings.user.errors.professional_affiliation.required': 'Professional affiliation cannot be empty',

  'settings.user.errors.location_country.max': 'Country cannot be longer than 256 characters',
  'settings.user.errors.location_country.required': 'Country cannot be empty',

  'settings.user.errors.location_province.max': 'State cannot be longer than 256 characters',
  'settings.user.errors.location_province.required': 'State cannot be empty',

  'settings.user.errors.location_city.max': 'City cannot be longer than 256 characters',
  'settings.user.errors.location_city.required': 'City cannot be empty',

  'settings.user.errors.phone.pattern': 'Phone can only contain a country code +(1 to 3 digits) and number (max 12 digits)',

  'settings.user.errors.socials_website.max': 'Website cannot be longer than 256 characters',
  'settings.user.errors.socials_website.uri': 'Please enter a valid website  (https://my-website.com)',

  'settings.user.errors.socials_facebook.max': 'Facebook user cannot be longer than 256 characters',

  'settings.user.errors.socials_instagram.max': 'Instagram user cannot be longer than 256 characters',

  'settings.user.errors.socials_twitter.max': 'Twitter user cannot be longer than 256 characters',

  'settings.user.errors.socials_linkedin.max': 'LinkedIn user cannot be longer than 256 characters',

  'settings.user.errors.bio.max': 'Bio cannot be longer than 256 characters',

  'settings.errors.header': 'Could not update your info',
  'settings.user.menu.header': 'User Settings',
  'settings.user.menu.account': 'Account',
  'settings.user.menu.privacy': 'Privacy',
  'settings.user.menu.advanced': 'Advanced'
};

const BECOME_CURATOR = {
  'become_curator.form.description.label': 'Why do you want to become a curator?',
  'become_curator.form.actions.submit': 'Submit',

  'become_curator.form.errors.description.max': 'Description cannot be longer than 512 characters',
  'become_curator.form.errors.description.min': 'Description cannot be shorter than 100 characters',
  'become_curator.form.errors.description.required': 'Description cannot be empty',

  'become_curator.form.errors.header': 'Could not process your application',
  'become_curator.form.success.header': 'Your application was sent successfully',

  'become_curator.link.back_to_applications': 'Back to applications',

  'become_curator.btn.create_new_application': 'Create new application',
  'become_curator.past_applications.errors.header': 'Could not retrieve your past applications',
  'become_curator.past_applications.empty.header': 'You have no past applications',

  'become_curator.past_applications.active.header': 'Active Application: ',
  'become_curator.past_applications.active.empty.header': 'You have no active application',

  'become_curator.past_applications.header': 'Past Applications: ',

  'become_curator.application.header': 'Application Reason: ',
  'become_curator.application.date': 'Date: ',
  'become_curator.application.status': 'Status: ',
  'become_curator.application.status.accepted': 'Accepted',
  'become_curator.application.status.denied': 'Denied',
  'become_curator.application.status.active': 'In review'
};

const ADMIN = {
  'admin.dashboard.curator_requests.link': 'Go to curator requests',

  'admin.dashboard.menu.header': 'Admin Menu',
  'admin.dashboard.menu.dashboard': 'Dashboard',
  'admin.dashboard.menu.curator_requests': 'Curator Requests',
  'admin.dashboard.menu.resource_management': 'Resource Management',

  'admin.curator_requests.header': 'Curator Requests',
  'admin.curator_requests.error.header': 'Could not get the list of curator applications',
  'admin.curator_requests.empty.header': 'No Current Applications',
  'admin.curator_requests.empty.description': 'There are no curator applications at the moment',
  'admin.curator_request.current_role': 'CURRENT ROLE: ',
  'admin.curator_request.active.empty.header': 'No Active Applications',
  'admin.curator_request.active.empty.description': 'There are no active applications at the moment',

  'admin.curator_request.process_application.confirm_modal.error.header': 'Could not process this application',
  'admin.curator_request.process_application.confirm_modal.header.approve': 'Are you sure you want to approve this application?',
  'admin.curator_request.process_application.confirm_modal.header.deny': 'Are you sure you want to deny this application?',
  'admin.curator_request.process_application.confirm_modal.content.approve.1': 'If you approve this application, the user will be promoted to Curator and will have access to comment and review unpublished transformations',
  'admin.curator_request.process_application.confirm_modal.content.deny.1': 'If you deny this application, the user will stay as contributor and will have to submit another application in order to be reviewed again',

  'admin.curator_request.date': 'Date: ',
  'admin.curator_request.detail.header': 'Detail: ',
  'admin.curator_request.ui.approve': 'Approve',
  'admin.curator_request.ui.deny': 'Deny',

  'admin.resource_management.header': 'Resource Management',
  'admin.resource_management.errors.header': 'Could not fetch resources',
  'admin.resource_management.info_message.title': 'What is this section about?',
  'admin.resource_management.info_message.description': 'Below you will find all selectable resources corresponding to transformation document forms. They are divided into 8 categories. You can add or edit resources in all 3 supported languages (english, spanish, and portuguese). Please keep in mind that the edition of a given resource will also affect all published and unpublished transformation documents that had selected that resource. Creating a new resource requires the input of each of the 3 languages.',
  'admin.resource_management.edit_modal.header': 'Edit {category} #{id}',
  'admin.resource_management.edit_modal.errors.header': 'Could not edit resource',
  'admin.resource_management.add_modal.header': 'Add New {category} Resource',
  'admin.resource_management.add_modal.errors.header': 'Could not add new resource',

  'admin.resource_management.table.id': 'ID',
  'admin.resource_management.table.resource_name': 'Resource Name',
  'admin.resource_management.table.short_name': 'Short Name',
  'admin.resource_management.table.actions.add': 'Add',

  'admin.resource_management.table.titles.climates': 'Climates',
  'admin.resource_management.table.titles.currencies': 'Currencies',
  'admin.resource_management.table.titles.source_classes': 'Source Classes',
  'admin.resource_management.table.titles.source_sub_classes': 'Source Sub-Classes',
  'admin.resource_management.table.titles.source_types': 'Source Types',
  'admin.resource_management.table.titles.trl_eu': 'Technology Readiness Level',
  'admin.resource_management.table.titles.sustainable_development_goals': 'Sustainable Development Goals',
  'admin.resource_management.table.titles.locations': 'Locations',

  'admin.resource_management.table.titles.climate': 'Climate',
  'admin.resource_management.table.titles.currency': 'Currency',
  'admin.resource_management.table.titles.currency_unit': 'Currency Unit',
  'admin.resource_management.table.titles.source_class': 'Source Class',
  'admin.resource_management.table.titles.source_sub_class': 'Source Sub-Class',
  'admin.resource_management.table.titles.source_type': 'Source Type',
  'admin.resource_management.table.titles.sustainable_development_goal': 'Sustainable Development Goal',
  'admin.resource_management.table.titles.location': 'Location',
  'admin.resource_management.table.titles.location_short_name': 'Location Short Name',

  'admin.resource_management.modal.form.en.label': 'English {title}',
  'admin.resource_management.modal.form.es.label': 'Spanish {title}',
  'admin.resource_management.modal.form.pt.label': 'Portuguese {title}'
};

const MY_CONTRIBUTIONS = {
  'my_contributions.error.get.header': 'Could not get the list of your contributions.',
  'my_contributions.error.post.header': 'Could not create a new transformation document.',

  'my_contributions.header': 'My Contributions',

  'my_contributions.ongoing.title': 'Ongoing:',
  'my_contributions.ongoing.empty.header': 'No Ongoing Transformations',
  'my_contributions.ongoing.empty.description': 'You have no ongoing transformations.',

  'my_contributions.changes_requested.title': 'Changes Requested:',
  'my_contributions.changes_requested.empty.header': 'No Changes Requested',
  'my_contributions.changes_requested.empty.description': 'No curator has requested changes on any of your transformations.',

  'my_contributions.awaiting_curation.title': 'Waiting for Curation:',
  'my_contributions.awaiting_curation.empty.header': 'No Transformation Waiting for Curation',
  'my_contributions.awaiting_curation.empty.description': 'You have no transformations that need to be curated. Try publishing a transformation to receive feedback from one of our curators.',

  'my_contributions.in_curation.title': 'In Curation:',
  'my_contributions.in_curation.empty.header': 'No Transformation in Curation',
  'my_contributions.in_curation.empty.description': 'You have no transformations that are being curated. If you have a transformation in need of curation, wait until one of curators begins working on your suggested transformation.',

  'my_contributions.add_btn': 'Add new'
};

const AWAITING_CURATIONS = {
  'awaiting_curations.errors.header': 'Could not get the list of awaiting curation transformations.',

  'awaiting_curations.awaiting_curation.title': 'Transformations in Need of Curation',
  'awaiting_curations.awaiting_curation.empty.header': 'No transformations in need of curation',
  'awaiting_curations.awaiting_curation.empty.description': 'There are no transformations that need to be curated.',

  'awaiting_curations.awaiting_curation.post.error_modal.title': 'Could not curate this transformation.',
  'awaiting_curations.awaiting_curation.post.error_modal.buttons.close': 'Close',

  'awaiting_curations.in_curation.title': 'Your Transformations in Curation',
  'awaiting_curations.in_curation.empty.header': 'No transformations in curation',
  'awaiting_curations.in_curation.empty.description': 'You are not curating any transformation currently. You can start curating one by clicking on any of the transformations in the list below.'
};

const EDIT_CONTRIBUTION = {
  'edit_contribution.error.header': 'Could not edit this transformation document.',
  'edit_contribution.error.status.description': 'Transformations can only be updated when they\'re ongoing or have changes requested by a curator.'
};

const DELETE_CONTRIBUTION = {
  'delete_contribution.confirm_modal.header': 'Are you sure you want to delete this transformation document?',
  'delete_contribution.confirm_modal.description.1': 'This process cannot be undone.',

  'delete_contribution.confirm_modal.actions.delete.label': 'Delete',
  'delete_contribution.confirm_modal.actions.close.label': 'Cancel',

  'delete_contribution.error.header': 'Could not delete this transformation document.'
};

const CURATE_CONTRIBUTION = {
  'curate_contribution.error.header': 'Could not curate this transformation document.',

  'curate_contribution.form.error.header': 'Could not curate this transformation.',
  'curate_contribution.form.ui.save_and_exit': 'Save and exit',
  'curate_contribution.form.ui.delete': 'Delete Curation',
  'curate_contribution.form.ui.submit': 'Submit Curation',

  'curate_contribution.form.heading.message.header': "Author's instructions for this section",

  'curate_contribution.form.comment_basic_information.label': 'Any comments on the basic information?',
  'curate_contribution.form.comment_transformation_process.label': 'Any comments on the transformation process?',
  'curate_contribution.form.comment_scalability.label': 'Any comments on the scalability?',
  'curate_contribution.form.comment_sustainability.label': 'Any comments on the sustainability?',
  'curate_contribution.form.comment_market.label': 'Any comments on the market?',
  'curate_contribution.form.comment_regulations.label': 'Any comments on the regulations?',
  'curate_contribution.form.publish.label': 'Is this transformation ready to be published?',

  'curate_contribution.form.errors.deny_comment_check': 'At least one comment needs to be made if you feel like this transformation is not ready to be published.',

  'curate_contribution.modals.delete.header': 'Are you sure you want to delete this ongoing curation?',
  'curate_contribution.modals.delete.description.1': 'This will only delete your ongoing curation, it will not delete the transformation.',
  'curate_contribution.modals.delete.description.2': 'You can always curate this transformation again if no other curator handles it before you do.',
  'curate_contribution.modals.delete.actions.delete.label': 'Delete',
  'curate_contribution.modals.delete.actions.close.label': 'Cancel',
  'curate_contribution.modals.delete.error.header': 'Could not delete this ongoing curation.',

  'curate_contribution.modals.commit.header': 'Is this curation ready to be submitted?',
  'curate_contribution.modals.commit.description.1': 'If you decide to publish this transformation, the author will not see any of your comments. Publishing a transformation makes it visible to anyone on the platform. This process can take up to 30 seconds while we translate the document, please do not close this window until this process finishes.',
  'curate_contribution.modals.commit.description.2': 'If you decide to not publish this transformation, the author will see your comments in the relevant sections and will need to re-submit their document for another revision. You may choose to re-curate the document or let somebody else handle it.',
  'curate_contribution.modals.commit.actions.submit.label': 'Submit',
  'curate_contribution.modals.commit.actions.close.label': 'Cancel',
  'curate_contribution.modals.commit.error.header': 'Could not commit this ongoing curation.'
};

const DELETE_CURATION = {
  'delete_curation.confirm_modal.header': 'Are you sure you want to delete this ongoing curation?',
  'delete_curation.confirm_modal.description.1': 'This will only delete your ongoing curation, it will not delete the transformation.',
  'delete_curation.confirm_modal.description.2': 'You can always curate this transformation again if no other curator handles it before you do.',

  'delete_curation.confirm_modal.actions.delete.label': 'Delete',
  'delete_curation.confirm_modal.actions.close.label': 'Cancel',

  'delete_curation.error.header': 'Could not delete this ongoing curation.'
};

const FOOTER = {
  'footer.copyright': 'USFQ-BID',
  'footer.items.section.header': 'Ready to join our Transformar community? ',
  'footer.items.section.text': 'Let\'s contribute to protect our nature and create a more sustainable future.',
  'footer.items.section.btn.signup': 'Sign Up',
  'footer.items.section.btn.login': 'Login',
  'footer.items.section.btn.publish': 'Publish',
  'footer.items.bar.links.about': 'About',
  'footer.items.bar.links.help': 'Help',
  'footer.items.bar.links.contact': 'Contact',
  'footer.items.bar.links.terms_and_conditions': 'Terms and Conditions'
};

const UI = {
  'ui.loading.loading': 'Loading...',

  'ui.cancel': 'Cancel',
  'ui.confirm': 'Confirm',
  'ui.selected': 'Selected',
  'ui.progress': 'Progress: ',
  'ui.ok': 'Ok',
  'ui.progress.page': '{currentPage} of {pages}'
};

const NOT_FOUND = {
  'not_found.page.subtitle': 'Oops, sorry we can\'t find',
  'not_found.page.something_went_wrong': 'Either something went wrong or the page doesn\'t exist.',
  'not_found.page.back': 'Back to homepage',

  'not_found.user': 'User not found'
};

const COMING_SOON = {
  'coming_soon.header': 'Coming soon',
  'coming_soon.subtitle': 'Under Construction',
  'coming_soon.back.btn': 'Return to Homepage'
};

const FORM = {
  'form.multi_month_picker.january': 'January',
  'form.multi_month_picker.february': 'February',
  'form.multi_month_picker.march': 'March',
  'form.multi_month_picker.april': 'April',
  'form.multi_month_picker.may': 'May',
  'form.multi_month_picker.june': 'June',
  'form.multi_month_picker.july': 'July',
  'form.multi_month_picker.august': 'August',
  'form.multi_month_picker.september': 'September',
  'form.multi_month_picker.october': 'October',
  'form.multi_month_picker.november': 'November',
  'form.multi_month_picker.december': 'December',

  'form.array_form_field.add': 'Add',

  'form.boolean_form_field.default.yes': 'Yes',
  'form.boolean_form_field.default.no': 'No',

  'form.yes_no_idk_form_field.default.yes': 'Yes',
  'form.yes_no_idk_form_field.default.no': 'No',
  'form.yes_no_idk_form_field.default.idk': "Don't Know"
};

const SERVICES = {
  'services.api.errors.unknown.message': 'An unknown error has occurred. Try refreshing the page. If this error keeps happening contact a system administrator.',
  'services.api.errors.not_found.message': 'The requested resource was not found.',
  'services.api.errors.validation.message': 'The data that was sent is not valid. Please check that all the information you have added in the form is correct.',
  'services.api.errors.bad_request.message': 'The server could not respond to the request. Try refreshing the page. If this error keeps happening contact a system administrator.',
  'services.api.errors.forbidden.message': 'You\'re not allowed to see this.',
  'services.api.errors.unauthorized.message': 'You\'re not allowed to see this. Are you logged in to your account?',
  'services.api.errors.invalidated.message': 'It looks like your session has timed out. Try refreshing the page.'
};

const USER_PROFILE = {
  'user_profile.error.header': 'Could not get the information for this user.',
  'user.profile.side_profile.at': 'at',

  'user.profile.contributions.header': 'Opportunities',
  'user.profile.contributions.header.subtitle': 'Check out the latest opportunities',
  'user.profile.contributions.empty.header': 'No opportunities available yet',
  'user.profile.contributions.empty.description': 'This user has not published any opportunity',

  'user.profile.curations.header': 'Curations',
  'user.profile.curations.header.subtitle': 'Check out the latest curations',
  'user.profile.curations.empty.header': 'No curations available yet',
  'user.profile.curations.empty.description': 'This user has not curated any document'
};

const TERMS_AND_CONDITIONS = {
  'terms_and_conditions.title': 'Terms and Conditions',

  'terms_and_conditions.content.1': 'The use of Transformar web site (the “Web Site”) is governed by the terms and conditions set forth below. By using this Web Site you agree to such terms and conditions, as they may be modified by the Inter-American Development Bank (“IDB”) from time-to-time and posted on this Web Site. Certain features or functions of the Web Site, or linked to by the Web Site may be subject to additional or different terms and conditions (“Associated Sites”). Associated Sites may or may not be under the control or influence of IDB. IDB disclaims any responsibility or liability for the content or operation of Associated Sites not actually operated by IDB and emphasizes that users will be subject to the terms and conditions applicable to such Associated Sites, whether or not operated by IDB.',
  'terms_and_conditions.content.2': 'The IDB maintains this Web Site for information purposes only. Users may download, copy and reprint information from the Web Site for non-commercial purposes, subject to any terms and conditions contained within materials expressly attributed to third parties and so long as Transformar is cited as the source of the originating material. Users may not reprint or distribute material for which a third party is identified as the copyright holder, unless permission to do so is expressly permitted for such third party material. Users may not place “frames” around material from the Web Site without the express written authorization of the IDB.',
  'terms_and_conditions.content.3': 'The contents of materials expressly attributed to or identified as being produced by third parties, are the property and responsibility of such third parties and not of the IDB. Use of these documents is subject to the legal terms and conditions contained therein, if any.',
  'terms_and_conditions.content.4': 'This Web Site also contains links to web sites and information not under the control of the IDB. The IDB provides these links and any such information as a convenience for users of its Web Site and is not responsible for their contents. The presence of a link does not imply endorsement or approval of its contents by the IDB.',
  'terms_and_conditions.content.5': 'IDB and its affiliates have established and/or collaborate with other organizations on the establishment and maintenance of other web-based resources. Each of such other web-based resources is subject to terms and conditions that may be in addition to or supplant these terms and conditions.',
  'terms_and_conditions.content.6': 'All information on the Web Site is provided “AS IS”, with no express or implied warranty of any kind, including but not limited to any warranty of merchantability, suitability, non-infringement or fitness for any particular purpose. Use of this Web Site is at user’s sole risk. While the IDB attempts to provide accurate data, information available in the Web Site may use data resources from other sources, including public sources. As such, the IDB does not guarantee or represent that any information is free of any errors, omissions, and/or positional inaccuracies. The IDB makes no warranty as to the accuracy, completeness or timeliness of any of the material on the Web Site and under no circumstances, including but not limited to, negligence, shall the IDB be liable for any direct, indirect, incidental, special or consequential expenses or damages incurred by reason of its use, including expenses or damages arising from communication failure and/or computer malfunctions associated with use of the Web Site.',
  'terms_and_conditions.content.7': 'Information that relates to a particular date is offered only as of such date and the IDB undertakes no obligation to update any material on the Web Site. The IDB may, in its discretion, periodically add, change, improve or update the materials on the Web Site without notice, and reserves the right to limit or discontinue the Web Site or deny any user access thereto without notice.',
  'terms_and_conditions.content.8': 'Maps and any other information of a similar nature found in this Web Site are for reference only, and may not have been prepared for, or be suitable for legal, engineering, surveying or any other specific purpose and/or use. The boundaries, colors, denominations, or any other information shown in any map or otherwise cannot be construed as an opinion on the legal status of any territory, or any endorsement or acceptance of such boundaries.',
  'terms_and_conditions.content.9': 'As a condition of use of the Web Site, the user agrees to indemnify the IDB from and against any and all actions, claims, losses, damages, liabilities and expenses (including reasonable attorneys’ fees) arising out of such user’s use of the Web Site, including without limitation any claims alleging facts that if true would constitute a breach by the user of these terms and conditions. If the user is dissatisfied with any material on the Web Site or with any of terms and conditions of use of the Web Site, such user’s sole and exclusive remedy is to discontinue using the Web Site.',
  'terms_and_conditions.content.10': 'The Web Site is controlled, operated and administered by the IDB from its offices within the United States of America and all data is stored in the United States of America. The IDB makes no representation that materials at the Web Site are appropriate or available for use at other locations outside of the United States of America. Access to the materials on the Web Site at any location where such content may be illegal is prohibited. If you access the Web Site from a location outside of the United States of America, you are solely responsible for compliance with local laws.',
  'terms_and_conditions.content.11': 'Any information submitted to IDB shall be subject to our Privacy Policy, which is incorporated into these terms and conditions, and is subject to change from time to time as set forth in the Privacy Policy. The Web Site provides a means for reporting suspected fraud and other improper conduct. Those reporting may choose not to disclose their identities; however, if they do, their identities will constitute Personal Identifying Information, subject to our Privacy Policy. We may contact those so reporting and may identify them in connection with investigations of the conduct they report to us.',
  'terms_and_conditions.content.12': 'The opinions and findings of authors or compilers contained herein are not necessarily those of the IDB or its member countries. Except as otherwise noted, the material on the Web Site is the property of the IDB and is subject to the copyright, trademark and other intellectual property laws of the United States of America and other jurisdictions. All uses of the Web Site are subject to these laws.',
  'terms_and_conditions.content.13': 'These terms and conditions will be governed, interpreted and enforced in accordance with the laws of the District of Columbia, United States of America, without giving effect to the conflict of laws provisions of that jurisdiction. Any legal action over the contents of the Web Site shall be submitted to and finally resolved by arbitration conducted in the English language in Washington, District of Columbia, United States of America, under the commercial arbitration rules of the American Arbitration Association, provided that IDB reserves the right to apply for temporary, preliminary and final injunctive relief to enforce any of the provisions of these terms and conditions relating to the use of its copyrights, trademarks, other intellectual property rights and data security. All decisions of a single arbitrator shall be final and binding on both the IDB and you and enforceable in any court of competent jurisdiction. The IDB and you otherwise agree to the exclusive jurisdiction of the courts of Washington, District of Columbia, United States of America for the resolution of any disputes not subject to arbitration and for the enforcement of any award resulting from any such arbitration.',
  'terms_and_conditions.content.14': 'No waiver by the IDB of any provision of these terms and conditions shall be binding except as set forth in writing signed by its duly authorized representative.',
  'terms_and_conditions.content.15': 'Nothing in the Web Site shall constitute or be considered to be a limitation upon or a waiver of the privileges and immunities of the IDB, which are specifically reserved.'
};

const strings = {
  ...PAGE_TITLES,
  ...NAVBAR,
  ...HOME,
  ...ABOUT,
  ...SIGNUP,
  ...LOGIN,
  ...AUTH,
  ...RESEND_VERIFICATION,
  ...FORGOT_PASSWORD,
  ...EXPLORE,
  ...TRANSFORMATION,
  ...TRANSFORMATION_VIEW,
  ...SETTINGS,
  ...BECOME_CURATOR,
  ...ADMIN,
  ...MY_CONTRIBUTIONS,
  ...AWAITING_CURATIONS,
  ...EDIT_CONTRIBUTION,
  ...DELETE_CONTRIBUTION,
  ...CURATE_CONTRIBUTION,
  ...DELETE_CURATION,
  ...FOOTER,
  ...UI,
  ...NOT_FOUND,
  ...COMING_SOON,
  ...FORM,
  ...SERVICES,
  ...USER_PROFILE,
  ...TERMS_AND_CONDITIONS
};

export default strings;
