import React from 'react';
import PropTypes from 'prop-types';
import { Outlet, Navigate } from 'react-router-dom';
import { PUBLIC_ROUTES } from '../constants/routes';

const ProtectedRoute = ({ allowed, redirectPath, children }) => {
  if (!allowed) {
    return (
      <Navigate to={redirectPath} replace />
    );
  }

  if (children) {
    return children;
  }

  return (
    <Outlet />
  );
};

ProtectedRoute.propTypes = {
  allowed: PropTypes.bool.isRequired,
  redirectPath: PropTypes.string,
  children: PropTypes.node
};

ProtectedRoute.defaultProps = {
  redirectPath: PUBLIC_ROUTES.login,
  children: null
};

export default ProtectedRoute;
