import React, { useState } from 'react';
import ResourceModalForm from './ResourceModalForm';
import { useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import PropTypes from 'prop-types';
import { useMutation } from '@tanstack/react-query';
import useAPI from '../../../hooks/api';
import { createCompleteSchema, resourceSchemas } from './EditResourceModalLogic';
import useLocale from '../../../hooks/i18n';

const postResourceFunctions = [
  'postClimate',
  'postCurrency',
  'postSourceClass',
  'postSourceSubClass',
  'postSourceType',
  'postTRL',
  'postSustainableDevelopmentGoal',
  'postLocation'
];

const AddResourceModalLogic = ({
  currentFormID,
  open,
  onClose,
  onConfirm,
  header
}) => {
  const { t } = useLocale();
  const form = useForm({
    mode: 'onSubmit',
    resolver: joiResolver(createCompleteSchema(resourceSchemas[currentFormID]))
  });

  const { client, queryClient } = useAPI();
  const [error, setError] = useState(null);

  const addNewResourceMutation = useMutation((data) =>
    client.resource[postResourceFunctions[currentFormID]](
      data
    )
  );

  const handleConfirm = async (data) => {
    if (Object.keys(data).length === 3) {
      try {
        console.log('Will create a new resource the following data: ', data);
        await addNewResourceMutation.mutateAsync(data);
        await queryClient.invalidateQueries(
          client.resource.getAllResources('en').key
        );
        await queryClient.invalidateQueries(
          client.resource.getAllResources('es').key
        );
        await queryClient.invalidateQueries(
          client.resource.getAllResources('pt').key
        );
        onConfirm();
      } catch (error) {
        setError(error.message);
      }
    }
  };

  const handleClose = () => {
    form.reset();
    onClose();
  };

  return (
    <ResourceModalForm
      onClose={handleClose}
      onConfirm={handleConfirm}
      currentFormID={currentFormID}
      header={header}
      open={open}
      form={form}
      error={error}
      errorHeader={t('admin.resource_management.add_modal.errors.header')}
    />
  );
};

AddResourceModalLogic.propTypes = {
  currentFormID: PropTypes.number.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  header: PropTypes.string.isRequired,
  onConfirm: PropTypes.func.isRequired
};

export default AddResourceModalLogic;
