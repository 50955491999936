import React from 'react';
import { useLocation } from 'react-router-dom';
import { PUBLIC_ROUTES } from '../../../constants/routes';
import useAuth from '../../../hooks/auth';
import FooterBasic from './FooterBasic';
import FooterFull from './FooterFull';

export const TRANSFORMAR_SOCIALS = {
  linkedin: 'https://www.linkedin.com/in/transformar-bio-7a2922266',
  twitter: 'https://twitter.com/TransformarBio'
};

const Footer = () => {
  const { signedIn } = useAuth();
  const { pathname } = useLocation();
  const isPathAFullFooterLocation =
    pathname === PUBLIC_ROUTES.home || pathname === PUBLIC_ROUTES.about;

  if (isPathAFullFooterLocation) {
    return <FooterFull signedIn={signedIn} />;
  }

  return <FooterBasic />;
};

export default Footer;
