import React from 'react';
import { Button, Form, Message, Modal } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import ResourceModalFormView from './ResourceModalFormView';
import useLocale from '../../../hooks/i18n';

const formNames = [
  ['name'],
  ['name', 'unit'],
  ['class'],
  ['sub_class'],
  ['type'],
  ['name'],
  ['goal'],
  ['name', 'short_name']
];

const formNamesTitles = [
  ['admin.resource_management.table.titles.climate'],
  ['admin.resource_management.table.titles.currency', 'admin.resource_management.table.titles.currency_unit'],
  ['admin.resource_management.table.titles.source_class'],
  ['admin.resource_management.table.titles.source_sub_class'],
  ['admin.resource_management.table.titles.source_type'],
  ['admin.resource_management.table.titles.trl_eu'],
  ['admin.resource_management.table.titles.sustainable_development_goal'],
  ['admin.resource_management.table.titles.location', 'admin.resource_management.table.titles.location_short_name']
];

const ResourceModalForm = ({
  open,
  onClose,
  onConfirm,
  header,
  currentFormID,
  form,
  error,
  errorHeader
}) => {
  const { t } = useLocale();
  const {
    handleSubmit,
    formState: { isSubmitting }
  } = form;

  return (
    <Modal
      as={Form}
      onSubmit={handleSubmit(onConfirm)}
      open={open}
      className="resource-modal-form"
    >
      <Modal.Header>{header}</Modal.Header>

      <Modal.Content>
        <Message
          hidden={!error}
          error
          header={errorHeader ?? ''}
          content={error}
        />
        <ResourceModalFormView names={formNames[currentFormID]} form={form} titles={formNamesTitles[currentFormID]} />
      </Modal.Content>

      <Modal.Actions>
        <Button
          color="green"
          content={t('ui.ok')}
          type="submit"
          loading={isSubmitting}
          disabled={isSubmitting}
        />
        <Button
          type="button"
          color="blue"
          content={t('ui.cancel')}
          onClick={onClose}
          loading={isSubmitting}
          disabled={isSubmitting}
        />
      </Modal.Actions>
    </Modal>
  );
};

ResourceModalForm.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  header: PropTypes.string.isRequired,
  onConfirm: PropTypes.func.isRequired,
  currentFormID: PropTypes.number.isRequired,
  form: PropTypes.shape({
    formState: PropTypes.shape({
      isSubmitting: PropTypes.bool
    }),
    handleSubmit: PropTypes.func
  }).isRequired,
  error: PropTypes.string,
  errorHeader: PropTypes.string
};

ResourceModalForm.defaultProps = {
  error: null,
  errorHeader: null
};

export default ResourceModalForm;
