/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect, useMemo, useRef } from 'react';
import PageDelimiter from '../../components/common/pageDelimiter';
import sprinkler from '../../assets/images/sprinkler.webp';
import cropsLogo from '../../assets/logo/crops-logo.webp';
import CardContainer from '../../components/ui/cardContainer';
import { Button, Grid, Image } from 'semantic-ui-react';
import idb from '../../assets/logo/idb-logo.webp';
import usfq from '../../assets/logo/usfq-logo.svg';
import Faq from 'react-faq-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TRANSFORMAR_SOCIALS } from '../../components/common/footer/Footer';
import useQueryParams from '../../hooks/queryParams';
import useLocale from '../../hooks/i18n';
import useTitle from '../../hooks/title';

const AboutPage = () => {
  const { t, currentLocale } = useLocale();
  useTitle(t('page.titles.about'));

  const qualityDataGuideHref = `/static/pdf/quality_data_guide_${currentLocale}.pdf`;
  const faqHref = `/static/pdf/faq_${currentLocale}.pdf`;

  const data = useMemo(
    () => ({
      rows: [
        {
          title: t('about.fourth_section.faq.content.first.question'),
          content: <p>{t('about.fourth_section.faq.content.first.answer')}</p>
        },
        {
          title: t('about.fourth_section.faq.content.second.question'),
          content: (
            <Fragment>
              <p>{t('about.fourth_section.faq.content.second.answer.1')}</p>
              <p className="tabs-list-title">{t('about.fourth_section.faq.content.second.answer.2')}</p>
              <ol>
                <li><p>{t('about.fourth_section.faq.content.second.answer.tabs.1')}</p></li>
                <li><p>{t('about.fourth_section.faq.content.second.answer.tabs.2')}</p></li>
                <li><p>{t('about.fourth_section.faq.content.second.answer.tabs.3')}</p></li>
                <li><p>{t('about.fourth_section.faq.content.second.answer.tabs.4')}</p></li>
                <li><p>{t('about.fourth_section.faq.content.second.answer.tabs.5')}</p></li>
                <li><p>{t('about.fourth_section.faq.content.second.answer.tabs.6')}</p></li>
              </ol>
              <p>{t('about.fourth_section.faq.content.second.answer.3')}</p>
            </Fragment>
          )
        },
        {
          title: t('about.fourth_section.faq.content.third.question'),
          content: <p>{t('about.fourth_section.faq.content.third.answer')}</p>
        },
        {
          title: t('about.fourth_section.faq.content.fourth.question'),
          content: <p>{t('about.fourth_section.faq.content.fourth.answer')}</p>
        },
        {
          title: t('about.fourth_section.faq.content.fifth.question'),
          content: (
            <Fragment>
              <p>{t('about.fourth_section.faq.content.fifth.answer')}</p>
              <p>
                <a
                  href={qualityDataGuideHref}
                  download={t('about.fourth_section.faq.pdf.quality_criteria_guide.filename')}
                >
                  {t('about.fourth_section.faq.content.fifth.answer.download_quality_criteria_guide')}
                </a>
              </p>
            </Fragment>
          )
        }
      ]
    }),
    [t]
  );
  const { queryParams } = useQueryParams();
  const scrollTo = queryParams.get('scrollTo');
  const scrollableRefs = {
    faq: useRef(null),
    contact: useRef(null)
  };

  useEffect(() => {
    if (scrollTo) {
      const elementRef = scrollableRefs[scrollTo];
      const visualDelay = setTimeout(() => {
        elementRef?.current?.scrollIntoView({ behavior: 'smooth' });
      }, 275);

      return () => {
        clearTimeout(visualDelay);
      };
    }
  });

  return (
    <div className="about-page">
      <PageDelimiter className="first-section">
        <div className="first-section-header">
          <h1>{t('about.first_section.header')}</h1>
          <h2>{t('about.first_section.subheader')}</h2>
        </div>
        <div className="sprinkler-container">
          <img loading="lazy" src={sprinkler} alt="sprinkler" />
        </div>
      </PageDelimiter>
      <div className="second-section">
        <PageDelimiter className="second-section-delimiter">
          <div className="second-section__crops-logo-container">
            <img
              loading="lazy"
              className="crops-logo"
              src={cropsLogo}
              alt="crops-logo"
            />
          </div>
          <div className="second-section__text">
            <h3>{t('about.second_section.header')}</h3>
            <div className="second-section__explanation">
              <p>{t('about.second_section.description')}</p>
            </div>
          </div>
        </PageDelimiter>
      </div>

      <PageDelimiter className="organization-section third-section">
        <h3>{t('about.third_section.header')}</h3>
        <p className="subtitle">{t('about.third_section.subheader')}</p>
        <div className="organization-section__cards">
          <CardContainer className="organization-section__card">
            <a href="https://www.iadb.org/en" target="_blank" rel="noreferrer">
              <Image loading="lazy" size="medium" src={idb} />
              <h4>{t('about.third_section.organizations.idb')}</h4>
              <p>{t('about.third_section.organizations.idb.description')}</p>
            </a>
          </CardContainer>
          <CardContainer className="organization-section__card usfq-card">
            <a href="https://www.usfq.edu.ec/" target="_blank" rel="noreferrer">
              <Image loading="lazy" size="small" src={usfq} />
              <h4>{t('about.third_section.organizations.usfq')}</h4>
              <p>{t('about.third_section.organizations.usfq.description')}</p>
            </a>
          </CardContainer>
        </div>
      </PageDelimiter>

      <PageDelimiter className="fourth-section faq">
        <h2 ref={scrollableRefs.faq}>{t('about.fourth_section.faq.header')}</h2>
        <p className="subheader">{t('about.fourth_section.faq.subheader')}</p>
        <CardContainer className="faq-container">
          <Faq data={data} />
        </CardContainer>
        <p className="subheader pdf">
          {t('about.fourth_section.faq.pdf.subheader')}
        </p>
        <a href={faqHref} download={t('about.fourth_section.faq.pdf.filename')}>
          <Button color="green" size="huge" className="pdf-download-btn">
            {t('about.fourth_section.faq.pdf.btn.label')}
          </Button>
        </a>
      </PageDelimiter>

      <div className="fifth-section contact">
        <PageDelimiter>
          <h2 ref={scrollableRefs.contact}>
            {t('about.fifth_section.contact.header')}
          </h2>
          <h4>{t('about.fifth_section.contact.subheader')}</h4>
          <Grid className="contact-items-grid" columns={2} stackable>
            <Grid.Column>
              <h3>{t('about.fifth_section.contact.socials.title')}</h3>
              <div className="contact-item__socials">
                <a
                  href={TRANSFORMAR_SOCIALS.linkedin}
                  target="_blank"
                  rel="noreferrer"
                >
                  <FontAwesomeIcon icon="fa-brands fa-linkedin" />
                </a>
                <a
                  href={TRANSFORMAR_SOCIALS.twitter}
                  target="_blank"
                  rel="noreferrer"
                >
                  <FontAwesomeIcon icon="fa-brands fa-twitter" />
                </a>
              </div>
            </Grid.Column>
            <Grid.Column>
              <h3>{t('about.fifth_section.contact.email.title')}</h3>
              <a
                className="mail-btn-container"
                href="mailto:info@transformar.bio"
                target="_blank"
                rel="noreferrer"
              >
                <Button
                  className="mail-btn"
                  color="green"
                  icon="mail"
                  content="info@transformar.bio"
                />
              </a>
            </Grid.Column>
          </Grid>
        </PageDelimiter>
      </div>
    </div>
  );
};

export default AboutPage;
